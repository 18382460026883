import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ImageIcon from "@material-ui/icons/Image";
import Editor from "rich-markdown-editor";
import "./AnnouncementForm.scss";

const AnnouncementForm = (props) => {
    const history = useHistory();
    const [title, setTitle] = useState(props.title || "");
    const [text, setText] = useState(props.text || "");
    const [image, setImage] = useState(props.image || undefined);

    const setBlogImage = async (imageFile) => {
        if (image) {
            await fetch(
                `https://blog.risingstar.blackmint.io/upload/files/${image.id}`,
                {
                    method: "DELETE",
                }
            );
        }

        const data = new FormData();
        data.append("files", imageFile);
        const imgReq = await fetch(
            "https://blog.risingstar.blackmint.io/upload",
            {
                method: "POST",
                body: data,
            }
        );

        const imgRes = await imgReq.json();
        setImage(imgRes[0]);
    };

    return (
        <div className="announcement-form__container">
            <form
                className="announcement-form"
                onSubmit={(e) => {
                    e.preventDefault();

                    if (!text) {
                        alert("Text is required");
                    } else {
                        props
                            .onAnnouncementSubmit(title, text, image, true)
                            .then((id) => history.push(`/announcement/${id}`))
                            .catch((err) => alert(err));
                    }
                }}
            >
                <div className="announcement-form__section">
                    <p className="announcement-form__label">
                        <label htmlFor="image">Choose an image</label>
                    </p>
                    <div>
                        {image ? (
                            <div className="announcement-form__image-container">
                                <img
                                    className="announcement-form__image"
                                    src={`https://blog.risingstar.blackmint.io${image.url}`}
                                    alt={"Uploaded image"}
                                />
                            </div>
                        ) : (
                            <div className="announcement-form__placeholder-image">
                                <div className="announcement-form__placeholder-image-icon-background">
                                    <ImageIcon
                                        className="announcement-form__placeholder-image-icon"
                                        style={{
                                            fontSize: "100px",
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <label
                            htmlFor="image"
                            className="announcement-form__image-field"
                        >
                            Upload
                        </label>
                        <input
                            id="image"
                            type="file"
                            onChange={(e) => setBlogImage(e.target.files[0])}
                            hidden
                        />
                    </div>
                </div>
                <div className="announcement-form__section">
                    <p className="announcement-form__label">
                        <label htmlFor="title">Title</label> (Required)
                    </p>
                    <input
                        className="announcement-form__field"
                        id="title"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Create a title that's eye-catching!"
                        required
                    />
                </div>
                <div className="announcement-form__section announcement-form__text">
                    <p className="announcement-form__label">
                        <label htmlFor="text">Content</label> (Required)
                    </p>
                    <Editor
                        id="text"
                        defaultValue={props.text}
                        onChange={setText}
                        disableExtensions={["checkbox"]}
                        headingsOffset={1}
                    />
                </div>
                <div className="announcement-form__submit-container">
                    {/* <button
                        className="announcement-form__button-save"
                        type="button"
                        onClick={() => {
                            props
                                .onAnnouncementSubmit(
                                    title,
                                    text,
                                    image,
                                    props.blogId || undefined,
                                    false
                                )
                                .then(() => history.push("/announcements"))
                                .catch((err) => alert(err));
                        }}
                    >
                        SAVE
                    </button> */}
                    {!props.editMode && (
                        <button className="announcement-form__button-submit">
                            PUBLISH
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default AnnouncementForm;
