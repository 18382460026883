import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import getInstitutionImageUrl from "../../helpers/getInstitutionImage";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  cardRoot: {},
  media: {
    height: 215,
    backgroundSize: "contain",
    borderBottom: "1px solid #DADADA",
  },
  aboutParagraph: {
    fontSize: "16px",
    height: "150px",
    overflow: "scroll",
  },
  viewSchoolBtn: {
    backgroundColor: "#4DC591",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: 8,
    marginBottom: "2rem",
    marginTop: "2rem",
  },
}));

const InstitutionCard = ({ degreeName, linkTo, imgLink, description }) => {
  const classes = useStyles();

  const [schoolImage, setSchoolImage] = useState(null);

  useEffect(() => {
    getInstitutionImageUrl(imgLink).then(setSchoolImage);
  });

  return (
    <React.Fragment>
      <Grid item md={4} sm={6} xs={12}>
        <Card className={classes.cardRoot}>
          <CardActionArea href={linkTo}>
            {schoolImage ? (
              <CardMedia
                className={classes.media}
                image={schoolImage}
                title="Contemplative Reptile"
              />
            ) : (
              <Skeleton
                variant="rect"
                width={"100%"}
                height={215}
                animation="wave"
              />
            )}
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                style={{
                  fontFamily: "Inter",
                  fontWeight: 700,
                  fontSize: "16px",
                }}
              >
                {degreeName}
              </Typography>
              <Typography className={classes.aboutParagraph}>
                {description}
              </Typography>
              <div style={{ textAlign: "center" }}>
                <Button
                  className={classes.viewSchoolBtn}
                  style={{ width: "100%" }}
                  href={linkTo}
                >
                  VIEW INSTITUTION’S PAGE
                </Button>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </React.Fragment>
  );
};
export default InstitutionCard;
