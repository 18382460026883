import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import React from "react";

export const blackmintTheme = {
  typographyBlackmint: {
    heading: {
      lineHeight: "1.28571429em",
      margin: "0 0 1rem 0",
      fontWeight: 700,
      padding: 0,
    },
    heroBannerTitle: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "40px",
    },
    h1: {},
    h2: {
      lineHeight: "1.28571429em",
      margin: "0 0 1rem 0",
      fontWeight: 700,
      padding: 0,
      fontSize: "4rem",
      fontFamily: "var(--font-secondary)",
      letterSpacing: "0.2rem",
    },
    figmaH2: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "24px",
    },
    h3: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "20px",
    },
    h5: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "14px",
    },
    tabHeader: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "0.5rem",
      color: "var(--color-primary)",
    },
    paragraph: {
      lineHeight: "3rem",
      fontSize: "var(--default-font-size)",
      fontFamily: "var(--font-secondary)",
    },
    link: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontSize: "16px",
      textDecorationLine: "underline",
    },
    mainContent: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontSize: "16px",
    },
    subContent: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontSize: "14px",
      color: "rgba(39, 39, 39, 0.8)",
      lineHeight: "140%",
    },
  },
  layout: {
    paddingSide: "--padding-screen-current-side",
    paddingVertical: "--padding-screen-current-top",
  },
};

const blackMintThemeConfig = createMuiTheme({
  typography: {
    fontFamily: "Montserrat",
  },
  palette: {
    primary: {
      main: "#4dc591",
      light: "#dbf3e9",
      dark: "#259665",
    },
    secondary: {
      main: "#ffe54f",
      light: "#fff8f0",
      dark: " #ffd700",
    },
    success: { main: "#008000" },
    warning: { main: "#ffff00" },
    error: { main: "#ff6066" },
    background: { default: "#fff" },
    text: { primary: "#000" },
  },
});

export default function BlackMintThemeProvider({ children }) {
  return <ThemeProvider theme={blackMintThemeConfig}>{children}</ThemeProvider>;
}
