import { taskTypes } from "../constants";

export default [
    { id: 304, type: taskTypes.watchNumberVideos, description: 'Watch and complete 4 videos on any topic', amount: 4, level: 1, step: 1, points: 20 },
    { id: 16, type: taskTypes.readNumberCareers, description: 'Explore 5 new career options', amount: 5, level: 1, step: 1, points: 5 },
    { id: 13434343, type: taskTypes.readNumberMentors, description: 'Read the background of any 4 mentors', amount: 4, level: 1, step: 1, points: 5 },
    { id: 1111, type: taskTypes.readNumberDegrees, description: 'Learn about 4 different tech programs', amount: 4, level: 1, step: 1, points: 5 },
    { id: 30, type: taskTypes.giveMoreInfo, description: 'Go to profile settings under settings and tell us more about yourself', amount: 4, level: 1, step: 1, points: 10 },
    { id: 330, type: taskTypes.feedback, description: 'Go to the menu overlay (hamburgers at the top right corner) and click on feedback to give us some feedback or report some bugs. You can submit as many times as you want', amount: 1, level: 2, step: 1, points: 10, successMessage:  'Thanks alot for the feedback you\'ve earned the points', title: 'Give us some feedback'},
    { id: 45, type: taskTypes.joinLinkedIn, description: 'Create a linkedin profile, copy your profile link, go to account settings, and submit it in the field provided', amount: 1, level: 2, step: 1, points: 10, successMessage:  'You\'ve successfully setup your linkedIn', title: 'Join linkedIn'},
    { id: 9500, type: taskTypes.leaveComment, description: 'Open your favourite video on the platform and ask at least 1 question or provide a comment', amount: 1, level: 2, step: 1, points: 10, successMessage:  'Congratulations, you\'ve earned 10 points for leaving a comment', title: 'Leave a comment'},
]
