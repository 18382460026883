import React, { useState } from "react";
import { ButtonDefault, H3 } from "../../../../components";
import "./SignUpForm.scss";
import { useHistory } from "react-router";
import firebase from "firebase";
import "firebase/auth";
import { useStatus, useAuth } from "../../../../Providers";
import { Link } from "react-router-dom";
import { FormInput } from "../../../../components";
import { statusTypes } from "../../../../constants";
import { Firebase } from "../../../../apis";
import { newUserDetails, newAzureUserDetails } from "../../../../models/user";
import { upsertUserFromGraphQL } from "../../../../azureGraphQL/userMutation";

export default function SignUpForm() {
  const { changeStatus } = useStatus();

  const { updateAuthUser } = useAuth();

  const initialState = {
    email: "",
    passwordOne: "",
    passwordTwo: "",
  };

  const [state, setState] = useState(initialState);

  const history = useHistory();

  const onSubmit = (e) => {
    e.preventDefault();
    Firebase.doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        // Create a reference to the cities collection
        var usersRef = Firebase.store.collection("users");
        // Create a query against the collection.
        var query = usersRef.where("email", "==", email);

        query
          .get()
          .then(function (querySnapshot) {
            if (!querySnapshot.empty) {
              querySnapshot.forEach(function (doc) {
                // doc.data() is never undefined for query doc snapshots
                // if email account already exists
                console.log(doc.id, " => ", doc.data());
              });
            } else {
              //Update Azure Cosmos DB User Document
              upsertUserFromGraphQL({
                    id: authUser.user.uid,
                    rowKey: authUser.user.uid,
                    partitionKey: "users",
                    email: email,
                    joined: Date.now(),
                    ...newAzureUserDetails
              }).then(function () {
                console.log("New User Data Uploaded to Azure Cosmos DB");
                authUser.user.sendEmailVerification({
                  url: "http://risingstar.blackmint.io",
                });
                console.log(authUser.user);
                updateAuthUser(authUser.user);
                history.push("/signup/moreInfo");
              });
              usersRef
                .doc(authUser.user.uid)
                .set({
                  id: authUser.user.uid,
                  email: email,
                  joined: Date.now(),
                  ...newAzureUserDetails,
                })
                .then(function () {
                  console.log("Document successfully written!");
                  authUser.user.sendEmailVerification({
                    url: "http://risingstar.blackmint.io",
                  });
                  console.log(authUser.user);
                  updateAuthUser(authUser.user);
                  history.push("/signup/moreInfo");
                })
                .catch(function (error) {
                  console.error("Error writing document: ", error);
                });
            }
          })
          .catch(function (error) {
            console.log("Error getting documents or doc doesnt exist: ", error);
          });
      })
      .catch((error) => {
        console.log(error);
        changeStatus(error, statusTypes.error);
      });
  };

  const signUpWithGoogleHandler = (e) => {
    e.preventDefault();
    Firebase.auth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((authUser) => {
        console.log(authUser.user);
        // Create a reference to the cities collection
        var usersRef = Firebase.store.collection("users");
        // Create a query against the collection.
        var query = usersRef.where("email", "==", authUser.user.email);

        query
          .get()
          .then(function (querySnapshot) {
            if (!querySnapshot.empty) {
              querySnapshot.forEach(function (doc) {
                // doc.data() is never undefined for query doc snapshots
                // if email account already exists
                console.log(doc.id, " => ", doc.data());
              });
            } else {
              console.log("no query snapshot");
              console.log(authUser.user);
              upsertUserFromGraphQL({
                  id: authUser.user.uid,
                  rowKey: authUser.user.uid,
                  partitionKey: "users",
                  email: email,
                  joined: Date.now(),
                  ...newAzureUserDetails
              }).then(function () {
                console.log("New User Data Uploaded to Azure Cosmos DB");
                authUser.user.sendEmailVerification({
                  url: "http://risingstar.blackmint.io",
                });
                console.log(authUser.user);
                updateAuthUser(authUser.user);
                history.push("/signup/moreInfo");
              });
              usersRef
                .doc(authUser.user.uid)
                .set({
                  id: authUser.user.uid,
                  email: authUser.user.email,
                  joined: Date.now(),
                  ...newAzureUserDetails,
                })
                .then(function () {
                  console.log("Document successfully written!");
                  console.log(authUser.user);
                  updateAuthUser(authUser.user);
                  history.push("/signup/moreInfo");
                })
                .catch(function (error) {
                  console.error("Error writing document: ", error);
                });
            }
          })
          .catch(function (error) {
            console.log("Error getting documents or doc doesnt exist: ", error);
          });
      })
      .catch((error) => {
        console.log(error);
        changeStatus(error, statusTypes.error);
      });
  };

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { email, passwordOne, passwordTwo /*username*/ } = state;

  const isInvalid =
    passwordOne !== passwordTwo || passwordOne === "" || email === "";

  return (
    <form onSubmit={onSubmit} className="signUpForm">
      <div className="signUpForm__gotoSignIn">
        <Link
          to="/mentorship/signup"
          style={{ color: "red", fontSize: "2.5rem" }}
        >
          Mentors: Sign up by clicking here
        </Link>
      </div>
      <FormInput
        name="email"
        value={email}
        onChange={onChange}
        type="email"
        placeholder="Email Address"
        required
      />
      <p style={{ marginBottom: "1rem" }}>
        Password must be at least 8 characters with atleast one uppercase and
        lowercase letter and one number
      </p>
      <FormInput
        name="passwordOne"
        value={passwordOne}
        onChange={onChange}
        type="password"
        placeholder="Password"
        required
        invalid={
          !passwordOne.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
            ? {
                error:
                  "Password must be atleast 8 characters with atleast one uppercase and lowercase letter and one number",
              }
            : null
        }
      />

      <FormInput
        name="passwordTwo"
        value={passwordTwo}
        onChange={onChange}
        type="password"
        placeholder="Confirm Password"
        invalid={
          passwordOne !== passwordTwo
            ? { error: "Passwords do not match" }
            : null
        }
        required
      />
      <div className="signUpForm__buttons">
        <ButtonDefault onClick={signUpWithGoogleHandler}>
          Join with Google
        </ButtonDefault>
        <ButtonDefault type="submit">Sign Up</ButtonDefault>
        <div className="signUpForm__buttonsDivider">
          <hr className="signUpForm__rule" />
          <p className="signUpForm__or">or</p>
          <hr className="signUpForm__rule" />
        </div>
      </div>
      <div className="signUpForm__gotoSignIn u-margin-bottom-medium">
        <Link to="/signin">Already a rising star? Click here.</Link>
      </div>
      {/* <H3 align='center'>For Mentors</H3> */}
    </form>
  );
}
