import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import ImageIcon from "@material-ui/icons/Image";
import DropdownInput from "../../Form/DropdownInput/DropdownInput";
import Editor from "rich-markdown-editor";
import "./BlogForm.scss";
import { useCareers } from "../../../Providers";

const BlogForm = (props) => {
    const history = useHistory();
    const [title, setTitle] = useState(props.title || "");
    const [subTitle, setSubTitle] = useState(props.subTitle || "");
    const [careers, setCareers] = useState(props.careers || []);
    const [text, setText] = useState(props.text || "");
    const [image, setImage] = useState(props.image || undefined);
    const [careerList, setCareerList] = useState([{ name: "Other", id: "#" }]);

    const { getAllCareers } = useCareers();

    useEffect(() => {
        (async () => {
            const careerListFetched = await getAllCareers();
            careerListFetched.forEach(({ name, id }) => {
                const career = {
                    name,
                    id,
                };
                setCareerList((prev) => [...prev, career]);
            });
        })();
    }, []);

    const setBlogImage = async (imageFile) => {
        if (image) {
            await fetch(
                `https://blog.risingstar.blackmint.io/upload/files/${image.id}`,
                {
                    method: "DELETE",
                }
            );
        }

        const data = new FormData();
        data.append("files", imageFile);
        const imgReq = await fetch(
            "https://blog.risingstar.blackmint.io/upload",
            {
                method: "POST",
                body: data,
            }
        );

        if (imgReq.status === 413) {
            alert("Image is too large. Must be less than 1MB");
            return;
        }

        const imgRes = await imgReq.json();
        setImage(imgRes[0]);
    };

    return (
        <div className="blog-form__container">
            <form
                className="blog-form"
                onSubmit={(e) => {
                    e.preventDefault();

                    if (!text) {
                        alert("Text is required");
                    } else if (!careers) {
                        alert("Please select related careers");
                    } else if (!image) {
                        alert("Please select an image");
                    } else {
                        props
                            .onBlogSubmit(
                                title,
                                subTitle,
                                text,
                                careers,
                                image,
                                true
                            )
                            .then((id) => history.push(`/blogs/${id}`))
                            .catch((err) => alert(err));
                    }
                }}
            >
                <div className="blog-form__section">
                    <p className="blog-form__label">
                        <label htmlFor="image">Choose an image</label>
                    </p>
                    <div>
                        {image ? (
                            <div className="blog-form__image-container">
                                <img
                                    className="blog-form__image"
                                    src={`https://blog.risingstar.blackmint.io${image.url}`}
                                    alt={subTitle}
                                />
                            </div>
                        ) : (
                            <div className="blog-form__placeholder-image">
                                <div className="blog-form__placeholder-image-icon-background">
                                    <ImageIcon
                                        className="blog-form__placeholder-image-icon"
                                        style={{
                                            fontSize: "100px",
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <label
                            htmlFor="image"
                            className="blog-form__image-field"
                        >
                            Upload
                        </label>
                        <input
                            id="image"
                            type="file"
                            onChange={(e) => setBlogImage(e.target.files[0])}
                            hidden
                        />
                    </div>
                </div>
                <div className="blog-form__section">
                    <p className="blog-form__label">
                        <label htmlFor="title">Title</label> (Required)
                    </p>
                    <input
                        className="blog-form__field"
                        id="title"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Create a title that's eye-catching!"
                        required
                    />
                </div>
                <div className="blog-form__section">
                    <p className="blog-form__label">
                        <label htmlFor="subHeading">Sub-Title</label>
                    </p>
                    <input
                        id="subHeading"
                        className="blog-form__field"
                        type="text"
                        value={subTitle}
                        onChange={(e) => setSubTitle(e.target.value)}
                        placeholder="Give some context on what your blog post is about"
                    />
                </div>
                <div className="blog-form__section blog-form__career-section">
                    <p className="blog-form__label">
                        <label htmlFor="related-careers">Related Careers</label>{" "}
                        (Required)
                    </p>
                    <DropdownInput
                        className="blog-form__career-field"
                        value={careers}
                        placeholder="Choose a career the blog is related to"
                        options={careerList.map((val) => ({
                            value: val.id,
                            label: val.name,
                        }))}
                        onChange={setCareers}
                        required={true}
                        limit={3}
                        multi
                    />
                </div>

                <div className="blog-form__section blog-form__text">
                    <p className="blog-form__label">
                        <label htmlFor="text">Content</label> (Required)
                    </p>
                    <Editor
                        id="text"
                        defaultValue={props.text}
                        onChange={setText}
                        disableExtensions={["checkbox"]}
                        headingsOffset={1}
                    />
                </div>
                <div className="blog-form__submit-container">
                    <button
                        className="blog-form__button-save"
                        type="button"
                        onClick={() => {
                            props
                                .onBlogSubmit(
                                    title,
                                    subTitle,
                                    text,
                                    careers,
                                    image,
                                    props.blogId || undefined,
                                    false
                                )
                                .then(() => history.push(`/blogs/mentor`))
                                .catch((err) => alert(err));
                        }}
                    >
                        SAVE
                    </button>
                    {!props.editMode && (
                        <button className="blog-form__button-submit">
                            PUBLISH
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default BlogForm;
