import React from "react";
import "./TrackBump.scss";

export default function TrackBump({ progress = 0, amount = 1 }) {
  const bumps = [];

  for (let index = 1; index <= amount; index++) {
    if (index > progress) bumps.push(<div className="trackBump__bump"></div>);
    else
      bumps.push(
        <div className="trackBump__bump trackBump__bump-active">
          <div className="trackBump__check">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 21 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 8.5L9.4375 15L19 2"
                stroke="#4DC591"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 8.5L9.4375 15L19 2"
                stroke="black"
                stroke-opacity="0.6"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      );
  }

  return (
    <div className="trackBump card">
      <div className="trackBump__progress">{bumps}</div>
    </div>
  );
}
