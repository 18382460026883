const ROUTES = {
  landing: "/",
  home: "/home",
  videosList: "/videos",
  video: "/videos/:id",
  mentorsList: "/mentors",
  teamMembersList: "/team",
  mentor: "/mentors/:id",
  settings: "/settings",
  signIn: "/signin",
  signUp: "/signup",
  careersList: "/careers",
  career: "/careers/:id",
  articleList: "/articles",
  article: "/articles/:id",
  savedVideos: "/saved/videos",
  likedVideos: "/liked/videos",
  feedback: "/feedback",
  mentorshipLanding: "/mentorship",
  mentorshipSignUp: "/mentorship/signup",
  mentorshipDashboard: "/mentorship/dashboard",
  mentorshipCareers: "/mentorship/careers/:id",
  degree: "/degrees/:id",
  degrees: "/degrees",
  institution: "/institutions/:id",
  blogSubmit: "/blogs/submit",
  blogPost: "/blogs/:id",
  blogList: "/blogs/all",
  blogMentorList: "/blogs/mentor/",
  blogEdit: "/blogs/:id/edit",
  announcements: "/announcements",
  announcementsSubmit: "/announcements/submit",
  announcement: "/announcement/:id",
  announcementEdit: "/announcement/:id/edit",
  announcementManage: "/announcements/manage",
};

export default ROUTES;
