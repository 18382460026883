import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import withAuthorizationComponent from "../../../components/Auth/WithAuthorization/WithAuthorizationComponent";
import Skeleton from "@material-ui/lab/Skeleton";
import { useMediaQuery } from "../../../hooks";
import _ from "lodash";
import getInstitutionImageUrl from "../../../helpers/getInstitutionImage";
import getDegreeImageUrl from "../../../helpers/getDegreeImage";
import clsx from "clsx";
import {
  useDegrees,
  useCareers,
  useTask,
  useAuth,
  useUsers,
  useInstitutions,
} from "../../../Providers";

import { taskTypes } from "../../../constants";

import {
  Hidden,
  Typography,
  Grid,
  Button,
  Box,
  Tabs,
  Tab,
  TableCell,
  Table,
  TableRow,
  TableBody,
  Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import InstitutionCard from "../../../components/Institution/InstitutionCard";
import InstitutionCardLarge from "../../../components/Institution/InstitutionCardLarge";
import { blackmintTheme } from "../../../style/theme";
import { Screen, Section } from "../../../components";
import WebNavBar from "../../../components/Layout/Nav/NavBar/WebNav";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ backgroundColor: "#F1F1EE", height: "100%" }}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--color-background-default)",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "290px",
    },
  },
  h1: {
    ...blackmintTheme.typographyBlackmint.heroBannerTitle,
    fontSize: 30,
    fontWeight: 700,
    lineHeight: "40px",
    [theme.breakpoints.down("sm")]: {
      ...blackmintTheme.typographyBlackmint.heroBannerTitle,
      lineHeight: "56px",
    },
  },
  titleContainer: {
    height: "100%",
    padding: "124px 0px",
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: "28px 0px",
    },
  },
  bookmarkBtn: {
    ...blackmintTheme.typographyBlackmint.h5,
    fontWeight: 400,
    border: "1px solid #272727",
    borderRadius: "10px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  tabRoot: {
    ...blackmintTheme.typographyBlackmint.mainContent,
    fontSize: "12px",
    padding: "0px 12px",
    minHeight: 32,
  },
  textColor: { color: "#757575" },
  activeTab: { color: "#4DC591" },
  indicator: {
    backgroundColor: "#4DC591",
  },
  subHeader: {
    ...blackmintTheme.typographyBlackmint.h3,
    color: "#00664F",
  },
  aboutParagraph: {
    ...blackmintTheme.typographyBlackmint.mainContent,
    color: "#272727",
  },
  gridContainer: {
    marginTop: "2rem",
    marginBottom: "2rem",
    display: "flex",
    alignItems: "center",
  },
  whiteBox: {
    backgroundColor: "white",
    borderRadius: 20,
    height: 150,
    padding: "20px 10px",
    margin: "0px 10px",
    textAlign: "center",
  },
  boxHeader: {
    ...blackmintTheme.typographyBlackmint.h5,
    color: "#272727",
  },
  boxNumber: {
    color: "#4DC591",
    fontSize: "45px",
    fontWeight: "bold",
    fontFamily: "Inter",
  },
  boxSubHeader: {
    ...blackmintTheme.typographyBlackmint.subContent,
  },
  table: { marginBottom: "2rem" },
  tableLeft: {
    ...blackmintTheme.typographyBlackmint.mainContent,
    color: "#272727",
    paddingLeft: 0,
    paddingRight: 0,
    width: "50%",
  },
  tableLeftReq: {
    fontFamily: "Inter",
    fontSize: "16px",
    color: "#272727",
    paddingLeft: 0,
    paddingRight: 0,
  },
  tableRight: {
    ...blackmintTheme.typographyBlackmint.subContent,
  },
  tableRightExpenses: {
    color: "#757575",
  },
  viewSchoolBtn: {
    backgroundColor: "#4DC591",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: 8,
    marginBottom: "2rem",
    marginTop: "2rem",
    "&:hover": {
      backgroundColor: "#00664F",
    },
  },
  careerChip: {
    backgroundColor: "white",
    fontSize: "14px",
    fontFamily: "Inter",
    margin: 10,
    marginLeft: 0,
  },
  cardMedia: {
    height: 200,
    borderBottom: "1px solid #DADADA",
  },
}));

const DegreeScreen = () => {
  const classes = useStyles();
  const { authUserDetails } = useAuth();

  const { getDegree } = useDegrees();
  const { getInstitution } = useInstitutions();

  const { getUsers } = useUsers();

  const { taskableHandler } = useTask();

  const media = useMediaQuery();

  const [degree, setDegree] = useState(null);
  const [mainInstitution, setMainInstitution] = useState(null);
  const [schoolImage, setSchoolImage] = useState(null);
  const [degreeImage, setDegreeImage] = useState(null);

  const params = useParams();

  const history = useHistory();

  useEffect(() => {
    getDegree(params["id"]).then((degree) => {
      setDegree(degree);
      setDegreeImage(degree.image);
      //getDegreeImageUrl(degree.image).then(setDegreeImage);
      getInstitution(degree.primaryInstitution.id).then((institution) => {
        const schoolDegree = _.find(institution.degrees, ["id", params["id"]]);
        setMainInstitution({
          name: institution.name,
          image: institution.image,
          expenses: institution.expenses,
          program: schoolDegree,
          id: institution.id,
        });
        getInstitutionImageUrl(institution.image)
          .then(setSchoolImage)
          .then((result) => {
            taskableHandler(
              taskTypes.readNumberDegrees,
              authUserDetails,
              1,
              params["id"]
            );
          });
      });
    });
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <WebNavBar />
      {degree ? (
        <Screen className={classes.root}>
          <div style={{ flex: 1 }}>
            <div
              style={{
                backgroundColor: "#DECABA",
                paddingLeft: 16,
                paddingRight: 16,
              }}
            >
              <Grid container>
                <Grid item xs={8} md={12}>
                  <div className={classes.titleContainer}>
                    <Typography className={classes.h1}>
                      {degree.name}
                    </Typography>
                    <Button variant="outlined" className={classes.bookmarkBtn}>
                      Bookmark
                    </Button>
                  </div>
                </Grid>
                <Hidden smUp>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <img
                      src={degreeImage}
                      style={{ objectFit: "contain", width: "100px" }}
                      height="100%"
                    />
                  </Grid>
                </Hidden>
              </Grid>
            </div>
            <Section.Container>
              <Tabs
                value={value}
                onChange={handleChange}
                classes={{
                  textColorPrimary: classes.textColor,
                  indicator: classes.indicator,
                }}
                style={{
                  backgroundColor: "#F1F1EE",
                  minHeight: 32,
                  height: 32,
                }}
              >
                <Tab
                  label="Overview"
                  classes={{
                    selected: classes.activeTab,
                    root: classes.tabRoot,
                  }}
                />
                <Tab
                  label="Requirements"
                  classes={{
                    selected: classes.activeTab,
                    root: classes.tabRoot,
                  }}
                />
                <Tab
                  label="Insititutions"
                  classes={{
                    selected: classes.activeTab,
                    root: classes.tabRoot,
                  }}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <div>
                  <Typography className={classes.subHeader}>
                    What is this degree about?
                  </Typography>
                  <Typography className={classes.aboutParagraph}>
                    {degree?.description}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.subHeader}>
                    Recommended Institutions
                  </Typography>
                  <Grid container spacing={2} className={classes.gridContainer}>
                    {degree?.institutions?.map((d) => {
                      return (
                        <InstitutionCard
                          key={d.id}
                          degreeName={d.name}
                          linkTo={"/institutions/" + d.id}
                          imgLink={d.image}
                        />
                      );
                    })}
                  </Grid>
                </div>
                {mainInstitution ? (
                  <>
                    <div>
                      <Typography className={classes.subHeader}>
                        Featured Institution
                      </Typography>
                      {schoolImage ? (
                        <img
                          src={schoolImage}
                          style={{ width: "100%", backgroundColor: "white" }}
                        />
                      ) : (
                        <Skeleton variant="rect" width={"100%"} height={118} />
                      )}

                      <Grid
                        container
                        className={classes.gridContainer}
                        style={{
                          marginBottom: "2rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid item xs={6}>
                          <div className={classes.whiteBox}>
                            <Typography className={classes.boxHeader}>
                              Program Length
                            </Typography>
                            <Typography className={classes.boxNumber}>
                              {mainInstitution?.program?.years}
                            </Typography>
                            <Typography className={classes.boxSubHeader}>
                              years
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className={classes.whiteBox}>
                            <Typography className={classes.boxHeader}>
                              Tuition
                            </Typography>
                            <Typography
                              className={classes.boxNumber}
                              style={{ fontSize: "25px", lineHeight: "65px" }}
                            >
                              $
                              {numberWithCommas(
                                mainInstitution?.expenses?.tuition?.inProvince
                              )}
                            </Typography>
                            <Typography className={classes.boxSubHeader}>
                              per year
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div>
                      <Typography className={classes.subHeader}>
                        Program Details
                      </Typography>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableLeft}
                            >
                              Education Required
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableRight}
                            >
                              {mainInstitution?.program?.requiredEducation}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableLeft}
                            >
                              Credentials Awarded
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableRight}
                            >
                              {mainInstitution?.program?.credential}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableLeft}
                            >
                              Program Length
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableRight}
                            >
                              {mainInstitution?.program?.years} years
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableLeft}
                            >
                              Co-Op Option
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableRight}
                            >
                              {mainInstitution?.program?.coop ? "Yes" : "No"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableLeft}
                            >
                              Part-time Study Option
                            </TableCell>
                            <TableCell
                              align="left"
                              className={classes.tableRight}
                            >
                              {mainInstitution?.program?.partTimeStudy
                                ? "Yes"
                                : "No"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                    <div>
                      <Typography className={classes.subHeader}>
                        Estimated Expenses
                      </Typography>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableLeft}
                            >
                              Tuition (In-Province)
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.tableRight,
                                classes.tableRightExpenses
                              )}
                            >
                              $
                              {numberWithCommas(
                                mainInstitution?.expenses?.tuition?.inProvince
                              )}{" "}
                              for 1 year(s)
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableLeft}
                            >
                              Tuition (International)
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.tableRight,
                                classes.tableRightExpenses
                              )}
                            >
                              $
                              {numberWithCommas(
                                mainInstitution?.expenses?.tuition
                                  ?.international?.min
                              )}{" "}
                              - $
                              {numberWithCommas(
                                mainInstitution?.expenses?.tuition
                                  ?.international?.max
                              )}{" "}
                              for 1 year(s)
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableLeft}
                            >
                              Residence Cost
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.tableRight,
                                classes.tableRightExpenses
                              )}
                            >
                              $
                              {numberWithCommas(
                                mainInstitution?.expenses?.residence?.min
                              )}{" "}
                              - $
                              {numberWithCommas(
                                mainInstitution?.expenses?.residence?.max
                              )}{" "}
                              for 2 semesters
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableLeft}
                            >
                              Meal Plan Cost
                            </TableCell>
                            <TableCell
                              align="left"
                              className={clsx(
                                classes.tableRight,
                                classes.tableRightExpenses
                              )}
                            >
                              $
                              {numberWithCommas(
                                mainInstitution?.expenses?.mealPlan?.min
                              )}{" "}
                              - $
                              {numberWithCommas(
                                mainInstitution?.expenses?.mealPlan?.max
                              )}{" "}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <div style={{ textAlign: "center" }}>
                        <Button
                          className={classes.viewSchoolBtn}
                          href={"/institutions/" + mainInstitution?.id}
                          style={{ width: "297px" }}
                        >
                          VIEW INSTITUTION’S PAGE
                        </Button>
                      </div>
                    </div>
                  </>
                ) : null}

                <div style={{ marginBottom: "2rem" }}>
                  <Typography className={classes.subHeader}>
                    Related Careers
                  </Typography>
                  {degree?.relatedCareers?.map((d) => {
                    return (
                      <Chip
                        key={d.id}
                        label={d.name}
                        component="a"
                        href={"/careers/" + d.id}
                        clickable
                        className={classes.careerChip}
                      />
                    );
                  })}
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div>
                  <Typography
                    className={classes.subHeader}
                    style={{ color: "#272727" }}
                  >
                    {degree.name}
                  </Typography>
                  <Typography className={classes.aboutParagraph}>
                    {degree?.primaryInstitution?.name}
                  </Typography>
                </div>
                {mainInstitution ? (
                  <div>
                    <Typography className={classes.subHeader}>
                      Admission Requirements
                    </Typography>
                    <Grid
                      container
                      className={classes.gridContainer}
                      style={{
                        marginBottom: "2rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={6}>
                        <div className={classes.whiteBox}>
                          <Typography className={classes.boxHeader}>
                            Minmum Average
                          </Typography>
                          <Typography className={classes.boxNumber}>
                            {mainInstitution?.program?.admission?.minAvg}s
                          </Typography>
                          <Typography className={classes.boxSubHeader}>
                            low level
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.whiteBox}>
                          <Typography className={classes.boxHeader}>
                            Required Courses
                          </Typography>
                          <Typography className={classes.boxNumber}>
                            {mainInstitution?.program?.admission?.courses}
                          </Typography>
                          <Typography className={classes.boxSubHeader}>
                            4U/M level
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Table className={classes.table} aria-label="simple table">
                      <TableBody>
                        {mainInstitution?.program?.admission?.otherReq?.map(
                          (m, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tableLeftReq}
                                >
                                  {m}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                    <Typography className={classes.aboutParagraph}>
                      {mainInstitution?.program?.admission?.disclaimer}
                    </Typography>
                    <div style={{ textAlign: "center" }}>
                      <Button className={classes.viewSchoolBtn}>
                        GO TO PROGRAM WEBSITE
                        <ArrowRightAltIcon fontSize={"large"} />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Skeleton />
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div>
                  <Typography className={classes.subHeader}>
                    Other Institutions
                  </Typography>
                  <Typography className={classes.aboutParagraph}>
                    The BlackMINT team has compiled a list of the top 4
                    institutions we believe are suitable for studying{" "}
                    {degree.name}.
                  </Typography>
                  <Grid container spacing={2}>
                    {degree?.institutions?.map((d, index) => {
                      return (
                        <InstitutionCardLarge
                          key={index}
                          degreeName={d.name}
                          linkTo={"/institutions/" + d.id}
                          imgLink={d.image}
                          description={d.description}
                        />
                      );
                    })}
                  </Grid>
                </div>
              </TabPanel>
            </Section.Container>
          </div>
        </Screen>
      ) : (
        <div style={{ minHeight: "100vh", backgroundColor: "#F1F1EE" }}>
          <Skeleton variant="rect" width={"100%"} height={"100vh"} />
        </div>
      )}
    </>
  );
};

export default withAuthorizationComponent((auth) => auth)(DegreeScreen);
