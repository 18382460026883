import React from "react";
import "./ButtonCard.scss";

export default function ButtonCard({ children, ...rest }) {
  return (
    <button
      {...rest}
      className="buttonCard card hoverEffect borderRadius-medium"
    >
      {children}
    </button>
  );
}
