import React, { useEffect, useState, useRef } from 'react';
import './StatusDisplay.scss'
import { statusTypes } from '../../constants';

export default function StatusDisplay({ state, type }) {

    const [open, setOpen] = useState(false)

    const displayTimer = useRef(null)

    let backgroundColor

    switch (type) {
        case statusTypes.success:
            backgroundColor = 'var(--color-success)'
            break;

        case statusTypes.error:
            backgroundColor = 'var(--color-error)'
            break;

        case statusTypes.warning:
            backgroundColor = 'var(--color-warning)'
            break;

        default:
            backgroundColor = 'var(--color-error)'
            break;
    }

    useEffect(() => {
        clearTimeout(displayTimer.current)
        setOpen(true)
        displayTimer.current = setTimeout(() => {
            setOpen(false)
        }, 5000);
    },[state])

    return (
        <div className={`statusDisplay ${open && 'statusDisplay__open'}`}  style={{backgroundColor}} onClick={() => {setOpen(false)}}>
            <p className="statusDisplay__text">{state.message}</p>
        </div>
    )
}
