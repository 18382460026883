import { makeStyles, Tooltip } from "@material-ui/core";
import React from "react";
import "./HelpTag.scss";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    color: "black",
    opacity: "0.4",
    fontSize: "2.3rem",
    marginLeft: "1rem",
  },
  helptagTooltip: {
    fontFamily: "var(--font-secondary)",
    letterSpacing: "0.2rem",
    color: "white",
    padding: "1rem",
    backgroundColor: "#113438",
    width: "30rem",
    fontSize: "1.2rem",
    fontWeight: "300",
    [theme.breakpoints.down("sm")]: {
      width: "26rem",
    },
  },
  helptagArrow: {
    color: "#113438",
  },
}));

export default function HelpTag({ message, children, placement = "top" }) {
  const styles = useStyles();

  return (
    <Tooltip
      title={message || children}
      placement={placement}
      arrow
      classes={{
        tooltip: styles.helptagTooltip,
        arrow: styles.helptagArrow,
      }}
    >
      <InfoOutlinedIcon fontSize="large" className={styles.infoIcon} />
    </Tooltip>
  );
}
