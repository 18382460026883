import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import queryFetch from "../../../azureGraphQL/queryFetch";
import BlogForm from "../../../components/Blogs/BlogForm/BlogForm";
import WebNav from "../../../components/Layout/Nav/NavBar/WebNav";
import { AuthUserContext } from "../../../Providers";
import { useBlogs } from "../../../Providers/BlogsProvider/BlogsProvider";
import "./BlogEditScreen.scss";

const BlogsEditScreen = () => {
    const { authUserDetails, isUserMentee } = useContext(AuthUserContext);
    const history = useHistory();
    const { getDraftPost } = useBlogs();
    const [blog, setBlog] = useState(null);
    const { id } = useParams();
    const { fetchAllBlogs } = useBlogs();

    useEffect(() => {
        if (isUserMentee) {
            history.push("/");
        }
        (async () => {
            const blogRes = await getDraftPost(id);

            if (blogRes.user.id !== authUserDetails.id) {
                return history.push("/blogs/mentor");
            }

            blogRes.careers = blogRes.careers.map((careers) => ({
                value: careers,
                label: careers,
            }));
            setBlog(blogRes);
        })();
    }, []);

    const onBlogSubmit = async (
        title,
        subTitle,
        text,
        careers,
        image,
        submit
    ) => {
        const user = authUserDetails;
        const mentorData = await queryFetch(
            `
            query getMentorById($queryid: String) {
                mentor(id: $queryid){
                image,
                careers
                }
            }
        `,
            { queryid: authUserDetails.id }
        );

        user.image = mentorData.mentor.image;
        const careerRes = await queryFetch(
            `
            query getCareerById($queryids: [String]) {
                careers(ids: $queryids){
                    name,
                }
            }
        `,
            { queryids: mentorData.mentor.careers[0] }
        );

        user.career = careerRes.careers[0].name;
        const blogReq = await fetch(
            submit
                ? `https://blog.risingstar.blackmint.io/blogs/${blog.id}`
                : `https://blog.risingstar.blackmint.io/blogs`,
            {
                method: submit ? "PUT" : "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    title,
                    text: text.toString(),
                    image,
                    subTitle,
                    careers,
                    published_at: submit ? new Date() : null,
                }),
            }
        );

        if (blogReq.status !== 200) {
            throw new Error("Error with submitting blogs");
        }

        const blogRes = await blogReq.json();
        await fetchAllBlogs();
        return blogRes.id;
    };

    return (
        <div className="blog-edit--background">
            <WebNav />
            <div className="blog-edit">
                <div className="blog-edit__container">
                    <div className="blog-edit__container--underline">
                        <h1 className="blog-edit__h1">Edit your blog post</h1>
                    </div>
                </div>
            </div>

            {blog && blog !== 404 ? (
                <BlogForm
                    onBlogSubmit={onBlogSubmit}
                    image={blog.image}
                    title={blog.title}
                    subTitle={blog.subTitle}
                    careers={blog.careers}
                    text={blog.text}
                />
            ) : (
                <h2>Blog post not found</h2>
            )}
        </div>
    );
};

export default BlogsEditScreen;
