import { Firebase } from "../apis";

const getInstitutionImageUrl = async (imageUrl) => {
  if (imageUrl?.includes("institutionImages")) {
    try {
      const imageRef = Firebase.storage.ref(imageUrl);
      await imageRef.updateMetadata({
        cacheControl: "private, max-age=15552000",
      });
      return imageRef.getDownloadURL();
    } catch {
      return null;
    }
  } else return imageUrl;
};

export default getInstitutionImageUrl;
