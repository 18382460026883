import React, { useState, useEffect } from "react";
import { Section } from "../../../../components";

export default function VideoDescription({video}) {

  return (
    <Section.Container style={{ paddingBottom: 0, paddingTop: 0 }}>
      {video
        ? video.description.map((paragraph) => (
            <p className="videoScreenMainDescription__paragraph paragraph">
              {paragraph.value}
            </p>
          ))
        : null}
    </Section.Container>
  );
}
