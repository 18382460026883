import React, { createContext, useState, useContext } from "react";
import { pushToObject } from "../../helpers";
import { Firebase } from "../../apis";

export const CommentsContext = createContext();

export const useComments = () => useContext(CommentsContext);

export default function CommentsProvider({ children }) {
  const [comments, setComments] = useState({});

  const updateCommentsStore = (newComments) =>
    setComments(pushToObject({}, newComments));

  const addCommentToStore = (newComment) =>
    setComments({ ...comments, [newComment.id]: newComment });

  const addCommentsToStore = (newComments) =>
    setComments(pushToObject(comments, newComments));

  const refreshCommentsStore = async () => {
    const commentListSnapshot = await Firebase.getList(
      Firebase.collections.comments
    )
      .where("id", "in", Object.keys(comments))
      .get();
    const comments = Firebase.snapshotArrayToDataArray(commentListSnapshot);
    if (comments) setComments(pushToObject({}, comments));
  };

  const getComment = async (id) => {
    if (comments[id]) return comments[id];
    const result = await Firebase.get(Firebase.collections.comments, id);
    addCommentToStore(result);
    return result;
  };

  const refreshComment = async (id) => {
    const result = await Firebase.get(Firebase.collections.comments, id);
    addCommentToStore(result);
    return result;
  };

  return (
    <CommentsContext.Provider
      value={{
        updateCommentsStore,
        addCommentToStore,
        addCommentsToStore,
        refreshCommentsStore,
        refreshComment,
        getComment,
        comments,
      }}
    >
      {children}
    </CommentsContext.Provider>
  );
}
