import React, { useState, useEffect } from "react";
import "./Comment.scss";
import { ButtonDefault } from "../..";
import { formatDateSlash } from "../../../helpers/formatDate";
import { useAuth } from "../../../Providers";
import { Link } from "react-router-dom";
import { FormSingleInput } from "../..";
import { useTask } from "../../../Providers";
import { useUsers } from "../../../Providers";
import { taskTypes } from "../../../constants";
import { Firebase } from "../../../apis";

export default function Comment({ comment, isThread = false }) {
  const [commentData, setCommentData] = useState(comment);
  const { authUser, authUserDetails } = useAuth();

  const [showThread, setShowThread] = useState(false);

  const { taskableHandler } = useTask();

  const { getUser } = useUsers();

  const [author, setAuthor] = useState(null);
  const [thread, setThread] = useState(comment.thread);

  const commentThreadSubmitHandler = async (event) => {
    event.preventDefault();
    const newComment = {
      id: Firebase.store.collection("comments").doc().id,
      type: commentData.type,
      contentId: commentData.contentId,
      dateCreated: Date.now(),
      author: authUserDetails.id,
      text: event.target["comment"].value,
      thread: null,
      parent: commentData.id,
    };
    const newCommentDataThread = [...commentData.thread, newComment];
    await Firebase.store
      .collection("comments")
      .doc(commentData.id)
      .update({ thread: newCommentDataThread });
    setCommentData({ ...commentData, thread: newCommentDataThread });
    setThread(newCommentDataThread);
    if (!authUserDetails.mentor) {
      taskableHandler(taskTypes.leaveComment, authUserDetails);
    }
  };

  useEffect(() => {
    getUser(commentData.author).then((result) => {
      if (result) setAuthor(result);
      else {
        Firebase.store
          .collection("users")
          .doc(commentData.author)
          .get()
          .then((authorSnapshot) => {
            const fetchedAuthor = authorSnapshot.data();
            setAuthor(fetchedAuthor);
          });
      }
    });
  }, []);

  const getThread = async (id) => {
    const commentsSnapshot = await Firebase.store
      .collection("comments")
      .where("parent", "==", id)
      .get();
    const fetchedComments = commentsSnapshot.docs.map((commentDoc) =>
      commentDoc.data()
    );
    return fetchedComments;
  };

  const deleteCommentHandler = async (comment) => {
    if (comment.thread) {
      await Firebase.store.collection("comments").doc(comment.id).delete();
    } else {
      const parent = await Firebase.get("comments", comment.parent);
      if (parent) {
        const newParentThread = parent.thread.filter(
          (commentItem) => comment.id !== commentItem.id
        );
        await Firebase.store
          .collection("comments")
          .doc(parent.id)
          .update({ thread: newParentThread });
      }
    }
    setCommentData(null);
  };

  return commentData ? (
    <div className={`comment`}>
      <div
        className={`comment__head ${isThread ? "comment__head-thread" : ""}`}
        onClick={() => {
          if (!isThread && !showThread) setShowThread(true);
          else setShowThread(false);
        }}
      >
        <div className="comment__text">
          <div className="comment__details">
            {author && author.mentor ? (
              <Link
                to={`/mentors/${author.id}`}
                className="comment__authorName"
              >
                {author && author.name}
              </Link>
            ) : (
              <span className="comment__authorName">
                {author && author.username}
              </span>
            )}
            {author && author.mentor ? (
              <span className="comment__mentorSign">Mentor</span>
            ) : null}
            <span className="comment__date">
              {formatDateSlash(commentData.dateCreated)}
            </span>
          </div>
          <p className="comment__comment paragraph">{commentData.text}</p>
        </div>
        {!isThread ? (
          <>
            <p className="comment__threadInfo">{commentData.thread.length}</p>
            <div
              className="comment__arrow"
              style={{ transform: showThread ? "rotate(180deg)" : "rotate(0)" }}
            >
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.86602 15.5C9.48112 16.1667 8.51888 16.1667 8.13398 15.5L0.339746 2C-0.0451546 1.33333 0.43597 0.5 1.20577 0.5L16.7942 0.5C17.564 0.5 18.0452 1.33333 17.6603 2L9.86602 15.5Z"
                  fill="#272727"
                  fill-opacity="0.8"
                />
              </svg>
            </div>
          </>
        ) : null}
        {comment.author === authUser.uid ? (
          <p className="comment__delete">
            <ButtonDefault
              variant="danger"
              onClick={(e) => {
                e.stopPropagation();
                deleteCommentHandler(comment);
              }}
            >
              x
            </ButtonDefault>
          </p>
        ) : null}
      </div>
      <div
        className={`comment__thread ${
          !showThread ? "comment__thread-hidden" : ""
        }`}
      >
        {!isThread ? (
          <div className="comment__new">
            <FormSingleInput
              onSubmit={commentThreadSubmitHandler}
              button="reply"
              name="comment"
              type="textarea"
              placeholder="Send a reply..."
            ></FormSingleInput>
          </div>
        ) : null}
        {commentData.thread
          ? thread
              .sort((a, b) => a - b)
              .map((comment) => (
                <Comment comment={comment} isThread={true} key={comment.id} />
              ))
          : null}
      </div>
    </div>
  ) : null;
}
