import React, { useEffect, useState } from "react";
import BlogCard from "../../../components/Blogs/BlogCard/BlogCard";
import WebNav from "../../../components/Layout/Nav/NavBar/WebNav";
import { useBlogs } from "../../../Providers/BlogsProvider/BlogsProvider";
import "./BlogListScreen.scss";

const BlogList = () => {
    const { blogs } = useBlogs();
    
    return (
        <div className="blog-list--background">
            <WebNav />
            <div className="blog-list">
                <div className="blog-list__header">
                    <div>Blog Posts</div>
                </div>
                <div className="blog-list__page-desc">
                    <h1>Blog posts are written by BlackMINT's mentors</h1>
                    <p>
                        Want to read what it’s like working in tech? Or how
                        about knowing first-hand the latest tech trends? Our
                        blog posts features many different topics about tech
                        through the lens of our mentors.
                    </p>
                </div>
                <div className="blog-list__display">
                    {blogs &&
                        blogs.map((blog) => <BlogCard title={blog.title} author={blog.user} imageUrl={blog.image.url} id={blog.id} key={blog.id} />)}
                </div>
            </div>
        </div>
    );
};

export default BlogList;
