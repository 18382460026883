import { makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import {
  TaskTracker,
  ButtonDefault,
  MentorThumbnail,
  VideoThumbnail,
  HelpTag,
  Screen,
  H2,
  Section,
  Track,
  H3,
} from "../../../components";
import { useCareers, useDegrees, useUsers } from "../../../Providers";
import WebMentorThumbnail from "../../../components/Mentor/WebMentorThumbnail/WebMentorThumbnail";
import DegreeCard from "../../../components/Degree/DegreeCard";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "5rem",
    paddingTop: 0,
  },
  grid: {
    display: "grid",
    gap: "3rem",
    marginTop: "5rem",
    gridTemplateColumns: "repeat(1, 1fr)",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
}));

export default function Mentors({}) {
  const { grid, container } = useStyles();

  const { getAllUsers } = useUsers();

  const history = useHistory();

  return (
    <Section.Container
      title={
        <>
          Featured Mentors
          <HelpTag message="Mentors needed to start from somewhere! Read about their tech career journey and soak in their wisdom. Hit the subscribe button if you like what you read!." />
        </>
      }
      // style={{backgroundColor: 'green'}}
      className={container}
      viewMore
      onViewMore={() => {
        history.push(`/mentors`);
      }}
    >
      <div className={grid}>
        {getAllUsers()
          .slice(0, 4)
          .map((mentor, index) => {
            return <WebMentorThumbnail mentor={mentor} key={index} />;
          })}
      </div>
    </Section.Container>
  );
}
