import React, { useEffect, useState } from "react";
import "./ArticleThumbnail.scss";
import MentorThumbnail from "../../Mentor/MentorThumbnail/MentorThumbnail";
import { Link } from "react-router-dom";
import { Firebase } from "../../../apis";
import {getAllUsersFromGraphQL, getUserByIdFromGraphQL, getUsersByListOfIdsFromGraphQL} from "../../../azureGraphQL/userQuery";


export default function ArticleThumbnail({ article }) {
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    // Youtube.getSnippet(video.youtubeId).then(setSnippet);
    const userPromise = getUserByIdFromGraphQL(article.author)
        .then(async (authorSnapshot) => {
          if (authorSnapshot) {
            setAuthor(authorSnapshot);
          }
        });
    /*
      .collection("users")
      .doc(article.author)
      .get()
      .then((authorSnapshot) => {
        const fetchedAuthor = authorSnapshot.data();
        setAuthor(fetchedAuthor);
      });
    */
  }, []);

  return (
    <Link to={`/articles/${article.id}`}>
      <div className="articleThumbnail">
        <div
          className="articleThumbnail__image"
          style={{ backgroundImage: `url(${article.mainImage})` }}
        ></div>
        <div className="articleThumbnail__content">
          <h4 className="articleThumbnail__title">{article.title}</h4>
          <MentorThumbnail mentor={author} wide={true}></MentorThumbnail>
        </div>
      </div>
    </Link>
  );
}
