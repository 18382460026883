const careers = [];
const students = [];
const links = {
  linkedIn: null,
  instagram: null,
  twitter: null,
  website: null,
};

export const newMentorDetails = {
  careers,
  students,
  bio: null,
  image: null,
  job: null,
  company: null,
  name: null,
  mentor: true,
};
