import React from "react";
import { useState, useEffect } from "react";
import "./AnnouncementManageScreen.scss";
import { useAuth } from "../../../Providers";
import { useAnnouncement } from "../../../Providers/AnnouncementProvider/AnnouncementProvider";
import WebNav from "../../../components/Layout/Nav/NavBar/WebNav";
import { useHistory } from "react-router";
import Modal from "react-modal";
import AnnouncementManageCard from "../../../components/Announcements/AnnouncementManageCard/AnnouncementManageCard";

const AnnouncementManageScreen = () => {
  const history = useHistory();
  const { authUserDetails, isUserMentee } = useAuth();
  const { announcements, deleteAnnouncement } = useAnnouncement();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState("");

  useEffect(() => {
    if (isUserMentee || (!isUserMentee && !authUserDetails.isTeamMember)) {
      history.push("/");
    }
  }, []);

  const onDeleteAnnouncement = async (id) => {
    await deleteAnnouncement(id);
  };

  const deletePopup = (id) => {
    setShowPopup(true);
    setSelectedAnnouncement(id);
  };

  return (
    <div className="announcement-manage--background">
      <WebNav />
      <Modal
        isOpen={showPopup}
        contentLabel="Are you sure you want to delete your announcement post?"
      >
        <h3 className="announcement-manage__popup-warning">
          Are you sure you want to delete your announcement post?
        </h3>
        <div className="announcement-manage__popup-message">
          Your announcement post will be permanently deleted and will not appear
          on the platform
        </div>
        <div className="announcement-manage__popup-options">
          <div
            onClick={() => {
              setShowPopup(false);
              onDeleteAnnouncement(selectedAnnouncement);
            }}
          >
            DELETE
          </div>
          <div onClick={() => setShowPopup(false)}>GO BACK</div>
        </div>
      </Modal>
      <div className="announcement-manage">
        <div className="announcement-manage__container">
          <div className="announcement-manage__header">
            <h1>Manage Announcements</h1>
            <hr />
          </div>
          <div className="blog-mentor__display">
            {announcements.length ? (
              announcements.map((announcement) => (
                <AnnouncementManageCard
                  key={announcement.id}
                  id={announcement.id}
                  imageUrl={announcement.image.url}
                  date={announcement.published_at}
                  deletePopup={deletePopup}
                  title={announcement.title}
                />
              ))
            ) : (
              <h2 className="blog-mentor__list-message">No Announcements</h2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementManageScreen;
