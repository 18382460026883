import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  TaskTracker,
  ButtonDefault,
  MentorThumbnail,
  CareerThumbnail,
  HelpTag,
  Screen,
  H2,
  Section,
  Track,
  H3,
} from "../../../components";
import { useCareers } from "../../../Providers";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "5rem",
    paddingTop: 0,
  },
  grid: {
    display: "grid",
    gap: "3rem",
    marginTop: "5rem",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridAutoRows: "5rem",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
}));

export default function Careers({}) {
  const { grid, container } = useStyles();

  const { getAllCareers } = useCareers();

  const history = useHistory();

  const [careers, setCareers] = useState([]);

  // useEffect(() => {
  //   getAllCareers().then(async (data) => {
  //     setCareers(data);
  //   });
  // }, []);

  return (
    <Section.Container
      title={
        <>
          Explore Careers{" "}
          <HelpTag message="Curious about life after high school? Get a head start and learn about the many tech careers that you can potentially do!" />
        </>
      }
      // style={{backgroundColor: 'green'}}
      className={container}
      viewMore
      onViewMore={() => {
        history.push(`/careers`);
      }}
    >
      <div className={grid}>
        {careers.slice(0, 6).map((career, index) => {
          return (
            <ButtonDefault
              key={index}
              onClick={() => {
                history.push(`/careers/${career.id}`);
              }}
            >
              {career.name}
            </ButtonDefault>
          );
        })}
      </div>
    </Section.Container>
  );
}
