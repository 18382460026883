import queryFetch from "../azureGraphQL/queryFetch";
import azure from "azure-storage";

class Azure{

    azureImagesURL = "https://risingstarimages.blob.core.windows.net/public/"
    sasToken = "se=2022-12-31&sp=rwdlac&sv=2018-03-28&ss=b&srt=sco&sig=Px0k3yTr2YTKRB0nA0LG1XZ6b9oib0EbSHXNvVBsNu4%3D"; // Fill string with your SAS token
    containerName = `public`;
    storageAccountName = "risingstarimages"; // Fill string with your Storage resource name
    concnStr = "DefaultEndpointsProtocol=https;AccountName=risingstarimages;AccountKey=LljFi/RRE//O3sd2nuqqt82I/HU9t9vAM1EdxHyXA08Csxs+jvCNj98O2wXoxYeD+iiywUylss4+kXiY3PtHeA==;EndpointSuffix=core.windows.net"

    // Feature flag - disable storage feature to app if not configured
    async isStorageConfigured () {
        return !((!this.storageAccountName || !this.sasToken));
    }

    async uploadImgToAzure(image, fileName) {
        return new Promise((resolve, reject) => {
    
            let matchesBlobImg = image.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
            let imgType = matchesBlobImg[1]
            let imgBuffer = new Buffer.from(matchesBlobImg[2], 'base64')
            var imgFileType = imgType.split("/")[1];
            var filename = fileName + `.${imgFileType}`
            
            //const blobService = new BlobServiceClient(`https://${this.storageAccountName}.blob.core.windows.net${this.sas}`);
            const blobService = azure.createBlobService(this.concnStr);
            console.log(blobService)
            blobService.createBlockBlobFromText(this.containerName, filename, imgBuffer, {
                contentSettings: { contentType: imgType }
            }, (error, result, response) => {
                if (error)
                    reject(error);
                console.log("🚀 ~ uploadImgToAzure ~ response", response)
                console.log("🚀 ~ uploadImgToAzure ~ result", result)
                const fileUrl = `https://${this.storageAccountName}.blob.core.windows.net/${this.containerName}/${filename}`;
                resolve(fileUrl)
            })
        })
    }

    async upsertMentorFromGraphQL(variable){
        try{
            console.log({mentorData: variable})
            const data = await queryFetch(`
            mutation upsertMentor ($mentor: MentorInput!){
                upsertMentor(mentorData: $mentor) {
                     mentor{
                        id,
                        partitionKey,
                        rowKey,
                        mentor,
                        joined,
                        name,
                        company,
                        bio,
                        students,
                        job,
                        links{
                            website,
                            linkedIn,
                            twitter,
                            instagram
                        },
                        location{
                            country,
                            province,
                            city
                        },
                        image,
                        allowViewProfile,
                        email,
                        degrees,
                        careers
                    }
                    ok
                }
            }
            `, 
            {mentor: variable});
            return data;
        }
        catch (error) {
            throw error;
        }
    }
}

export default new Azure();
