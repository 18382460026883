import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import WebNav from "../../../components/Layout/Nav/NavBar/WebNav";
import { AuthUserContext } from "../../../Providers";
import { useBlogs } from "../../../Providers/BlogsProvider/BlogsProvider";
import { v4 as uuidv4 } from "uuid";
import "./AnnouncementSubmitScreen.scss";
import AnnouncementForm from "../../../components/Announcements/AnnouncementForm/AnnouncementForm";

const AnnouncementSubmitScreen = () => {
    const { authUserDetails, isUserMentee } = useContext(AuthUserContext);
    const history = useHistory();

    useEffect(() => {
        if (isUserMentee || (!isUserMentee && !authUserDetails.isTeamMember)) {
            history.push("/");
        }
    }, []);

    const onAnnouncementSubmit = async (title, text, image, submit) => {
        const user = authUserDetails;
        const announcementReq = await fetch(
            "https://blog.risingstar.blackmint.io/announcements",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    id: uuidv4(),
                    title,
                    text: text.toString(),
                    image,
                    user,
                    viewers: [],
                    published_at: submit ? undefined : null,
                }),
            }
        );

        if (announcementReq.status !== 200) {
            alert("Error with submitting announcements");
        }

        const announcementRes = await announcementReq.json();
        return announcementRes.id;
    };

    return (
        <div className="announcement-submit--background">
            <WebNav />
            <div className="announcement-submit">
                <div className="announcement-submit__container">
                    <div className="announcement-submit__container--underline">
                        <h1 className="announcement-submit__h1">
                            Create your announcement
                        </h1>
                    </div>
                </div>
            </div>

            <AnnouncementForm onAnnouncementSubmit={onAnnouncementSubmit} />
        </div>
    );
};

export default AnnouncementSubmitScreen;
