import React, { createContext, useState, useEffect, useContext } from "react";
import { pushToObject } from "../../helpers";
import { Firebase } from "../../apis";

export const ArticlesContext = createContext();

export const useArticles = () => {
  const {
    updateArticlesStore,
    addArticleToStore,
    addArticlesToStore,
    refreshArticlesStore,
    refreshArticle,
    getArticle,
    getArticles,
    getAllArticles,
    initializeArticles,
    articles,
  } = useContext(ArticlesContext);
  return {
    updateArticlesStore,
    addArticleToStore,
    addArticlesToStore,
    refreshArticlesStore,
    refreshArticle,
    getArticle,
    getArticles,
    getAllArticles,
    initializeArticles,
    articles,
  };
};

export default function ArticlesProvider({ children }) {
  useEffect(() => {
    return Firebase.auth.onAuthStateChanged(async (auth) => {
      if (auth) {
        initializeArticles();
      }
    });
  }, []);

  const initializeArticles = async () => {
    const result = await Firebase.getCollection(Firebase.collections.articles);
    if (result) updateArticlesStore(result);
  };

  const [articles, setArticles] = useState({});

  const updateArticlesStore = (newArticles) => {
    setArticles(pushToObject({}, newArticles));
  };

  const addArticleToStore = (newArticle) =>
    setArticles((articles) => ({ ...articles, [newArticle.id]: newArticle }));

  const addArticlesToStore = (newArticles) =>
    setArticles((articles) => pushToObject(articles, newArticles));

  const refreshArticlesStore = async () => {
    const articleListSnapshot = await Firebase.getList(
      Firebase.collections.articles
    )
      .where("id", "in", Object.keys(articles))
      .get();
    const articles = Firebase.snapshotArrayToDataArray(articleListSnapshot);
    if (articles) setArticles(pushToObject({}, articles));
  };

  const getArticle = async (id) => {
    if (articles[id]) return articles[id];
    const result = await Firebase.get(Firebase.collections.articles, id);
    addArticleToStore(result);
    return result;
  };

  const getArticles = async (arrayOfIds) => {
    const returnedArticles = [];
    const unStoredArticles = [];
    arrayOfIds.forEach((id) => {
      const article = articles[id];
      if (article) returnedArticles.push(article);
      else unStoredArticles.push(id);
    });
    if (!unStoredArticles.length) return returnedArticles;
    else {
      const result = await Firebase.store
        .collection(Firebase.collections.articles)
        .where("id", "in", unStoredArticles)
        .get();
      const resultData = Firebase.snapshotArrayToDataArray(result);
      if (resultData) {
        returnedArticles.push(...resultData);
        addArticlesToStore(resultData);
      }
      return returnedArticles;
    }
  };

  const refreshArticle = async (id) => {
    const result = await Firebase.get(Firebase.collections.articles, id);
    addArticleToStore(result);
    return result;
  };

  const getAllArticles = () => {
    return Object.values(articles);
  };

  return (
    <ArticlesContext.Provider
      value={{
        updateArticlesStore,
        addArticleToStore,
        addArticlesToStore,
        refreshArticlesStore,
        refreshArticle,
        getArticle,
        getArticles,
        getAllArticles,
        initializeArticles,
        articles,
      }}
    >
      {children}
    </ArticlesContext.Provider>
  );
}
