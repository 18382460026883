import React, { createContext, useState, useEffect, useContext } from "react";
import { pushToObject } from "../../helpers";
import { Firebase } from "../../apis";
import {
  getAllDegreesFromGraphQL,
  getDegreeByIdFromGraphQL,
  getDegreesByListOfIdsFromGraphQL,
} from "../../azureGraphQL/degreeQuery";
// import { getCareerByIdFromGraphQL } from "../../azureGraphQL/careerQuery";
export const DegreesContext = createContext();

export const useDegrees = () => useContext(DegreesContext);

export default function DegreesProvider({ children }) {
  useEffect(() => {
    return Firebase.auth.onAuthStateChanged(async (auth) => {
      if (auth) {
        initializeDegrees();
      }
    });
  }, []);

  const initializeDegrees = async () => {
    const data = await getAllDegreesFromGraphQL();
    if (data) updateDegreesStore(data);
    return data;
  };

  const [degrees, setDegrees] = useState({});

  const updateDegreesStore = (newDegrees) => {
    setDegrees(pushToObject({}, newDegrees));
  };

  const addDegreeToStore = (newDegree) =>
    setDegrees((degrees) => ({ ...degrees, [newDegree.id]: newDegree }));

  const addDegreesToStore = (newDegrees) =>
    setDegrees((degrees) => pushToObject(degrees, newDegrees));

  /*

  const refreshDegreesStore = async () => {
    const degreeListSnapshot = await Firebase.getList(
      Firebase.collections.degrees
    )
      .where("id", "in", Object.keys(degrees))
      .get();
    const degrees = Firebase.snapshotArrayToDataArray(degreeListSnapshot);
    if (degrees) setDegrees(pushToObject({}, degrees));
  }; 

  */

  const getDegree = async (id) => {
    if (degrees[id]) return degrees[id];
    //const result = await Firebase.get(Firebase.collections.degrees, id);
    const result = await getDegreeByIdFromGraphQL(id);
    addDegreeToStore(result);
    return result;
  };

  const refreshDegree = async (id) => {
    //const result = await Firebase.get(Firebase.collections.degrees, id);
    const result = await getDegreeByIdFromGraphQL(id);
    addDegreeToStore(result);
    return result;
  };

  const getAllDegrees = () => {
    return Object.values(degrees);
  };

  return (
    <DegreesContext.Provider
      value={{
        updateDegreesStore,
        addDegreeToStore,
        addDegreesToStore,
        // refreshDegreesStore,
        refreshDegree,
        getAllDegrees,
        getDegree,
        initializeDegrees,
        degrees,
      }}
    >
      {children}
    </DegreesContext.Provider>
  );
}
