import React, { createContext, useState, useEffect, useContext } from "react";
import { pushToObject } from "../../helpers";
import {getAllVideosFromGraphQL, getVideoByIdFromGraphQL, getVideosByListOfIdsFromGraphQL} from "../../azureGraphQL/videoQuery";
import { Firebase } from "../../apis";
export const VideosContext = createContext();

export const useVideos = () => useContext(VideosContext);

export default function VideosProvider({ children }) {
  useEffect(() => {
    return Firebase.auth.onAuthStateChanged(async (auth) => {
      if (auth) {
        initializeVideos();
      }
    });
  }, []);

/*   const initializeVideos = async () => {
    const result = await Firebase.getCollection(Firebase.collections.videos);
    if (result) updateVideosStore(result);
    return result;
  }; */

  const initializeVideos = async () => {
    const data = await getAllVideosFromGraphQL();
    if (data) updateVideosStore(data);
    return data;
  };

  const [videos, setVideos] = useState({});

  const updateVideosStore = (newVideos) =>
    setVideos(pushToObject({}, newVideos));

  const addVideoToStore = (newVideo) =>
    setVideos((videos) => ({ ...videos, [newVideo.id]: newVideo }));

  const addVideosToStore = (newVideos) =>
    setVideos((videos) => pushToObject(videos, newVideos));
  
  
  /*
  
   const refreshVideosStore = async () => {
    const videoListSnapshot = await Firebase.getList(
      Firebase.collections.videos
    )
      .where("id", "in", Object.keys(videos))
      .get();
    const videos = Firebase.snapshotArrayToDataArray(videoListSnapshot);
    if (videos) setVideos(pushToObject({}, videos));
  }; 
  */
  

  const getVideo = async (id) => {
    if (videos[id]) return videos[id];
    //const result = await Firebase.get(Firebase.collections.videos, id);
    const result = await getVideoByIdFromGraphQL(id);
    addVideoToStore(result);
    return result;
  };

  const getVideos = async (arrayOfIds) => {
    const returnedVideos = [];
    const unStoredVideos = [];
    arrayOfIds.forEach((id) => {
      const video = videos[id];
      if (video) returnedVideos.push(video);
      else unStoredVideos.push(id);
    });
    if (!unStoredVideos.length) return returnedVideos;
    else {
      /*
      const result = await Firebase.store
        .collection(Firebase.collections.videos)
        .where("id", "in", unStoredVideos)
        .get();
      result.docs.forEach((data) => {
        console.log(data.data());
      });
      const resultData = Firebase.snapshotArrayToDataArray(result);
      */
      const resultData = await getVideosByListOfIdsFromGraphQL(unStoredVideos);
      if (resultData) {
        returnedVideos.push(...resultData);
        addVideosToStore(resultData);
      }
      return returnedVideos;
    }
  };

  const refreshVideo = async (id) => {
    const result = await getVideoByIdFromGraphQL(id);
    //const result = await Firebase.get(Firebase.collections.videos, id);
    addVideoToStore(result);
    return result;
  };
  
  // const getAllVideos = () => {
  //   return Object.values(videos);
  // };

  const getAllVideos = async () => {
    const data = await getAllVideosFromGraphQL();
    return Object.values(data);
  };

  return (
    <VideosContext.Provider
      value={{
        updateVideosStore,
        addVideoToStore,
        addVideosToStore,
        //refreshVideosStore,
        refreshVideo,
        getVideo,
        getVideos,
        getAllVideos,
        initializeVideos,
        videos,
      }}
    >
      {children}
    </VideosContext.Provider>
  );
}
