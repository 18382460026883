import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import LogoDefault from "../../Logos/WebLogoDark";
import { BottomNavigation } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "right",
    backgroundColor: "#272727",
    padding: "2rem 2rem",
    alignItems: "center",
    // paddingLeft: "60rem",
  },
  footerLink: {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: "400",
    color: "white",
    letterSpacing: "2px",
    marginBottom: "0.5rem",
    width: "max-content",
    "&:hover": {
      color: "#4DC591",
    },

  },
  logoBox: {
    // width: "20rem",
    // height: "3rem",
    // paddingBottom: "2rem",
    display: 'flex',
    alignItems: 'center'
  },
  container:{
    marginLeft: 'auto',
    display: 'grid',
    gridTemplateColumns: "repeat(4, max-content)",
    columnGap: '2rem',
    marginRight: '4rem',
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  }
}));

export default function WebFooter() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
          <a href="https://www.blackmint.io" className={classes.footerLink}>
            About
          </a>
          <a href="https://www.blackmint.io" className={classes.footerLink}>
            FAQ
          </a>
          <a href="https://www.blackmint.io" className={classes.footerLink}>
            Contact
          </a>
          <a href="https://www.blackmint.io" className={classes.footerLink}>
            Privacy Policy
          </a>
      </div>
      <div className={classes.logoBox}>
        <LogoDefault style={{ width: "250px" }}></LogoDefault>
      </div>
    </footer>
  );
}
