import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import quoteImage from "./images/quote.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding:
      "var(--padding-screen-large-top) var(--padding-screen-large-side) 0 var(--padding-screen-large-side)",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "var(--padding-screen-medium-side)",
      paddingRight: "var(--padding-screen-medium-side)",
    },
    [theme.breakpoints.down("xs")]: {
      padding:
        "var(--padding-screen-large-top) var(--padding-screen-small-side) 0 var(--padding-screen-small-side)",
    },
  },
  testimonials: {
    gap: "4rem",
    // height: "40rem",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: 'self-end',
    // gridAutoRows: "1fr",
    // minHeight: "90rem",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  landingScreenSection: {
    backgroundColor: "var(--color-secondary-light)",
    textAlign: "center",
    paddingTop: "5rem",
    paddingBottom: "5rem",
    // paddingLeft: "15rem",
    // paddingRight: "15rem",
    // [theme.breakpoints.down("sm")]: {
    //   paddingRight: "3rem",
    //   paddingLeft: "3rem",
    // },
  },
  testimonial: {
    marginBottom: "3rem",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
}));

export default function Testimonials({}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.landingScreenSection, classes.root)}
      style={{ marginTop: "5rem" }}
    >
      <div style={{}}>
        <div style={{}} className={classes.testimonials}>
          {testimonials.map((testimonial) => <TestimonialThumbnail {...testimonial}/>)}
        </div>
      </div>
    </div>
  );
}

const testimonials = [
  {
    text: "I feel this app would help me know what I need to do to get there and there’s mentors here too I can talk to.",
    image: require('./images/test-avatar-1.png'),
    name: "Robert Anderson",
    grade: "10",
  },
  {
    text: "I think this app is very helpful because people get into jobs without knowing how much they would be making.",
    image: require('./images/test-avatar-2.png'),
    name: "Amanda Kohl",
    grade: "10",
  },
  {
    text: "I think this app is very helpful because people get into jobs without knowing how much they would be making.",
    image: require('./images/test-avatar-2.png'),
    name: "Amanda Kohl",
    grade: "10",
  },
];

function TestimonialThumbnail({ text, image, grade, name }) {
  const classes = useStyles();

  return (
    <div>
      <div
        className={clsx(
          "landingScreenTestimonials__group card borderRadius-medium",
          classes.testimonial
        )}
      >
        <svg
          className="landingScreenTestimonials__blob landingScreenTestimonials__blob-1"
          width="333"
          height="201"
          viewBox="0 0 333 201"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M157.943 3.48274C191.575 -1.56935 230.226 -2.48804 258.535 10.1216C286.976 22.7898 291.098 47.4832 303.205 68.1865C315.965 90.0064 340.146 111.454 330.971 133.991C321.654 156.876 287.784 171.853 256.013 184.107C226.358 195.545 192.511 202.489 157.943 200.728C125.101 199.055 92.4588 190.381 70.655 174.664C50.8964 160.422 58.6394 138.674 47.4267 121.067C34.8175 101.268 -8.1241 86.3391 1.35418 65.8447C10.7632 45.5002 58.5549 47.1697 86.8065 35.9184C112.155 25.8235 128.84 7.85461 157.943 3.48274Z"
            fill="#4DC591"
            fill-opacity="0.5"
          />
        </svg>
        <svg
          className="landingScreenTestimonials__blob landingScreenTestimonials__blob-2"
          width="273"
          height="190"
          viewBox="0 0 273 190"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M129.485 3.29214C157.057 -1.48347 188.744 -2.35188 211.952 9.56771C235.268 21.5426 238.648 44.8846 248.573 64.4549C259.034 85.0807 278.858 105.354 271.336 126.658C263.699 148.29 235.931 162.448 209.885 174.032C185.573 184.844 157.824 191.408 129.485 189.743C102.561 188.162 75.7996 179.962 57.9243 165.105C41.7259 151.643 48.0737 131.085 38.8813 114.442C28.5441 95.7258 -6.66029 81.614 1.11018 62.2413C8.82384 43.0101 48.0044 44.5882 71.1657 33.9527C91.9465 24.4102 105.625 7.42476 129.485 3.29214Z"
            fill="#150485"
            fill-opacity="0.5"
          />
        </svg>
        <div className="landingScreenTestimonials__group__content borderRadius-medium card">
          <div
            className="landingScreenTestimonials__group__content__quote"
            style={{ backgroundImage: `url(${quoteImage})` }}
          ></div>
          <p className="landingScreenTestimonials__group__content__text">
            {text}
          </p>
        </div>
      </div>
      <div className="landingScreenTestimonials__author">
        <div className="landingScreenTestimonials__author__thumbnail" style={{backgroundImage: `url('${image}')`}}></div>
        <div className="landingScreenTestimonials__author__text">
          <div className="landingScreenTestimonials__author__name">
            {name}
          </div>
          <div className="landingScreenTestimonials__author__job color-primary">
            Grade {grade}
          </div>
        </div>
      </div>
    </div>
  );
}
