import React, { useState } from "react";
import "./FormRadioGroup.scss";
import FormRadio from "../FormRadio/FormRadio";

export default function FormRadioGroup({
  title,
  name,
  radioData = [],
  onChange,
  initialValue,
}) {

  const [value, setValue] = useState()
  return (
    <div className="formRadioGroup">
      <h3 className="formRadioGroup__title">{title}</h3>
      <div className="formRadioGroup__radios">
        {radioData.map((data) => {
          return (
            <FormRadio
              name={name}
              placeholder={data.placeholder}
              value={data.value}
              onClick={onChange}
              checked={initialValue === data.value}
            />
          );
        })}
      </div>
    </div>
  );
}
