import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { ButtonTransparent, H2 } from "../..";
import { blackmintTheme } from "../../../style/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    // marginRight: layout.paddingSideScreen,
    // marginLeft: layout.paddingSideScreen,
    padding: `var(${blackmintTheme.layout.paddingVertical}) var(${blackmintTheme.layout.paddingSide})`,
    marginBottom: "3rem",
    // paddingHorizontal: layout.paddingSideScreen,
  },
  title: {
    marginBottom: "3rem",
  },
  seeMore: {
    marginTop: "3rem",
  },
}));

function Container({
  children,
  style,
  title,
  className = "",
  containerClassName='',
  bodyStyle,
  viewMore,
  onViewMore,
}) {

  const styles = useStyles();

  return (
    <div className={clsx(styles.container, className)} style={style}>
      {title && <Section.Title>{title}</Section.Title>}
      <div className={containerClassName} style={{ ...bodyStyle }}>{children}</div>
      {viewMore && (
        <Section.ViewMore
          text={viewMore === true ? null : viewMore}
          onViewMore={onViewMore}
        />
      )}
    </div>
  );
}

function Title({ text, children, style, className = "" }) {
  const styles = useStyles();
  return (
    <H2 className={clsx(styles.title, className)} style={{display: 'flex', alignItems: 'center'}}>
      {text || children}
    </H2>
  );
}

function ViewMore({ text, children, style, onViewMore = () => {} }) {
  const styles = useStyles();
  return (
    <div className={styles.seeMore} style={style}>
      <ButtonTransparent
        onClick={() => {
          onViewMore();
        }}
      >
        {text || children || "See more"}
      </ButtonTransparent>
    </div>
  );
}

const Section = {
  Title,
  Container,
  ViewMore,
};

export default Section;
