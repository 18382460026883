import React, { createContext, useState, useEffect, useContext } from "react";
import { pushToObject } from "../../helpers";
import { Firebase } from "../../apis";

export const InstitutionsContext = createContext();

export const useInstitutions = () => useContext(InstitutionsContext);

export default function InstitutionsProvider({ children }) {
  useEffect(() => {
    return Firebase.auth.onAuthStateChanged(async (auth) => {
      if (auth) {
        initializeInstitutions();
      }
    });
  }, []);

  const initializeInstitutions = async () => {
    const result = await Firebase.getCollection(
      Firebase.collections.institutions
    );
    if (result) updateInstitutionsStore(result);
    return result;
  };

  const [institutions, setInstitutions] = useState({});

  const updateInstitutionsStore = (newInstitutions) => {
    setInstitutions(pushToObject({}, newInstitutions));
  };

  const addInstitutionToStore = (newInstitutions) =>
    setInstitutions((institutions) => ({
      ...institutions,
      [newInstitutions.id]: newInstitutions,
    }));

  const addInstitutionsToStore = (newInstitutions) =>
    setInstitutions((institutions) =>
      pushToObject(institutions, newInstitutions)
    );

  const refreshInstitutionsStore = async () => {
    const institutionListSnapshot = await Firebase.getList(
      Firebase.collections.institutions
    )
      .where("id", "in", Object.keys(institutions))
      .get();
    const institutions = Firebase.snapshotArrayToDataArray(
      institutionListSnapshot
    );
    if (institutions) setInstitutions(pushToObject({}, institutions));
  };

  const getInstitution = async (id) => {
    if (institutions[id]) return institutions[id];
    const result = await Firebase.get(Firebase.collections.institutions, id);
    addInstitutionToStore(result);
    return result;
  };

  const refreshInstitution = async (id) => {
    const result = await Firebase.get(Firebase.collections.institutions, id);
    addInstitutionToStore(result);
    return result;
  };

  const getAllInstitutions = () => {
    return Object.values(institutions);
  };

  return (
    <InstitutionsContext.Provider
      value={{
        updateInstitutionsStore,
        addInstitutionToStore,
        addInstitutionsToStore,
        refreshInstitutionsStore,
        refreshInstitution,
        getAllInstitutions,
        getInstitution,
        initializeInstitutions,
        institutions,
      }}
    >
      {children}
    </InstitutionsContext.Provider>
  );
}
