import React, { useRef, useEffect, useState } from "react";
import Select from "react-select";
import "./DropdownInput.scss";

export default function DropdownInput({
  placeholder,
  type,
  name,
  required = true,
  inputRef,
  invalid = false,
  value = null,
  onChange = () => {},
  options = null,
  disabled,
  multi,
  limit,
  ...rest
}) {
  const ref = useRef();

  const [inputValue, setInputValue] = useState(value);

  if (!inputRef) inputRef = ref;

  const changeHandler = (selectedValue) => {
    if (multi) {
      if ((selectedValue && limit && selectedValue.length <= limit) || !limit) {
        setInputValue(selectedValue);
        onChange(selectedValue, name);
      }
      if (selectedValue === null) {
        setInputValue([]);
        onChange([], name);
      }
    } else {
      setInputValue(selectedValue.value);
      onChange(selectedValue.value, name);
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div
      className={"dropdownInput"}
      //   style={{ height: "59px" }}
    >
      <Select
        name={name}
        ref={inputRef}
        className={"dropdownInput__input"}
        placeholder={placeholder}
        value={
          inputValue
            ? multi
              ? [...inputValue]
              : { label: inputValue, value: inputValue }
            : null
        }
        options={options}
        onChange={changeHandler}
        isDisabled={disabled}
        isMulti={multi}
        {...rest}
        styles={{
          control: (style) => ({
            ...style,
            border: "none",
            backgroundColor: "transparent",
            outline: "none",
            boxShadow: "none",
          }),
          menu: (style) => ({
            ...style,
            boxShadow: "0 1rem 2rem rgba(black, 0.1)",
            border: "1px solid var(--color-primary)",
            borderBottom: "3px solid var(--color-primary)",
            borderRadius: "10px",
          }),
        }}
      />
    </div>
  );
}
