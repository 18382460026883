import React, { useState, useEffect } from "react";
import "./FormUploadImage.scss";
import { Grid, makeStyles, Avatar } from "@material-ui/core";
import ButtonDefault from "../../../Buttons/ButtonDefault/ButtonDefault";

export default function FormUploadImage({
  placeholder,
  type,
  name,
  required = true,
  inputRef,
  invalid = false,
  value = null,
  onChange = () => {},
  options = null,
  ...rest
}) {
  const [image, setImage] = useState(value);

  useEffect(() => {
    setImage(value);
  }, [value]);

  const changeHandler = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      const reader = new FileReader();
      const originalEvent = e;
      reader.onload = (e) => {
        setImage(e.target.result);
        onChange({
          ...originalEvent,
          target: {
            ...originalEvent.target,
            name: name,
            value: e.target.result,
          },
        });
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const useStyles = makeStyles((theme) => ({
    container: {
      background: "#F1F1EE",
      // height: '10rem'
      position: "relative",
      // display: 'flex'
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    input: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      opacity: 0,
      outline: "none",
      border: "none",
    },
    avatar: {
      height: theme.spacing(20),
      width: theme.spacing(20),
    },
  }));

  const styles = useStyles();

  return (
    <Grid
      container
      className={styles.container}
      spacing={2}
      direction="column"
      alignItems="center"
    >
      <input
        type="file"
        required={required}
        className={styles.input}
        id={name}
        name={name}
        onChange={changeHandler}
        accept="image/*"
        {...rest}
      />
      <Grid item>
        <Avatar className={styles.avatar} src={image} />
      </Grid>
      <Grid item>
        <label for={name}>
          <ButtonDefault type="button" style={{pointerEvents:'none'}}>Upload</ButtonDefault>
        </label>
      </Grid>
    </Grid>
  );

  return (
    <div className="formUploadImage card hoverEffect borderRadius-medium">
      <input
        type="file"
        required={required}
        className="formUploadImage__input"
        id={name}
        name={name}
        onChange={changeHandler}
        accept="image/*"
        {...rest}
      />
      <label
        for={name}
        className="formUploadImage__label"
        style={{ backgroundImage: image ? `url(${image})` : "none" }}
      >
        {value ? null : "+"}
      </label>
    </div>
  );
}
