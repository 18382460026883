import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import NavBar from "../Nav/NavBar/NavBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridAutoRows: "min-content",
    minHeight: "100vh",
    // padding: "6rem 0 0 0",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      paddingRight: "0",
    },
    [theme.breakpoints.down("xs")]: {
      // padding: "6rem 0 0 0",
    },
    // padding:
    //   "6rem var(--padding-screen-large-side) 0 var(--padding-screen-large-side)",
    // [theme.breakpoints.down("sm")]: {
    //   paddingLeft: "var(--padding-screen-medium-side)",
    //   paddingRight: "var(--padding-screen-medium-side)",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   padding:
    //     "6rem var(--padding-screen-small-side) 0 var(--padding-screen-small-side)",
    // },
  },
}));

export default function Screen({ children, style, className }) {
  const styles = useStyles();
  return (
    <div className={clsx(styles.root, className)} style={style}>
      {children}
    </div>
  );
}
