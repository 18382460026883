import React, { useState, useEffect } from "react";
import { ButtonDefault, H2, Screen, Section } from "../../../components";
import { useHistory } from "react-router";
import { useAuth, useCareers } from "../../../Providers";
import { makeStyles } from "@material-ui/core/styles";
import { blackmintTheme } from "../../../style/theme";
import WebNavBar from "../../../components/Layout/Nav/NavBar/WebNav";
import withAuthorizationComponent from "../../../components/Auth/WithAuthorization/WithAuthorizationComponent";
import DegreeCard from "../../../components/Degree/DegreeCard";
import WebCareerCard from "../../../components/Career/WebCareerCard";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--color-background-default)",
    display: "flex",
    marginRight: 0,
    [theme.breakpoints.up("md")]: {
      paddingLeft: "290px",
    },
  },
  headerBg: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    paddingTop: "10rem",
    paddingBottom: "5rem",
    backgroundColor: "var(--color-primary)",
    textAlign: "center",
    marginBottom: "2rem",
  },
  secondaryHeading: {
    ...blackmintTheme.typographyBlackmint.h2,
    fontWeight: "bold",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.5rem",
    },
  },
  // careersList: {
  //   wordWrap: "break-word !important",
  //   display: "grid",
  //   rowGap: "11rem",
  //   columnGap: "2rem",
  //   gridTemplateColumns: "repeat(2, 1fr)",
  //   gridAutoRows: "10rem",
  // },
  grid: {
    //makes the element formatted as a grid
    display: "grid",
    gap: "3rem",
    marginTop: "5rem",
    //grid settings
    gridTemplateColumns: "repeat(2, 1fr)",
    //when window width goes to extra small size
    [theme.breakpoints.down("sm")]: {
      //changes the grid settings
      gridTemplateColumns: "repeat(2, 1fr)",
      //closes gap left when the sidebar is hidden
      paddingLeft: "0px",
    },
    //moves element over to not overlap with side navbar
    paddingLeft: "290px",
  },
  subHeader: {
    ...blackmintTheme.typographyBlackmint.h3,
    color: "#00664F",
  },
  aboutParagraph: {
    ...blackmintTheme.typographyBlackmint.mainContent,
    color: "#272727",
  },
}));

export function CareersListScreen() {
  const classes = useStyles();
  const history = useHistory();
  const { authUserDetails } = useAuth();
  const { getAllCareers } = useCareers();
  const [careers, setCareers] = useState([]);

  useEffect(() => {
    getAllCareers().then(async (data) => {
      setCareers(data);
    });
  }, []);

  return (
    <>
      <WebNavBar />
      <Screen className={classes.root}>
        <div style={{ flex: 1 }}>
          <div className={classes.headerBg}>
            <Typography component="h2" className={classes.secondaryHeading}>
              Our Careers
            </Typography>
          </div>
          <Section.Container>
            <div>
              <Typography className={classes.subHeader}>
                Career options for you
              </Typography>
              <Typography className={classes.aboutParagraph}>
                Here's a list of various career options in the tech industry for
                you to consider.
              </Typography>
            </div>

            <div
              style={{
                marginBottom: "2rem",
                marginTop: "4rem",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "2rem",
                // display: "flex",
                // alignItems: "center",
              }}
            >
              {careers
                .filter((career) => career.description)
                .map((career) => {
                  return (
                    <WebCareerCard
                      careerName={career.name}
                      linkTo={`/careers/${career.id}`}
                      imgLink={career.image}
                    />
                  );
                })}
            </div>
          </Section.Container>
        </div>
      </Screen>
    </>
  );
}

export default withAuthorizationComponent((auth) => auth)(CareersListScreen);
