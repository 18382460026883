import React, { useEffect, useState } from "react";
import { FormSingleInput, Comment, Section } from "../../../../components";
import { Firebase } from "../../../../apis";
import { useUsers, useTask, useAuth } from "../../../../Providers";
import { taskTypes } from "../../../../constants";
import { useParams } from "react-router";

export default function VideoComments({video}) {
  const { authUser, authUserDetails } = useAuth();

  const { taskableHandler } = useTask();
  const { getUser } = useUsers();
  const params = useParams();
  const [videoComments, setVideoComments] = useState([]);


  useEffect(() => {
    Firebase.store
    .collection("comments")
    .where("contentId", "==", params.id)
    .where("parent", "==", null)
    .get()
    .then((commentsSnapshot) => {
      const fetchedComments = commentsSnapshot.docs.map((commentDoc) =>
        commentDoc.data()
      );
      setVideoComments(fetchedComments.sort((a, b) => a - b));
    });
  }, [params, getUser]);

  const commentSubmitHandler = (event) => {
    event.preventDefault();
    const newComment = {
      type: "videoComment",
      contentId: video.id,
      dateCreated: Date.now(),
      author: authUser.uid,
      text: event.target["comment"].value,
      thread: [],
      parent: null,
    };
    const newCommentDoc = Firebase.store.collection("comments").doc();
    newCommentDoc
      .set({ ...newComment, id: newCommentDoc.id })
      .then(async () => {
        const commentsSnapshot = await Firebase.store
          .collection("comments")
          .where("contentId", "==", video.id)
          .where("parent", "==", null)
          .get();
        const fetchedComments = commentsSnapshot.docs.map((commentDoc) =>
          commentDoc.data()
        );
        setVideoComments(fetchedComments.sort((a, b) => a - b));
        if (!authUserDetails.mentor) {
          taskableHandler(taskTypes.leaveComment, authUserDetails);
        }
      });
  };

  return (
    <Section.Container style={{ paddingBottom: 0, paddingTop: 0 }}>
      <div className="videoScreenComments section__content">
        <div className="videoScreenComments__new">
          <FormSingleInput
            type="textarea"
            name="comment"
            invalid={false}
            onSubmit={commentSubmitHandler}
            placeholder="Your comment..."
          ></FormSingleInput>
        </div>
        <div className="videoScreenComments__comments fullWidth fullWidth-conditional">
          {videoComments.map((comment) => (
            <Comment comment={comment} key={comment.id}></Comment>
          ))}
        </div>
      </div>
    </Section.Container>
  );
}
