import React, { useEffect, useState } from "react";
import {
  TaskTracker,
  ButtonDefault,
  MentorThumbnail,
  VideoThumbnail,
  HelpTag,
  Screen,
  H2,
  Section,
  Track,
  H3,
} from "../../../components";
import WebVideoThumbnail from "../../../components/Video/WebVideoThumbnail/WebVideoThumbnail";

import Skeleton from "@material-ui/lab/Skeleton";
import { useVideos } from "../../../Providers";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '5rem',
    paddingTop: "3rem",
  },
  grid: {
    display: "grid",
    rowGap: "5rem",
    marginTop: "5rem",
  },
}));

export default function Videos({}) {
  //const { getAllVideos, videos } = useVideos();

  const history = useHistory();

  const { grid, container } = useStyles();

  const { getAllVideos } = useVideos();
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    getAllVideos().then(async (data) => {setVideos(data);});
  },[]);

  return (
    <Section.Container
      className={container}
      title={
        <>
          Most popular videos.
          <HelpTag message="Videos are created by BlackMINT’s mentors. Sit back and go on a learning binge watch. You can also ask the mentors’ questions in the Q&amp;A section." />
        </>
      }
      viewMore
      onViewMore={() => {
        history.push(`/videos`);
      }}
    >
      <div className={grid}>
        {!videos.length
          ? Array.from(Array(4).keys()).map(() => {
              return (
                <Skeleton
                  variant="rect"
                  width={"100%"}
                  height={"20rem"}
                  animation="wave"
                  style={{ borderRadius: 10 }}
                />
              );
            })
          : videos
              .slice(0, 4)
              .map((video, index) => {
                return (
                  <WebVideoThumbnail
                    video={video}
                    key={index}
                  ></WebVideoThumbnail>
                );
              })}
      </div>
    </Section.Container>
  );
}
