import React, { useEffect } from "react";
import { useAuth } from "../../../Providers";
import { useHistory } from "react-router";

// import * as ROUTES from '../../constants/routes';

const withAuthorizationComponent = (condition, route = "/") => (Component) => {
  const WithAuthorizationComponent = (props) => {
    const history = useHistory();
    const { authUser, authUserDetails } = useAuth();

    useEffect(() => {
      if (!condition(authUser, authUserDetails)) {
        history.push(route);
      }
    }, [authUserDetails]);

    return condition(authUser, authUserDetails) ? <Component /> : null;
  };

  return WithAuthorizationComponent;
};

export default withAuthorizationComponent;
