import React, { useState } from "react";
import "./SignUpScreen.scss";
import SignUpForm from "./SignUpForm/SignUpForm";
import SignUpInterests from "./SignUpInterests/SignUpInterests";
import SignUpMoreInfo from "./SignUpMoreInfo/SignUpMoreInfo";
import SignUpCareers from "./SignUpCareers/SignUpCareers";
import { Route, Switch } from "react-router";

function SignUpScreen() {
  return (
    <div className="signUpScreen screen">
      <h2 className="signUpScreen__heading heading-secondary">Sign Up</h2>
      <p className="signUpScreen__sub">Join Us!</p>
      <Switch>
        <Route path="/signup" exact={true}>
          <SignUpForm></SignUpForm>
        </Route>
        <Route path="/signup/moreInfo" exact={true}>
          <SignUpMoreInfo></SignUpMoreInfo>
        </Route>
        <Route path="/signup/interests" exact={true}>
          <SignUpInterests></SignUpInterests>
        </Route>
        <Route path="/signup/careers" exact={true}>
          <SignUpCareers></SignUpCareers>
        </Route>
      </Switch>
    </div>
  );
}

export const signUpDisplays = {
  email: "email",
  interests: "interest",
  moreInfo: "moreInfo",
  careers: "careers",
};

export default SignUpScreen;
