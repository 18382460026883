import React, { useState } from "react";
import "./FormSingleInput.scss";
import { FormInput } from "../..";
import { FormTextArea } from "../..";
import { ButtonDefault } from "../..";

export default function FormSingleInput({
  placeholder,
  type = "text",
  name,
  required = true,
  inputRef,
  invalid = false,
  value = "",
  onChange = () => {},
  options = null,
  onSubmit = () => {},
  button = "submit",
  ...rest
}) {
  const [currentValue, setCurrentValue] = useState(value);

  const submitHandler = (event) => {
    event.preventDefault();
    onSubmit(event);
    setCurrentValue("");
  };

  const changeHandler = (e) => {
    e.preventDefault();
    setCurrentValue(e.target.value);
    onChange(e);
  };

  return (
    <form className="formSingleInput" onSubmit={submitHandler}>
      <div className="formSingleInput__textArea">
        {type === "textarea" ? (
          <FormTextArea
            placeholder={placeholder}
            required
            name={name}
            inputRef={inputRef}
            value={currentValue}
            invalid={invalid}
            onChange={changeHandler}
            {...rest}
          ></FormTextArea>
        ) : (
          <FormInput
            placeholder={placeholder}
            required
            name={name}
            inputRef={inputRef}
            value={currentValue}
            invalid={invalid}
            onChange={changeHandler}
            {...rest}
            type={type}
          ></FormInput>
        )}
      </div>
      <ButtonDefault>{button}</ButtonDefault>
    </form>
  );
}
