import React, { createContext, useContext, useState } from "react";

const AnnouncementContext = createContext();
export const useAnnouncement = () => useContext(AnnouncementContext);

const AnnouncementProvider = ({ children }) => {
    const [announcements, setAnnouncements] = useState([]);
    const fetchAllAnnouncements = async () => {
        const res = await fetch(
            "https://blog.risingstar.blackmint.io/announcements"
        );
        const resJson = await res.json();
        setAnnouncements(resJson);
    };

    useState(() => {
        (async () => {
            await fetchAllAnnouncements();
        })();
    }, []);

    const getAnnouncement = async (id) => {
        const res = await fetch(
            `https://blog.risingstar.blackmint.io/announcements/${id}`
        );
        const announcement = await res.json();
        return announcement;
    };

    const deleteAnnouncement = async (id) => {
        const res = await fetch(
            `https://blog.risingstar.blackmint.io/announcements/${id}`,
            {
                method: "DELETE",
            }
        );

        if (res.status === 200) {
            fetchAllAnnouncements();
        }
    };

    return (
        <AnnouncementContext.Provider
            value={{
                announcements,
                getAnnouncement,
                deleteAnnouncement,
                fetchAllAnnouncements,
            }}
        >
            {children}
        </AnnouncementContext.Provider>
    );
};

export default AnnouncementProvider;
