import React from "react";
import "./ButtonDefault.scss";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btnRoot: {
    boxShadow: "none",
    backgroundColor: "var(--color-primary)",
    border: "none",
    borderRadius: "10px",
    padding: "1rem",
    fontWeight: 700,
    transition: "all .3s",
    "&:hover": {
      backgroundColor: "var(--color-primary-dark)",
      boxShadow: "0 5px 10px 0 rgba(0,0,0,.15)",
      transform: "translateY(-2px) scale(1.01)",
    },
  },
  btnLabel: {
    fontSize: "2rem",
    fontFamily: "var(--font-secondary)",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
    },
  },
}));

export default function ButtonDefault({
  children,
  variant = "default",
  isLoading = false,
  disabled = false,
  type='submit',
  onClick = () => {},
  ...rest
}) {
  const classes = useStyles();
  let backgroundColor = null;

  switch (variant) {
    case "default":
      backgroundColor = null;
      break;

    case "warning":
      backgroundColor = "var(--color-warning)";
      break;

    case "danger":
      backgroundColor = "var(--color-error)";
      break;

    default:
      backgroundColor = null;
      break;
  }

  return (
    <Button
      type={type}
      {...rest}
      onClick={onClick}
      disabled={isLoading || disabled}
      className={classes.btnRoot}
      variant="contained"
      classes={{ label: classes.btnLabel }}
    >
      {!isLoading ? children : "Loading"}
    </Button>
    // <button
    //   {...rest}
    //   style={{ backgroundColor }}
    //   disabled={isLoading || disabled}
    //   className="buttonDefault hoverEffect borderRadius-medium btnText"
    // >
    //   {!isLoading ? children : "Loading"}
    // </button>
  );
}
