import React, { useState } from "react";
import "./SignInScreen.scss";
import SignInForm from "./SignInForm/SignInForm";
import withAuthorizationComponent from "../../../components/Auth/WithAuthorization/WithAuthorizationComponent";
import ForgotPasswordPopUp from "../../../components/Auth/ForgotPasswordPopUp/ForgotPasswordPopUp";
import { Link } from "react-router-dom";

function SignInScreen() {
  const [forgotPassword, setForgotPassword] = useState(null);

  return (
    <div className="signInScreen screen">
      {forgotPassword ? (
        <ForgotPasswordPopUp
          setForgotPassword={setForgotPassword}
        ></ForgotPasswordPopUp>
      ) : null}
      <h2 className="signInScreen__heading heading-secondary">Sign In</h2>
      <div className="signInScreen__form">
        <SignInForm></SignInForm>
      </div>
      <div className="signInScreen__gotoSignUp">
        <Link to="/signup">Don't have an account?</Link>
      </div>
      <p
        onClick={() => {
          setForgotPassword(true);
        }}
        className="signInScreen__forgot"
      >
        Forgot Password
      </p>
    </div>
  );
}

export default withAuthorizationComponent((auth) => !auth, "/")(SignInScreen);
