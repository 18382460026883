import React, { useEffect } from "react";
import "./SettingsScreen.scss";
import {
  useHistory,
  Switch,
  Route,
  useLocation,
  NavLink,
} from "react-router-dom";
import withAuthorizationComponent from "../../../components/Auth/WithAuthorization/WithAuthorizationComponent";
import { useAuth } from "../../../Providers";
import SettingsProfile from "./SettingsProfile/SettingsProfile";
import SettingsAccount from "./SettingsAccount/SettingsAccount";
import SettingsHistory from "./SettingsHistory/SettingsHistory";
import SettingsInterests from "./SettingsInterests/SettingsInterests";
import { useMediaQuery } from "../../../hooks";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { blackmintTheme } from "../../../style/theme";
import { Screen, Section } from "../../../components";
import WebNavBar from "../../../components/Layout/Nav/NavBar/WebNav";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--color-background-default)",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      // gridTemplateColumns: "repeat(1, 1fr)",
      paddingLeft: "290px",
    },
  },
  secondaryHeading: {
    ...blackmintTheme.typographyBlackmint.h2,
    fontWeight: "normal",
    paddingBottom: "2rem",
    marginBottom: "2rem",
    borderBottom: "2px solid #dadada",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.5rem",
    },
  },
  boldFont: {
    fontWeight: "bold",
  },
}));

function SettingsScreen() {
  const classes = useStyles();
  const { authUserDetails } = useAuth();

  const history = useHistory();

  const location = useLocation();

  const media = useMediaQuery();

  const redirectOnChange = (from, to, history, location) => {
    from.forEach((from) => {
      if (location.pathname === from) history.replace(to);
    });
  };

  useEffect(() => {
    if (media > 2)
      redirectOnChange(
        ["/settings", "/settings/"],
        "/settings/account",
        history,
        location
      );
  }, [media, history, location]);

  return authUserDetails ? (
    <>
      <WebNavBar />
      <Screen className={classes.root}>
        <div style={{ flex: 1 }}>
          <Section.Container style={{padding: '5rem'}}>
            <Typography
              component="h2"
              className={clsx(classes.secondaryHeading, classes.boldFont)}
            >
              Settings
            </Typography>
            {/* <h2 className="settingsScreen__heading font-bold heading-secondary">
        Settings
      </h2> */}
            <div className="settingsScreen__content">
              <Route path="/settings" exact={media <= 2 ? true : false}>
                <div className="settingsScreenNav">
                  <NavLink
                    to="/settings/account"
                    activeClassName="settingsScreenNav__link-active"
                    className="settingsScreenNav__link font-bold"
                  >
                    <span className="settingsScreenNav__link__icon">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="15"
                          cy="15"
                          r="14"
                          fill="none"
                          stroke="#4DC591"
                          stroke-width="2"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15 7C12.79 7 11 8.79 11 11C11 13.21 12.79 15 15 15C17.21 15 19 13.21 19 11C19 8.79 17.21 7 15 7ZM17.1 11C17.1 9.84 16.16 8.9 15 8.9C13.84 8.9 12.9 9.84 12.9 11C12.9 12.16 13.84 13.1 15 13.1C16.16 13.1 17.1 12.16 17.1 11ZM21.1 20C21.1 19.36 17.97 17.9 15 17.9C12.03 17.9 8.9 19.36 8.9 20V21.1H21.1V20ZM7 20C7 17.34 12.33 16 15 16C17.67 16 23 17.34 23 20V23H7V20Z"
                          fill="#4DC591"
                        />
                      </svg>
                    </span>
                    <span className="settingsScreenNav__link__text">
                      Account
                    </span>
                    <span className="settingsScreenNav__link__arrow">
                      <svg
                        width="12"
                        height="20"
                        viewBox="0 0 12 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.165039 18.13L1.93504 19.9L11.835 9.99998L1.93504 0.0999756L0.165039 1.86998L8.29504 9.99998L0.165039 18.13H0.165039Z"
                          fill="black"
                        />
                      </svg>
                    </span>{" "}
                  </NavLink>
                  {!authUserDetails.mentor ? (
                    <NavLink
                      to="/settings/history"
                      activeClassName="settingsScreenNav__link-active"
                      className="settingsScreenNav__link font-bold"
                    >
                      <span className="settingsScreenNav__link__icon">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="15"
                            cy="15"
                            r="14"
                            fill="none"
                            stroke="#4DC591"
                            stroke-width="2"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.215 7C19.0067 7 18.7901 7.08333 18.6318 7.24167L17.107 8.76667L20.2315 11.8917L21.7563 10.3667C22.0813 10.0417 22.0813 9.51667 21.7563 9.19167L19.8066 7.24167C19.6399 7.075 19.4316 7 19.215 7ZM16.2155 12.0167L16.982 12.7833L9.43306 20.3334H8.6665V19.5667L16.2155 12.0167ZM7 18.875L16.2154 9.65828L19.34 12.7833L10.1246 22H7V18.875Z"
                            fill="#4DC591"
                          />
                        </svg>
                      </span>
                      <span className="settingsScreenNav__link__text">
                        History
                      </span>
                      <span className="settingsScreenNav__link__arrow">
                        <svg
                          width="12"
                          height="20"
                          viewBox="0 0 12 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.165039 18.13L1.93504 19.9L11.835 9.99998L1.93504 0.0999756L0.165039 1.86998L8.29504 9.99998L0.165039 18.13H0.165039Z"
                            fill="black"
                          />
                        </svg>
                      </span>{" "}
                    </NavLink>
                  ) : null}
                  <NavLink
                    to="/settings/profile"
                    activeClassName="settingsScreenNav__link-active"
                    className="settingsScreenNav__link font-bold"
                  >
                    <span className="settingsScreenNav__link__icon">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="15"
                          cy="15"
                          r="14"
                          fill="none"
                          stroke="#4DC591"
                          stroke-width="2"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.5 14.5C8.5 10.3583 11.8583 7 16 7C20.1417 7 23.5 10.3583 23.5 14.5C23.5 18.6417 20.1417 22 16 22C13.925 22 12.0583 21.1583 10.7 19.8L11.8833 18.6167C12.9333 19.675 14.3917 20.3333 16 20.3333C19.225 20.3333 21.8333 17.725 21.8333 14.5C21.8333 11.275 19.225 8.66667 16 8.66667C12.775 8.66667 10.1667 11.275 10.1667 14.5H12.6667L9.3 17.8583L9.24167 17.7417L6 14.5H8.5ZM15.1667 15.3334V11.1667H16.4167V14.625L19.35 16.3667L18.7083 17.4334L15.1667 15.3334Z"
                          fill="#4DC591"
                        />
                      </svg>
                    </span>
                    <span className="settingsScreenNav__link__text">
                      Profile
                    </span>
                    <span className="settingsScreenNav__link__arrow">
                      <svg
                        width="12"
                        height="20"
                        viewBox="0 0 12 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.165039 18.13L1.93504 19.9L11.835 9.99998L1.93504 0.0999756L0.165039 1.86998L8.29504 9.99998L0.165039 18.13H0.165039Z"
                          fill="black"
                        />
                      </svg>
                    </span>{" "}
                  </NavLink>
                  {!authUserDetails.mentor ? (
                    <NavLink
                      to="/settings/interests"
                      activeClassName="settingsScreenNav__link-active"
                      className="settingsScreenNav__link font-bold"
                    >
                      <span className="settingsScreenNav__link__icon">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="15"
                            cy="15"
                            r="14"
                            fill="none"
                            stroke="#4DC591"
                            stroke-width="2"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.3333 9L8 13L10.6667 14.4533V18.4533L15.3333 21L20 18.4533V14.4533L21.3333 13.7267V18.3333H22.6667V13L15.3333 9ZM19.88 13.0001L15.3333 15.4801L10.7867 13.0001L15.3333 10.5201L19.88 13.0001ZM15.3333 19.48L18.6667 17.66V15.18L15.3333 17L12 15.18V17.66L15.3333 19.48Z"
                            fill="#4DC591"
                          />
                        </svg>
                      </span>
                      <span className="settingsScreenNav__link__text">
                        Interests
                      </span>
                      <span className="settingsScreenNav__link__arrow">
                        <svg
                          width="12"
                          height="20"
                          viewBox="0 0 12 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.165039 18.13L1.93504 19.9L11.835 9.99998L1.93504 0.0999756L0.165039 1.86998L8.29504 9.99998L0.165039 18.13H0.165039Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </NavLink>
                  ) : null}
                </div>
              </Route>
              <Switch>
                <Route path="/settings/account">
                  <SettingsAccount></SettingsAccount>
                </Route>
                <Route path="/settings/history">
                  <SettingsHistory></SettingsHistory>
                </Route>
                <Route path="/settings/interests">
                  <SettingsInterests></SettingsInterests>
                </Route>
                <Route path="/settings/profile">
                  <SettingsProfile></SettingsProfile>
                </Route>
              </Switch>
            </div>
          </Section.Container >
        </div>
      </Screen>
    </>
  ) : null;
}

export default withAuthorizationComponent((auth) => auth)(SettingsScreen);
