import React from "react";
import { Link } from "react-router-dom";
import "./AnnouncementCard.scss";

const AnnouncementCard = ({ id, title, imageUrl, date }) => {
    return (
        <Link
            to={`/announcement/${id}`}
            className="announcement-card__container"
        >
            <div className="announcement-card">
                <h3 className="announcement-card__title">{title}</h3>
                <div className="announcement-card__time">
                    <div>{date}</div>
                </div>
            </div>
            <img
                className="announcement-card__img"
                src={`https://blog.risingstar.blackmint.io${imageUrl}`}
            />
        </Link>
    );
};

export default AnnouncementCard;
