import { REGEX_EXPRESSIONS } from "../constants";

const checkIfValidUrl = (value) =>
  value &&
  value.match(REGEX_EXPRESSIONS.validLink) &&
  value.match(REGEX_EXPRESSIONS.validLink).length
    ? true
    : false;

export default checkIfValidUrl;
