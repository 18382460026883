import React, { useState } from "react";
import "./MentorSignUpScreen.scss";
import MentorSignUpCareers from "./MentorSignUpCareers/MentorSignUpCareers";
import { Route, Switch, useRouteMatch } from "react-router";
import MentorSignUpForm from "./MentorSignUpForm/MentorSignUpForm";
import MentorSignUpMoreInfo from "./MentorSignUpMoreInfo/MentorSignUpMoreInfo";

function MentorSignUpScreen() {
  const [error, setError] = useState("");

  const { path, url } = useRouteMatch();

  return (
    <div className="mentorSignUpScreen screen">
      <h2 className="mentorSignUpScreen__heading heading-secondary">Sign Up</h2>
      <p className="mentorSignUpScreen__sub">Join Us!</p>
      <p className="mentorSignUpScreen__sub">
        To get the pass key, please email ayobami@blackmint.io to get approved
        as a mentor
      </p>
      <Switch>
        <Route path={path} exact={true} strict={true}>
          <MentorSignUpForm></MentorSignUpForm>
        </Route>
        <Route path={`${url}/moreinfo`} exact={true}>
          <MentorSignUpMoreInfo></MentorSignUpMoreInfo>
        </Route>
        <Route path={`${url}/careers`} exact={true}>
          <MentorSignUpCareers></MentorSignUpCareers>
        </Route>
      </Switch>
      {error && <p>{error.message}</p>}
    </div>
  );
}

export default MentorSignUpScreen;
