import React, { useState } from "react";
import { ButtonDefault } from "../../../../components";
import "./SignInForm.scss";
import { useHistory } from "react-router";
import firebase from "firebase";
import "firebase/auth";
import { FormInput } from "../../../../components";
import { useAuth } from "../../../../Providers";
import { Firebase } from "../../../../apis";

export default function SignInForm() {
  const { setListenForAuthUserDetails, updateAuthUser } = useAuth();

  const initialState = {
    email: "",
    password: "",
  };

  const [state, setState] = useState(initialState);
  const [error, setError] = useState(null);

  const history = useHistory();

  const onSubmit = (e) => {
    e.preventDefault();
    Firebase.doSignInWithEmailAndPassword(email, password)
      .then((authUserSnapshot) => {
        updateAuthUser(authUserSnapshot.user);
        setListenForAuthUserDetails(true);
        setState({ ...initialState });
        history.push("/home");
      })
      .catch((error) => {
        setError(error);
      });
  };

  const signInWithGoogleHandler = (e) => {
    e.preventDefault();
    Firebase.auth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((authUserSnapshot) => {
        updateAuthUser(authUserSnapshot.user);
        setListenForAuthUserDetails(true);
        setState({ ...initialState });
        history.push("/home");
      })
      .catch((error) => {
        setError(error);
      });
  };

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    setError(null);
  };

  const makeErrorMessage = (errorMsg) => {
    return errorMsg.message;
  };

  const { email, password } = state;

  const isInvalid = password === "" || email === "";

  return (
    <form onSubmit={onSubmit} className="signInForm">
      <FormInput
        name="email"
        value={email}
        onChange={onChange}
        type="email"
        placeholder="Email Address"
        required
      />
      <FormInput
        name="password"
        value={password}
        onChange={onChange}
        type="password"
        placeholder="Password"
        required
      />
      {error ? <p className="errorText">{makeErrorMessage(error)}</p> : null}
      <div className="signUpForm__buttons">
        <ButtonDefault disabled={isInvalid} type="submit">
          Sign In
        </ButtonDefault>
        <div className="signUpForm__buttonsDivider">
          <hr className="signUpForm__rule" />
          <p className="signUpForm__or">or</p>
          <hr className="signUpForm__rule" />
        </div>
        <ButtonDefault onClick={signInWithGoogleHandler}>
          Sign In With Google
        </ButtonDefault>
      </div>
    </form>
  );
}
