import queryFetch from "./queryFetch";

export const deleteUserFromGraphQL = async (variable) => {
  const data = await queryFetch(
    `
    mutation deleteUser ($input: DeleteUserInput!){
      deleteUser(input: $input) {
      ok
      }
  }
    `,
    { 
      input: variable
    }
  );
  return data;
};
