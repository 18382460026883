import React from "react";
import { Link } from "react-router-dom";
import "./AnnouncementManageCard.scss";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageIcon from "@material-ui/icons/Image";

import { IconButton } from "@material-ui/core";

const AnnouncementManageCard = ({ id, title, imageUrl, date, deletePopup }) => {
    date = new Date(date).toLocaleDateString();

    return (
        <div className="announcement-manage-card__container">
            <div className="announcement-manage-card">
                <Link to={`/announcement/${id}`}>
                    <h3>{title}</h3>
                </Link>
                <div className="announcement-manage-card__date-text">
                    <div className="announcement-manage-card__icons-text">
                        <div>Published on {date} </div>
                        <div>
                            <Link to={`/announcement/${id}/edit`}>
                                <IconButton>
                                    <EditIcon
                                        style={{
                                            color: "rgb(39, 39, 39)",
                                            fontSize: 25,
                                        }}
                                    />
                                </IconButton>
                            </Link>
                            <IconButton onClick={() => deletePopup(id)}>
                                <DeleteIcon
                                    style={{
                                        color: "rgb(39, 39, 39)",
                                        fontSize: 25,
                                    }}
                                />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
            {imageUrl ? (
                <img
                    className="announcement-manage-card__img"
                    src={`https://blog.risingstar.blackmint.io${imageUrl}`}
                />
            ) : (
                <div className="announcement-manage-card__placeholder-image">
                    <div className="announcement-manage-card__placeholder-image-icon-background">
                        <ImageIcon
                            className="announcement-manage-card__placeholder-image-icon"
                            style={{
                                fontSize: "100px",
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AnnouncementManageCard;
