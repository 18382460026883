import app from "firebase/app";
import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/analytics";
import config from "../config/firebase";

app.initializeApp(config);

app.analytics().logEvent("login");

class Firebase {
  db = app.database();

  store = app.firestore();

  auth = app.auth();

  storage = app.storage();

  analytics = app.analytics();

  //  *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  users = () => this.store.collection(`users`);

  user = (uid) => this.store.collection("users").doc(uid);

  async create(collection, document) {
    const result = await this.store.collection(collection).add();
    document.id = result.id;
    return document;
  }

  async getCollection(collection) {
    const result = await this.store.collection(collection).get();
    return this.snapshotArrayToDataArray(result);
  }

  async get(collection, id) {
    const result = await this.store.collection(collection).doc(id).get();
    if (!result.exists) return null; // Record not found

    const doc = result.data();
    doc.id = result.id;
    return doc;
  }

  async set(collection, id, document) {
    try {
      const doc = this.store.collection(collection).doc(id);
      await doc.set(document);
      document.id = id;
      return document;
    } catch (error) {
      throw error;
    }
  }

  async update(collection, id, document) {
    try {
      const doc = this.store.collection(collection).doc(id);
      // const result = await doc.get();

      // if (!result.exists) return null; // Record not found

      await doc.update(document);
      document.id = id;
      return document;
    } catch (error) {
      throw error;
    }
  }

  async delete(collection, id) {
    const doc = this.store.collection(collection).doc(id);
    const result = await doc.get();

    if (!result.exists) return null; // Record not found

    await doc.delete();

    return { id };
  }

  snapshotArrayToDataArray(snapshotArray) {
    const list = [];
    snapshotArray.forEach((doc) => {
      if (doc.exists) {
        const data = doc.data();
        data.id = doc.id;
        list.push(data);
      }
    });
    return list.length ? list : null;
  }

  async deleteUser(authUser) {
    await authUser.delete();
    await this.user(authUser.uid).delete();
  }

  async deleteMentor(authUser) {
    await authUser.delete();
    await this.store.collection("mentors").doc(authUser.uid).delete();
  }

  collections = {
    videos: "videos",
    users: "users",
    articles: "articles",
    tasks: "tasks",
    careers: "careers",
    degrees: "degrees",
    institutions: "institutions",
    comments: "comments",
    mentors: "mentors",
    passKeys: "passKeys",
  };
}

// const newFirebase = new Firebase()

// newFirebase.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

export default new Firebase();
