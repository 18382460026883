export default (string, numberOfSentences, end = "") => {
  const wordsSplit = string.split(" ");
  if (wordsSplit.length <= numberOfSentences) return string;
  const wordsArr = wordsSplit.slice(0, numberOfSentences);
  let newSentence = "";
  for (let wordIndex = 0; wordIndex < wordsArr.length - 1; wordIndex++) {
    newSentence = newSentence.concat(wordsArr[wordIndex] + " ");
  }
  return newSentence.concat(wordsArr[wordsArr.length - 1], end);
};
