import React, { useState, useEffect } from "react";
import { MentorThumbnail, Section, VideoPlayer } from "../../../../components";
import { Firebase } from "../../../../apis";
import { useParams } from "react-router";
import { useUsers, useTask, useAuth } from "../../../../Providers";
import { ReactComponent as LikedActiveSvg } from "./images/liked-active.svg";
import { ReactComponent as LikedInActiveSvg } from "./images/liked-inactive.svg";
import { ReactComponent as SavedActiveSvg } from "./images/saved-active.svg";
import { ReactComponent as SavedInActiveSvg } from "./images/saved-inactive.svg";
import { upsertUserFromGraphQL } from "../../../../azureGraphQL/userMutation";



export default function VideoDiplay({ video, videoAuthor }) {
  const { authUser, authUserDetails, updateAuthUserDetails } = useAuth();

  const { getUser } = useUsers();

  // const [video, setVideo] = useState(null);

  const params = useParams();

  // const [videoAuthor, setVideoAuthor] = useState(null);

  const [liked, setLiked] = useState(
    authUserDetails.likes
      ? authUserDetails.likes.videos.includes(params["id"])
      : false
  );

  const [saved, setSaved] = useState(
    authUserDetails.saves
      ? authUserDetails.saves.videos.includes(params["id"])
      : false
  );

  const likeHandler = () => {
    if (!liked) {
      console.log("new likes")
      const newLikes = authUserDetails.likes
        ? [...authUserDetails.likes.videos, video.id]
        : [video.id];
      upsertUserFromGraphQL({
          id: authUser.uid,
          rowKey: authUser.uid,
          partitionKey: "users",
          likes : {
            videos : newLikes
          }
      }).then(() =>
      updateAuthUserDetails({
        ...authUserDetails,
        likes : {
          videos : newLikes
        }
      }));
      Firebase.user(authUser.uid)
        .update({ "likes.videos": newLikes })
        .then(setLiked(true));
    } else {
      const newLikes = authUserDetails.likes
        ? authUserDetails.likes.videos.filter((id) => id !== video.id)
        : [];
      upsertUserFromGraphQL({
          id: authUser.uid,
          rowKey: authUser.uid,
          partitionKey: "users",
          likes : {
            videos : newLikes
          }
      }).then(() =>
      updateAuthUserDetails({
        ...authUserDetails,
        likes : {
          videos : newLikes
        }
      }));
      Firebase.user(authUser.uid)
        .update({ "likes.videos": newLikes })
        .then(setLiked(true))
        .then(() => {
          setLiked(false);
        });
    }
  };

  const saveHandler = () => {

    if (!saved) {
      console.log("new saves")
      const newSaves = authUserDetails.saves
        ? [...authUserDetails.saves.videos, video.id]
        : [video.id];
      upsertUserFromGraphQL({
        id: authUser.uid,
        rowKey: authUser.uid,
        partitionKey: "users",
        saves : {
          videos : newSaves
        }
      }).then(() =>
      updateAuthUserDetails({
        ...authUserDetails,
        saves : {
          videos : newSaves
        }
      }));
      Firebase.user(authUser.uid)
        .update({ "saves.videos": newSaves })
        .then(setSaved(true));
    } else {
      const newSaves = authUserDetails.saves
        ? authUserDetails.saves.videos.filter((id) => id !== video.id)
        : [];
      upsertUserFromGraphQL({
        id: authUser.uid,
        rowKey: authUser.uid,
        partitionKey: "users",
        saves : {
          videos : newSaves
        }
      }).then(() =>
      updateAuthUserDetails({
        ...authUserDetails,
        saves : {
          videos : newSaves
        }
      }));
      Firebase.user(authUser.uid)
        .update({ "saves.videos": newSaves })
        .then(setSaved(false));
    }
  };

  return (
    <Section.Container>
      <div className="videoScreenVideo__videoPlayer fullWidth fullWidth-conditional">
        {video ? (
          <VideoPlayer
            youtubeId={video.youtubeId}
            videoId={video.id}
          ></VideoPlayer>
        ) : null}
      </div>
      <h2 className="heading-tertiary videoScreenVideo__title">
        {video ? video.title : null}
      </h2>
      <div className="videoScreenVideo__content">
        <div className="videoScreenVideo__details">
          <div className="videoScreenVideo__author">
            {videoAuthor ? (
              <MentorThumbnail
                mentor={videoAuthor}
                wide="true"
              ></MentorThumbnail>
            ) : null}
          </div>
        </div>
        <div className="videoScreenVideo__buttons">
          <div
            onClick={() => {
              saveHandler();
            }}
            className={
              !saved ? `videoScreenVideo__button` : `videoScreenVideo__button`
            }
          >
            {saved ? <SavedActiveSvg /> : <SavedInActiveSvg />}
          </div>
          <div
            onClick={() => {
              likeHandler();
            }}
            className={
              !liked ? `videoScreenVideo__button` : `videoScreenVideo__button`
            }
          >
            {liked ? <LikedActiveSvg /> : <LikedInActiveSvg />}
            {/* <p className="videoScreenVideo__button__text">Like Video</p> */}
          </div>
        </div>
      </div>
    </Section.Container>
  );
}
