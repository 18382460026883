import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { ReactComponent as RewardTrophySvg } from "./images/rewardTrophy.svg";
import { ReactComponent as AccessSvg } from "./images/access.svg";
import { ReactComponent as DiscussionSvg } from "./images/discussion.svg";
import { ReactComponent as LearningSvg } from "./images/learning.svg";
import { blackmintTheme } from "../../../style/theme";

const useStyles = makeStyles((theme) => ({
  boldFont: {
    fontWeight: "bold",
  },
  sectionContainer: {
    marginBottom: "5rem",
    marginTop: "5rem",
  },
  paragraph: {
    ...blackmintTheme.typographyBlackmint.paragraph,
  },
  landingScreenMainText: {
    lineHeight: "200% !important",
    fontSize: "18px",
  },
  landingScreenSection: {
    //   backgroundColor: "var(--color-secondary-light)",
    textAlign: "center",
    padding: "5rem",
    paddingLeft: "15rem",
    paddingRight: "15rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "3rem",
      paddingLeft: "3rem",
    },
  },
  landingScreenGrid: {
    // padding: "2rem 0",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    // alignItems: "center",
    gridAutoRows: '70rem',
    gap: '5rem',
    [theme.breakpoints.down("sm")]: {
    gridAutoRows: 'max-content',
    gridTemplateColumns: "1fr",
      padding: "2rem 2rem",
    },
  },
  landingScreenGroup: {
    padding: '5rem',
    height: '100%',
    backgroundColor: "var(--color-secondary-light)",
    display: "grid",
    columnGap: "4rem",
    borderRadius: '2rem',
    
    [theme.breakpoints.down("xs")]: {

      flexBasis: "100%",
    },
  },
  h2: {
    ...blackmintTheme.typographyBlackmint.h2,
    marginBottom: "8rem",
  },
  headingTertiary: {
    fontWeight: "bold",
    fontFamily: "Inter",
    fontSize: "28px",
    lineHeight: "150%",
    fontSize: "var(--default-font-size)",
    color: "var(--color-primary)",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
    },
  },
}));

export default function Features({}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.sectionContainer,
        classes.landingScreenSection
        //   classes.fullWidth
      )}
    >
      <Typography component="h2" className={clsx(classes.boldFont, classes.h2)}>
        Features
      </Typography>
      <div className={classes.landingScreenGrid}>
        <div
          className={classes.landingScreenGroup}
          style={{ marginBottom: "4rem" }}
        >
          <RewardTrophySvg />
          <Typography component="h2" className={classes.headingTertiary}>
            Earn points for Rewards
          </Typography>
          <div
            className={clsx(classes.paragraph, classes.landingScreenMainText)}
          >
            Earn points by completing various activities within the platform
            like watching videos, reading about various careers in tech,
            communicating with mentors, and much more. You get to climb up to
            various levels as a rising star as you{" "}
            <span className={classes.boldFont}>
              complete more tasks and earn star points.
            </span>
          </div>
        </div>
        <div
          className={classes.landingScreenGroup}
          style={{ marginBottom: "4rem" }}
        >
          <DiscussionSvg />
          <Typography component="h2" className={classes.headingTertiary}>
            Mentorship from Top Companies
          </Typography>
          <div
            className={clsx(classes.paragraph, classes.landingScreenMainText)}
          >
            Communicate with mentors directly under the video page or check out
            their profile where you can learn about their careers and message
            them directly. Also, by{" "}
            <span className={classes.boldFont}>
              earning more points, you have a higher chance in getting your own
              BlackMINT mentor!
            </span>
          </div>
        </div>
        <div
          className={classes.landingScreenGroup}
          style={{ marginBottom: "4rem" }}
        >
          <AccessSvg />
          <Typography component="h2" className={classes.headingTertiary}>
            Access to Career Information
          </Typography>
          <div
            className={clsx(classes.paragraph, classes.landingScreenMainText)}
          >
            <span className={classes.boldFont}>
              Find out everything you need to know about different careers{" "}
            </span>
            within the tech industry such as as potential salary information,
            day in the life, and the industries that require those career
            options.
          </div>
        </div>
        <div
          className={classes.landingScreenGroup}
          style={{ marginBottom: "4rem" }}
        >
          <LearningSvg />
          <Typography component="h2" className={classes.headingTertiary}>
            Learning Resources
          </Typography>
          <div
            className={clsx(classes.paragraph, classes.landingScreenMainText)}
          >
            <span className={classes.boldFont}>
              Watch inspiring videos featuring our mentors
            </span>{" "}
            as they talk about their career journey and what it is like to be a
            tech professional in our rising star podcast.
          </div>
        </div>
      </div>
    </div>
  );
}
