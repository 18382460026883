import React from "react";
import "./PopUp.scss";

export default function PopUp({ children, onBackgroundClick }) {
  return (
    <div className="popUp">
      <div className="popUp__bg" onClick={onBackgroundClick}></div>
      <div className="popUp__content">{children}</div>
    </div>
  );
}
