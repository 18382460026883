// export default [
//     { name: 'Art', value: 'art' },
//     { name: 'Maths', value: 'maths' },
//     { name: 'Teamwork', value: 'teamwork' },
//     { name: 'Writing', value: 'writing' },
//     { name: 'Design', value: 'design' },
//     { name: 'Leadership', value: 'leadership' },
//     { name: 'Entrepreneurship', value: 'entrepreneurship' },
//     { name: 'Cars', value: 'cars' },
//     { name: 'Games', value: 'games' },
//     { name: 'Money', value: 'Money' },
//     { name: 'Jewelry', value: 'jewelry' },
//     { name: 'Computers', value: 'computers' },
//     { name: 'Games', value: 'games' },
//     { name: 'Money', value: 'money' },
//     { name: 'Jewelry', value: 'jewelry' },
//     { name: 'Computers', value: 'computers' },
//     { name: 'Cosmetics', value: 'cosmetics' },
//     { name: 'Gadget', value: 'gadget' },
//     { name: 'Fashion', value: 'fashion' },
//     { name: 'Photography', value: 'photography' },
//     { name: 'AI', value: 'ai' },
//     { name: 'Virtual Reality', value: 'virtualReality'},
//     { name: 'Sports', value: 'sports' },
//     { name: 'Social Media', value: 'social' },
// ]

export const hobbies = [
  "Art",
  "Maths",
  "Teamwork",
  "Writing",
  "Design",
  "Leadership",
  "Entrepreneurship",
  "Cars",
  "Games",
  "Money",
  "Jewelry",
  "Computers",
  "Cosmetics",
  "Gadget",
  "Fashion",
  "Photography",
  "AI",
  "Virtual Reality",
  "Sports",
  "Social Media",
];

export const careerInterests = [
  "Software Engineer",
  "Cloud Solutions Architect",
  "Data Scientist",
  "Data Analyst",
  "Business Intelligence Analyst",
  "UX/UI Designer",
  "Product Manager",
  "Product Owner",
  "Front End Developer",
  "Back End Developer",
  "Full Stack Developer",
  "User Researcher",
  "Professor",
  "Technical Sales",
  "Product Marketing Manager",
  "ML Engineer",
  "IT Project Manager",
  "Technology Consultant",
  "Business System Analyst",
  "Data Architect",
  "Business Intelligence Developer",
  "Data Engineer",
];
