import React, { useState } from "react";
import "./MentorCareer.scss";
import { ButtonDefault, FormEditable, ButtonCard } from "../../../components";
import { useParams } from "react-router";
import { useCareers } from "../../../Providers";
import { ReactComponent as BookSvg } from "./images/book.svg";
import { ReactComponent as GearsSvg } from "./images/gears.svg";
import { ReactComponent as GradCapSvg } from "./images/gradCap.svg";
import { ReactComponent as MoneyPotSvg } from "./images/moneyPot.svg";
import { ReactComponent as PadSvg } from "./images/pad.svg";
import { Firebase } from "../../../apis";

export default function MentorCareer() {
  const params = useParams();

  const isNew = params.id === "new";

  const initialState = {
    name: null,
    descriptionText: null,
    descriptionArticle: null,
    potentialIndustriesText: null,
    potentialIndustriesArticle: null,
    juniorSalaryMin: null,
    juniorSalaryMax: null,
    midSalaryMin: null,
    midSalaryMax: null,
    seniorSalaryMin: null,
    seniorSalaryMax: null,
    skillsText: null,
    skillsArticle: null,
    degrees: null,
    tags: null,
  };

  const [state, setState] = useState(isNew ? initialState : {});

  const [, setCareer] = useState(null);

  const { getCareer } = useCareers();

  useState(() => {
    if (!isNew) {
      getCareer(params.id).then((career) => {
        setCareer(career);
        setState({
          name: career.name,
          descriptionText:
            (career.description && career.description.value) || null,
          descriptionArticle:
            (career.description && career.description.article) || null,
          potentialIndustriesText:
            (career.potentialIndustries && career.potentialIndustries.value) ||
            null,
          potentialIndustriesArticle:
            (career.potentialIndustries &&
              career.potentialIndustries.article) ||
            null,
          juniorSalaryMin:
            (career.salaries &&
              career.salaries.junior &&
              career.salaries.junior.min) ||
            null,
          juniorSalaryMax:
            (career.salaries &&
              career.salaries.junior &&
              career.salaries.junior.max) ||
            null,
          midSalaryMin:
            (career.salaries &&
              career.salaries.mid &&
              career.salaries.mid.min) ||
            null,
          midSalaryMax:
            (career.salaries &&
              career.salaries.mid &&
              career.salaries.mid.max) ||
            null,
          seniorSalaryMin:
            (career.salaries &&
              career.salaries.senior &&
              career.salaries.senior.min) ||
            null,
          seniorSalaryMax:
            (career.salaries &&
              career.salaries.senior &&
              career.salaries.senior.max) ||
            null,
          skillsText: (career.skills && career.skills.value) || null,
          skillsArticle: (career.skills && career.skills.article) || null,
          degrees: (career.degrees && career.degrees.value) || null,
          tags:
            (career.tags &&
              ((career.tags instanceof Array && career.tags.join(", ")) ||
                career.tags)) ||
            null,
        });
      });
    }
  });

  const {
    name,
    descriptionText,
    descriptionArticle,
    potentialIndustriesArticle,
    potentialIndustriesText,
    juniorSalaryMin,
    juniorSalaryMax,
    midSalaryMin,
    midSalaryMax,
    seniorSalaryMin,
    seniorSalaryMax,
    skillsText,
    skillsArticle,
    degrees,
    tags,
  } = state;

  const buildCareer = ({
    name,
    descriptionText,
    descriptionArticle,
    potentialIndustriesText,
    potentialIndustriesArticle,
    juniorSalaryMin,
    juniorSalaryMax,
    midSalaryMin,
    midSalaryMax,
    seniorSalaryMin,
    seniorSalaryMax,
    skillsText,
    skillsArticle,
    degrees,
    tags,
  }) => {
    const newCareer = {
      name: name ? name : null,
      description: {
        value: descriptionText ? descriptionText : null,
        article: descriptionArticle ? descriptionArticle : null,
      },
      potentialIndustries: {
        value: potentialIndustriesText ? potentialIndustriesText : null,
        article: potentialIndustriesArticle ? potentialIndustriesArticle : null,
      },
      skills: {
        value: skillsText ? skillsText : null,
        article: skillsArticle ? skillsArticle : null,
      },
      degrees: {
        value: degrees ? degrees : null,
      },
      salaries: {
        junior: {
          min: juniorSalaryMin ? juniorSalaryMin : null,
          max: juniorSalaryMax ? juniorSalaryMax : null,
        },
        mid: {
          min: midSalaryMin ? midSalaryMin : null,
          max: midSalaryMax ? midSalaryMax : null,
        },
        senior: {
          min: seniorSalaryMin ? seniorSalaryMin : null,
          max: seniorSalaryMax ? seniorSalaryMax : null,
        },
      },
      tags: tags.split(",").map((tag) => tag.trim()),
    };

    return newCareer;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (isNew) {
    } else {
      Firebase.store
        .collection("careers")
        .doc(params["id"])
        .update(buildCareer(state));
    }
  };

  const onDone = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  useState(() => {});

  return (
    <form className="careerScreen screen" onSubmit={submitHandler}>
      {Object.keys(state).length ? (
        <>
          <div className="section">
            <FormEditable
              name="name"
              initialValue={name}
              //   richText={false}
              onDone={onDone}
              editing={
                (typeof name === "string" && name.length === 0) ||
                name === null ||
                name === undefined
              }
              required={true}
            >
              <h2 className="heading-secondary font-bold color-primary">
                {name}
              </h2>
            </FormEditable>
          </div>
          <section className="careerScreen__section card borderRadius-medium careerMainDescription">
            <h2 className="careerScreen__section__title">
              <span className="careerScreen__section__title__image">
                <BookSvg />
              </span>
              Career Profile
            </h2>
            <FormEditable
              name="descriptionText"
              initialValue={descriptionText}
              //   richText={true}
              type="textarea"
              onDone={onDone}
              required={true}
              editing={
                (typeof descriptionText === "string" &&
                  descriptionText.length === 0) ||
                descriptionText === null ||
                descriptionText === undefined
              }
            >
              <p className="paragraph">{descriptionText}</p>
              <div className="mentorCareer__seeMoreEditableBox">
                <FormEditable
                  name="descriptionArticle"
                  initialValue={descriptionArticle}
                  //   richText={false}
                  onDone={onDone}
                  required={false}
                >
                  {descriptionArticle ? (
                    <p
                      to={`/articles/${descriptionArticle}`}
                      className="careerScreen__sectionSeeMore"
                    >
                      See More
                    </p>
                  ) : (
                    <ButtonCard
                      onClick={(e) => {
                        e.preventDefault();
                        setState({ ...state, descriptionArticle: "" });
                      }}
                      style={{ height: "4rem", width: "15rem" }}
                    >
                      Add Article
                    </ButtonCard>
                  )}
                </FormEditable>
              </div>
            </FormEditable>
          </section>
          <section className="careerScreen__section card borderRadius-medium careerPotentialIndustries">
            <h2 className="careerScreen__section__title">
              <span className="careerScreen__section__title__image">
                <GearsSvg />
              </span>
              Potential Industries
            </h2>
            <FormEditable
              name="potentialIndustriesText"
              initialValue={potentialIndustriesText}
              onDone={onDone}
              required={false}
            >
              {potentialIndustriesText ? (
                <>
                  <p className="paragraph">{potentialIndustriesText}</p>
                  <div className="mentorCareer__seeMoreEditableBox">
                    <FormEditable
                      name="potentialIndustriesArticle"
                      initialValue={potentialIndustriesArticle}
                      //   richText={false}
                      onDone={onDone}
                      required={false}
                    >
                      {potentialIndustriesArticle ? (
                        <p
                          to={`/articles/${potentialIndustriesArticle}`}
                          className="careerScreen__sectionSeeMore"
                        >
                          See More
                        </p>
                      ) : (
                        <ButtonCard
                          onClick={(e) => {
                            e.preventDefault();
                            setState({
                              ...state,
                              potentialIndustriesArticle: "",
                            });
                          }}
                          style={{ height: "4rem", width: "15rem" }}
                        >
                          Add Article
                        </ButtonCard>
                      )}
                    </FormEditable>
                  </div>
                </>
              ) : (
                <div className="mentorCareer__addSection">
                  <ButtonCard
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ ...state, potentialIndustriesText: "" });
                    }}
                  >
                    +
                  </ButtonCard>
                </div>
              )}
            </FormEditable>
          </section>
          <section className="careerScreen__section card borderRadius-medium careerSalaries">
            <h2 className="careerScreen__section__title">
              <span className="careerScreen__section__title__image">
                <MoneyPotSvg />
              </span>
              Potential Salaries
            </h2>
            <div className="mentorCareerSalaries__salaryGroup">
              <p>Junior-Level</p>
              <div className="mentorCareer__salaryBoxes">
                <div className="mentorCareer__salaryEditableBox">
                  <FormEditable
                    name="juniorSalaryMin"
                    initialValue={juniorSalaryMin}
                    // richText={false}
                    onDone={onDone}
                    required={false}
                  >
                    {juniorSalaryMin ? (
                      <p className="">{juniorSalaryMin}</p>
                    ) : (
                      <ButtonCard
                        onClick={(e) => {
                          e.preventDefault();
                          setState({ ...state, juniorSalaryMin: "" });
                        }}
                        style={{ height: "4rem", width: "10rem" }}
                      >
                        +
                      </ButtonCard>
                    )}
                  </FormEditable>
                </div>
                <p className="mentorCareerSalaries__dash">-</p>
                <div className="mentorCareer__salaryEditableBox">
                  <FormEditable
                    name="juniorSalaryMax"
                    initialValue={juniorSalaryMax}
                    onDone={onDone}
                    required={false}
                    type="number"
                  >
                    {juniorSalaryMax ? (
                      <p to={`/articles/${juniorSalaryMax}`} className="">
                        {juniorSalaryMax}
                      </p>
                    ) : (
                      <ButtonCard
                        onClick={(e) => {
                          e.preventDefault();
                          setState({ ...state, juniorSalaryMax: null });
                        }}
                        style={{ height: "4rem", width: "10rem" }}
                      >
                        +
                      </ButtonCard>
                    )}
                  </FormEditable>
                </div>
              </div>
            </div>
            <div className="mentorCareerSalaries__salaryGroup">
              <p>Mid-Level</p>
              <div className="mentorCareer__salaryBoxes">
                <div className="mentorCareer__salaryEditableBox">
                  <FormEditable
                    name="midSalaryMin"
                    initialValue={midSalaryMin}
                    type="number"
                    onDone={onDone}
                    required={false}
                  >
                    {midSalaryMin ? (
                      <p className="">{midSalaryMin}</p>
                    ) : (
                      <ButtonCard
                        onClick={(e) => {
                          e.preventDefault();
                          setState({ ...state, midSalaryMin: "" });
                        }}
                        style={{ height: "4rem", width: "10rem" }}
                      >
                        +
                      </ButtonCard>
                    )}
                  </FormEditable>
                </div>
                <p className="mentorCareerSalaries__dash">-</p>
                <div className="mentorCareer__salaryEditableBox">
                  <FormEditable
                    name="midSalaryMax"
                    initialValue={midSalaryMax}
                    type="number"
                    onDone={onDone}
                    required={false}
                  >
                    {midSalaryMax ? (
                      <p to={`/articles/${midSalaryMax}`} className="">
                        {midSalaryMax}
                      </p>
                    ) : (
                      <ButtonCard
                        onClick={(e) => {
                          e.preventDefault();
                          setState({ ...state, midSalaryMax: "" });
                        }}
                        style={{ height: "4rem", width: "10rem" }}
                      >
                        +
                      </ButtonCard>
                    )}
                  </FormEditable>
                </div>
              </div>
            </div>
            <div className="mentorCareerSalaries__salaryGroup">
              <p>Senior-Level</p>
              <div className="mentorCareer__salaryBoxes">
                <div className="mentorCareer__salaryEditableBox">
                  <FormEditable
                    name="seniorSalaryMin"
                    initialValue={seniorSalaryMin}
                    type="number"
                    onDone={onDone}
                    required={false}
                  >
                    {seniorSalaryMin ? (
                      <p className="">{seniorSalaryMin}</p>
                    ) : (
                      <ButtonCard
                        onClick={(e) => {
                          e.preventDefault();
                          setState({ ...state, seniorSalaryMin: "" });
                        }}
                        style={{ height: "4rem", width: "10rem" }}
                      >
                        +
                      </ButtonCard>
                    )}
                  </FormEditable>
                </div>
                <p className="mentorCareerSalaries__dash">-</p>
                <div className="mentorCareer__salaryEditableBox">
                  <FormEditable
                    name="seniorSalaryMax"
                    initialValue={seniorSalaryMax}
                    type="number"
                    onDone={onDone}
                    required={false}
                  >
                    {seniorSalaryMax ? (
                      <p to={`/articles/${seniorSalaryMax}`} className="">
                        {seniorSalaryMax}
                      </p>
                    ) : (
                      <ButtonCard
                        onClick={(e) => {
                          e.preventDefault();
                          setState({ ...state, seniorSalaryMax: "" });
                        }}
                        style={{ height: "4rem", width: "10rem" }}
                      >
                        +
                      </ButtonCard>
                    )}
                  </FormEditable>
                </div>
              </div>
            </div>
          </section>
          <section className="careerScreen__section card borderRadius-medium careerDegrees">
            <h2 className="careerScreen__section__title">
              <span className="careerScreen__section__title__image">
                <GradCapSvg />
              </span>
              Relevant Degrees
            </h2>{" "}
            <FormEditable
              name="degrees"
              initialValue={degrees}
              type="textarea"
              onDone={onDone}
              required={false}
            >
              {degrees ? (
                <p className="paragraph">{degrees}</p>
              ) : (
                <div className="mentorCareer__addSection">
                  <ButtonCard
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ ...state, degrees: "" });
                    }}
                  >
                    +
                  </ButtonCard>
                </div>
              )}
            </FormEditable>
          </section>
          <section className="careerScreen__section card borderRadius-medium careerSkills">
            <h2 className="careerScreen__section__title">
              <span className="careerScreen__section__title__image">
                <PadSvg />
              </span>
              Required Skills
            </h2>{" "}
            <FormEditable
              name="skillsText"
              initialValue={skillsText}
              type="textarea"
              onDone={onDone}
            >
              {skillsText ? (
                <>
                  <p className="paragraph">{skillsText}</p>
                  <div className="mentorCareer__seeMoreEditableBox">
                    <FormEditable
                      name="skillsArticle"
                      initialValue={skillsArticle}
                      //   richText={false}
                      onDone={onDone}
                      required={false}
                    >
                      {skillsArticle ? (
                        <p
                          to={`/articles/${skillsArticle}`}
                          className="careerScreen__sectionSeeMore"
                        >
                          See More
                        </p>
                      ) : (
                        <ButtonCard
                          onClick={(e) => {
                            e.preventDefault();
                            setState({ ...state, skillsArticle: "" });
                          }}
                          style={{ height: "4rem", width: "15rem" }}
                        >
                          Add Article
                        </ButtonCard>
                      )}
                    </FormEditable>
                  </div>
                </>
              ) : (
                <div className="mentorCareer__addSection">
                  <ButtonCard
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ ...state, skillsText: "" });
                    }}
                  >
                    +
                  </ButtonCard>
                </div>
              )}
            </FormEditable>
          </section>
          <section className="careerScreen__section card borderRadius-medium">
            <h2 className="careerScreen__section__title">Tags</h2>
            <FormEditable
              name="tags"
              initialValue={tags}
              onDone={onDone}
              invalidFunc={(value) => value && value.split(",").length > 10}
              editing={
                (typeof tags === "string" && tags.length === 0) ||
                tags === null ||
                tags === undefined
              }
              required={true}
            >
              <p className="paragraph">{tags}</p>
            </FormEditable>
          </section>
          <ButtonDefault>Submit</ButtonDefault>
        </>
      ) : null}
    </form>
  );
}
