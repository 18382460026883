import React, { useEffect, useState } from "react";
import "./MentorSignUpCareers.scss";
import { ButtonDefault } from "../../../../components";
import { useHistory } from "react-router";
import { removeValueFromArray } from "../../../../helpers";
import { FormBoxInput } from "../../../../components";
import { useStatus, useAuth, useCareers } from "../../../../Providers";
import { statusTypes } from "../../../../constants";
import { Firebase } from "../../../../apis";

export default function MentorSignUpCareers() {
  const [selectedCareers, setSelectedCareers] = useState([]);
  const [careers, setCareers] = useState([]);

  const { changeStatus } = useStatus();

  const { setListenForAuthUserDetails, authUser } = useAuth();
  const history = useHistory();

  const { initializeCareers, getAllCareers } = useCareers();

  useEffect(() => {
    initializeCareers();
  }, []);

  useEffect(async () => {
    const data = await getAllCareers();
    setCareers(data);
  }, []);

  const minSelected = 1,
    maxSelected = 8;

  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      selectedCareers.length >= minSelected &&
      selectedCareers.length <= maxSelected
    ) {
      await Firebase.store
        .collection(Firebase.collections.mentors)
        .doc(authUser.uid)
        .update({ careers: selectedCareers });
      const selectedCareersDocs = await Firebase.store
        .collection(Firebase.collections.careers)
        .where("id", "in", selectedCareers)
        .get();
      selectedCareersDocs.docs.forEach(async (selectedCareerDoc) => {
        await selectedCareerDoc.ref.update({
          mentors: [...selectedCareerDoc.data().mentors, authUser.uid],
        });
      });
      setListenForAuthUserDetails(true);
      history.push("/home");
    } else {
      if (selectedCareers.length <= minSelected)
        changeStatus(
          {
            message:
              "The number of selected interests is too low. Please add more up to 8",
          },
          statusTypes.error
        );
      else
        changeStatus(
          {
            message:
              "The number of selected interests is too high. Please remove some.",
          },
          statusTypes.error
        );
    }
  };

  const careerClickHandler = (e) => {
    const newSelectedCareers = [...selectedCareers];
    const element = e.target;
    if (!newSelectedCareers.includes(element.value)) {
      newSelectedCareers.push(e.target.value);
      setSelectedCareers(newSelectedCareers);
    } else {
      setSelectedCareers(
        removeValueFromArray(
          newSelectedCareers,
          (item) => element.value === item
        )
      );
    }
  };

  return (
    <form className="mentorSignUpCareers" onSubmit={submitHandler}>
      <h1 className="heading-tertiary mentorSignUpCareers__title">
        Which of the Careers interest you?
      </h1>
      <div className="mentorSignUpCareers__grid">
        {careers.map((career, index) => (
          <div className="mentorSignUpCareers__input">
            <FormBoxInput
              label={career.name}
              value={career.id}
              key={index}
              onClick={careerClickHandler}
            ></FormBoxInput>
          </div>
        ))}
      </div>
      <p className="mentorSignUpCareers__counter">{`${selectedCareers.length}/${maxSelected}`}</p>
      <div className="mentorSignUpCareers__submit">
        <ButtonDefault>Proceed &rarr;</ButtonDefault>
      </div>
    </form>
  );
}
