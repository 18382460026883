import React, { useRef, useEffect, useState } from "react";
import "./FormInput.scss";

export default function FormInput({
  placeholder,
  type,
  name,
  required = true,
  inputRef,
  invalid = false,
  value = null,
  onChange = () => {},
  options = null,
  ...rest
}) {
  const ref = useRef();

  const [inputValue, setInputValue] = useState(value);

  if (!inputRef) inputRef = ref;

  let invalidIsFunction = false;

  if (typeof invalid === "function") invalidIsFunction = true;

  useEffect(() => {
    if (invalidIsFunction ? invalid(inputValue) : invalid)
      inputRef.current.setCustomValidity(
        invalidIsFunction ? invalid(inputValue).error : invalid.error
      );
    else inputRef.current.setCustomValidity("");
  }, [invalid, inputValue]);

  const changeHandler = (e) => {
    setInputValue(e.target.value);
    onChange(e);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div class="formInput">
      <input
        ref={inputRef}
        type={type}
        name={name}
        class="formInput__input focusEffect borderRadius-medium"
        placeholder={placeholder}
        required={required}
        value={inputValue}
        onChange={changeHandler}
        {...rest}
        id={name}
        list={`${name}-options`}
      />
      <label for={name} class="formInput__label">
        {placeholder}
      </label>
      {options ? (
        <datalist id={`${name}-options`}>
          {options.map((element) => {
            return <option value={element} />;
          })}
        </datalist>
      ) : null}
    </div>
  );
}
