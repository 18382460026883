import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import WebNav from "../../../components/Layout/Nav/NavBar/WebNav";
import "./AnnouncementPostScreen.scss";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Providers";
import { useAnnouncement } from "../../../Providers/AnnouncementProvider/AnnouncementProvider";
import EmailIcon from "@material-ui/icons/Email";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import WatchLaterIcon from "@material-ui/icons/WatchLaterOutlined";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { Button, IconButton } from "@material-ui/core";

TimeAgo.addDefaultLocale(en);

const AnnouncementPostScreen = (props) => {
    const timeAgo = new TimeAgo("en-US");

    const [announcement, setAnnouncement] = useState(undefined);
    const { authUserDetails } = useAuth();
    const params = useParams();
    const history = useHistory();
    const [read, setRead] = useState(false);
    const { getAnnouncement } = useAnnouncement();

    useEffect(() => {
        (async () => {
            const announceRes = await getAnnouncement(params.id);
            setAnnouncement(announceRes);
        })();
    }, []);

    useEffect(() => {
        if (
            announcement &&
            authUserDetails &&
            announcement.viewers.includes(authUserDetails.id)
        )
            setRead(true);
    }, [announcement]);

    const markRead = async () => {
        try {
            const newViewers = [...announcement.viewers, authUserDetails.id];

            const res = await fetch(
                `https://blog.risingstar.blackmint.io/announcements/${announcement.id}`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        viewers: newViewers,
                    }),
                }
            );

            const announcementRes = await res.json();
            setAnnouncement(announcementRes);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="announcement-post--background">
            <WebNav />
            <div className="announcement-post">
                {!announcement ? (
                    <div className="announcement-post__error">
                        <h1 className="announcement-post__error-message">
                            Something went wrong with getting the announcement
                        </h1>
                    </div>
                ) : (
                    <article>
                        <div className="announcement-post__header">
                            Announcement
                        </div>
                        <div className="announcement-post__container">
                            <div className="announcement-post__image-container">
                                <img
                                    className="announcement-post__image"
                                    src={`https://blog.risingstar.blackmint.io${announcement.image.url}`}
                                />
                            </div>
                            <div className="announcement-post__title">
                                <h1>{announcement.title}</h1>
                            </div>

                            <div className="announcement-post__section">
                                <div>
                                    <div className="announcement-post__read-section">
                                        <div>
                                            <WatchLaterIcon />
                                            <div>
                                                {timeAgo.format(
                                                    new Date(
                                                        announcement.published_at
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        {read ? (
                                            <Button>
                                                <CheckBoxOutlinedIcon />
                                                <span>Read</span>
                                            </Button>
                                        ) : (
                                            <Button onClick={() => markRead()}>
                                                <EmailIcon />
                                                <>Mark as Read</>
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <summary className="announcement-post__section announcement-post__text">
                                <ReactMarkdown>
                                    {announcement.text}
                                </ReactMarkdown>
                            </summary>
                            <div>
                                <Link to="/announcements">
                                    <Button>
                                        <ArrowBackOutlinedIcon />
                                        Back to announcements
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </article>
                )}
            </div>
        </div>
    );
};

export default AnnouncementPostScreen;
