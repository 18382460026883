const interests = {
  hobbies: [],
  careers: [],
};

const saves = {
  videos: [],
  articles: [],
};

const likes = {
  videos: [],
  articles: [],
};

const history = {
  articles: [],
  videos: [],
  mentors: [],
  careers: [],
};

const location = {
  country: null,
  province: null,
  city: null,
};

const personalInfo = {
  location,
  name: null,
  age: null,
  gender: null,
  bio: null,
};

const runningTasks = [];

const subscribedMentors = [];

const joinedCareers = [];

const completedTasks = [];

const links = {};

export const newUserDetails = {
  runningTasks,
  completedTasks,
  level: 1,
  points: 0,
  mentor: false,
  history,
  likes,
  saves,
  subscribedMentors,
  joinedCareers,
  personalInfo,
  interests,
  links,
};

export const newAzureUserDetails = {
  runningTasks,
  completedTasks,
  level: 1,
  points: 0,
  mentor: false,
  history,
  likes,
  saves,
  subscribedMentors,
  joinedCareers,
  location,
  age: null,
  gender: null,
  bio: null,
  interests,
};

