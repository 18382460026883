import React from "react";
// import LandingScreen from "./screens/Landing/LandingScreen";
import WebLandingScreen from "./screens/Landing/WebLandingScreen";
import SignUpScreen from "./screens/Auth/SignUp/SignUpScreen";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import HomeScreen from "./screens/Home/HomeScreen";
import SignInScreen from "./screens/Auth/SignIn/SignInScreen";
import CareerScreen from "./screens/Career/CareerScreen/CareerScreen";
import VideoScreen from "./screens/Video/VideoScreen/VideoScreen";
import MentorsListScreen from "./screens/UserMentor/MentorsListScreen/MentorsListScreen";
import VideosListScreen from "./screens/Video/VideosListScreen/VideosListScreen";
import MentorScreen from "./screens/UserMentor/MentorScreen/MentorScreen";
import MentorSignUpScreen from "./screens/Auth/MentorSignUp/MentorSignUpScreen";
import MentorDashboard from "./screens/Mentor/MentorDashboard/MentorDashboard";
import ArticlesListScreen from "./screens/Article/ArticleListScreen/ArticlesListScreen";
import ArticleScreen from "./screens/Article/ArticleScreen/ArticleScreen";
import CareersListScreen from "./screens/Career/CareersListScreen/CareersListScreen";
import SettingsScreen from "./screens/Settings/SettingsScreen/SettingsScreen";
import SavedVideosScreen from "./screens/Settings/SavedVideosScreen/SavedVideosScreen";
import MentorLandingScreen from "./screens/Mentor/MentorLandingScreen/MentorLandingScreen";
import MentorCareer from "./screens/Mentor/MentorCareer/MentorCareer";
import DegreeScreen from "./screens/Degree/DegreeScreen/DegreeScreen";
import TeamMembersListScreen from "./screens/TeamMembers/TeamMembersListScreen";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Footer from "./components/Layout/Footer/Footer";
import NavBar from "./components/Layout/Nav/NavBar/NavBar";
import WebNavBar from "./components/Layout/Nav/NavBar/WebNav";
import ResponsiveDrawer from "./components/Layout/Nav/NavBar/SideNav";
import FeedbackScreen from "./screens/FeedbackScreen/FeedbackScreen";
import EmailVerificationRequest from "./components/Auth/EmailVerificationRequest/EmailVerificationRequest";
import { Providers } from "./Providers";
import { ROUTES } from "./constants";
import InstitutionScreen from "./screens/Institution/InstitutionScreen";
import ProgramsListScreen from "./screens/Programs/ProgramsList";
import BlogsSubmitScreen from "./screens/Blogs/BlogsSubmitScreen/BlogsSubmitScreen";
import BlogPostScreen from "./screens/Blogs/BlogPostScreen/BlogPostScreen";
import BlogListScreen from "./screens/Blogs/BlogListScreen/BlogListScreen";
import BlogMentorScreen from "./screens/Blogs/BlogMentorScreen/BlogMentorScreen";
import BlogsEditScreen from "./screens/Blogs/BlogsEditScreen/BlogsEditScreen";
import AnnouncementListScreen from "./screens/Announcement/AnnouncementListScreen/AnnouncementListScreen";
import AnnouncementSubmitScreen from "./screens/Announcement/AnnouncementSubmitScreen/AnnouncementSubmitScreen";
import AnnouncementPostScreen from "./screens/Announcement/AnnouncementPostScreen/AnnouncementPostScreen";
import AnnouncementManageScreen from "./screens/Announcement/AnnouncementManageScreen/AnnouncementManageScreen";
import AnnouncementEditScreen from "./screens/Announcement/AnnouncementEditScreen/AnnouncementEditScreen";
import LikeVideosScreen from "./screens/Settings/LikedVideosScreen/LikeVideosScreen";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Providers>
          {/* <NavBar /> */}

          <Route path="/">
            <Switch>
              <Route exact={true} path={ROUTES.landing}>
                <WebLandingScreen />
              </Route>
              <Route path={ROUTES.signUp}>
                <WebNavBar />
                <SignUpScreen></SignUpScreen>
                <Footer></Footer>
              </Route>
              <Route exact={true} path={ROUTES.signIn}>
                <WebNavBar />

                <SignInScreen></SignInScreen>
              </Route>
              <Route exact={true} path={ROUTES.home}>
                {/* <EmailVerificationRequest /> */}
                <HomeScreen />
              </Route>
              <Route exact={true} path={ROUTES.careersList}>
                {/* <EmailVerificationRequest /> */}
                <CareersListScreen></CareersListScreen>
              </Route>
              <Route exact={true} path={ROUTES.career}>
                {/* <EmailVerificationRequest /> */}
                <CareerScreen></CareerScreen>
              </Route>
              <Route exact={true} path={ROUTES.degrees}>
                {/* <EmailVerificationRequest /> */}
                <ProgramsListScreen />
              </Route>
              <Route exact={true} path={ROUTES.degree}>
                {/* <EmailVerificationRequest /> */}
                <DegreeScreen />
              </Route>
              <Route exact={true} path={ROUTES.institution}>
                {/* <EmailVerificationRequest /> */}
                <InstitutionScreen />
              </Route>
              <Route exact={true} path={ROUTES.video}>
                {/* <EmailVerificationRequest /> */}
                <VideoScreen></VideoScreen>
              </Route>
              <Route exact={true} path={ROUTES.mentorsList}>
                {/* <EmailVerificationRequest /> */}
                <NavBar></NavBar>
                <MentorsListScreen></MentorsListScreen>
              </Route>
              <Route exact={true} path={ROUTES.mentor}>
                {/* <EmailVerificationRequest /> */}
                <NavBar></NavBar>
                <MentorScreen></MentorScreen>
              </Route>
              <Route exact={true} path={ROUTES.articleList}>
                {/* <EmailVerificationRequest /> */}
                <NavBar></NavBar>
                <ArticlesListScreen></ArticlesListScreen>
              </Route>
              <Route exact={true} path={ROUTES.article}>
                {/* <EmailVerificationRequest /> */}
                <NavBar></NavBar>
                <ArticleScreen></ArticleScreen>
              </Route>
              <Route exact={true} path={ROUTES.videosList}>
                {/* <EmailVerificationRequest /> */}
                <VideosListScreen></VideosListScreen>
              </Route>
              <Route exact={true} path={ROUTES.savedVideos}>
                {/* <EmailVerificationRequest /> */}
                <NavBar></NavBar>
                <SavedVideosScreen></SavedVideosScreen>
              </Route>

              {/*<Route exact={true} path={ROUTES.likedVideos}>
                {<EmailVerificationRequest />}
                <NavBar></NavBar>
                <LikeVideosScreen></LikeVideosScreen>
              </Route> */}
              <Route path={ROUTES.settings} strict={true}>
                {/* <EmailVerificationRequest /> */}
                <NavBar></NavBar>
                <SettingsScreen></SettingsScreen>
              </Route>
              <Route path={ROUTES.feedback} exact={true}>
                {/* <EmailVerificationRequest /> */}
                <FeedbackScreen></FeedbackScreen>
              </Route>
              <Route path={ROUTES.teamMembersList}>
                <TeamMembersListScreen></TeamMembersListScreen>
              </Route>
              <Route path={ROUTES.blogSubmit}>
                <BlogsSubmitScreen />
              </Route>
              <Route path={ROUTES.blogList}>
                <BlogListScreen />
              </Route>
              <Route path={ROUTES.blogMentorList}>
                <BlogMentorScreen />
              </Route>
              <Route path={ROUTES.blogEdit}>
                <BlogsEditScreen />
              </Route>
              <Route path={ROUTES.blogPost}>
                <BlogPostScreen />
              </Route>
              <Route path={ROUTES.announcementsSubmit}>
                <AnnouncementSubmitScreen />
              </Route>
              <Route path={ROUTES.announcementManage}>
                <AnnouncementManageScreen />
              </Route>
              <Route path={ROUTES.announcements}>
                <AnnouncementListScreen />
              </Route>
              <Route path={ROUTES.announcementEdit}>
                <AnnouncementEditScreen />
              </Route>

              {/*  */}
              <Route path={ROUTES.announcement}>
                <AnnouncementPostScreen />
              </Route>
              <Route path={ROUTES.mentorshipSignUp}>
                <WebNavBar />
                <MentorSignUpScreen></MentorSignUpScreen>
              </Route>
              <Redirect to="/home" />
            </Switch>
          </Route>
          <Route path="/mentorship">
            <Switch>
              {/* <Route path={ROUTES.mentorshipLanding} exact={true}>
                <NavBar></NavBar>
                <MentorLandingScreen></MentorLandingScreen>
              </Route> */}
              {/* <Route path={ROUTES.mentorshipSignUp}>
                <WebNavBar />
                <MentorSignUpScreen></MentorSignUpScreen>
              </Route> */}
              {/* <Route path={ROUTES.mentorshipDashboard}>
                <NavBar></NavBar>
                <MentorDashboard></MentorDashboard>
              </Route> */}
              <Route path={ROUTES.mentorshipCareers}>
                <NavBar></NavBar>
                <MentorCareer />
              </Route>
            </Switch>
          </Route>
          <Footer />
        </Providers>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
