import React, { useState, useEffect, useRef } from "react";
import "./FormEditable.scss";
import { FormInput } from "../..";
import { ButtonDefault } from "../..";
import Quill from "quill";
import { FormTextArea } from "../..";

export default function FormEditable({
  children,
  editable = true,
  editing = false,
  variant,
  type = "text",
  name,
  initialValue = null,
  invalidFunc = () => false,
  required = false,
  onChange = () => {},
  onDone = () => {},
  onCancel = () => {},
  ...rest
}) {
  const inputRef = useRef();

  const [value, setValue] = useState(initialValue);

  const [isEditing, setIsEditing] = useState(editing);

  const [editor, setEditor] = useState(null);

  useEffect(() => {
    if (type === "richText") {
      const editor = new Quill(`#${name}-quill`, {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"], // remove formatting button
          ],
        },
        debug: "info",
        placeholder: "Compose an epic...",
        readOnly: false,
        theme: "snow",
      });
      typeof initialValue === "string"
        ? editor.setText(initialValue)
        : editor.setContents(initialValue);
      editor.on("text-change", () => {
        setValue(editor.getContents());
        onChange({ target: { value: editor.getContents(), name } });
      });
      setEditor(editor);
    }
  }, [initialValue, name, onChange, type]);

  useEffect(() => {
    setIsEditing(editing);
  }, [editing]);

  const onChangeHandler = (e) => {
    setValue(e.target.value);
    onChange({ ...e, target: inputRef.current });
  };

  const onDoneHandler = (e) => {
    e.preventDefault();
    const isEmpty = editor
      ? editor.getText().trim().length === 0
      : value.length === 0;
    if (required && !isEmpty) {
      if (editor) {
        setIsEditing(false);
        onDone({
          target: {
            value: editor.getText().trim().length > 0 ? value : null,
            name,
          },
          preventDefault: e.preventDefault,
        });
      } else {
        if (!invalidFunc(value)) {
          setIsEditing(false);
          onDone({
            ...e,
            target: {
              ...inputRef.current,
              value: value.length > 0 ? value : null,
              name,
            },
          });
        }
      }
    }
    if (!required) {
      if (editor) {
        setIsEditing(false);
        onDone({
          target: {
            value: editor.getText().trim().length > 0 ? value : null,
            name,
          },
          preventDefault: e.preventDefault,
        });
      } else {
        if (!invalidFunc(value)) {
          setIsEditing(false);
          onDone({
            ...e,
            target: {
              ...inputRef.current,
              value: value.length > 0 ? value : null,
              name,
            },
          });
        }
      }
    }
  };

  const onCancelHandler = (e) => {
    e.preventDefault();
    if (
      required &&
      ((typeof initialValue === "string" && initialValue.length > 0) ||
        initialValue === null ||
        initialValue === undefined)
    ) {
      setValue(initialValue);
      setIsEditing(false);
      if (editor) {
        onCancel({
          target: { value: value, name },
          preventDefault: e.preventDefault,
        });
        editor.setContents(initialValue);
      } else {
        onCancel({ ...e, target: inputRef.current });
      }
    }
    if (!required) {
      setValue(initialValue);
      setIsEditing(false);
      if (editor) {
        onCancel({
          target: { value: value, name },
          preventDefault: e.preventDefault,
        });
        editor.setContents(initialValue);
      } else {
        onCancel({ ...e, target: inputRef.current });
      }
    }
  };

  return (
    <div
      className="formEditable"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="formEditable__children"
        style={{
          visibility: !isEditing ? "visible" : "hidden",
          opacity: !isEditing ? 1 : 0,
          height: !isEditing ? "fit-content" : 0,
        }}
        onClick={(e) => {
          setIsEditing(true);
        }}
      >
        {children}
      </div>
      {!(type === "richText") ? (
        <>
          <div
            className="formEditable__form"
            style={{
              visibility: isEditing ? "visible" : "hidden",
              opacity: isEditing ? 1 : 0,
              height: isEditing ? "fit-content" : 0,
            }}
          >
            {type !== "textarea" ? (
              <FormInput
                invalid={invalidFunc(value)}
                inputRef={inputRef}
                name={name}
                value={value}
                type={type}
                onChange={onChangeHandler}
                required={required}
                {...rest}
              />
            ) : (
              <FormTextArea
                invalid={invalidFunc(value)}
                inputRef={inputRef}
                name={name}
                value={value}
                type={type}
                onChange={onChangeHandler}
                required={required}
                // {...rest}
              />
            )}
            <div className="formEditable__buttons">
              <ButtonDefault onClick={onDoneHandler}>Done</ButtonDefault>
              <ButtonDefault onClick={onCancelHandler}>Cancel</ButtonDefault>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="formEditable__form"
            style={{
              visibility: isEditing ? "visible" : "hidden",
              opacity: isEditing ? 1 : 0,
              height: isEditing ? "fit-content" : 0,
            }}
          >
            <div id={`${name}-quill-toolbar`}></div>
            <div id={`${name}-quill`}></div>
            <div className="formEditable__buttons">
              <ButtonDefault onClick={onDoneHandler}>Done</ButtonDefault>
              <ButtonDefault onClick={onCancelHandler}>Cancel</ButtonDefault>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
