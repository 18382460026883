import React, { useEffect, useState } from "react";
import "./CareerScreen.scss";
import { useParams, useHistory } from "react-router";
import withAuthorizationComponent from "../../../components/Auth/WithAuthorization/WithAuthorizationComponent";
import {
  updateHistory,
  historyTypes,
  removeValueFromArray,
} from "../../../helpers";
import WebNavBar from "../../../components/Layout/Nav/NavBar/WebNav";
import { ButtonDefault, Screen, H2, Section } from "../../../components";
import { ReactComponent as BookSvg } from "./images/book.svg";
import { ReactComponent as GearsSvg } from "./images/gears.svg";
import { ReactComponent as GradCapSvg } from "./images/gradCap.svg";
import { ReactComponent as MentorSvg } from "./images/mentor.svg";
import { ReactComponent as MoneyPotSvg } from "./images/moneyPot.svg";
import { ReactComponent as PadSvg } from "./images/pad.svg";
import { numberWithCommas } from "../../../helpers";
import MentorThumbnail from "../../../components/Mentor/MentorThumbnail/MentorThumbnail";
import { useMediaQuery } from "../../../hooks";
import { PopUp } from "../../../components";
import { MentorsDescription } from "../../../components";
import { taskTypes } from "../../../constants";
import {
  useCareers,
  useTask,
  useAuth,
  useUsers,
  useVideos,
} from "../../../Providers";
import { Firebase } from "../../../apis";
import CareerVideo from "./CareerVideo";
import CareerSection from "./CareerSection/CareerSection";
import CareerActions from "./CareerActions";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import WebMentorThumbnail from "../../../components/Mentor/WebMentorThumbnail/WebMentorThumbnail";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--color-background-default)",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "290px",
    },
  },
  mainHeading: {
    width: "100%",
    margin: 0,
    display: "flex",
    borderRadius: 0,
    justifyContent: "center",
    alignItems: "center",
    padding: "5rem",
    backgroundColor: "#58c494",
  },
}));

export function CareerScreen() {
  const { root, mainHeading } = useStyles();

  const { authUserDetails, isUserMentee } = useAuth();

  const { getCareer } = useCareers();

  const { getUsers } = useUsers();

  const { getVideo } = useVideos();

  const { taskableHandler } = useTask();

  const media = useMediaQuery();

  const [career, setCareer] = useState(null);

  const [mentors, setMentors] = useState([]);

  // const [video, setVideo] = useState();

  const [isShowMentorsPopUp, setIsShowMentorsPopUp] = useState(false);

  const params = useParams();

  const history = useHistory();

  useEffect(() => {
    getCareer(params["id"]).then((career) => {
      getUsers(career.mentors).then(setMentors);
      setCareer(career);
      if (isUserMentee) {
        updateHistory(historyTypes.careers, authUserDetails, params["id"], "users").then(
          () => {
            taskableHandler(
              taskTypes.readNumberCareers,
              authUserDetails,
              1,
              params["id"]
            );
          }
        );
      }
    });
  }, [authUserDetails, getCareer, getUsers, params, taskableHandler]);

  return (
    <>
      <WebNavBar />
      <Screen className={root}>
        {career && (
          <div style={{ flex: 1 }}>
            {/* <Section.Container> */}

            <div className={clsx(mainHeading, "card borderRadius-medium")}>
              <H2
                style={{ marginBottom: 0, color: "white", textAlign: "center" }}
              >
                {career.name}
              </H2>
            </div>
            {/* </Section.Container> */}
            {career.careerVideo && (
              <Section.Container className="videoScreenVideo__videoPlayer--theatre">
                <CareerVideo youtubeId={career.careerVideo}></CareerVideo>
              </Section.Container>
            )}
            {isShowMentorsPopUp && (
              <PopUp
                onBackgroundClick={() => {
                  setIsShowMentorsPopUp(false);
                }}
              >
                <div className="careerScreenAllMentors">
                  {mentors.map((mentor, index) => {
                    return (
                      <MentorsDescription
                        mentor={mentor}
                        key={index}
                      ></MentorsDescription>
                    );
                  })}
                </div>
              </PopUp>
            )}
            {authUserDetails.mentor ? <CareerActions career={career} /> : null}
            <CareerSection
              title="Career Profile"
              titleImage={<BookSvg />}
              onViewMore={() => {
                if (career.description.article)
                  history.push(`/articles/${career.description.article}`);
              }}
              viewMore={career.description.article}
            >
              <p className="paragraph">{career.description.value}</p>
              {!authUserDetails.mentor && <CareerActions career={career} />}
            </CareerSection>
            {career.potentialIndustries && (
              <CareerSection
                title="Potential Industries"
                titleImage={<GearsSvg />}
                onViewMore={() => {
                  if (career.potentialIndustries.article)
                    history.push(
                      `/articles/${career.potentialIndustries.article}`
                    );
                }}
                viewMore={career.potentialIndustries.article}
              >
                <p className="paragraph">{career.potentialIndustries.value}</p>
              </CareerSection>
            )}
            {career.salaries && (
              <CareerSection
                title="Potential Salaries"
                titleImage={<MoneyPotSvg />}
                className="careerScreen__section card borderRadius-medium careerSalaries font-bold"
                onViewMore={() => {
                  if (career.salaries.article)
                    history.push(`/articles/${career.salaries.article}`);
                }}
                viewMore={career.salaries.article}
              >
                <div className="careerScreenSalaries__salaryGroup paragraph">
                  <p className="">Junior-Level</p>
                  <p className="">
                    ${numberWithCommas(career.salaries.junior.min)}-$
                    {numberWithCommas(career.salaries.junior.max)}{" "}
                    <span className="careerScreenSalaries__annualSign">
                      /yr
                    </span>
                  </p>
                </div>
                <div className="careerScreenSalaries__salaryGroup paragraph">
                  <p className="">Mid-Level</p>
                  <p className="">
                    ${numberWithCommas(career.salaries.mid.min)}-$
                    {numberWithCommas(career.salaries.mid.max)}{" "}
                    <span className="careerScreenSalaries__annualSign">
                      /yr
                    </span>
                  </p>
                </div>
                <div className="careerScreenSalaries__salaryGroup paragraph">
                  <p className="">Senior-Level</p>
                  <p className="">
                    ${numberWithCommas(career.salaries.senior.min)}-$
                    {numberWithCommas(career.salaries.senior.max)}{" "}
                    <span className="careerScreenSalaries__annualSign">
                      /yr
                    </span>
                  </p>
                </div>
                <p className="careerScreenSalaries__disclaimer">
                  * According to randstad 2020 salary guide values for Toronto
                </p>
              </CareerSection>
            )}
            {career.skills && (
              <CareerSection
                title="Required Skills"
                titleImage={<PadSvg />}
                onViewMore={() => {
                  if (career.skills.article)
                    history.push(`/articles/${career.skills.article}`);
                }}
                viewMore={career.skills.article}
              >
                <p className="paragraph">{career.skills.value}</p>
              </CareerSection>
            )}
            {career.degrees && (
              <CareerSection
                title="Relevant Degrees"
                titleImage={<GradCapSvg />}
                onViewMore={() => {
                  if (career.degrees.article)
                    history.push(`/articles/${career.skills.article}`);
                }}
                viewMore={career.degrees.article}
              >
                <p className="paragraph">{career.degrees.value}</p>
              </CareerSection>
            )}
            {mentors.length && (
              <CareerSection
                title={`${career.name} Mentors`}
                titleImage={<MentorSvg />}
                onViewMore={() => {
                  setIsShowMentorsPopUp(true);
                }}
                viewMore
              >
                <div className="careerMentors__mentors section__content">
                  {mentors.slice(0, media <= 3 ? 3 : 4).map((mentor, index) => {
                    return (
                      <WebMentorThumbnail
                        mentor={mentor}
                        key={index}
                      ></WebMentorThumbnail>
                    );
                  })}
                </div>
              </CareerSection>
            )}
          </div>
        )}
      </Screen>
    </>
  );
}

export default withAuthorizationComponent((auth) => auth)(CareerScreen);
