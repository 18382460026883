import queryFetch from "./queryFetch";
const getAllCareersFromGraphQL = async () => {
    const data = await queryFetch(`
        query getAllCareers {
            allcareers{
                id,
                name,
                image,
                careerVideo,
                mentors,
                tags,
                description{
                    value,
                    article
                },
                potentialIndustries{
                    value,
                    article,
                },
                skills{
                    value,
                    article
                },
                salaries{
                    senior{
                        max,
                        min
                    },
                    mid{
                        max,
                        min
                    },
                    lead{
                        max,
                        min
                    },
                    junior{
                        max,
                        min
                    }
                }
            }
        }
    `);
    return data.allcareers;
};

const getCareerByIdFromGraphQL = async (variable) => {
    const data = await queryFetch(`
        query getCareerById($queryid: String) {
            career(id: $queryid){
                id,
                name,
                image,
                mentors,
                tags,
                description{
                    value,
                    article
                },
                potentialIndustries{
                    value,
                    article,
                },
                skills{
                    value,
                    article
                },
                salaries{
                    senior{
                        max,
                        min
                    },
                    mid{
                        max,
                        min
                    },
                    lead{
                        max,
                        min
                    },
                    junior{
                        max,
                        min
                    }
                }
            }
        }
    `, {queryid: variable});
    return data.career;
};


const getCareersByListOfIdsFromGraphQL = async (variable) => {
    const data = await queryFetch(`
        query getCareerById($queryids: [String]) {
            careers(ids: $queryids){
                id,
                name,
                image,
                mentors,
                tags,
                description{
                    value,
                    article
                },
                potentialIndustries{
                    value,
                    article,
                },
                skills{
                    value,
                    article
                },
                salaries{
                    senior{
                        max,
                        min
                    },
                    mid{
                        max,
                        min
                    },
                    lead{
                        max,
                        min
                    },
                    junior{
                        max,
                        min
                    }
                }
            }
        }
    `, {queryid: variable});
    return data.careers;
};




export {getAllCareersFromGraphQL, getCareerByIdFromGraphQL, getCareersByListOfIdsFromGraphQL}