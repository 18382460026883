import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@material-ui/core";
import { AuthUserContext, useAuth } from "../../../../Providers";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import {
  HomeIcon,
  BookmarkIcon,
  FavoriteIcon,
  SettingIcon,
  PlayIcon,
  PersonIcon,
  BriefcaseIcon,
  GraduationIcon,
} from "./NavBarIcons";
import WebNavBar from "./WebNav";
import { LogoDefault } from "../../..";
import { useHistory, useLocation } from "react-router-dom";
import WithAuthorizationElement from "../../../Auth/WithAuthorization/WithAuthorizationElement";
import { ROUTES } from "../../../../constants";
import { blackmintTheme } from "../../../../style/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  navBar: {
    padding: "0 var(--padding-screen-current-side)",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#272727",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "1.7rem",
    height: "6rem",
    width: "100%",
    zIndex: 100,
    boxShadow: "0 1px 5px 0px rgba(0, 0, 0, 0.4)",
  },
  logoBox: {
    width: "20rem",
    height: "100%",
  },
  dropTrigger: {
    width: "3.5rem",
    height: "3.5rem",
    cursor: "pointer",
    color: "white",
  },
  back: {
    padding: "1rem",
    width: "5rem",
    height: "5rem",
    color: "white",
  },
  list: {
    width: 293,
  },
  sidebarText: {
    ...blackmintTheme.typographyBlackmint.link,
  },
  sidebarHeader: {
    ...blackmintTheme.typographyBlackmint.h3,
    color: "#4DC591",
    margin: "0.5rem 2rem",
  },
  menuIcon: {
    fontSize: "4rem",
    "&:hover": {
      color: "#4DC591",
    },
  },
  username: {
    ...blackmintTheme.typographyBlackmint.figmaH2,
  },
  listIcon: {
    minWidth: "44px",
    placeContent: "center",
  },
}));

export default function NavBar() {
  const classes = useStyles();
  const [showDropdown, setShowDropdown] = useState(false);

  const history = useHistory();

  const location = useLocation();

  const showGoBack = !(
    location.pathname === ROUTES.home ||
    location.pathname === ROUTES.mentorshipDashboard ||
    location.pathname.includes(ROUTES.signUp) ||
    location.pathname === ROUTES.signIn ||
    location.pathname === ROUTES.landing
  );

  const { authUserDetails, signOut } = useAuth();

  const navigateToPage = (route) => {
    history.push(route);
    setShowDropdown(false);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.navBar}>
          {showGoBack ? (
            <IconButton
              onClick={() => {
                history.goBack();
              }}
              className={classes.back}
            >
              <ArrowBackIosRoundedIcon
                fontSize={"large"}
                style={{ fontSize: "3.5rem" }}
              />
            </IconButton>
          ) : null}
          <div className={classes.logoBox}>
            <LogoDefault></LogoDefault>
          </div>
          <WithAuthorizationElement condition={(auth) => auth}>
            <IconButton
              onClick={() => {
                setShowDropdown(!showDropdown);
              }}
              className={classes.dropTrigger}
            >
              <MenuRoundedIcon
                fontSize={"large"}
                classes={{ root: classes.menuIcon }}
              />
            </IconButton>
            <Drawer
              anchor={"right"}
              open={showDropdown}
              onClose={() => {
                setShowDropdown(false);
              }}
            >
              <div className={classes.list} role="presentation">
                <List
                  style={{
                    padding: "2rem",
                    backgroundColor: "rgba(39, 39, 39, 0.1)",
                  }}
                >
                  <ListItem style={{ padding: 0 }}>
                    <Typography component="h2" className={classes.username}>
                      {authUserDetails &&
                        (authUserDetails.name || authUserDetails.username)}
                    </Typography>
                  </ListItem>
                  <ListItem style={{ padding: 0 }}>
                    <div onClick={signOut} className={classes.sidebarText}>
                      Sign Out
                    </div>
                  </ListItem>
                </List>
                <List>
                  <ListItem
                    button
                    onClick={() => {
                      navigateToPage(ROUTES.home);
                    }}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography className={classes.sidebarText}>
                          Home
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
                <List>
                  <Typography className={classes.sidebarHeader}>
                    My Account
                  </Typography>
                  <ListItem
                    button
                    onClick={() => {
                      navigateToPage(ROUTES.savedVideos);
                    }}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <BookmarkIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography className={classes.sidebarText}>
                          View Saved Items
                        </Typography>
                      }
                    />
                  </ListItem>
{/*                   <ListItem
                    button
                    onClick={() => {
                      navigateToPage(ROUTES.likedVideos);
                    }}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <FavoriteIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography className={classes.sidebarText}>
                          View Liked Items
                        </Typography>
                      }
                    />
                  </ListItem> */}
                  <ListItem
                    button
                    onClick={() => {
                      navigateToPage(ROUTES.settings);
                    }}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <SettingIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography className={classes.sidebarText}>
                          Account Settings
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
                <List>
                  <Typography className={classes.sidebarHeader}>
                    Learn
                  </Typography>
                  <ListItem
                    button
                    onClick={() => {
                      navigateToPage(ROUTES.videosList);
                    }}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <PlayIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography className={classes.sidebarText}>
                          View All Videos
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      navigateToPage(ROUTES.mentorsList);
                    }}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography className={classes.sidebarText}>
                          View All Mentors
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      navigateToPage(ROUTES.careersList);
                    }}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <BriefcaseIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography className={classes.sidebarText}>
                          View All Careers
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      navigateToPage(ROUTES.degrees);
                    }}
                  >
                    <ListItemIcon className={classes.listIcon}>
                      <GraduationIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography className={classes.sidebarText}>
                          View All Programs
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </div>
            </Drawer>
          </WithAuthorizationElement>
        </Toolbar>
      </AppBar>
    </div>
  );
}
