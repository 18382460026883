import queryFetch from "./queryFetch";

//GraphQL Variables Similar to the following format
/*
    {
        "userData":{
                "id": "tD2LgAt7BTXa5qaLTeWD8jtzFWB2",
                "age": 25,
                "partitionKey" : "users"
    }
}    
*/

const upsertUserFromGraphQL = async (variable) => {
    const data = await queryFetch(`
    mutation upsertUser ($userData: UserInput!){
        upsertUser(userData: $userData) {
            ok
        }
    }
    `, {userData: variable});
    return data;
};

export {upsertUserFromGraphQL}