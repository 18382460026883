import React, { useEffect, useState } from "react";
import "./VideoPlayer.scss";
import { taskTypes } from "../../../constants";
import { useAuth } from "../../../Providers";
import { useTask } from "../../../Providers";
import { updateHistory, historyTypes } from "../../../helpers";

export default function VideoPlayer({ youtubeId, videoId }) {
  const [player, setPlayer] = useState(null);

  const { taskableHandler } = useTask();

  const { authUserDetails } = useAuth();

  const playerOptions = {
    modestbranding: 1,
    iv_load_policy: 3,
    rel: 0,
  };

  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";

      window.onYouTubeIframeAPIReady = loadVideo;

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      loadVideo();
    }
  }, [youtubeId]);

  const loadVideo = () => {
    if (player) return player.loadVideoById(youtubeId);
    //Comment this for now, will update History to Mentee only when integration to Azure is done
    updateHistory(historyTypes.videos, authUserDetails, videoId, "users");

    return new window.YT.Player("player", {
      videoId: youtubeId,
      playerVars: playerOptions,
      width: "100%",
      height: "100%",
      events: {
        onStateChange: stateChangeHandler,
        onReady: playerReadyHandler,
      },
    });
  };

  function playerReadyHandler(event) {
    setPlayer(event.target);
  }
  async function stateChangeHandler(event) {
    if (event.data === 0 && authUserDetails) {
      if (!authUserDetails.mentor) {
        taskableHandler(taskTypes.watchNumberVideos, authUserDetails, 1, videoId);
      }
    }
  }

  return (
    <div className="videoPlayer">
      <div className="videoPlayer__video">
        <div id="player"></div>
      </div>
    </div>
  );
}
