import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
} from "react";
import { tasks } from "../../testData";
import { newUserDetails, newAzureUserDetails } from "../../models/user";
import { Firebase } from "../../apis";
import {
  getAllUsersFromGraphQL,
  getUserByIdFromGraphQL,
  getUsersByListOfIdsFromGraphQL,
  getIsMentee,
} from "../../azureGraphQL/userQuery";
import {
  getAllMentorsFromGraphQL,
  getMentorByIdFromGraphQL,
  getMentorsByListOfIdsFromGraphQL,
} from "../../azureGraphQL/mentorQuery";

export const AuthUserContext = createContext(null);

export const useAuth = () => useContext(AuthUserContext);

export default function AuthUserProvider({ children }) {
  const initialAuthUser =
    localStorage.getItem("authUser") === "undefined"
      ? null
      : localStorage.getItem("authUser");
  const initialAuthUserDetails =
    localStorage.getItem("authUserDetails") === "undefined"
      ? null
      : localStorage.getItem("authUserDetails");
  const [authUser, setAuthUser] = useState(JSON.parse(initialAuthUser));
  const [authUserDetails, setAuthUserDetails] = useState(
    JSON.parse(initialAuthUserDetails)
  );

  const [listenForAuthUserDetails, setListenForAuthUserDetails] =
    useState(false);

  const [isMentee, setIsMentee] = useState(null);
  const [isMentor, setIsMentor] = useState(null);

  //const authUserDetailsListener = useRef(null);

  const [isUserMentee, setIsUserMentee] = useState(true);

  const updateAuthUser = (newAuthUser) => {
    setAuthUser(newAuthUser);
    localStorage.setItem("authUser", JSON.stringify(newAuthUser));
  };

  const updateAuthUserDetails = (newAuthUserDetails) => {
    setAuthUserDetails(newAuthUserDetails);
    localStorage.setItem("authUserDetails", JSON.stringify(newAuthUserDetails));
  };

  const signOut = () => {
    Firebase.doSignOut();
    updateAuthUser(null);
    updateAuthUserDetails(null);
    /*
    if (authUserDetailsListener.current) {
      authUserDetailsListener.current();
      authUserDetailsListener.current = null;
    }
    */
    if (listenForAuthUserDetails) {
      setListenForAuthUserDetails(false);
    }
  };

  useEffect(() => {
    if (!authUser && authUserDetails) {
      updateAuthUser(null);
      updateAuthUserDetails(null);
    }
    return Firebase.auth.onAuthStateChanged((authUserSnapshot) => {
      updateAuthUser(authUserSnapshot);
    });
  }, []);

  useEffect(() => {
    if (listenForAuthUserDetails || authUser) {
      setUserDetails();

      if (isMentee != null && isMentor != null) {
        Promise.all([isMentee, isMentor]).then();
      }
    }
  }, [listenForAuthUserDetails]);

  const setUserDetails = async () => {
    const isUserMentee = await getIsMentee(authUser.uid);
    if (isUserMentee) {
      setIsMentee(true);
      setIsUserMentee(true);
      getUserByIdFromGraphQL(authUser.uid).then(async (authUserDetails) => {
        const currentTasks = await getCurrentTasks(authUserDetails);
        const updates = getAuthUserDetailsUpdates(
          authUserDetails,
          newAzureUserDetails
        );

        authUserDetails.runningTasks = currentTasks;
        authUserDetails = { ...authUserDetails, ...updates };
        updateAuthUserDetails(authUserDetails);
      });
    }
    //user is a mentor
    else {
      setIsUserMentee(false);
      getMentorByIdFromGraphQL(authUser.uid).then(async (authUserDetails) => {
        updateAuthUserDetails(authUserDetails);
      });
    }
  };

  const getCurrentTasks = async (authUserDetails, authUserId) => {
    const completedTasksIds =
      authUserDetails?.completedTasks?.map((task) => task.id) || [];

    const allTasks = tasks; // await Firebase.getCollection('tasks')

    const currentTasks = [];

    allTasks.forEach((task) => {
      if (completedTasksIds.includes(task.id)) return;
      const currentlyRunningTask = authUserDetails.runningTasks.find(
        (runningTask) => runningTask.id === task.id
      );
      if (currentlyRunningTask) return currentTasks.push(currentlyRunningTask);
      if (task.step > 1) return;
      if (task.level > authUserDetails.level) return;
      currentTasks.push(task);
    });

    return currentTasks;
  };

  return (
    <AuthUserContext.Provider
      value={{
        authUser,
        authUserDetails,
        updateAuthUser,
        updateAuthUserDetails,
        signOut,
        setListenForAuthUserDetails,
        isUserMentee,
      }}
    >
      {/* {authUser && !authUser.emailVerified && <EmailVerificationRequest/>} */}
      {children}
    </AuthUserContext.Provider>
  );
}

const getAuthUserDetailsUpdates = (authUserDetails, model) => {
  const updates = {};

  if (!(authUserDetails.interests && authUserDetails.interests.hobbies)) {
    updates.interests = model.interests;
  }

  if (!authUserDetails.saves) {
    updates.saves = model.saves;
  }

  if (!authUserDetails.likes) {
    updates.likes = model.likes;
  }

  if (!authUserDetails.history) {
    updates.history = model.history;
  }

  if (!authUserDetails.subscribedMentors) {
    updates.subscribedMentors = model.subscribedMentors;
  }

  if (!authUserDetails.joinedCareers) {
    updates.joinedCareers = model.joinedCareers;
  }

  if (!authUserDetails.personalInfo) {
    updates.personalInfo = model.personalInfo;
  }

  return updates;
};
