import React, { useState } from "react";
import { Card, Typography, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rootActive: {
    // minWidth: 275,
    width: "100%",
    height: "100%",
    backgroundColor: "var(--color-primary)",
    borderRadius: 10,
    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.15)",
  },
  rootInactive: {
    // minWidth: 275,
    width: "100%",
    height: "100%",
    backgroundColor: "var(--color-grey-light-3)",
    borderRadius: 10,
    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.15)",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    // fontSize: 14,
    fontFamily: "var(--font-primary),sans-serif",
    fontSize: "1.7rem",
    fontWeight: 400,
    color: "black",
  },
  cardContent: {
    textAlign: "center",
    height: "100%",
    verticalAlign: "middle",
    display: "flex",
    alignItems: "center",
    placeContent: "center",
  },
}));

export default function FormBoxInputCareers({
  label,
  value,
  onChange = () => {},
  onClick = () => {},
  initialSelected = false,
  ...rest
}) {
  const classes = useStyles();
  const [selected, setSelected] = useState(initialSelected);

  const selectHandler = (e) => {
    e.preventDefault();
    setSelected(!selected);
    onClick(value);
  };
  return (
    <Card
      className={selected ? classes.rootActive : classes.rootInactive}
      onClick={selectHandler}
    >
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title}>{label}</Typography>
      </CardContent>
      {/* <CardActions style={{ placeContent: "center" }}>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}
