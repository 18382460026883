import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import queryFetch from "../../../azureGraphQL/queryFetch";
import BlogForm from "../../../components/Blogs/BlogForm/BlogForm";
import WebNav from "../../../components/Layout/Nav/NavBar/WebNav";
import { AuthUserContext } from "../../../Providers";
import { useBlogs } from "../../../Providers/BlogsProvider/BlogsProvider";
import { v4 as uuidv4 } from "uuid";
import "./BlogSubmitScreen.scss";

const BlogsSubmitScreen = () => {
    const { authUserDetails, isUserMentee } = useContext(AuthUserContext);
    const history = useHistory();
    const { fetchAllBlogs } = useBlogs();

    useEffect(() => {
        if (isUserMentee) {
            history.push("/");
        }
    }, []);

    const onBlogSubmit = async (
        title,
        subTitle,
        text,
        careers,
        image,
        submit
    ) => {
        const user = authUserDetails;
        const mentorData = await queryFetch(
            `
            query getMentorById($queryid: String) {
                mentor(id: $queryid){
                image,
                careers
                }
            }
        `,
            { queryid: authUserDetails.id }
        );

        user.image = mentorData.mentor.image;
        const careerRes = await queryFetch(
            `
            query getCareerById($queryids: [String]) {
                careers(ids: $queryids){
                    name,
                }
            }
        `,
            { queryids: mentorData.mentor.careers[0] }
        );

        user.career = careerRes.careers[0].name;
        const blogReq = await fetch(
            "https://blog.risingstar.blackmint.io/blogs",
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    id: uuidv4(),
                    title,
                    text: text.toString(),
                    image,
                    subTitle,
                    careers,
                    user,
                    comments: [],
                    likedBy: [],
                    published_at: submit ? undefined : null,
                }),
            }
        );

        if (blogReq.status !== 200) {
            alert("Error with submitting blogs");
        }

        const blogRes = await blogReq.json();
        await fetchAllBlogs();
        return blogRes.id;
    };

    return (
        <div className="blog-submit--background">
            <WebNav />
            <div className="blog-submit">
                <div className="blog-submit__container">
                    <div className="blog-submit__container--underline">
                        <h1 className="blog-submit__h1">
                            Create your blog post
                        </h1>
                    </div>
                </div>
            </div>

            <BlogForm onBlogSubmit={onBlogSubmit} />
        </div>
    );
};

export default BlogsSubmitScreen;
