import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HomeIcon from "@material-ui/icons/Home";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ImportContactsOutlinedIcon from "@material-ui/icons/ImportContactsOutlined";
import BookmarkBorderRoundedIcon from "@material-ui/icons/BookmarkBorderRounded";
import FavoriteBorderRoundedIcon from "@material-ui/icons/FavoriteBorderRounded";
import CreateIcon from "@material-ui/icons/Create";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
import BookOutlinedIcon from "@material-ui/icons/BookOutlined";
import AnnouncementOutlined from "@material-ui/icons/AnnouncementOutlined";
import Collapse from "@material-ui/core/Collapse";
import LogoDefault from "../../../Logos/WebLogoDefault.js";
import ButtonDefault from "../../../Buttons/ButtonDefault/ButtonDefault";
import { AuthUserContext, useAuth } from "../../../../Providers";
import { BookmarkIcon } from "./NavBarIcons.js";
import { ROUTES } from "../../../../constants";
import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "@material-ui/core";
import PopUp from "../../../PopUps/PopUp/PopUp.js";
import H2 from "../../../Typography/Headers/H2.js";

const drawerWidth = "290px";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar

  drawerPaper: {
    top: "10rem",
    paddingBottom: "3rem",
    width: drawerWidth,
    backgroundColor: "#EBF0F2",
    height: "min-content",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    height: "60rem",
    paddingBottom: "3rem",
    maxHeight: "500px",
  },
  name: {
    backgroundColor: "rgba(52, 52, 52, 0.1)",
  },
  logo: {
    padding: "1.5rem",
  },
  mentee: {
    fontFamily: "Inter",
    fontWeight: "800",
    fontSize: "16px",
  },
  signout: {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "12px",
    textDecorationLine: "underline",
  },
  listitem: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "16px",
    fontStyle: "normal",
    padding: "1.5rem",
  },
  listIconlessItem: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    padding: "1.5rem",
    textDecoration: "underline !important",
  },
  navitem: {
    fontFamily: "Inter",
    fontWeight: "800",
    fontSize: "16px",
    fontStyle: "normal",
    padding: "1.5rem",
  },
  listlogo: {
    width: "20px",
    height: "20px",
  },
  mentorButton: {
    width: "250px",
    height: "35px",
    fontSize: "16px",
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [open, setOpen] = React.useState(true);

  const [isNewFeatureOpen, setIsNewFeatureOpen] = useState(false);

  const { authUserDetails, signOut, isUserMentee } = useAuth();

  const history = useHistory();

  const handleClick1 = () => {
    setOpen(!open);
  };

  const handleClick2 = () => {
    setOpen(!open);
  };

  const navigateToPage = (route) => {
    history.push(route);
  };

  const drawer = (
    <div>
      {isNewFeatureOpen && (
        <PopUp>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <H2 style={{ textAlign: "center" }}>On the way</H2>
            <H2
              style={{
                textAlign: "center",
                fontWeight: "normal",
                fontSize: "2rem",
              }}
            >
              This feature is in development and will be available soon.
            </H2>
            <ButtonDefault onClick={() => setIsNewFeatureOpen(false)}>
              Continue
            </ButtonDefault>
          </div>
        </PopUp>
      )}
      <div className={classes.logo}>
        <LogoDefault style={{ width: "130px" }}></LogoDefault>
      </div>
      {/* <div className={classes.toolbar} /> */}
      <div className={classes.name}>
        <List>
          <ListItem className={classes.mentee}>
            {authUserDetails.username}
            <ListItemText />
          </ListItem>
          <ListItem button onClick={signOut} className={classes.signout}>
            Sign Out
            <ListItemText />
          </ListItem>
        </List>
      </div>
      <ListItem
        button
        className={classes.listitem}
        onClick={() => {
          navigateToPage(ROUTES.home);
        }}
      >
        <ListItemIcon>
          <HomeIcon className={classes.listlogo} />{" "}
        </ListItemIcon>
        Home
        <ListItemText />
      </ListItem>
      <ListItem button onClick={handleClick1} className={classes.navitem}>
        My Account
        <ListItemText />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.listitem}
            onClick={() => {
              navigateToPage(ROUTES.savedVideos);
            }}
          >
            <ListItemIcon>
              <BookmarkBorderRoundedIcon className={classes.listlogo} />
            </ListItemIcon>
            View Saved Items
            <ListItemText />
          </ListItem>
{/*           <ListItem
            button
            className={classes.listitem}
            onClick={() => {
              navigateToPage(ROUTES.likedVideos);
            }}
          >
            <ListItemIcon>
              <FavoriteBorderRoundedIcon className={classes.listlogo} />
            </ListItemIcon>
            View Liked Items
            <ListItemText />
          </ListItem> */}

          <>
            <ListItem
              button
              className={classes.listitem}
              onClick={() => {
                navigateToPage(ROUTES.announcements);
              }}
            >
              <ListItemIcon>
                <AnnouncementOutlined className={classes.listlogo} />
              </ListItemIcon>
              {!authUserDetails.isTeamMember && "View"} Announcements
              <ListItemText />
            </ListItem>
            {authUserDetails && !isUserMentee && (
              <>
                <ListItem
                  button
                  className={classes.listIconlessItem}
                  onClick={() => {
                    navigateToPage(ROUTES.announcementsSubmit);
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  + Create Announcement
                  <ListItemText />
                </ListItem>
                <ListItem
                  button
                  className={classes.listIconlessItem}
                  onClick={() => {
                    navigateToPage(ROUTES.announcementManage);
                  }}
                >
                  <ListItemIcon></ListItemIcon>
                  Manage
                  <ListItemText />
                </ListItem>
              </>
            )}
          </>
          <ListItem
            button
            className={classes.listitem}
            onClick={() => {
              navigateToPage(ROUTES.settings);
            }}
          >
            <ListItemIcon>
              <SettingsRoundedIcon className={classes.listlogo} />
            </ListItemIcon>
            Account Settings
            <ListItemText />
          </ListItem>
          {authUserDetails && !isUserMentee && (
            <>
              <ListItem
                button
                className={classes.listitem}
                onClick={() => {
                  navigateToPage(ROUTES.blogMentorList);
                }}
              >
                <ListItemIcon>
                  <BookOutlinedIcon className={classes.listlogo} />
                </ListItemIcon>
                View Your Blog Posts <br />
                <ListItemText />
              </ListItem>
              <ListItem
                button
                className={classes.listIconlessItem}
                onClick={() => {
                  navigateToPage(ROUTES.blogSubmit);
                }}
              >
                <ListItemIcon></ListItemIcon>
                + Create Blog Post
                <ListItemText />
              </ListItem>
            </>
          )}
        </List>
      </Collapse>
      <ListItem button onClick={handleClick1} className={classes.navitem}>
        Learn
        <ListItemText />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.listitem}
            onClick={() => {
              navigateToPage(ROUTES.videosList);
            }}
          >
            <ListItemIcon>
              <PlayArrowOutlinedIcon className={classes.listlogo} />
            </ListItemIcon>
            View All Videos
            <ListItemText />
          </ListItem>
          <ListItem
            button
            className={classes.listitem}
            onClick={() => {
              navigateToPage(ROUTES.mentorsList);
            }}
          >
            <ListItemIcon>
              <PersonOutlineOutlinedIcon className={classes.listlogo} />
            </ListItemIcon>
            View All Mentors
            <ListItemText />
          </ListItem>
          <ListItem
            button
            className={classes.listitem}
            onClick={() => {
              navigateToPage(ROUTES.teamMembersList);
            }}
          >
            <ListItemIcon>
              <PersonOutlineOutlinedIcon className={classes.listlogo} />
            </ListItemIcon>
            Meet the Team
            <ListItemText />
          </ListItem>
          <ListItem
            button
            className={classes.listitem}
            onClick={() => {
              navigateToPage(ROUTES.careersList);
            }}
          >
            <ListItemIcon>
              <WorkOutlineOutlinedIcon className={classes.listlogo} />
            </ListItemIcon>
            View All Careers
            <ListItemText />
          </ListItem>
          <ListItem
            button
            className={classes.listitem}
            onClick={() => {
              navigateToPage(ROUTES.degrees);
            }}
          >
            <ListItemIcon>
              <SchoolOutlinedIcon className={classes.listlogo} />
            </ListItemIcon>
            View All Degrees
            <ListItemText />
          </ListItem>
          <ListItem
            button
            className={classes.listitem}
            onClick={() => {
              navigateToPage(ROUTES.blogList);
            }}
          >
            <ListItemIcon>
              <ImportContactsOutlinedIcon className={classes.listlogo} />
            </ListItemIcon>
            View All Blog Posts
            <ListItemText />
          </ListItem>
          <ListItem
            button
            className={classes.listitem}
            onClick={() => {
              navigateToPage(ROUTES.feedback);
            }}
          >
            <ListItemIcon>
              <CreateIcon className={classes.listlogo} />
            </ListItemIcon>
            Give us feedback ♥️
            <ListItemText />
          </ListItem>
        </List>
      </Collapse>
      <ListItem>
        <a href="https://mentorship.blackmint.io">
          <ButtonDefault
            className={classes.mentorButton}
            style={{ fontSize: "10px" }}
          >
            Mentorship Portal
          </ButtonDefault>
        </a>
      </ListItem>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      {props.isOpen && (
        <div className={classes.root}>
          <nav className={classes.drawer} aria-label="mailbox folders">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </nav>
        </div>
      )}
      <Hidden smDown implementation="css">
        <div className={classes.root}>
          <nav className={classes.drawer} aria-label="mailbox folders">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </nav>
        </div>
      </Hidden>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
