import React, { useState } from "react";
import { FormTextArea } from "../../components";
import { useStatus, useAuth, useTask } from "../../Providers";
import { ButtonDefault } from "../../components";
import { taskTypes, statusTypes } from "../../constants";
import { Firebase } from "../../apis";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridAutoRows: "min-content",
    minHeight: "90rem",
    padding:
      "var(--padding-screen-large-top) var(--padding-screen-large-side) 0 var(--padding-screen-large-side)",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "var(--padding-screen-medium-side)",
      paddingRight: "var(--padding-screen-medium-side)",
    },
    [theme.breakpoints.down("xs")]: {
      padding:
        "var(--padding-screen-large-top) var(--padding-screen-small-side) 0 var(--padding-screen-small-side)",
    },
  },
  sectionHeading: {
    fontFamily: "var(--font-primary), sans-serif",
    fontSize: "var(--default-font-size)",
    color: "var(--color-primary)",
    marginBottom: "2rem",
    lineHeight: "150%",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
    },
  },
  feedbackContainer: {
    marginBottom: "5rem",
  },
}));

export default function FeedbackScreen() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const [feedback, setFeedback] = useState("");

  const { authUserDetails } = useAuth();

  const { taskableHandler } = useTask();

  const { changeStatus } = useStatus();

  const submitFeedback = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await Firebase.store
        .collection("feedbacks")
        .doc()
        .set({ message: feedback });
      changeStatus(
        { message: "Thank you for your feedback" },
        statusTypes.success
      );
      setFeedback("");
      if (!authUserDetails.mentor) {
        taskableHandler(taskTypes.feedback, authUserDetails);
      }
    } catch (error) {
      changeStatus(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className={classes.root} onSubmit={submitFeedback}>
      <Typography component="h2" className={classes.sectionHeading}>
        Provide feedback or report bug
      </Typography>
      <div className={classes.feedbackContainer}>
        <FormTextArea
          name="feedback"
          value={feedback}
          onChange={(e) => {
            setFeedback(e.target.value);
          }}
          placeholder={"Your feedback would be appreciated"}
          autoComplete="off"
          required
        />
        <ButtonDefault type="submit" isLoading={isLoading}>
          Submit
        </ButtonDefault>
      </div>
    </form>
  );
}
