import { Firebase } from "../apis";
import { upsertUserFromGraphQL } from "../azureGraphQL/userMutation";

const updateHistory = async (type, user, id, userType) => {
  switch (type) {
    case historyTypes.videos:
      if (!user?.history?.videos?.includes(id)) {
        await upsertUserFromGraphQL({
          id: user.id,
          rowKey: user.id,
          partitionKey: userType,
          history: {
            videos: [...user?.history[historyTypes.videos], id],
          },
        });
        await Firebase.user(user.id).update({
          [`history.${historyTypes.videos}`]: [
            ...user?.history[historyTypes.videos],
            id,
          ],
        });
        return true;
      }
      return false;

    case historyTypes.careers:
      if (!user?.history?.careers?.includes(id)) {
        // console.log(user)
        await upsertUserFromGraphQL({
          id: user.id,
          rowKey: user.id,
          partitionKey: userType,
          history: {
            careers: [...(user?.history?.[historyTypes.careers] || []), id],
          },
        });
        await Firebase.user(user.id).update({
          [`history.${historyTypes.careers}`]: [
            ...(user?.history?.[historyTypes.careers] || []),
            id,
          ],
        });
        return true;
      }
      return false;

    case historyTypes.mentors:
      if (!user?.history?.mentors?.includes(id)) {
        await upsertUserFromGraphQL({
          id: user.id,
          rowKey: user.id,
          partitionKey: userType,
          history: {
            mentors: [...(user?.history?.[historyTypes.mentors] || []), id],
          },
        });
        await Firebase.user(user.id).update({
          [`history.${historyTypes.mentors}`]: [
            ...(user?.history?.[historyTypes.mentors] || []),
            id,
          ],
        });
        return true;
      }
      return false;

    default:
      return false;
  }
};

export default updateHistory;

export const historyTypes = {
  videos: "videos",
  mentors: "mentors",
  careers: "careers",
};
