import React from "react";
import "./FormRadio.scss";

export default function FormRadio({ value, name, placeholder, onClick=() => {}, ...rest }) {

  const clickHandler = (e) => {
    let value = e.target.value
    if(value === 'false') value = false
    if(value === 'true') value = true
    onClick({target: {name: e.target.name, value}})
  }

  return (
    <div className="formRadio">
      <input
        type="radio"
        className="formRadio__input"
        value={value}
        id={`radio${value}`}
        name={name}
        onClick={clickHandler}
        {...rest}
      />
      <label for={`radio${value}`} className="formRadio__label">
        <span className="formRadio__button"></span>
        {placeholder}
      </label>
    </div>
  );
}
