import React, { useState } from "react";
import {
  Typography,
  Paper,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    // maxWidth: "440px",
  },
  media: {
    height: 130,
    backgroundSize: "contain",
    backgroundColor: "#DECABA",
    objectFit: "contain",
    padding: 30,
  },
}));

const ProgramsCard = ({ degreeName, linkTo, imgLink }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper elevation={3}>
        <Card className={classes.cardRoot}>
          <CardActionArea href={linkTo}>
            <CardMedia
              className={classes.media}
              image={imgLink}
              title="Contemplative Reptile"
              component="img"
            />

            <CardContent style={{ padding: "16px 0px" }}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                style={{
                  fontFamily: "Inter",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                {degreeName}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Paper>
    </React.Fragment>
  );
};
export default ProgramsCard;
