import React, { useState } from "react";
import mediaQuery from "../helpers/mediaQuery";

export default function useMediaQuery() {
  const [media, setMedia] = useState(mediaQuery(window.innerWidth));

  window.onresize = () => {
    setMedia(mediaQuery(window.innerWidth));
  };

  return media;
}
