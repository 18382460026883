import { getValueFromPropertyPath } from "../helpers";

const getProfileCompleteness = (user, requiredFields) => {
  return requiredFields.filter((field) => {
    const fieldValue = getValueFromPropertyPath(user, field);
    return (
      (fieldValue &&
        Object.values(fieldValue).filter((value) => value).length > 0) ||
      typeof fieldValue === "number" ||
      typeof fieldValue === "boolean"
    );
  });
};

export default getProfileCompleteness

