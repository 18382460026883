import React from "react";
import CommentsProvider from "./CommentsProvider/CommentsProvider";
import { FirebaseProvider } from "../components/Firebase";
import AuthUserProvider from "./AuthUser/AuthUserProvider";
import UsersProvider from "./UsersProviders/UsersProvider";
import VideosProvider from "./VideosProvider/VideosProvider";
import TaskableProvider from "./TaskableProvider/TaskableProvider";
import CareersProvider from "./CareersProvider/CareersProvider";
import ArticlesProvider from "./ArticlesProvider/ArticlesProvider";
import StatusProvider from "./StatusProvider/StatusProvider";
import DegreesProvider from "./DegreesProvider/DegreesProvider";
import InstitutionsProvider from "./InstitutionsProvider/InstitutionsProvider";
import { ThemeProvider } from "@material-ui/core";
import BlackMintThemeProvider from "../style/theme";
import BlogsProvider from "./BlogsProvider/BlogsProvider";
import AnnouncementProvider from "./AnnouncementProvider/AnnouncementProvider";

export default function Providers({ children }) {
  return (
    <BlackMintThemeProvider>
      <StatusProvider>
        <AuthUserProvider>
          <TaskableProvider>
            <ArticlesProvider>
              <UsersProvider>
                <VideosProvider>
                  <CareersProvider>
                    <DegreesProvider>
                      <InstitutionsProvider>
                        <BlogsProvider>
                        <CommentsProvider>
                          <AnnouncementProvider>{children}</AnnouncementProvider>
                          </CommentsProvider>
                        </BlogsProvider>
                      </InstitutionsProvider>
                    </DegreesProvider>
                  </CareersProvider>
                </VideosProvider>
              </UsersProvider>
            </ArticlesProvider>
          </TaskableProvider>
        </AuthUserProvider>
      </StatusProvider>
    </BlackMintThemeProvider>
  );
}
