import queryFetch from "./queryFetch";
const getAllDegreesFromGraphQL = async () => {
    const data =  await queryFetch(`
    query getAllDegrees {
      alldegrees{
        id,
        image,
        institutions{
            id,
            description,
            image,
            name,
            url
        },
        primaryInstitution{
            id,
            description,
            image,
            name,
            url
        }
        description,
        name,
        relatedCareers{
            name,
            id
        }
      }
    }
    `);
      return data.alldegrees;
}

const getDegreeByIdFromGraphQL = async (variable) => {
  const data = await queryFetch(`
      query getDegreeById($queryid: String) {
          degree(id: $queryid){
            id,
            image,
            institutions{
                id,
                description,
                image,
                name,
                url
            },
            primaryInstitution{
                id,
                description,
                image,
                name,
                url
            }
            description,
            name,
            relatedCareers{
                name,
                id
            }
          }
      }
  `, {queryid: variable});
  return data.degree;
};

const getDegreesByListOfIdsFromGraphQL = async (variable) => {
  const data = await queryFetch(`
      query getDegreeById($queryids: [String]) {
          degrees(ids: $queryids){
            id,
            image,
            institutions{
                id,
                description,
                image,
                name,
                url
            },
            primaryInstitution{
                id,
                description,
                image,
                name,
                url
            }
            description,
            name,
            relatedCareers{
                name,
                id
            }
          }
      }
  `, {queryids: variable});
  return data.degrees;
};

export {getAllDegreesFromGraphQL, getDegreeByIdFromGraphQL, getDegreesByListOfIdsFromGraphQL}
