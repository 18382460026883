import { Avatar, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useHistory } from "react-router";
import { ButtonDefault } from "../../../components";
import BlobImage from "../../../components/BlobImage";
import { blackmintTheme } from "../../../style/theme";
import heroImage2 from "./images/hero-2.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // gridAutoRows: "min-content",
    // minHeight: "90rem",
    padding:
      "var(--padding-screen-large-top) var(--padding-screen-large-side) 0 var(--padding-screen-large-side)",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "var(--padding-screen-medium-side)",
      paddingRight: "var(--padding-screen-medium-side)",
      flexDirection: "column",
    },
    [theme.breakpoints.down("xs")]: {
      padding:
        "var(--padding-screen-large-top) var(--padding-screen-small-side) 0 var(--padding-screen-small-side)",
    },
    paddingTop: "5rem",
  },

  boldFont: {
    fontWeight: "bold",
  },
  sectionContainer: {
    marginBottom: "5rem",
    marginTop: "5rem",
    width: "50%",
    paddingRight: "5%",
    [theme.breakpoints.down("sm")]: {
    paddingRight: "0",
      marginTop: "0",
      width: "100%",
    },
  },

  mainImage: {
    width: "50%",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {},
  },

  paragraph: {
    ...blackmintTheme.typographyBlackmint.paragraph,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  landingScreenMainText: {
    lineHeight: "200% !important",
    fontSize: "18px",
  },

  landingSecond: {
    fontWeight: "bold",
    fontFamily: "Inter",
    fontSize: "28px",
    lineHeight: "150%",
    fontSize: "var(--default-font-size)",
    color: "var(--color-primary)",
    marginBottom: "4rem",
    marginTop: "10rem",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0rem",
      textAlign: "center",
      marginTop: "0rem",
    },
  },
  buttons: {
    paddingTop: "3rem",
    width: 'fit-content',
    [theme.breakpoints.down("sm")]: {
      margin: 'auto',
    },
  }
}));

export default function ImageSection({}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div style={{}} className={classes.root}>
      <div className={classes.sectionContainer}>
        <Typography component="h3" className={classes.landingSecond}>
          Start by becoming a rising star!
        </Typography>
        <p className={clsx(classes.paragraph, classes.landingScreenMainText)}>
          A star is not born overnight. But with BlackMINT’s Rising Star, you
          will learn everything you need to{" "}
          <span className={classes.boldFont}>
            become a star at your own pace!
          </span>
        </p>
        <div className={classes.buttons}>
          <ButtonDefault
            onClick={() => {
              history.push("/signup");
            }}
            style={{ width: "30rem" }}
          >
            START YOUR JOURNEY
          </ButtonDefault>
        </div>
      </div>
      <BlobImage src={heroImage2} className={classes.mainImage} />
    </div>
  );
}
