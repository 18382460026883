import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    // maxWidth: "440px",
  },
  media: {
    height: 130,
    backgroundSize: "contain",
    backgroundColor: "#4DC591",
    //pink colour from "programs"
    //backgroundColor: "#DECABA"
    objectFit: "contain",
    padding: 30,
  },
}));

const WebCareerCard = ({ careerName, linkTo, imgLink }) => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <React.Fragment>
      {/* <Grid item md={3} sm={4} xs={6}> */}
      <Paper elevation={3}>
        <Card className={classes.cardRoot}>
          <CardActionArea onClick={() => history.push(linkTo)}>
            {imgLink ? (
              <CardMedia
                className={classes.media}
                src={imgLink}
                title="Contemplative Reptile"
                component="img"
              />
            ) : (
              <Skeleton
                variant="rect"
                width={"100%"}
                height={130}
                animation="wave"
              />
            )}

            <CardContent style={{ padding: "16px 0px" }}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                style={{
                  fontFamily: "Inter",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                {careerName}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Paper>
      {/* </Grid> */}
    </React.Fragment>
  );
};
export default WebCareerCard;
