import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import trophyImage from "./images/trophy.png";
import { ReactComponent as ComputerCheckSvg } from "./images/computerCheck.svg";
import { ReactComponent as BigStarSvg } from "./images/bigStar.svg";
import { blackmintTheme } from "../../../style/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    width: "100%",
    padding:
      "var(--padding-screen-large-top) var(--padding-screen-large-side) 0 var(--padding-screen-large-side)",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "var(--padding-screen-medium-side)",
      paddingRight: "var(--padding-screen-medium-side)",
    },
    [theme.breakpoints.down("xs")]: {
      padding:
        "var(--padding-screen-large-top) var(--padding-screen-small-side) 0 var(--padding-screen-small-side)",
    },
  },
  landingHeader: {
    marginBottom: "2rem",
    fontWeight: "normal",
    fontSize: "44px",
    padding: "4rem 0",
    fontFamily: "Inter",
    fontWeight: "700",
  },
  paragraph: {
    ...blackmintTheme.typographyBlackmint.paragraph,
  },
  landingScreenMainText: {
    lineHeight: "200% !important",
    fontSize: "18px",
  },
  landingScreenSection: {
    backgroundColor: "var(--color-secondary-light)",
    textAlign: "center",
    paddingTop: "5rem",
    paddingBottom: "5rem",
    // paddingLeft: "15rem",
    // paddingRight: "15rem",
    [theme.breakpoints.down("sm")]: {
      //   paddingRight: "3rem",
      //   paddingLeft: "3rem",
    },
  },
  landingScreenGrid: {
    padding: "2rem 0",
    display: "grid",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 2rem",
    },
  },
  landingScreenGroup: {
    // flexBasis: "45%",
    gridTemplateColumns: "1fr 1fr 1fr",
    display: "grid",
    gap: "4rem",
    marginBottom: "2rem",
    "&:last-child": {
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      flexBasis: "100%",
      gridTemplateColumns: "1fr",
    },
  },
  h2: {
    ...blackmintTheme.typographyBlackmint.h2,
    marginBottom: "2rem",
  },

  headingTertiary: {
    fontWeight: "bold",
    fontFamily: "Inter",
    fontSize: "28px",
    lineHeight: "150%",
    fontSize: "var(--default-font-size)",
    color: "var(--color-primary)",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5rem",
    },
  },
}));

export default function How({}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, classes.landingScreenSection)}
      style={{ marginTop: "5rem" }}
    >
      <Typography
        component="h2"
        className={clsx(classes.landingHeader, classes.h2)}
      >
        How Does It Work?
      </Typography>
      <div className={classes.landingScreenGrid}>
        <div className={classes.landingScreenGroup}>
          <div style={{ flex: 1 }}>
            <ComputerCheckSvg />
            <Typography component="h2" className={classes.headingTertiary}>
              Complete your tasks
            </Typography>
            <div
              className={clsx(classes.paragraph, classes.landingScreenMainText)}
            >
              Start your journey by getting assigned to tasks from reading
              articles to watching videos based on your interests or activities.
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <BigStarSvg />
            <Typography component="h2" className={classes.headingTertiary}>
              Earn star points
            </Typography>
            <div
              className={clsx(classes.paragraph, classes.landingScreenMainText)}
            >
              After you complete your tasks, you will earn star points! The more
              points you have, the higher you move up in your level.
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <img
              className="landingScreenHow__group__icon"
              src={trophyImage}
              alt="trophy"
            />
            <Typography component="h2" className={classes.headingTertiary}>
              Trade your points for rewards
            </Typography>
            <div
              className={clsx(classes.paragraph, classes.landingScreenMainText)}
            >
              Once you have enough star points, redeem them for perks and
              rewards provided by BlackMINT!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
