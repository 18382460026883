import React, { useEffect, useState } from "react";
import "./MentorThumbnail.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";

export default function MentorThumbnail({ mentor, wide, noPicture = false }) {
  const [mentorImage, setMentorImage] = useState(null);

  useEffect(() => {
    setMentorImage(mentor.image)
  });

  return (
    <Link to={`${ROUTES.mentorsList}/${mentor && mentor.id}`}>
      <div className={`mentorThumbnail ${wide && "mentorThumbnail-wide"}`}>
        {!noPicture ? (
          <div
            className={`mentorThumbnail__thumbnail ${
              wide && "mentorThumbnail__thumbnail-wide"
            }`}
            style={{ backgroundImage: `url(${mentorImage})` }}
          ></div>
        ) : null}
        <div
          className={`mentorThumbnail__text ${
            wide && "mentorThumbnail__text-wide"
          }`}
        >
          <div
            className={`mentorThumbnail__name ${
              wide && "mentorThumbnail__name-wide"
            }`}
          >
            {mentor ? mentor.name : null}
          </div>
          <div
            className={`mentorThumbnail__job ${
              wide && "mentorThumbnail__job-wide"
            }`}
          >
            {mentor ? `${mentor.job}${mentor.company ? ` @ ${mentor.company}` : ''}` : null}
          </div>
        </div>
      </div>
    </Link>
  );
}
