import React from "react";
 import { Link } from "react-router-dom";
 import img from "./darkmodelogo.png"

 export default function LogoDefault() {
   return (
     <Link to="/home">
      <img src={img} width="250px"/>
     </Link>
   );
 } 