import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import queryFetch from "../../../azureGraphQL/queryFetch";
import AnnouncementForm from "../../../components/Announcements/AnnouncementForm/AnnouncementForm";
import WebNav from "../../../components/Layout/Nav/NavBar/WebNav";
import { AuthUserContext } from "../../../Providers";
import { useAnnouncement } from "../../../Providers/AnnouncementProvider/AnnouncementProvider";
import "./AnnouncementEditScreen.scss";

const AnnouncementEditScreen = () => {
    const { authUserDetails, isUserMentee } = useContext(AuthUserContext);
    const history = useHistory();
    const [announcement, setAnnouncement] = useState(null);
    const { id } = useParams();
    const { getAnnouncement, fetchAllAnnouncements } = useAnnouncement();

    useEffect(() => {
        if (isUserMentee || (!isUserMentee && !authUserDetails.isTeamMember)) {
            history.push("/");
        }
        (async () => {
            const res = await getAnnouncement(id);
            setAnnouncement(res);
        })();
    }, []);

    const onAnnouncementEdit = async (title, text, image) => {
        const res = await fetch(
            `https://blog.risingstar.blackmint.io/announcements/${announcement.id}`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    title,
                    text: text.toString(),
                    image,
                }),
            }
        );

        if (res.status !== 200) {
            throw new Error("Error with submitting blogs");
        }

        const announcementRes = await res.json();
        await fetchAllAnnouncements();
        return announcementRes.id;
    };

    return (
        <div className="announcement-edit--background">
            <WebNav />
            <div className="announcement-edit">
                <div className="announcement-edit__container">
                    <div className="announcement-edit__container--underline">
                        <h1 className="announcement-edit__h1">
                            Edit your announcement post
                        </h1>
                    </div>
                </div>
            </div>

            {announcement && announcement !== 404 ? (
                <AnnouncementForm
                    onAnnouncementSubmit={onAnnouncementEdit}
                    image={announcement.image}
                    title={announcement.title}
                    text={announcement.text}
                />
            ) : (
                <h2>Announcement not found</h2>
            )}
        </div>
    );
};

export default AnnouncementEditScreen;
