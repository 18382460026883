import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { HelpTag, Section } from "../..";
import { useBlogs } from "../../../Providers/BlogsProvider/BlogsProvider";
import BlogCard from "../BlogCard/BlogCard";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import { IconButton } from "@material-ui/core";
import "./BlogFeatured.scss";
import { useAuth } from "../../../Providers";

const BlogFeatured = () => {
  const history = useHistory();
  const { blogs } = useBlogs();
  const { authUserDetails, isUserMentee } = useAuth();

  return (
    <Section.Container
      title={
        <>
          Explore Blog Posts{" "}
          <HelpTag message="Explore blog posts written by our mentors!" />
          {authUserDetails && !isUserMentee && (
            <IconButton
              style={{
                marginLeft: "auto",
                backgroundColor: "#dbf3e9",
                padding: "25px",
              }}
              onClick={() => {
                history.push("/blogs/submit");
              }}
            >
              <NoteAddIcon style={{ fontSize: 70, color: "#1f4f3a" }} />
            </IconButton>
          )}
        </>
      }
      viewMore
      onViewMore={() => {
        history.push(`/blogs/all`);
      }}
      style={{
        padding: "5rem",
        paddingTop: 0,
      }}
    >
      <div className="blog-featured__list">
        {blogs.length !== 0 &&
          blogs
            .slice(-2)
            .reverse()
            .map((blog) => (
              <BlogCard
                key={blog.id}
                title={blog.title}
                author={blog.user}
                imageUrl={blog.image.url}
                id={blog.id}
              />
            ))}
      </div>
    </Section.Container>
  );
};

export default BlogFeatured;
