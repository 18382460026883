import React, { createContext, useState, useEffect, useContext } from "react";
import { pushToObject } from "../../helpers";
import { Firebase } from "../../apis";
import {getMentorByIdFromGraphQL, getAllMentorsFromGraphQL, getMentorsByListOfIdsFromGraphQL} from "../../azureGraphQL/mentorQuery";
import {getAllUsersFromGraphQL, getUserByIdFromGraphQL, getUsersByListOfIdsFromGraphQL} from "../../azureGraphQL/userQuery";



export const UsersContext = createContext();

export const useUsers = () => useContext(UsersContext);

export default function UsersProvider({ children }) {
  const [users, setUsers] = useState({});

  useEffect(() => {
    return Firebase.auth.onAuthStateChanged(async (auth) => {
      if (auth) {
        getValidMentors();
      }
    });
  }, []);

  const getValidMentors = async () => {
    const result = await getAllMentorsFromGraphQL()
    const validMenotrs = [];
    result.forEach((mentor) => {
      if (mentor.allowViewProfile && mentor.allowViewProfile == "true"){
        validMenotrs.push(mentor);
      }
    });
    validMenotrs && updateUsersStore(validMenotrs);
  };

  const updateUsersStore = (newUsers) => setUsers(pushToObject({}, newUsers));

  const addUserToStore = (newUser) =>
    setUsers((users) => ({ ...users, [newUser.id]: newUser }));

  const addUsersToStore = (newUsers) =>
    setUsers((users) => pushToObject(users, newUsers));

  const getUser = async (id) => {
    if (users[id]) return users[id];
    const result = await Firebase.get("mentors", id);

    if (result?.allowViewProfile) {
      addUserToStore(result);
      return result;
    }
    return null;
  };

  const getUsers = async (arrayOfIds) => {
    const returnedUsers = [];
    const unStoredUsers = [];
    arrayOfIds.forEach((id) => {
      const user = users[id];
      if (user) returnedUsers.push(user);
      else unStoredUsers.push(id);
    });
    if (!unStoredUsers.length) return returnedUsers;
    else {
      const result = await getMentorsByListOfIdsFromGraphQL(unStoredUsers);
      const validMenotrs = []
      result.forEach((mentor) => {
        if (mentor.allowViewProfile && mentor.allowViewProfile == "true"){
          validMenotrs.push(mentor);
        }
      });
      if (validMenotrs) {
        returnedUsers.push(...validMenotrs);
        addUsersToStore(validMenotrs);
      }
      return returnedUsers;
    }
  };

  const getAllUsers = () => {
    return Object.values(users);
  };

  return (
    <UsersContext.Provider
      value={{
        updateUsersStore,
        addUserToStore,
        addUsersToStore,
        getUser,
        getUsers,
        getAllUsers,
        getValidMentors,
        users,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}
