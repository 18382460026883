const config = {
  // apiKey: "AIzaSyBNzV9dtrCgvVejoEhFO_2zCHQa3wNqDkI",
  // authDomain: "brightstar-1e6d4.firebaseapp.com",
  // databaseURL: "https://brightstar-1e6d4.firebaseio.com",
  // projectId: "brightstar-1e6d4",
  // storageBucket: "brightstar-1e6d4.appspot.com",
  // messagingSenderId: "160196172374",
  // appId: "1:160196172374:web:07472737331139f5feb9f7"
  apiKey: "AIzaSyAv2YvI4eqGmP5LSyj1U-cMVjXXRbKH_i0",
  authDomain: "black-mint-24253.firebaseapp.com",
  databaseURL: "https://black-mint-24253.firebaseio.com",
  projectId: "black-mint-24253",
  storageBucket: "black-mint-24253.appspot.com",
  messagingSenderId: "996294361696",
  appId: "1:996294361696:web:d8303075f2298faf84b905",
  measurementId: "G-GFLYCKFYH7",
};

export default config;
