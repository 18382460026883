import React, { useState } from "react";
import { Card, Typography, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.15)",
    cursor: "pointer",
  },
  rootActive: {
    // minWidth: 275,
    backgroundColor: "var(--color-primary)",
    "&:hover": {
      backgroundColor: "var(--color-primary-dark)",
    },
  },
  rootInactive: {
    // minWidth: 275,
    backgroundColor: "var(--color-grey-light-3)",
    "&:hover": {
      backgroundColor: "var(--color-grey-dark-2)",
    },
  },
  title: {
    // fontSize: 14,
    fontFamily: "var(--font-primary),sans-serif",
    fontSize: "1.7rem",
    fontWeight: 400,
    color: "black",
  },
  cardContent: {
    textAlign: "center",
    height: "100%",
    verticalAlign: "middle",
    display: "flex",
    alignItems: "center",
    placeContent: "center",
  },
}));

export default function FormBoxInputV2({
  label,
  value,
  onChange = () => {},
  onClick = () => {},
  initialSelected = false,
  ...rest
}) {
  const classes = useStyles();
  const [selected, setSelected] = useState(initialSelected);

  const selectHandler = (e) => {
    e.preventDefault();
    setSelected(!selected);
    onClick(value);
  };
  return (
    <Card
      className={clsx(
        classes.root,
        selected ? classes.rootActive : classes.rootInactive
      )}
      onClick={selectHandler}
    >
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title}>{label}</Typography>
      </CardContent>
    </Card>
  );
}
