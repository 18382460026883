import React, { createContext } from "react";
import Firebase from "../../apis/Firebase";

export const FirebaseContext = createContext();

// export const withFirebase = Component => props => (
//     <FirebaseContext.Consumer>
//       {firebase => <Component {...props} firebase={firebase} />}
//     </FirebaseContext.Consumer>
// );

export default function FirebaseProvider({ children }) {
  return (
    <FirebaseContext.Provider value={Firebase}>
      {children}
    </FirebaseContext.Provider>
  );
}
