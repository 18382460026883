import { makeStyles } from "@material-ui/core";
import React from "react";
import StarSvg from "../../../../components/Tasks/StarSvg/StarSvg";
import { useAuth } from "../../../../Providers";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: "5rem",
    height: "10rem",
    display: "flex",
    alignItems: "center",
    flex: 1
  },
  track: {
    width: "100%",
    position: "relative",
    height: 0,
    borderTop: "3px solid var(--color-black)",
  },
  star: {
    position: "absolute",
    transform: "translate(-50%, -65%)",
    top: "40%",
    width: "2rem",
  },
  points: {
    fontSize: "1.5rem",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "2.5rem",
  },
  mark: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "-2rem",
    height: "2.5rem",
    width: "2.5rem",
  },
}));

export default function LevelProgress({}) {
  const { authUserDetails, authUser } = useAuth();

  const { root, mark, points, star, track } = useStyles();

  return (
    <div className={root}>
      <div className={track}>
        <div className="homeScreenProgress__checkPoint">
          <div className={star} style={{ left: 0 }}>
            <StarSvg type="bronze" />
          </div>
          <div className={points} style={{ left: 0 }}>
            0
          </div>
        </div>
        <div className="homeScreenProgress__checkPoint">
          <div className={star} style={{ left: "50%" }}>
            <StarSvg type="silver" />
          </div>
          <div className={points} style={{ left: "50%" }}>
            100
          </div>
        </div>
        <div className="homeScreenProgress__checkPoint">
          <div className={star} style={{ left: "100%" }}>
            <StarSvg type="gold" />
          </div>
          <div className={points} style={{ left: "100%" }}>
            200
          </div>
        </div>
        <div className="homeScreenProgress__checkPoint font-bold">
          <div
            className={mark}
            style={{
              left: `${(authUserDetails.points / 200) * 100}%`,
            }}
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 14 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 0.265015C3.13 0.265015 0 3.39501 0 7.26501C0 11.435 4.42 17.185 6.24 19.375C6.64 19.855 7.37 19.855 7.77 19.375C9.58 17.185 14 11.435 14 7.26501C14 3.39501 10.87 0.265015 7 0.265015Z"
                fill="#4DC591"
              />
            </svg>
          </div>
          <div
            className={points}
            style={{
              left: `${(authUserDetails?.points / 200) * 100}%`,
            }}
          >
            {authUserDetails?.points}
          </div>
        </div>
      </div>
    </div>
  );
}
