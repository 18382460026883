import React from "react";
import { Link } from "react-router-dom";
import "./BlogMentorCard.scss";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageIcon from "@material-ui/icons/Image";

import { IconButton } from "@material-ui/core";

const BlogMentorCard = ({
    id,
    title,
    imageUrl,
    publishMode,
    date,
    deletePopup,
}) => {
    date = new Date(date).toLocaleDateString();

    return (
        <div className="blog-mentor-card__container">
            <div className="blog-mentor-card">
                <Link to={publishMode ? `/blogs/${id}` : `/blogs/${id}/edit`}>
                    <h3>{title}</h3>
                </Link>
                <div className="blog-mentor-card__date-text">
                    <div className="blog-mentor-card__icons-text">
                        <div>
                            {publishMode ? "Published on" : "Last updated on"}{" "}
                            {date}{" "}
                        </div>
                        <div>
                            <Link to={`/blogs/${id}/edit`}>
                                <IconButton>
                                    <EditIcon
                                        style={{
                                            color: "rgb(39, 39, 39)",
                                            fontSize: 25,
                                        }}
                                    />
                                </IconButton>
                            </Link>
                            <IconButton onClick={() => deletePopup(id)}>
                                <DeleteIcon
                                    style={{
                                        color: "rgb(39, 39, 39)",
                                        fontSize: 25,
                                    }}
                                />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
            {imageUrl ? (
                <img
                    className="blog-mentor-card__img"
                    src={`https://blog.risingstar.blackmint.io${imageUrl}`}
                />
            ) : (
                <div className="blog-mentor-card__placeholder-image">
                    <div className="blog-mentor-card__placeholder-image-icon-background">
                        <ImageIcon
                            className="blog-mentor-card__placeholder-image-icon"
                            style={{
                                fontSize: "100px",
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default BlogMentorCard;
