import React from "react";
import "./MentorLandingScreen.scss";
import ButtonDefault from "../../../components/Buttons/ButtonDefault/ButtonDefault";
import { useHistory, useRouteMatch } from "react-router";
import WithAuthorizationElement from "../../../components/Auth/WithAuthorization/WithAuthorizationElement";
import withAuthorizationComponent from "../../../components/Auth/WithAuthorization/WithAuthorizationComponent";

function MentorLandingScreen() {
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <div className="mentorLandingScreen">
      <div className="mentorLandingScreen__bg"></div>
      <h2 className="heading-secondary mentorLandingScreen__heading">
        Welcome To Rising Star
      </h2>
      <p className="mentorLandingScreen__sub">
        With Rising Star, you can explore new careers, connect with mentors, and
        more! Log in or sign up to get started toward a tech career.
      </p>
      <div className="mentorLandingScreen__buttons">
        <WithAuthorizationElement condition={(auth) => !auth}>
          <ButtonDefault
            onClick={() => {
              history.push(`/signin`);
            }}
          >
            Log In
          </ButtonDefault>
          <ButtonDefault
            onClick={() => {
              history.push(`${url}/signup`);
            }}
          >
            Sign Up
          </ButtonDefault>
        </WithAuthorizationElement>
      </div>
    </div>
  );
}

export default withAuthorizationComponent(
  (auth, authUserDetails) => !auth,
  "/mentorship/dashboard"
)(MentorLandingScreen);
