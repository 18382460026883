import React, { useEffect, useState } from "react";
import "./ArticlesListScreen.scss";
import { ArticleThumbnail } from "../../../components";
import { Firebase } from "../../../apis";

export default function ArticlesListScreen() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    Firebase.store
      .collection("articles")
      .get()
      .then((articlesSnapshot) => {
        const fetchedArticles = articlesSnapshot.docs.map((articleDoc) =>
          articleDoc.data()
        );
        setArticles(fetchedArticles);
      });
  }, []);

  return (
    <div className="articlesListScreen">
      <h2 className="articlesListScreen__heading heading-secondary">
        Our Articles
      </h2>
      <section className="articlesListScreen__mentors articlesListScreen__section">
        {articles.map((article) => {
          return <ArticleThumbnail article={article}></ArticleThumbnail>;
        })}
      </section>
    </div>
  );
}
