import React, { useEffect, useState } from "react";
import "./VideoScreen.scss";
import { useParams } from "react-router";
import withAuthorizationComponent from "../../../components/Auth/WithAuthorization/WithAuthorizationComponent";
import { taskTypes } from "../../../constants";
import { Screen, Section } from "../../../components";
import { useUsers, useTask, useAuth } from "../../../Providers";
import { Firebase } from "../../../apis";
import VideoDiplay from "./VideoDisplay";
import VideoDescription from "./VideoDescription";
import VideoComments from "./VideoComments";
import WebNavBar from "../../../components/Layout/Nav/NavBar/WebNav";
import { makeStyles } from "@material-ui/core/styles";
import { useVideos } from "../../../Providers";


export function VideoScreen() {
  const { authUser, authUserDetails } = useAuth();

  const { getVideo } = useVideos();

  const { taskableHandler } = useTask();

  const params = useParams();

  const [video, setVideo] = useState(null);

  const [videoAuthor, setVideoAuthor] = useState(null);

  const [displayComments, setDisplayComments] = useState(false);

  const { getUser } = useUsers();

  const [liked, setLiked] = useState(
    authUserDetails.likes
      ? authUserDetails.likes.videos.includes(params["id"])
      : false
  );

  const [saved, setSaved] = useState(
    authUserDetails.saves
      ? authUserDetails.saves.videos.includes(params["id"])
      : false
  );

  useEffect(() => {
    getVideo(params.id)
    .then((data) => {
      setVideo(data);
      getUser(data.author).then(setVideoAuthor);
    });
  }, [params, getUser]);


  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "var(--color-background-default)",
      display: "flex",
      [theme.breakpoints.up("md")]: {
        // gridTemplateColumns: "repeat(1, 1fr)",
        paddingLeft: "290px",
      },
    },
  }));

  const { root } = useStyles();

  return (
    <>
      <WebNavBar />
      <Screen className={root}>
        <div style={{ flex: 1 }}>
          <VideoDiplay video={video} videoAuthor={videoAuthor} />
          <Section.Container style={{ paddingBottom: 0, paddingTop: 0 }}>
            <div className="tabSelector fullWidth fullWidth-conditional">
              <button
                onClick={() => {
                  setDisplayComments(false);
                }}
                className={
                  !displayComments
                    ? `tabSelector__tab tabSelector__tab-active`
                    : `tabSelector__tab`
                }
              >
                <p className="tabSelector__tabText">Description</p>
              </button>
              <button
                onClick={() => {
                  setDisplayComments(true);
                }}
                className={
                  displayComments
                    ? `tabSelector__tab tabSelector__tab-active`
                    : `tabSelector__tab`
                }
              >
                <p className="tabSelector__tabText">Q &amp; A</p>
              </button>
            </div>
          </Section.Container>
          {!displayComments ? <VideoDescription video={video} /> : null}
          {displayComments ? <VideoComments video={video} /> : null}
        </div>
      </Screen>
    </>
  );
}

export default withAuthorizationComponent((auth) => auth)(VideoScreen);
