import React, { useState } from "react";
import "./SettingsAccount.scss";
import { useStatus, useTask, useAuth } from "../../../../Providers";
import { upsertUserFromGraphQL } from "../../../../azureGraphQL/userMutation";
import { deleteUserFromGraphQL } from "../../../../azureGraphQL/userDeletion";
import { deleteMentorFromGraphQL } from "../../../../azureGraphQL/mentorDeletion";


import {
  ButtonDefault,
  FormInput,
  FormRadioGroup,
} from "../../../../components";
import {
  REGEX_EXPRESSIONS,
  taskTypes,
  statusTypes,
} from "../../../../constants";
import { Firebase } from "../../../../apis";
import getProfileCompleteness from "../../../../utilities/getProfileCompleteness";
import checkIfValidUrl from "../../../../helpers/checkIfValidUrl";

export default function SettingsAccount() {
  const [isLoading, setIsLoading] = useState(false);

  const { changeStatus } = useStatus();

  const { authUser, authUserDetails, signOut } = useAuth();

  const { taskableHandler } = useTask();

  const initialState = {
    name: authUserDetails.name,
    email: "",
    passwordOne: "",
    passwordTwo: "",
    username: authUserDetails.username,
    linkedIn: authUserDetails?.links?.linkedIn || "",
    allowViewProfile: authUserDetails?.allowViewProfile || false,
  };

  const [state, setState] = useState(initialState);

  const {
    passwordOne,
    passwordTwo,
    username,
    linkedIn,
    allowViewProfile,
  } = state;

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const changePasswordHandler = (e) => {
    e.preventDefault();
    const newPassword = e.target.elements.passwordOne.value;
    setIsLoading(true);
    authUser
      .updatePassword(newPassword)
      .then(() => {
        changeStatus(
          {
            message: "Your settings have been updated",
          },
          statusTypes.success
        );
      })
      .catch((err) => {
        changeStatus(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeUsernameHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);

    Firebase.store
      .collection(Firebase.collections.users)
      .where("username", "==", username.toLowerCase())
      .get()
      .then(async (result) => {
        const available = result.empty;
        if (available) {
          try {
            upsertUserFromGraphQL({
              id: authUser.uid,
              rowKey: authUser.uid,
              partitionKey: "users",
              username: username.toLowerCase()
            });
            await Firebase.user(authUser.uid).update({
              username: username.toLowerCase(),
            });
            changeStatus(
              {
                message: "Your settings have been updated",
              },
              statusTypes.success
            );
          } catch (err) {
            changeStatus(err);
          }
        } else {
          changeStatus(
            { message: "Username already taken" },
            statusTypes.error
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setAllowViewProfileHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (
        getProfileCompleteness(authUserDetails, [
          "job",
          "name",
          "bio",
          "careers",
          "location.country",
          "gender",
          "company",
          "links.linkedIn",
        ]).length >= 8
      ) {
        await Firebase.update("mentors", authUserDetails.id, {
          allowViewProfile,
        });
        changeStatus(
          {
            message: allowViewProfile
              ? "Students can now view your profile!!!"
              : "Students can no longer view your profile!!!",
          },
          statusTypes.success
        );
      } else {
        setState({ ...state, allowViewProfile: false });
        changeStatus({ message: "Your profile is not complete" });
      }
    } catch (err) {
      changeStatus(err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteUserHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (authUserDetails.mentor) {
      deleteMentorFromGraphQL({
        id: authUser.uid
      });
      Firebase.deleteMentor(authUser)
        .then(() => {
          signOut();
          changeStatus({ message: "Account Deleted" }, statusTypes.success);
        })
        .catch((err) => {
          changeStatus(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      deleteUserFromGraphQL({
        id: authUser.uid
      });
      Firebase.deleteUser(authUser)
        .then(() => {
          signOut();
          changeStatus({ message: "Account Deleted" }, statusTypes.success);
        })
        .catch((err) => {
          changeStatus(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const submitLinkedIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await Firebase.store
      .collection("users")
      .doc(authUser.uid)
      .update({ "links.linkedIn": linkedIn });
    setIsLoading(false);
    if (!authUserDetails.mentor) {
      taskableHandler(taskTypes.joinLinkedIn, authUserDetails);
    }
  };

  return (
    <div className="settingsAccount settingsScreen__section">
      <form onSubmit={changePasswordHandler} className="section">
        <h2 className="section__title heading-tertiary">Change Password</h2>
        <FormInput
          name="passwordOne"
          value={passwordOne}
          onChange={onChange}
          type="password"
          placeholder="Password"
          required
        />
        <FormInput
          name="passwordTwo"
          value={passwordTwo}
          onChange={onChange}
          type="password"
          placeholder="Confirm Password"
          invalid={(value) =>
            value !== passwordOne ? { error: "Passwords don't match" } : null
          }
          required
        />
        <ButtonDefault type="submit" isLoading={isLoading}>
          Submit
        </ButtonDefault>
      </form>
      {!authUserDetails.mentor && (
        <form onSubmit={changeUsernameHandler} className="section">
          <h2 className="section__title heading-tertiary">Change Username</h2>
          <FormInput
            name="username"
            value={username}
            onChange={onChange}
            placeholder="New Username"
            required
          />
          <ButtonDefault type="submit" isLoading={isLoading}>
            Submit
          </ButtonDefault>
        </form>
      )}
      {authUserDetails.mentor && (
        <form className="section" onSubmit={setAllowViewProfileHandler}>
          <FormRadioGroup
            initialValue={allowViewProfile}
            name="allowViewProfile"
            title="Allow students to view your profile?"
            radioData={[
              { value: true, placeholder: "Yes" },
              { value: false, placeholder: "No" },
            ]}
            onChange={onChange}
          />
          <ButtonDefault type="submit" isLoading={isLoading}>
            Submit
          </ButtonDefault>
        </form>
      )}
      <form className="section" onSubmit={deleteUserHandler}>
        <h2 className="section__title heading-tertiary">Delete Account</h2>
        <FormInput
          value=""
          onChange={onChange}
          placeholder={
            authUserDetails.mentor
              ? "Type your name to delete account"
              : "Type in your username to delete account"
          }
          invalid={(value) =>
            (
              authUserDetails.mentor
                ? value !== authUserDetails.name
                : value !== authUserDetails.username
            )
              ? { error: "Incorrect Username" }
              : null
          }
          autoComplete="off"
          required
        />
        <ButtonDefault variant="danger" type="submit" isLoading={isLoading}>
          Delete Account
        </ButtonDefault>
      </form>
      {!authUser.mentor && (
        <form className="section" onSubmit={submitLinkedIn}>
          <h2 className="section__title heading-tertiary">LinkedIn</h2>
          <FormInput
            name="linkedIn"
            value={linkedIn}
            onChange={onChange}
            placeholder={"Your linkedIn link"}
            autoComplete="off"
            invalid={(value) =>
              !checkIfValidUrl(value) && { error: "Please use a valid link" }
            }
            required
          />
          <ButtonDefault type="submit" isLoading={isLoading}>
            Submit
          </ButtonDefault>
        </form>
      )}
    </div>
  );
}
