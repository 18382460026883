import React from 'react';
import './TaskComplete.scss'
import { taskTypes } from '../../../constants';
import {ButtonDefault} from '../..';

export default function TaskComplete({ task, setTaskComplete }) {
  let taskCompleteText;
  switch (task.type) {
    case taskTypes.watchNumberVideos:
      taskCompleteText = `Congratulation!! You Watched ${
        task.amount
      } ${"Videos"} for ${task.points} points`;
      break;
    case taskTypes.readNumberCareers:
      taskCompleteText = `Congratulation!! You Visited ${
        task.amount
      } ${"Career Profiles"} for ${task.points} points`;
      break;
    case taskTypes.readNumberMentors:
      taskCompleteText = `Congratulation!! You Visited ${
        task.amount
      } ${"Mentor Bios"} for ${task.points} points`;
      break;
    case taskTypes.readNumberDegrees:
        taskCompleteText = `Congratulation!! You Visited ${
          task.amount
        } ${"Programs"} for ${task.points} points`;
        break;
    case taskTypes.giveMoreInfo:
      taskCompleteText = `Congratulations!! Got ${task.points} points for providing some information`;
      break;
    case "watchNumber":
      taskCompleteText = `Congratulation!! You Watched ${
        task.amount
      } ${"Videos"} for ${task.points} points`;
      break;
    case "readCareer":
      taskCompleteText = `Congratulation!! You Visited ${
        task.amount
      } ${"Career Profiles"} for ${task.points} points`;
      break;
    case "readMentor":
      taskCompleteText = `Congratulation!! You Visited ${
        task.amount
      } ${"Mentor Bios"} for ${task.points} points`;
      break;
    case "readDegree":
        taskCompleteText = `Congratulation!! You Visited ${
          task.amount
        } ${"Programs"} for ${task.points} points`;
        break;
    default:
      taskCompleteText = "";
      break;
  }

  return (
    <div className="taskComplete">
      <p className="taskComplete__text">
        {task.successMessage || taskCompleteText}
      </p>
      <ButtonDefault
        onClick={() => {
          setTaskComplete(null);
        }}
      >
        Continue
      </ButtonDefault>
    </div>
  );
}
