import queryFetch from "./queryFetch";
const getAllVideosFromGraphQL = async () => {

    const data = await queryFetch(`
    query getAllVideos {
        allvideos{
            id,
            title,
            dateCreated,
            author,
            youtubeId,
            description{
                value,
                type
            }
        }
      }
    `);
    return data.allvideos;

};

const getVideoByIdFromGraphQL = async (variable) => {
    const data = await queryFetch(`
        query getVideoById($queryid: String) {
            video(id: $queryid){
                id,
                title,
                dateCreated,
                author,
                youtubeId,
                description{
                    value,
                    type
                }
            }
        }
    `, {queryid: variable});
    return data.video;
};

const getVideosByListOfIdsFromGraphQL = async (variable) => {
    const data = await queryFetch(`
        query getVideoById($queryids: [String]) {
            videos(ids: $queryids){
                id,
                title,
                dateCreated,
                author,
                youtubeId,
                description{
                    value,
                    type
                }
            }
        }
    `, {queryids: variable});
    return data.videos;
};

export {getAllVideosFromGraphQL, getVideoByIdFromGraphQL, getVideosByListOfIdsFromGraphQL}