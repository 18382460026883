import queryFetch from "./queryFetch";
const getAllMentorsFromGraphQL = async () => {
    const data = await queryFetch(`
    query getAllMentors {
      allmentors{
        mentor,
        joined,
        name,
        company,
        bio,
        students,
        job,
        links{
            website,
            linkedIn,
            twitter,
            instagram
        },
        location{
            country,
            province,
            city
        },
        image,
        id,
        allowViewProfile,
        email,
        degrees,
        careers,
        isTeamMember
      }
    }
    `);
      return data.allmentors;
}

const getMentorByIdFromGraphQL = async (variable) => {
  const data = await queryFetch(`
      query getMentorById($queryid: String) {
          mentor(id: $queryid){
            mentor,
            joined,
            name,
            company,
            bio,
            students,
            job,
            links{
                website,
                linkedIn,
                twitter,
                instagram
            },
            location{
                country,
                province,
                city
            },
            image,
            id,
            allowViewProfile,
            email,
            degrees,
            careers,
            isTeamMember
          }
      }
  `, {queryid: variable});
  return data.mentor;
};

const getMentorsByListOfIdsFromGraphQL = async (variable) => {
  const data = await queryFetch(`
      query getMentorsById($queryids: [String]) {
          mentors(ids: $queryids){
            mentor,
            joined,
            name,
            company,
            bio,
            students,
            job,
            links{
                website,
                linkedIn,
                twitter,
                instagram
            },
            location{
                country,
                province,
                city
            },
            image,
            id,
            allowViewProfile,
            email,
            degrees,
            careers,
            isTeamMember
          }
      }
  `, {queryids: variable});
  return data.mentors;
};

export {getAllMentorsFromGraphQL, getMentorByIdFromGraphQL, getMentorsByListOfIdsFromGraphQL}
