const taskTypes = {
    watchNumberVideos: 'watchNumberVideos',
    readNumberCareers: 'readNumberCareers',
    readNumberMentors: 'readNumberMentors',
    readNumberDegrees: 'readNumberDegrees',
    giveMoreInfo: 'giveMoreInfo',
    joinLinkedIn: 'joinLinkedIn',
    feedback: 'feedback',
    leaveComment: 'leaveComment'
}

export default taskTypes