import React, { useState } from "react";
import "./SettingsInterests.scss";
import { useStatus, useAuth, useCareers } from "../../../../Providers";
import { hobbies, careerInterests } from "../../../../testData";
import { removeValueFromArray } from "../../../../helpers";
import { statusTypes } from "../../../../constants";
import { ButtonDefault, FormBoxInputV2 } from "../../../../components";
import { Firebase } from "../../../../apis";
import { makeStyles } from "@material-ui/core/styles";
import { upsertUserFromGraphQL } from "../../../../azureGraphQL/userMutation";


const useStyles = makeStyles(() => ({
  settingsRoot: {
    flex: "2 1",
  },
}));

export default function SettingsInterests() {
  const classes = useStyles();
  const { getAllCareers } = useCareers();

  const { changeStatus } = useStatus();

  const { authUser, authUserDetails } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const [selectedHobbies, setSelectedHobbies] = useState(
    authUserDetails &&
      authUserDetails.interests &&
      authUserDetails.interests.hobbies
      ? hobbies.filter((hobby) =>
          authUserDetails.interests.hobbies.includes(hobby)
        )
      : []
  );

  const [selectedCareerInterests, setSelectedCareerInterests] = useState(
    authUserDetails &&
      authUserDetails.interests &&
      authUserDetails.interests.careers
      ? careerInterests.filter((careerInterest) =>
          authUserDetails.interests.careers.includes(careerInterest)
        )
      : []
  );

  const minInterestsSelected = 2,
    maxInterestsSelected = 8;

  const hobbyClickHandler = (e) => {
    const newSelectedHobbies = [...selectedHobbies];
    const element = e;
    if (!newSelectedHobbies.includes(element)) {
      newSelectedHobbies.push(element);
      setSelectedHobbies(newSelectedHobbies);
    } else {
      setSelectedHobbies(
        removeValueFromArray(newSelectedHobbies, (item) => element === item)
      );
    }
  };

  const setHobbiesSubmitHandler = (e) => {
    e.preventDefault();
    if (
      selectedHobbies.length >= minInterestsSelected &&
      selectedHobbies.length <= maxInterestsSelected
    ) {
      console.log("Hobbies Submitted")
      setIsLoading(true);
      upsertUserFromGraphQL({
        id: authUser.uid,
        rowKey: authUser.uid,
        partitionKey: "users",
        "interests.hobbies": selectedHobbies
      });
      Firebase.user(authUser.uid)
        .update({ "interests.hobbies": selectedHobbies })
        .then(() => {
          changeStatus(
            {
              message: "Your settings have been updated",
            },
            statusTypes.success
          );

        })
        .catch((err) => {
          changeStatus(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (selectedHobbies.length <= minInterestsSelected)
        changeStatus(
          {
            message:
              "You have selected too few options. Please select between 1 and 8 options",
          },
          statusTypes.error
        );
      else
        changeStatus(
          {
            message:
              "You have selected too many options. Please select between 1 and 8 options.",
          },
          statusTypes.error
        );
    }
  };

  const careerInterestClickHandler = (e) => {
    const newSelectedCareerInterests = [...selectedCareerInterests];
    const element = e;
    if (!newSelectedCareerInterests.includes(element)) {
      newSelectedCareerInterests.push(element);
      setSelectedCareerInterests(newSelectedCareerInterests);
    } else {
      setSelectedCareerInterests(
        removeValueFromArray(
          newSelectedCareerInterests,
          (item) => element === item
        )
      );
    }
  };

  const setCareerInterestsSubmitHandler = async (e) => {
    e.preventDefault();
    if (
      selectedHobbies.length >= minInterestsSelected &&
      selectedHobbies.length <= maxInterestsSelected
    ) {
      setIsLoading(true);
      upsertUserFromGraphQL({
        id: authUser.uid,
        rowKey: authUser.uid,
        partitionKey: "users",
        "interests.careers": selectedCareerInterests
      });
      await Firebase.user(authUser.uid)
        .update({
          "interests.careers": selectedCareerInterests,
        })
        .catch((err) => {
          changeStatus(err);
        });
      const careersToJoin = [];
      getAllCareers().forEach((career) => {
        if (
          selectedCareerInterests.some((careerInterest) =>
            career.tags.includes(careerInterest)
          )
        ) {
          careersToJoin.push(career.id);
        }
      });
      upsertUserFromGraphQL({
        id: authUser.uid,
        rowKey: authUser.uid,
        partitionKey: "users",
        joinedCareers: careersToJoin
      });
      Firebase.user(authUser.uid)
        .update({ joinedCareers: careersToJoin })
        .then(() => {
          changeStatus(
            {
              message: "Your settings have been updated",
            },
            statusTypes.success
          );
        })
        .catch((err) => {
          changeStatus(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (selectedHobbies.length <= minInterestsSelected)
        changeStatus(
          {
            message:
              "You have selected too few options. Please select between 1 and 8 options",
          },
          statusTypes.error
        );
      else
        changeStatus(
          {
            message:
              "You have selected too many options. Please select between 1 and 8 options.",
          },
          statusTypes.error
        );
    }
  };

  return (
    <div className={classes.settingsRoot}>
      <form className="section" onSubmit={setHobbiesSubmitHandler}>
        <h2 className="section__title heading-tertiary">Hobbies</h2>
        <div className="settingsInterests__grid">
          {hobbies.map((interest, index) => (
            <div className="signUpInterests__input">
              <FormBoxInputV2
                label={interest}
                value={interest}
                key={index}
                onClick={hobbyClickHandler}
                initialSelected={selectedHobbies.includes(interest)}
              />
            </div>
          ))}
        </div>
        <p className="settingsInterests__counter">{`${selectedHobbies.length}/${maxInterestsSelected}`}</p>
        <ButtonDefault isLoading={isLoading} type="submit">
          Set Interests
        </ButtonDefault>
      </form>
      <form className="section" onSubmit={setCareerInterestsSubmitHandler}>
        <h2 className="section__title heading-tertiary">Career Interests</h2>
        <div className="settingsInterests__grid">
          {careerInterests.map((interest, index) => (
            <div className="signUpInterests__input">
              <FormBoxInputV2
                label={interest}
                value={interest}
                key={index}
                onClick={careerInterestClickHandler}
                initialSelected={selectedCareerInterests.includes(interest)}
              />
            </div>
          ))}
        </div>
        <p className="settingsInterests__counter">{`${selectedCareerInterests.length}/${maxInterestsSelected}`}</p>
        <ButtonDefault isLoading={isLoading} type="submit">
          Set Interests
        </ButtonDefault>
      </form>
    </div>
  );
}
