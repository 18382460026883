import React, { useEffect, useState } from "react";
import "./WebMentorThumbnail.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      // gridTemplateColumns: "repeat(1, 1fr)",
      flexDirection: "column",
      textAlign: "center",
    },
  },
  text: {
    flex: 1,
    maxWidth: "15rem",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    maxWidth: "none",
    marginBottom: '2rem'
      
    },
  },
}));

export default function WebMentorThumbnail({
  mentor,
  wide,
  noPicture = false,
}) {
  const [mentorImage, setMentorImage] = useState(null);

  const { content,text } = useStyles();

  useEffect(() => {
    setMentorImage(mentor.image)
  });

  return (
    <div className="webMentorThumbnail">
      {!noPicture ? (
        <div
          className={`webMentorThumbnail__thumbnail ${
            wide && "webMentorThumbnail__thumbnail-wide"
          }`}
          style={{ backgroundImage: `url(${mentorImage})` }}
        ></div>
      ) : null}

      <div className={content}>
        <div
          className={text}
        >
          <div
            className={`mentorThumbnail__name ${
              wide && "mentorThumbnail__name-wide"
            }`}
          >
            {mentor ? mentor.name : null}
          </div>
          <div
            className={`mentorThumbnail__job ${
              wide && "mentorThumbnail__job-wide"
            }`}
          >
            {mentor
              ? `${mentor.job}${mentor.company ? ` @ ${mentor.company}` : ""}`
              : null}
          </div>
        </div>
        <Link
          className="webMentorThumbnail__read-more"
          to={`${ROUTES.mentorsList}/${mentor && mentor.id}`}
        >
          Read More
        </Link>
      </div>
    </div>
  );
}
