import React, { useState } from "react";
import { Firebase } from "../../../../apis";
import { upsertUserFromGraphQL } from "../../../../azureGraphQL/userMutation";
import {
  H1,
  H2,
  Screen,
  Section,
  VideoThumbnail,
  ButtonDefault,
  PopUp,
} from "../../../../components";
import {
  useCareers,
  useTask,
  useAuth,
  useUsers,
  useVideos,
} from "../../../../Providers";
import { usecareer, useHistory } from "react-router";
import { removeValueFromArray } from "../../../../helpers";

export default function ({ career }) {
  const { authUserDetails } = useAuth();
  const [showPopUp, setShowPopUp] = useState(false);
  const history = useHistory();

  const joinCareerHandler = async () => {
    if (authUserDetails.mentor) {
      const newCareers = authUserDetails.careers
        ? [...authUserDetails.careers, career["id"]]
        : [career["id"]];
      await Firebase.store
        .collection(Firebase.collections.mentors)
        .doc(authUserDetails.id)
        .update({ careers: newCareers });
      await Firebase.store
        .collection("careers")
        .doc(career.id)
        .update({ mentors: [...career.mentors, authUserDetails.id] });
    } else {
      const newJoinedCareers = authUserDetails.joinedCareers
        ? [...authUserDetails.joinedCareers, career["id"]]
        : [career["id"]];
      upsertUserFromGraphQL({
        id: authUserDetails.id,
        rowKey: authUserDetails.id,
        partitionKey: "users",
        joinedCareers: newJoinedCareers
      });
      Firebase.user(authUserDetails.id).update({
        joinedCareers: newJoinedCareers,
      });
    }
    setShowPopUp(true);
    setTimeout(() => setShowPopUp(false), 5000);
  };

  const leaveCareerHandler = async () => {
    if (authUserDetails.mentor) {
      const newCareers = removeValueFromArray(
        authUserDetails.careers,
        career["id"]
      );
      await Firebase.store
        .collection(Firebase.collections.mentors)
        .doc(authUserDetails.id)
        .update({ careers: newCareers });
      await Firebase.store
        .collection("careers")
        .doc(career.id)
        .update({
          mentors: removeValueFromArray(career.mentors, authUserDetails.id),
        });
    } else {
      const newJoinedCareers = removeValueFromArray(
        authUserDetails.joinedCareers,
        career["id"]
      );
      upsertUserFromGraphQL({
        id: authUserDetails.id,
        rowKey: authUserDetails.id,
        partitionKey: "users",
        joinedCareers: newJoinedCareers
      });
      Firebase.user(authUserDetails.id).update({
        joinedCareers: newJoinedCareers,
      });
    }
  };
  return (
    <>
      {showPopUp ? (
        <PopUp>
          <p className="paragraph">Career Joined</p>
        </PopUp>
      ) : null}
      <div
        style={{ padding: "2rem 0", display: "flex", justifyContent: "center" }}
      >
        {(
          authUserDetails?.mentor
            ? !authUserDetails?.careers?.includes(career["id"])
            : !authUserDetails?.joinedCareers?.includes(career["id"])
        ) ? (
          <ButtonDefault onClick={joinCareerHandler}>Join Career</ButtonDefault>
        ) : (
          <ButtonDefault onClick={leaveCareerHandler}>
            Leave Career
          </ButtonDefault>
        )}
      </div>
    </>
  );
}
