import React, { useState, useEffect } from "react";
import "./MentorSignUpMoreInfo.scss";
import { useHistory, useLocation } from "react-router";
import { ButtonDefault } from "../../../../components";
import { useStatus } from "../../../../Providers/StatusProvider/StatusProvider";
import { newMentorDetails } from "../../../../models/mentor";
import { FormInput } from "../../../../components";
import { FormTextArea } from "../../../../components";
import { FormUploadImage } from "../../../../components";
import { useAuth } from "../../../../Providers";
import { Firebase } from "../../../../apis";

export default function MentorSignUpMoreInfo() {
  const { authUser } = useAuth();

  const { changeStatus } = useStatus();

  const history = useHistory();

  const { passKey: pass } = useLocation().state;

  const initialState = {
    passKey: pass || null,
    name: null,
    job: null,
    bio: null,
    mentorImage: null,
    twitter: null,
    instagram: null,
    linkedIn: null,
    website: null,
  };

  const [state, setState] = useState(initialState);

  const [, setIsLoading] = useState(false);

  useEffect(() => {}, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const passKeyDocData = Firebase.get(Firebase.collections.passKeys, passKey);
    if (passKeyDocData) {
      await Firebase.store
        .collection(Firebase.collections.mentors)
        .doc(authUser.uid)
        .set({
          ...newMentorDetails,
          email: authUser.email,
          id: authUser.uid,
          joined: Date.now(),
          passKey: passKey,
          job,
          name,
          bio,
          image: `profileImages/${authUser.uid}`,
          links: { website },
        })
        .then(async () => {
          if (mentorImage)
            await Firebase.storage
              .ref(`profileImages/${authUser.uid}`)
              .putString(mentorImage, "data_url");
          history.push("/mentorship/signup/careers");
        })
        .catch(changeStatus)
        .finally(setIsLoading(false));
    }
  };

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value || null });
  };

  const {
    name,
    passKey,
    bio,
    job,
    mentorImage,
    twitter,
    instagram,
    website,
    linkedIn,
  } = state;

  return (
    <form onSubmit={onSubmit} className="mentorSignUpMoreInfo">
      <div className="section">
        {!passKey ? (
          <FormInput
            name="passKey"
            value={passKey}
            onChange={onChange}
            type="text"
            placeholder="PassKey"
            required
          />
        ) : null}
        <FormInput
          name="name"
          value={name}
          onChange={onChange}
          type="text"
          placeholder="Name"
          required
        />
        <FormInput
          name="job"
          value={job}
          onChange={onChange}
          type="text"
          placeholder="Job"
          required
        />
        <FormTextArea
          name="bio"
          value={bio}
          onChange={onChange}
          type="text"
          placeholder="Tell us something about yourself..."
          required
        />
      </div>
      <div className="section">
        {/* <h2 className="section__title heading-tertiary">Social Media Links</h2> */}
        {/* <FormInput
          name="twitter"
          value={twitter}
          onChange={onChange}
          type="text"
          placeholder="Your twitter handle if you have one"
          required={false}
        />
        <FormInput
          name="instagram"
          value={instagram}
          onChange={onChange}
          type="text"
          placeholder="Your instagram handle if you have one"
          required={false}
        />
        <FormInput
          name="linkedIn"
          value={linkedIn}
          onChange={onChange}
          type="text"
          placeholder="Your linkedIn profile link if you have one"
          required={false}
        /> */}
        {/* <FormInput
          name="website"
          value={website}
          onChange={onChange}
          type="text"
          placeholder="Your website link if you have one"
          required={false}
        /> */}
      </div>
      {/* <div className="section">
        <h2 className="section__title heading-secondary">Your picture</h2>
        <FormUploadImage name="mentorImage" onChange={onChange} />
      </div> */}
      <div className="mentorSignUpMoreInfo__buttons section">
        <ButtonDefault type="submit">Submit</ButtonDefault>
      </div>
    </form>
  );
}
