import React from "react";
import "../../screens/UserMentor/MentorsListScreen/MentorsListScreen.scss";
import MentorsDescription from "../../components/Mentor/MentorsDescription/MentorsDescription";
import { useUsers } from "../../Providers";
import { H2, Screen, Section } from "../../components";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core";
import WebNavBar from "../../components/Layout/Nav/NavBar/WebNav";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--color-background-default)",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "290px",
    },
  },
}));

export default function TeamMembersListScreen() {
  const { getAllUsers } = useUsers();

  const { root } = useStyles();

  return (
    <>
      <WebNavBar />
      <Screen className={root}>
        <div style={{ flex: 1 }}>
          <Section.Container style={{ paddingBottom: 0, margin: 0 }}>
            <H2 bold={true} style={{ marginBottom: 0 }}>
              Meet the Team
            </H2>
          </Section.Container>
          <Section.Container containerClassName="mentorsListScreenMentors">
            {!getAllUsers().length
              ? Array.from(Array(12).keys()).map(() => {
                  return (
                    <Skeleton
                      variant="rect"
                      width={"100%"}
                      height={"20rem"}
                      animation="wave"
                      style={{ borderRadius: 10 }}
                    />
                  );
                })
              : getAllUsers().map((mentor) => {
                if(mentor.isTeamMember){
                return (
                    <MentorsDescription mentor={mentor}></MentorsDescription>
                  );
                }
                })}
          </Section.Container>
        </div>
      </Screen>
    </>
  );
}
