import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import WebNav from "../../../components/Layout/Nav/NavBar/WebNav";
import { useAuth } from "../../../Providers";
import { useBlogs } from "../../../Providers/BlogsProvider/BlogsProvider";
import BlogMentorCard from "../../../components/Blogs/BlogMentorCard/BlogMentorCard";
import Modal from "react-modal";
import "./BlogMentorScreen.scss";

const BlogMentorList = () => {
  const [publishTab, setTab] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const { authUserDetails, isUserMentee } = useAuth();
  const { getBlogsByUser, getUnpublishedByUser, deleteBlog } = useBlogs();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (isUserMentee) {
      history.push("/");
    }

    if (publishTab) {
      (async () => {
        const resBlogs = await getBlogsByUser(authUserDetails.id);
        setBlogs(resBlogs);
      })();
    } else {
      (async () => {
        const resBlogs = await getUnpublishedByUser(authUserDetails.id);
        setBlogs(resBlogs);
      })();
    }
  }, [publishTab]);

  const [displayMessage, setDisplayMessage] = useState("");

  //set a delay in which will display a message if there are no blog post published or in draft
  useEffect(() => {
    setDisplayMessage("");
    if (!blogs.length) {
      setTimeout(
        () =>
          setDisplayMessage(
            `No blog post ${publishTab ? "published" : "in draft"}`
          ),
        250
      );
    }
  }, [blogs]);

  const deletePopup = (id) => {
    setShowPopup(true);
    setSelectedBlog(id);
  };

  const onDeleteBlog = async (id) => {
    try {
      await deleteBlog(id);
      if (publishTab) {
        const resBlogs = await getBlogsByUser(authUserDetails.id);
        setBlogs(resBlogs);
      } else {
        const resBlogs = await getUnpublishedByUser(authUserDetails.id);
        setBlogs(resBlogs);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="blog-mentor--background">
      <Modal
        isOpen={showPopup}
        contentLabel="Are you sure you want to delete your blog post?"
      >
        <h3 className="blog-mentor__popup-warning">
          Are you sure you want to delete your blog post?
        </h3>
        <div className="blog-mentor__popup-message">
          Your blog post will be permanently deleted and will not appear on the
          platform
        </div>
        <div className="blog-mentor__popup-options">
          <div
            onClick={() => {
              setShowPopup(false);
              onDeleteBlog(selectedBlog);
            }}
          >
            DELETE
          </div>
          <div onClick={() => setShowPopup(false)}>GO BACK</div>
        </div>
      </Modal>

      {!showPopup && <WebNav />}
      <div className="blog-mentor">
        <div className="blog-mentor__container">
          <div className="blog-mentor__header">
            <h1>Your blog posts</h1>
            <hr />
            <div className="blog-mentor__tabs">
              <div
                onClick={() => setTab(false)}
                className={
                  !publishTab ? "blog-mentor__tab-selected" : "blog-mentor__tab"
                }
              >
                Drafts
              </div>
              <div
                onClick={() => setTab(true)}
                className={
                  publishTab ? "blog-mentor__tab-selected" : "blog-mentor__tab"
                }
              >
                Published
              </div>
            </div>
          </div>
        </div>

        <div className="blog-mentor__display">
          {blogs.length ? (
            blogs.map((blog, i) => (
              <BlogMentorCard
                key={i}
                id={blog.id}
                title={blog.title ? blog.title : `Draft ${i + 1}`}
                imageUrl={blog.image ? blog.image.url : null}
                id={blog.id}
                publishMode={publishTab ? true : false}
                date={publishTab ? blog.published_at : blog.updated_at}
                deletePopup={deletePopup}
              />
            ))
          ) : (
            <h2 className="blog-mentor__list-message">{displayMessage}</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogMentorList;
