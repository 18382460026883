import queryFetch from "./queryFetch";

export const deleteMentorFromGraphQL = async (variable) => {
  const data = await queryFetch(
    `
    mutation deleteMentor ($input: DeleteMentorInput!){
      deleteMentor(input: $input) {
      ok
      }
  }
    `,
    { 
      input: variable
    }
  );
  return data;
};
