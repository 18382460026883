import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import withAuthorizationComponent from "../../components/Auth/WithAuthorization/WithAuthorizationComponent";
import Skeleton from "@material-ui/lab/Skeleton";
import getInstitutionImageUrl from "../../helpers/getInstitutionImage";
import { useInstitutions } from "../../Providers";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { Typography, Container, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Screen, Section } from "../../components";
import WebNavBar from "../../components/Layout/Nav/NavBar/WebNav";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--color-background-default)",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "290px",
    },
  },
  subHeader: {
    // fontSize: "1.25rem",
    fontSize: "20px",
    fontWeight: 700,
    color: "#00664F",
    fontFamily: "Inter",
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  aboutParagraph: {
    fontSize: "16px",
  },
  viewSchoolBtn: {
    backgroundColor: "#4DC591",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: 8,
    marginBottom: "2rem",
    marginTop: "2rem",
  },
  imgClass: {
    width: "100%",
  },
}));

const InstitutionScreen = () => {
  const classes = useStyles();

  const { getInstitution } = useInstitutions();

  const [institution, setInstitution] = useState(null);
  const [schoolImage, setSchoolImage] = useState(null);

  const params = useParams();

  useEffect(() => {
    getInstitution(params["id"]).then((institution) => {
      setInstitution(institution);
      getInstitutionImageUrl(institution.image).then(setSchoolImage);
    });
  }, [getInstitution, params]);

  return (
    <React.Fragment>
      <WebNavBar />
      {institution ? (
        <Screen className={classes.root}>
          <Section.Container style={{ flex: 1 }}>
            <img
              src={schoolImage}
              className={classes.imgClass}
              alt={"school pic"}
            />
            <Container>
              <Typography className={classes.subHeader}>
                About Institution
              </Typography>
              <Typography className={classes.aboutParagraph}>
                {institution.description}
              </Typography>
              <div style={{ textAlign: "center" }}>
                <Button
                  className={classes.viewSchoolBtn}
                  style={{ width: "100%" }}
                  href={institution.website}
                >
                  GO TO INSTITUTION’S WEBSITE
                  <ArrowRightAltIcon fontSize={"large"} />
                </Button>
              </div>
              {/* <Typography className={classes.subHeader}>Location</Typography> */}
              {/* <Typography>{institution.location.city}, {institution.location.province}</Typography> */}
            </Container>
          </Section.Container>
        </Screen>
      ) : (
        <div style={{ minHeight: "100vh", backgroundColor: "#F1F1EE" }}>
          <Skeleton variant="rect" width={"100%"} height={"100vh"} />
        </div>
      )}
    </React.Fragment>
  );
};

export default withAuthorizationComponent((auth) => auth)(InstitutionScreen);
