import queryFetch from "./queryFetch";

const getIsMentee = async (variable) => {
  const data = await queryFetch(
    `
    query getUserType($queryid: String) {
        ismentee(id: $queryid)
    }
  `,
    { queryid: variable }
  );

  return data.ismentee;
};

const getAllUsersFromGraphQL = async () => {
  const data = await queryFetch(`
    query getAllUsers {
      allusers{
        likes{
            videos,
            articles
        }
        joinedCareers,
        subscribedMentors,
        id,
        runningTasks{
            id,
            step,
            amount,
            description,
            level,
            points,
            type
        },
        level,
        mentor,
        history{
            mentors,
            careers,
            articles,
            videos
        },
        saves{
            videos,
            articles
        },
        interests{
            hobbies,
            careers
        },
        completedTasks{
            id,
            step,
            amount,
            description,
            level,
            points,
            type
        },
        email,
        joined,
        points,
        username,
        name,
        age,
        bio,
        location{
            country,
            province,
            city
        },
        gender  
      }
    }
    `);
  return data.allusers;
};

const getUserByIdFromGraphQL = async (variable) => {
  const data = await queryFetch(
    `
      query getUserById($queryid: String) {
        user(id: $queryid){
          likes{
              videos,
              articles
          }
          joinedCareers,
          subscribedMentors,
          id,
          runningTasks{
              id,
              step,
              amount,
              description,
              level,
              points,
              progress,
              state{
                  history
              },
              type
          },
          level,
          mentor,
          history{
              mentors,
              careers,
              articles,
              videos
          },
          saves{
              videos,
              articles
          },
          interests{
              hobbies,
              careers
          },
          completedTasks{
              id,
              step,
              amount,
              description,
              level,
              points,
              type
          },
          email,
          joined,
          points,
          username,
          name,
          age,
          bio,
          location{
              country,
              province,
              city
          },
          gender  
        }
    }
  `,
    { queryid: variable }
  );
  return data.user;
};

const getUsersByListOfIdsFromGraphQL = async (variable) => {
  const data = await queryFetch(
    `
      query getUserById($queryids: [String]) {
          users(ids: $queryids){
            likes{
                videos,
                articles
            }
            joinedCareers,
            subscribedMentors,
            id,
            runningTasks{
                id,
                step,
                amount,
                description,
                level,
                points,
                type
            },
            level,
            mentor,
            history{
                mentors,
                careers,
                articles,
                videos
            },
            saves{
                videos,
                articles
            },
            interests{
                hobbies,
                careers
            },
            completedTasks{
                id,
                step,
                amount,
                description,
                level,
                points,
                type
            },
            email,
            joined,
            points,
            username,
            name,
            age,
            bio,
            location{
                country,
                province,
                city
            },
            gender  
          }
      }
  `,
    { queryids: variable }
  );
  return data.users;
};

export {
  getAllUsersFromGraphQL,
  getUserByIdFromGraphQL,
  getUsersByListOfIdsFromGraphQL,
  getIsMentee,
};
