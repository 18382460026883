import React, {useState, useEffect} from "react";
import "./VideosListScreen.scss";
import { useVideos } from "../../../Providers";
import { H1, H2, Screen, Section, VideoThumbnail } from "../../../components";
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import WebNav from "../../../components/Layout/Nav/NavBar/WebNav";
import { makeStyles } from "@material-ui/core/styles";
import WebVideoThumbnail from "../../../components/Video/WebVideoThumbnail/WebVideoThumbnail";
import withAuthorizationComponent from "../../../components/Auth/WithAuthorization/WithAuthorizationComponent";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--color-background-default)",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      // gridTemplateColumns: "repeat(1, 1fr)",
      paddingLeft: "290px",
    },
  },
}));

export function VideosListScreen() {
  const { getAllVideos } = useVideos();

  const { root } = useStyles();

  const [videos, setVideos] = useState([]);


  useEffect(() => {
    getAllVideos()
      .then((data) => {
        setVideos(data);
      });
  }, []);

  return (
    <>
      <WebNav />
      <Screen className={root}>
        <div style={{ flex: 1 }}>
          <Section.Container style={{ paddingBottom: 0, margin: 0 }}>
            <H2 bold={true} style={{ marginBottom: 0 }}>
              Videos
            </H2>
          </Section.Container>
          <Section.Container containerClassName="videosListScreenVideos">
            {!videos.length
              ? Array.from(Array(6).keys()).map(() => {
                  return (
                    <Skeleton
                      variant="rect"
                      width={"100%"}
                      height={"20rem"}
                      animation="wave"
                      style={{ borderRadius: 10 }}
                    />
                  );
                })
              : videos.map((video) => {
                  return <WebVideoThumbnail video={video}/>
                })}
          </Section.Container>
        </div>
      </Screen>
    </>
  );
}

export default withAuthorizationComponent((auth) => auth)(VideosListScreen);