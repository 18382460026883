import React, { useState } from "react";
import "./FormBoxInput.scss";

export default function FormBoxInput({
  value,
  label,
  onClick = () => {},
  initialSelected = false,
  ...rest
}) {
  const [selected, setSelected] = useState(initialSelected);

  const selectHandler = (e) => {
    e.preventDefault();
    setSelected(!selected);
    onClick(e);
  };

  return (
    <div className="formBoxInput">
      <label
        htmlFor={value}
        className={`formBoxInput__label hoverEffect borderRadius-medium ${
          selected ? `formBoxInput__label-selected` : ""
        }`}
      >
        {label}
      </label>
      <input
        {...rest}
        type="checkbox"
        value={value}
        id={value}
        className="formBoxInput__input"
        onClick={selectHandler}
      />
    </div>
  );
}
