import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Button,
} from "@material-ui/core";
import ButtonDefault from "../../../Buttons/ButtonDefault/ButtonDefault";
import { AuthUserContext, useAuth } from "../../../../Providers";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import {
  HomeIcon,
  BookmarkIcon,
  FavoriteIcon,
  SettingIcon,
  PlayIcon,
  PersonIcon,
  BriefcaseIcon,
  GraduationIcon,
} from "./NavBarIcons";

import LogoDefault from "../../../Logos/WebLogoDefault";
import { useHistory, useLocation } from "react-router-dom";
import WithAuthorizationElement from "../../../Auth/WithAuthorization/WithAuthorizationElement";
import { ROUTES } from "../../../../constants";
import { blackmintTheme } from "../../../../style/theme";
import SideNav from "./SideNav";

const useWebStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "10rem",
  },
  navBar: {
    padding: "0 var(--padding-screen-current-side)",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "1.7rem",
    height: "10rem",
    width: "100%",
    zIndex: 100,
    boxShadow: "0 1px 5px 0px rgba(0, 0, 0, 0.4)",
  },
  logoBox: {
    width: "20rem",
    height: "3rem",
    paddingBottom: "2rem",
  },
  landingScreenBtns: {
    margin: "auto",
    marginTop: "1rem",
    display: "grid",
    gridTemplateColumns: ".5fr .5fr",
    columnGap: "4rem",
    width: "30rem",
    fontSize: "3rem",
    maxWidth: "50rem",
    paddingBottom: "2rem",
  },
  dropTrigger: {
    width: "3.5rem",
    height: "3.5rem",
    cursor: "pointer",
    color: "white",
    display: "none",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  back: {
    padding: "1rem",
    width: "5rem",
    height: "5rem",
    color: "white",
  },
  list: {
    width: 293,
  },
  sidebarText: {
    ...blackmintTheme.typographyBlackmint.link,
  },
  sidebarHeader: {
    ...blackmintTheme.typographyBlackmint.h3,
    color: "#4DC591",
    margin: "0.5rem 2rem",
  },
  menuIcon: {
    fontSize: "4rem",
    color: "black",
    "&:hover": {
      color: "#4DC591",
    },
  },
  username: {
    ...blackmintTheme.typographyBlackmint.figmaH2,
  },
  listIcon: {
    minWidth: "44px",
    placeContent: "center",
  },
  authButtons: {},
  buttons: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "6px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

export default function WebNavBar() {
  const classes = useWebStyles();
  const [showDropdown, setShowDropdown] = useState(false);

  const history = useHistory();

  const location = useLocation();

  const showGoBack = !(
    location.pathname === ROUTES.home ||
    location.pathname === ROUTES.mentorshipDashboard ||
    location.pathname.includes(ROUTES.signUp) ||
    location.pathname === ROUTES.signIn ||
    location.pathname === ROUTES.landing
  );

  const { authUserDetails, signOut } = useAuth();

  const navigateToPage = (route) => {
    history.push(route);
    setShowDropdown(false);
  };
  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar className={classes.navBar}>
            {false ? (
              <IconButton
                onClick={() => {
                  history.goBack();
                }}
                className={classes.back}
              >
                <ArrowBackIosRoundedIcon
                  fontSize={"large"}
                  style={{ fontSize: "3.5rem" }}
                />
              </IconButton>
            ) : null}
            <div className={classes.logoBox}>
              <LogoDefault style={{ width: "250px" }}></LogoDefault>
            </div>
            <div className={classes.buttons}>
              {!authUserDetails ? (
                <>
                  <ButtonDefault
                    style={{ width: "15rem", backgroundColor: "white" }}
                    onClick={() => {
                      history.push("/signin");
                    }}
                  >
                    Log In
                  </ButtonDefault>
                  <ButtonDefault
                    style={{ width: "15rem" }}
                    onClick={() => {
                      history.push("/signup");
                    }}
                  >
                    Sign Up
                  </ButtonDefault>
                </>
              ) : (
                <IconButton
                  onClick={() => {
                    setShowDropdown(!showDropdown);
                  }}
                  className={classes.dropTrigger}
                >
                  <MenuRoundedIcon
                    fontSize={"large"}
                    classes={{ root: classes.menuIcon }}
                  />
                </IconButton>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </div>
      {authUserDetails && <SideNav isOpen={showDropdown} />}
    </>
  );
}
