import { makeStyles } from "@material-ui/core";
import React from "react";
import "./Track.scss";

const useStyles = makeStyles((theme) => ({
  track: {
    height: '1rem',
    width: '100%',
    padding: '0.3rem',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '1000rem',
  },
  trackProgress : {
    transition: 'width 0.5s',
    borderRadius: '1000rem',
    height: '100%',
    backgroundColor: theme.palette.primary.light,
  }
}));

export default function Track({ progress }) {

  const classes = useStyles()
  return (
    <div className={classes.track}>
      <div className={classes.trackProgress} style={{ width: `${progress}%` }}></div>
    </div>
  );
}
