import React, { useState } from "react";
import { createContext, useContext, useEffect } from "react";
import { getProfileImageUrl } from "../../helpers";
import { useCareers } from "../CareersProvider/CareersProvider";

const BlogsContext = createContext();

export const useBlogs = () => useContext(BlogsContext);

function BlogsProvider({ children }) {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      await fetchAllBlogs();
    })();
  }, []);

  const getAllBlogs = async () => {
    return blogs;
  };

    const fetchAllBlogs = async () => {
        const res = await fetch("https://blog.risingstar.blackmint.io/blogs");
        const blogRes = await res.json();
        setBlogs(blogRes);
    };

    const getBlogsByUser = async (userId, page) => {
        try {
            const res = await fetch(
                `https://blog.risingstar.blackmint.io/blogs/user/${userId}`
            );
            const resBlog = await res.json();
            return resBlog;
        } catch (err) {
            return 404;
        }
    };

    const getUnpublishedByUser = async (userId, page) => {
        try {
            const res = await fetch(
                `https://blog.risingstar.blackmint.io/blogs/unpublished/user/${userId}`
            );
            const resBlog = await res.json();
            return resBlog;
        } catch (err) {
            return 404;
        }
    };

    const getBlogPost = async (id) => {
        try {
            const res = await fetch(
                `https://blog.risingstar.blackmint.io/blogs/${id}`
            );
            const resBlog = await res.json();
            return resBlog;
        } catch (err) {
            return null;
        }
    };

    const getDraftPost = async (id) => {
        try {
            const res = await fetch(
                `https://blog.risingstar.blackmint.io/blogs/${id}/unpublished`
            );
            const resBlog = await res.json();
            return resBlog;
        } catch (err) {
            return 404;
        }
    };

    const deleteBlog = async (id) => {
        try {
            await fetch(`https://blog.risingstar.blackmint.io/blogs/${id}`, {
                method: "DELETE",
            });
        } catch (err) {
            return "Something went wrong";
        }
    };

  return (
    <BlogsContext.Provider
      value={{
        getAllBlogs,
        getBlogPost,
        getBlogsByUser,
        getUnpublishedByUser,
        getDraftPost,
        deleteBlog,
        fetchAllBlogs,
        blogs,
      }}
    >
      {children}
    </BlogsContext.Provider>
  );
}

export default BlogsProvider;
