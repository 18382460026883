import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./HomeScreen.scss";
import { useHistory } from "react-router-dom";
import withAuthorizationComponent from "../../components/Auth/WithAuthorization/WithAuthorizationComponent";
import WebNav from "../../components/Layout/Nav/NavBar/WebNav";
import { Screen, H2, Section } from "../../components";
import { useMediaQuery } from "../../hooks";
import { useAuth, useVideos } from "../../Providers";
import getProfileCompleteness from "../../utilities/getProfileCompleteness";
import ResponsiveDrawer from "../../components/Layout/Nav/NavBar/SideNav";
import WebTaskTracker from "../../components/Tasks/WebTaskTracker/WebTaskTracker";
import Intro from "./Intro";
import Videos from "./Videos";
import Careers from "./Careers";
import TechPrograms from "./TechPrograms";
import Mentors from "./Mentors";
import WebCareers from "../../components/Career/WebCareers";
import BlogFeatured from "../../components/Blogs/BlogFeatured/BlogFeatured";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--color-background-default)",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      // gridTemplateColumns: "repeat(1, 1fr)",
      paddingLeft: "290px",
    },
  },
  picksHeader: {
    borderBottom: "1px solid #C4C4C4",
    paddingBottom: "5rem",
  },
  tasksContainer: {
    paddingLeft: "0",
    paddingRight: "5rem",
    flex: 0.5,
    display: "block",
    [theme.breakpoints.down("xs")]: {
      // gridTemplateColumns: "repeat(1, 1fr)",
      display: "none",
    },
  },
  tasksContainerMobile: {
    // paddingLeft: "0",
    padding: "0 5rem",
    // flex: 0.5,
    display: "none",
    [theme.breakpoints.down("xs")]: {
      // gridTemplateColumns: "repeat(1, 1fr)",
      display: "block",
    },
  },
  tasksGrid: {
    display: "grid",
    gap: "3rem",
  },
}));

function HomeScreen() {
  const classes = useStyles();
  const history = useHistory();

  const { authUserDetails, authUser } = useAuth();

  const { getAllVideos, videos } = useVideos();

  const media = useMediaQuery();

  const [data, setUserData] = useState({ articles: [], videos: [] });

  // useEffect(() => {
  //   setUserData((data) => ({ ...data, videos: getAllVideos() }));
  // }, []);

  const mentorRequiredFields = [
    "job",
    "name",
    "bio",
    "careers",
    "location.country",
    "gender",
    "company",
    "links.linkedIn",
  ];

  const profileCompleteStatus = getProfileCompleteness(
    authUserDetails,
    mentorRequiredFields
  );

  return (
    <>
      <WebNav />
      {/* <ResponsiveDrawer /> */}
      {
        <Screen className={classes.root}>
          <div style={{ flex: 1 }}>
            <Intro />
            <Section.Container className={classes.tasksContainerMobile}>
              <div className={classes.tasksGrid}>
                {(authUserDetails?.runningTasks || []).map((task) => (
                  <WebTaskTracker task={task} />
                ))}
              </div>
            </Section.Container>
            <Section.Container style={{ padding: "0 5rem", marginBottom: 0 }}>
              <H2 className={classes.picksHeader}>Top picks for you.</H2>
            </Section.Container>
            <Videos />
            <WebCareers />
            <TechPrograms />
            <BlogFeatured />
            <Mentors />
          </div>
          <Section.Container className={classes.tasksContainer}>
            <div className={classes.tasksGrid}>
              {(authUserDetails?.runningTasks || []).map((task) => (
                <WebTaskTracker task={task} />
              ))}
            </div>
          </Section.Container>
        </Screen>
      }{" "}
    </>
  );
}

export default withAuthorizationComponent((auth) => auth, "/")(HomeScreen);

// import React, { useState, useEffect } from "react";
// import { Typography, Paper, Grid } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import "./HomeScreen.scss";
// import { Link, useHistory } from "react-router-dom";
// import withAuthorizationComponent from "../../components/Auth/WithAuthorization/WithAuthorizationComponent";
// import {
//   TaskTracker,
//   ButtonDefault,
//   MentorThumbnail,
//   VideoThumbnail,
//   HelpTag,
//   Screen,
//   H2,
//   Section,
//   Track,
//   H3,
// } from "../../components";
// import StarSvg from "../../components/Tasks/StarSvg/StarSvg";
// import { useMediaQuery } from "../../hooks";
// import {
//   useAuth,
//   useCareers,
//   useUsers,
//   useDegrees,
//   useVideos,
// } from "../../Providers";
// import clsx from "clsx";
// import { blackmintTheme } from "../../style/theme";
// import DegreeCard from "../../components/Degree/DegreeCard";
// import getProfileCompleteness from "../../utilities/getProfileCompleteness";
// import Skeleton from "@material-ui/lab/Skeleton";
// import ResponsiveDrawer from "../../components/Layout/Nav/NavBar/SideNav";
// import WebMentorThumbnail from "../../components/Mentor/WebMentorThumbnail/WebMentorThumbnail";
// import WebVideoThumbnail from "../../components/Video/WebVideoThumbnail/WebVideoThumbnail";

// const useStyles = makeStyles((theme) => ({
//   // secondaryHeading: {
//   //   ...blackmintTheme.typographyBlackmint.h2,
//   //   fontWeight: "normal",
//   //   [theme.breakpoints.down("sm")]: {
//   //     fontSize: "3.5rem",
//   //   },
//   // },
//   userInfo: {
//     position: "relative",
//     paddingBottom: 0,
//     paddingTop: "5rem",
//   },
//   boldFont: {
//     fontWeight: "bold",
//   },
//   paragraph: {
//     ...blackmintTheme.typographyBlackmint.paragraph,
//   },
//   homeScreenGettingStarted: {
//     marginBottom: "2rem",
//   },
//   homeScreenGettingStartedMain: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     marginBottom: "1rem",
//   },
//   homeScreenGettingStartedPoints: {
//     fontSize: "2rem",
//     display: "flex",
//   },
//   homeScreenGettingStartedSub: {},
//   sectionTitle: {
//     fontWeight: 700,
//     fontSize: "var(--default-font-size)",
//     lineHeight: "150%",
//   },
//   exploreSection: {
//     backgroundColor: theme.palette.primary.light,
//   },
// }));

// function HomeScreen() {
//   const classes = useStyles();
//   const history = useHistory();

//   const { authUserDetails, authUser } = useAuth();

//   const { getAllUsers } = useUsers();

//   const { getAllCareers } = useCareers();

//   const { getAllVideos, videos } = useVideos();

//   const { getAllDegrees } = useDegrees();

//   const media = useMediaQuery();

//   const [tasksIsOpen, setTaskIsOpen] = useState(false);

//   const [data, setUserData] = useState({ articles: [], videos: [] });
//   /*
//   useEffect(() => {
//     (async () => {
//       const userid = authUser.id;
//       setUserData(result.data);
//     })();
//   }, [authUser]); */

//   useEffect(() => {
//     //if (authUserDetails && authUserDetails.mentor)
//     setUserData((data) => ({ ...data, videos: getAllVideos() }));
//   }, [videos]);

//   const mentorRequiredFields = [
//     "job",
//     "name",
//     "bio",
//     "careers",
//     "location.country",
//     "gender",
//     "company",
//     "links.linkedIn",
//   ];

//   const profileCompleteStatus = getProfileCompleteness(
//     authUserDetails,
//     mentorRequiredFields
//   );

//   return (
//     <Screen style={{ display: "grid", gridTemplateColumns: '1fr 2fr 3fr' }}>
//       <ResponsiveDrawer></ResponsiveDrawer>

//       {authUserDetails ? (
//         <div style={{width: '100%'}}>
//           {!authUserDetails.mentor ? (
//             <Section.Container className={clsx("card", classes.userInfo)}>
//               {
//                 // authUserDetails.points > 0 ?
//                 <>
//                   <div className="homeScreenUserDetails ">
//                     <p className="homeScreenUserDetails__title">
//                       <span>
//                         WELCOME,{" "}
//                         <Link to="/settings">
//                           <span className="color-primary">
//                             {authUserDetails.username}!
//                           </span>
//                         </Link>
//                       </span>
//                       <span className="homeScreenUserDetails__points font-bold">
//                         {authUserDetails.points}
//                         <span className="star star-small">
//                           <StarSvg type="bronze"></StarSvg>
//                         </span>
//                       </span>
//                     </p>
//                     <div className="homeScreenProgress section">
//                       <div className="homeScreenProgress__track">
//                         <div className="homeScreenProgress__checkPoint">
//                           <div
//                             className="homeScreenProgress__star star star-small"
//                             style={{ left: 0 }}
//                           >
//                             <StarSvg type="bronze"></StarSvg>
//                           </div>
//                           <div
//                             className="homeScreenProgress__points"
//                             style={{ left: 0 }}
//                           >
//                             0
//                           </div>
//                         </div>
//                         <div className="homeScreenProgress__checkPoint">
//                           <div
//                             className="homeScreenProgress__star star star-small"
//                             style={{ left: "50%" }}
//                           >
//                             <StarSvg type="silver"></StarSvg>
//                           </div>
//                           <div
//                             className="homeScreenProgress__points"
//                             style={{ left: "50%" }}
//                           >
//                             100
//                           </div>
//                         </div>
//                         <div className="homeScreenProgress__checkPoint">
//                           <div
//                             className="homeScreenProgress__star star star-small"
//                             style={{ left: "100%" }}
//                           >
//                             <StarSvg type="gold"></StarSvg>
//                           </div>
//                           <div
//                             className="homeScreenProgress__points"
//                             style={{ left: "100%" }}
//                           >
//                             200
//                           </div>
//                         </div>
//                         <div className="homeScreenProgress__checkPoint font-bold">
//                           <div
//                             className="homeScreenProgress__mark"
//                             style={{
//                               left: `${(authUserDetails.points / 200) * 100}%`,
//                             }}
//                           >
//                             <svg
//                               width="100%"
//                               height="100%"
//                               viewBox="0 0 14 20"
//                               fill="none"
//                               xmlns="http://www.w3.org/2000/svg"
//                             >
//                               <path
//                                 d="M7 0.265015C3.13 0.265015 0 3.39501 0 7.26501C0 11.435 4.42 17.185 6.24 19.375C6.64 19.855 7.37 19.855 7.77 19.375C9.58 17.185 14 11.435 14 7.26501C14 3.39501 10.87 0.265015 7 0.265015Z"
//                                 fill="#4DC591"
//                               />
//                             </svg>
//                           </div>
//                           <div
//                             className="homeScreenProgress__points"
//                             style={{
//                               left: `${(authUserDetails.points / 200) * 100}%`,
//                             }}
//                           >
//                             {authUserDetails.points}
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <p
//                       className="homeScreenTasks__trigger color-primary"
//                       onClick={() => {
//                         setTaskIsOpen(!tasksIsOpen);
//                       }}
//                     >
//                       {authUserDetails.runningTasks.length
//                         ? tasksIsOpen
//                           ? "Close tasks "
//                           : "See how you can earn more points "
//                         : "More tasks will be added soon"}
//                       <svg
//                         style={{
//                           marginLeft: ".5rem",
//                           transform: tasksIsOpen ? "rotate(180deg)" : "",
//                         }}
//                         width="10"
//                         height="6"
//                         viewBox="0 0 10 6"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           d="M8.22917 0.499964L4.99583 3.7333L1.7625 0.499964C1.60681 0.34392 1.39543 0.256226 1.175 0.256226C0.954569 0.256226 0.743193 0.34392 0.5875 0.499964C0.2625 0.824964 0.2625 1.34996 0.5875 1.67496L4.4125 5.49996C4.7375 5.82496 5.2625 5.82496 5.5875 5.49996L9.4125 1.67496C9.7375 1.34996 9.7375 0.824964 9.4125 0.499964C9.0875 0.183297 8.55417 0.174964 8.22917 0.499964Z"
//                           fill="var(--color-black)"
//                         />
//                       </svg>
//                     </p>
//                   </div>
//                   <section
//                     className={`homeScreenTasks fullWidth fullWidth-padded ${
//                       tasksIsOpen ? "homeScreenTasks-open" : ""
//                     }`}
//                   >
//                     <div className="homeScreenTasks__tasks">
//                       {authUserDetails.runningTasks.map((task) => (
//                         <TaskTracker task={task} />
//                       ))}
//                     </div>
//                   </section>
//                 </>
//                 //   : (
//                 //     <>
//                 //       <div className={classes.homeScreenGettingStarted}>
//                 //         <div className={classes.homeScreenGettingStartedMain}>
//                 //           <H2>Getting Started</H2>
//                 //           <Typography
//                 //             component="p"
//                 //             className={clsx(
//                 //               classes.homeScreenGettingStartedPoints,
//                 //               classes.boldFont
//                 //             )}
//                 //           >
//                 //             {authUserDetails.points}{" "}
//                 //             <span className="star star-small">
//                 //               <StarSvg type="bronze"></StarSvg>
//                 //             </span>
//                 //           </Typography>
//                 //         </div>
//                 //         <p className={classes.paragraph}>
//                 //           Here are some tasks to help you get started:
//                 //         </p>
//                 //       </div>
//                 //       <section className="homeScreenTasks fullWidth fullWidth-padded homeScreenTasks-open">
//                 //         <div className="homeScreenTasks__tasks">
//                 //           {authUserDetails.runningTasks.map((task) => (
//                 //             <TaskTracker task={task} />
//                 //           ))}
//                 //         </div>
//                 //       </section>
//                 //     </>

//                 // )
//               }
//             </Section.Container>
//           ) : (
//             <Section.Container className="card">
//               <H3 bold={false}>
//                 WELCOME,{" "}
//                 <Link to="/settings">
//                   <span className="color-primary">{authUserDetails.name}!</span>
//                 </Link>
//               </H3>
//               {!authUserDetails.allowViewProfile && (
//                 <>
//                   <p className="paragraph u-margin-bottom-medium">
//                     {
//                       profileCompleteStatus.length < mentorRequiredFields.length
//                         ? "We would like for you to complete your profile before you go to the Mentorship Portal."
//                         : "Go to your account settings and allow students to view your profile" /*"You’re free to browse the resources or you can head to the Mentorship Portal. "*/
//                     }
//                   </p>
//                   <div className="text-center">
//                     <H3 align="center">
//                       {(profileCompleteStatus.length * 100) /
//                         mentorRequiredFields.length}
//                       %
//                     </H3>
//                     <div className="u-margin-bottom-medium">
//                       <Track
//                         progress={
//                           (profileCompleteStatus.length * 100) /
//                           mentorRequiredFields.length
//                         }
//                       />
//                     </div>
//                     <ButtonDefault
//                       onClick={() =>
//                         history.push(
//                           profileCompleteStatus.length <
//                             mentorRequiredFields.length
//                             ? "/settings/profile"
//                             : "/settings/account"
//                         )
//                       }
//                     >
//                       {profileCompleteStatus.length <
//                       mentorRequiredFields.length
//                         ? "Complete profile"
//                         : "Go to settings"}
//                     </ButtonDefault>
//                   </div>
//                 </>
//               )}
//             </Section.Container>
//           )}
//           <Section.Container
//             style={{ paddingBottom: 0, paddingTop: 0, marginBottom: 0 }}
//           >
//             <H2>Top picks for you.</H2>
//           </Section.Container>
//           <Section.Container
//             title={
//               <>
//                 Most popular videos.
//                 <HelpTag message="Videos are created by BlackMINT’s mentors. Sit back and go on a learning binge watch. You can also ask the mentors’ questions in the Q&amp;A section." />
//               </>
//             }
//             viewMore
//             onViewMore={() => {
//               history.push(`/videos`);
//             }}
//           >
//             <div className="homeScreenVideos__videos section__content">
//               {!data.videos.length
//                 ? Array.from(Array(4).keys()).map(() => {
//                     return (
//                       <Skeleton
//                         variant="rect"
//                         width={"100%"}
//                         height={"20rem"}
//                         animation="wave"
//                         style={{ borderRadius: 10 }}
//                       />
//                     );
//                   })
//                 : data.videos.slice(0, 4).map((video, index) => {
//                     return (
//                       <WebVideoThumbnail
//                         video={video}
//                         key={index}
//                       ></WebVideoThumbnail>
//                     );
//                   })}
//             </div>
//           </Section.Container>
//           <Section.Container
//             title={
//               <>
//                 Explore Careers{" "}
//                 <HelpTag message="Curious about life after high school? Get a head start and learn about the many tech careers that you can potentially do!" />
//               </>
//             }
//             // style={{backgroundColor: 'green'}}
//             className={classes.exploreSection}
//             viewMore
//             onViewMore={() => {
//               history.push(`/careers`);
//             }}
//           >
//             <div className="homeScreenExploreCareers__careers section__content">
//               {getAllCareers()
//                 .slice(0, 6)
//                 .map((career, index) => {
//                   return (
//                     <ButtonDefault
//                       key={index}
//                       onClick={() => {
//                         history.push(`/careers/${career.id}`);
//                       }}
//                     >
//                       {career.name}
//                     </ButtonDefault>
//                   );
//                 })}
//             </div>
//           </Section.Container>
//           <Section.Container
//             title={
//               <>
//                 Explore Tech Programs{" "}
//                 <HelpTag message="Choosing the right program is a huge decision! Have a look at our tech degree guide to learn more about the different schools and requirements." />
//               </>
//             }
//           >
//             <Grid
//               container
//               spacing={2}
//               style={{
//                 marginBottom: "2rem",
//                 display: "flex",
//                 alignItems: "center",
//               }}
//             >
//               {getAllDegrees().map((degree) => {
//                 return (
//                   <DegreeCard
//                     degreeName={degree.name}
//                     linkTo={"/degrees/" + degree.id}
//                     imgLink={degree.image}
//                   />
//                 );
//               })}
//             </Grid>
//             <div className="section__content">
//               {/* <Section.ViewMore
//                 onViewMore={() => {
//                   history.push(`/degrees`);
//                 }}
//                 linkTo={"/degrees"}
//               >
//                 See more
//               </Section.ViewMore> */}
//               <Section.ViewMore
//                 onViewMore={() => {
//                   history.push(`/programs`);
//                 }}
//               >
//                 See more
//               </Section.ViewMore>
//             </div>
//           </Section.Container>
//           <Section.Container>
//             <Paper
//               elevation={5}
//               style={{
//                 // marginLeft: "-1rem",
//                 // marginRight: "-1rem",
//                 marginBottom: "5rem",
//                 padding: "2rem",
//                 borderRadius: 0,
//               }}
//             >
//               <div
//                 style={{
//                   marginBottom: "2rem",
//                   display: "flex",
//                   alignItems: "center",
//                 }}
//               >
//                 <Section.Title>
//                   Featured Mentors
//                   <HelpTag message="Mentors needed to start from somewhere! Read about their tech career journey and soak in their wisdom. Hit the subscribe button if you like what you read!." />
//                 </Section.Title>
//               </div>
//               <div className="homeScreenMentors__mentors section__content">
//                 {getAllUsers()
//                   .slice(0, media <= 3 ? 3 : 4)
//                   .map((mentor, index) => {
//                     return (
//                       <WebMentorThumbnail
//                         mentor={mentor}
//                         key={index}
//                       ></WebMentorThumbnail>
//                     );
//                   })}
//               </div>
//               <Section.ViewMore
//                 onViewMore={() => {
//                   history.push(`/mentors`);
//                 }}
//               >
//                 See more
//               </Section.ViewMore>
//             </Paper>
//           </Section.Container>
//         </div>
//       ) : null}

//       <div style={{width: '100%'}}>
//       <section
//           // className={`homeScreenTasks fullWidth fullWidth-padded ${
//           //   tasksIsOpen ? "homeScreenTasks-open" : ""
//           // }`}
//         >
//           <div style={{}}>
//             {authUserDetails.runningTasks.map((task) => (
//               <TaskTracker task={task} />
//             ))}
//           </div>
//         </section>
//       </div>
//     </Screen>
//   );
// }

// export default withAuthorizationComponent((auth) => auth, "/")(HomeScreen);
