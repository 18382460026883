import React, { useRef, useEffect, useState } from "react";
import "./FormTextArea.scss";

export default function FormTextArea({
  placeholder,
  type,
  value,
  inputRef,
  invalid,
  onChange = () => {},
  name,
  ...rest
}) {
  // const [ value, setValue ] = useState(defaultValue)
  const [inputValue, setInputValue] = useState(value);

  const ref = useRef();

  if (!inputRef) inputRef = ref;

  let invalidIsFunction = false;

  if (typeof invalid === "function") invalidIsFunction = true;

  const resize = (event) => {
    inputRef.current.style.height = "";
    inputRef.current.style.height = inputRef.current.scrollHeight + "px";
  };

  const changeHandler = (event) => {
    resize();
    setInputValue(event.target.value);
    onChange(event);
  };

  useEffect(() => {
    resize();
  });

  useEffect(() => {
    if (invalidIsFunction ? invalid(inputValue) : invalid)
      inputRef.current.setCustomValidity(invalid.error);
    else inputRef.current.setCustomValidity("");
  }, [invalid, inputValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div class="formTextArea">
      <textarea
        ref={inputRef}
        type={type}
        class="formTextArea__textArea focusEffect borderRadius-medium"
        placeholder={placeholder}
        required
        {...rest}
        onChange={changeHandler}
        name={name}
        value={inputValue}
      />
      <label for={name} class="formTextArea__label">
        {placeholder}
      </label>
    </div>
  );
}
