import React, { useState } from "react";
import "./ContentCreator.scss";
import Youtube from "../../../apis/youtube";
import { FormInput, ParagraphCreator } from "../..";
import { FormTextArea } from "../..";
import { paragraphTypes } from "./ParagraphCreator/ParagraphCreator";
import { Firebase } from "../../../apis";

export default function ContentCreator({ type, author, onSubmit = () => {} }) {
  const [paragraphCreators, setParagraphCreators] = useState([
    ParagraphCreator,
  ]);
  const [error, setError] = useState(null);

  const addParagraphHandler = (e) => {
    e.preventDefault();
    setParagraphCreators([...paragraphCreators, ParagraphCreator]);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    switch (type) {
      case ContentTypes.video:
        addVideoHandler(e);
        break;
      case ContentTypes.article:
        addArticleHandler(e);
        break;
      case ContentTypes.career:
        addCareerHandler(e);
        break;
      default:
        break;
    }
  };

  const addVideoHandler = (e) => {
    const youtubeId = e.target.elements["videoId"].value;
    const title = e.target.elements["name"].value;
    const paragraphs = generateParagraphs(e.target.elements["paragraph"]);
    const newVideo = {
      youtubeId,
      title,
      description: paragraphs,
      date: Date.now(),
      author: author.id,
    };
    Youtube.getSnippet(youtubeId)
      .then((snippet) => {
        if (snippet) {
          const videoDoc = Firebase.store.collection("videos").doc();
          videoDoc.set({ ...newVideo, id: videoDoc.id });
        } else setError({ message: "video doesnt exist" });
      })
      .catch((err) => {
        setError(err);
      });
  };

  const addArticleHandler = (e) => {
    const subTitle = e.target.elements["subTitle"].value;
    const title = e.target.elements["title"].value;
    const mainImage = e.target.elements["mainImage"].value;
    const paragraphs = generateParagraphs(e.target.elements["paragraph"]);
    const newArticle = {
      title,
      subTitle,
      mainImage,
      content: paragraphs,
      date: Date.now(),
      author: author.id,
    };
    const articleDoc = Firebase.store.collection("articles").doc();
    articleDoc
      .set({ ...newArticle, id: articleDoc.id })
      .then(() => {
        setParagraphCreators([ParagraphCreator]);
        setError(null);
        onSubmit(newArticle);
      })
      .catch(setError);
  };

  const addCareerHandler = (e) => {};

  const generateParagraphs = (nodeList) => {
    return Array.from(nodeList).map((element) => {
      return { type: element.dataset["type"], value: element["value"] };
    });
  };

  return (
    <form className="contentCreator" onSubmit={submitHandler}>
      <h2 className="heading-tertiary contentCreator__heading">
        Click on the + button to add a content segment.
      </h2>
      {type === ContentTypes.video ? (
        <div className="contentCreator__intialInfo">
          <FormInput name="name" placeholder="Name"></FormInput>
          <FormInput name="videoId" placeholder="Video Id"></FormInput>
        </div>
      ) : null}
      {type === ContentTypes.article ? (
        <div className="contentCreator__intialInfo">
          <FormInput name="title" placeholder="Title"></FormInput>
          <FormInput name="subTitle" placeholder="Sub Title"></FormInput>
          <FormInput name="mainImage" placeholder="Main Image"></FormInput>
        </div>
      ) : null}
      {type === ContentTypes.career ? (
        <div className="contentCreator__intialInfo">
          <FormInput name="name" placeholder="Name"></FormInput>
          <FormInput name="videoId" placeholder="Video Id"></FormInput>
        </div>
      ) : null}
      <div className="contentCreator__paragraphs">
        {type === ContentTypes.article ? (
          <FormTextArea
            required
            name="paragraph"
            data-type={paragraphTypes.text}
          />
        ) : null}
        {paragraphCreators.map((ParagraphCreator, index) => (
          <ParagraphCreator
            key={Date.now()}
            index={index}
            contentType={type}
          ></ParagraphCreator>
        ))}
      </div>
      <button onClick={addParagraphHandler}>addd</button>
      <button type="submit">submit</button>
    </form>
  );
}

export const ContentTypes = {
  video: "video",
  article: "article",
  career: "career",
};
