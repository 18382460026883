import React, { useState } from "react";
import "./SignUpInterests.scss";
import { ButtonDefault } from "../../../../components";
import { useHistory } from "react-router";
import { removeValueFromArray } from "../../../../helpers";
import { hobbies } from "../../../../testData/interestsOptions";
import { useStatus, useAuth } from "../../../../Providers";
import { FormBoxInput } from "../../../../components";
import { statusTypes } from "../../../../constants";
import { Firebase } from "../../../../apis";
import { upsertUserFromGraphQL } from "../../../../azureGraphQL/userMutation";


export default function SignUpInterests() {
  const { authUser } = useAuth();

  const history = useHistory();

  const { changeStatus } = useStatus();

  const [selectedInterests, setSelectedInterests] = useState([]);

  const minSelected = 2,
    maxSelected = 8;

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      selectedInterests.length >= minSelected &&
      selectedInterests.length <= maxSelected
    ) {
      upsertUserFromGraphQL({
        id: authUser.uid,
        rowKey: authUser.uid,
        partitionKey: "users",
        interests:{
          "hobbies": selectedInterests
        }
      });
      Firebase.user(authUser.uid)
        .update({ "interests.hobbies": selectedInterests })
        .then(() => {
          history.push("/signup/careers");
        })
        .catch((err) => changeStatus(err));
    } else {
      if (selectedInterests.length <= minSelected)
        changeStatus(
          {
            message:
              "You have selected too few options. Please select between 1 and 8 options",
          },
          statusTypes.error
        );
      else
        changeStatus(
          {
            message:
              "You have selected too many options. Please select between 1 and 8 options.",
          },
          statusTypes.error
        );
    }
  };

  const hobbiesClickHandler = (e) => {
    const newSelectedInterests = [...selectedInterests];
    const element = e.target;
    if (!newSelectedInterests.includes(element.value)) {
      newSelectedInterests.push(e.target.value);
      setSelectedInterests(newSelectedInterests);
    } else {
      setSelectedInterests(
        removeValueFromArray(
          newSelectedInterests,
          (item) => element.value === item
        )
      );
    }
  };

  return (
    <>
      <form className="signUpInterests__form" onSubmit={submitHandler}>
        <h1 className="heading-tertiary signUpInterests__title">
          Which of the subjects interest you?
        </h1>
        <div className="signUpInterests__grid">
          {hobbies.map((interest, index) => (
            <div className="signUpInterests__input">
              <FormBoxInput
                label={interest}
                value={interest}
                key={index}
                onClick={hobbiesClickHandler}
              ></FormBoxInput>
            </div>
          ))}
        </div>
        <p className="signUpInterests__counter">{`${selectedInterests.length}/${maxSelected}`}</p>
        <div className="signUpInterests__submit">
          <ButtonDefault>Proceed &rarr;</ButtonDefault>
        </div>
      </form>
    </>
  );
}
