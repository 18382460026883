import React from "react";
import { Section } from "../../../../components";

export default function CareerSection({
  children,
  title,
  titleImage,
  viewMore,
  onViewMore,
}) {
  return (
    <Section.Container style={{ paddingBottom: 0, paddingTop: 0 }}>
      <Section.Container
        className={
          "careerScreen__section card borderRadius-medium" +
          (title.search("Mentors") !== -1
            ? " careerMentors__mentors-section"
            : "")
        }
        viewMore={viewMore && "READ MORE"}
        onViewMore={onViewMore}
        style={{ padding: "5rem" }}
      >
        <h2 className="careerScreen__section__title">
          <span className="careerScreen__section__title__image">
            {titleImage}
          </span>
          {title}
        </h2>
        {children}
      </Section.Container>
    </Section.Container>
  );
}
