import React from "react";
import "./WebTaskTracker.scss";
import StarSvg from "../StarSvg/StarSvg";
import {TrackBump} from "../..";
import { taskTypes } from "../../../constants";

export default function WebTaskTracker({ task }) {
  let taskNameElementContent;
  switch (task.type) {
    case taskTypes.watchNumberVideos:
      taskNameElementContent = `Videos`;
      break;
    case taskTypes.readNumberCareers:
      taskNameElementContent = `Careers`;
      break;
    case taskTypes.readNumberMentors:
      taskNameElementContent = `Mentors`;
      break;
    case taskTypes.readNumberDegrees:
        taskNameElementContent = `Programs`;
        break;
    case taskTypes.giveMoreInfo:
      taskNameElementContent = `Profile Settings`;
      break;
    case "watchNumber":
      taskNameElementContent = `Watch ${task.amount} ${"Videos"}`;
      break;
    case "readCareer":
      taskNameElementContent = `Visit ${task.amount} ${"Career Profiles"}`;
      break;
    case "readMentor":
      taskNameElementContent = `Visit ${task.amount} ${"Mentor Bios"}`;
      break;
    case "readDegrees":
        taskNameElementContent = `Visit ${task.amount} ${"Programs"}`;
        break;
    default:
      taskNameElementContent = "";
      break;
  }

  return (
    <div className="webTaskTracker">
      <div className="webTaskTracker__task">
        <div className="webTaskTracker__info">
          <p className="webTaskTracker__points">{task.points}</p>
          <p className="star star-medium">  
            <StarSvg type="gold"></StarSvg>
          </p>
          <p>points</p>
          <p className="webTaskTracker__type font-bold">
            {task.title || taskNameElementContent}
          </p>
        </div>
        <p className="webTaskTracker__description">{task.description}</p>
      </div>
      <div className="webTaskTracker__track">
        <TrackBump progress={task.progress} amount={task.amount}></TrackBump>
      </div>
      <div className="webTaskTracker__progress">
        Progress: {task.progress || "0"}/{task.amount}
      </div>
    </div>
  );
}
