import React, { useEffect, useState } from "react";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useAuth } from "../../../Providers";
import { v4 as uuidv4 } from "uuid";
import { IconButton } from "@material-ui/core";
import BlogReply from "../BlogReply/BlogReply";
import { getIsMentee } from "../../../azureGraphQL/userQuery";

const BlogComment = ({
  comment,
  updateComment,
  deleteCommentHandler,
  deleteReplyHandler,
  replyIdToDelete,
}) => {
  const [isCommentorMentee, setIsCommentorMentee] = useState(true);
  const [showReplies, setShowReplies] = useState(false);
  const [reply, setReply] = useState("");
  const { authUserDetails } = useAuth();
  const [editMode, setEditMode] = useState(false);
  const [hover, setHover] = useState(false);
  const [newComment, setNewComment] = useState(comment.text);

  const date = new Date(comment.date);
  const replyOrReplies = () =>
    comment.replies.length > 1 ? "REPLIES" : "REPLY";

  const submitReply = async () => {
    const replyData = {
      text: reply,
      user: authUserDetails,
      id: uuidv4(),
      edited: false,
      date: new Date(),
    };

    comment.replies.push(replyData);
    await updateComment();
    setReply("");
  };

  const deleteReply = async (id) => {
    comment.replies = comment.replies.filter((reply) => reply.id !== id);
    await updateComment();
  };

  useEffect(() => {
    if (replyIdToDelete) deleteReply(replyIdToDelete);
    (async () => {
      const val = await getIsMentee(comment.user.id);
      setIsCommentorMentee(val);
    })();
  }, [replyIdToDelete, reply]);

  return (
    <div
      onMouseEnter={() => {
        if (comment.user.id === authUserDetails.id) setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
      className="blog-comments__comment"
    >
      <div className="blog-comments__comment-metadata--format">
        <div className="blog-comments__comment-user">{comment.user.name}</div>
        {!isCommentorMentee && (
          <div className="blog-comments__comment-mentor_tag">Mentor</div>
        )}
        <div className="blog-comments__comment-date">
          {date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}
        </div>
        {comment.edited && <div>Edited</div>}
        {hover && (
          <div className="blog-comments__buttons">
            <IconButton onClick={() => setEditMode(!editMode)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => deleteCommentHandler(comment.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>

      {!editMode ? (
        <div className="blog-comments__comment-text">{comment.text}</div>
      ) : (
        <div className="blog-comments__edit-flex">
          <textarea
            className="blog-comments__comment-edit"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
          />
          <button
            className="blog-comments__comment-submit-edit"
            onClick={async () => {
              comment.text = newComment;
              await updateComment();
              setEditMode(false);
            }}
          >
            Submit
          </button>
        </div>
      )}

      {showReplies ? (
        <div>
          <div
            onClick={() => setShowReplies(false)}
            className="blog-comments__reply-display-toggle"
          >
            <ArrowDropUpIcon
              style={{
                fontSize: 30,
              }}
            />{" "}
            HIDE {replyOrReplies()}
          </div>
          <div>
            <div className="blog-comments__replies">
              <div className="blog-comments__replies-list">
                {comment.replies.map((reply) => (
                  <BlogReply
                    reply={reply}
                    key={reply.id}
                    currentUser={authUserDetails}
                    deleteReplyHandler={deleteReplyHandler}
                    updateComment={updateComment}
                  />
                ))}
              </div>
              <div className="blog-comments__reply-buttons--format">
                <input
                  placeholder="Send a reply..."
                  value={reply}
                  onChange={(e) => setReply(e.target.value)}
                  className="blog-comments__reply-input"
                />
                <button
                  className="blog-comments__reply-submit"
                  disabled={!(authUserDetails && reply)}
                  onClick={() => submitReply()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={() => setShowReplies(true)}
          className="blog-comments__reply-display-toggle"
        >
          <ArrowDropDownIcon
            style={{
              fontSize: 30,
            }}
          />{" "}
          {replyOrReplies()}
        </div>
      )}
    </div>
  );
};

export default BlogComment;
