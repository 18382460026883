import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  TaskTracker,
  ButtonDefault,
  MentorThumbnail,
  VideoThumbnail,
  HelpTag,
  Screen,
  H2,
  Section,
  Track,
  H3,
} from "../../../components";

import StarSvg from "../../../components/Tasks/StarSvg/StarSvg";

import { makeStyles } from "@material-ui/core";

import { useAuth } from "../../../Providers";
import getProfileCompleteness from "../../../utilities/getProfileCompleteness";
import LevelProgress from "./LevelProgress";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "5rem",
  },
  root: {
    backgroundColor: "white",
    borderRadius: "2rem",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)",
    padding: "5rem",
  },
  name: {
    fontSize: "2.5rem",
    fontFamily: "Inter",
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "5rem",
    },
  },
  progressContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      // gridTemplateColumns: "repeat(1, 1fr)",
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  pointsWrapper: {
    display: "flex",
    marginLeft: "5rem",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      // gridTemplateColumns: "repeat(1, 1fr)",
      marginLeft: 0,
      justifyContent: "center",
      marginTop: "5rem",
      alignSelf: "center",
    },
  },
  points: {
    fontSize: "3rem",
    fontFamily: "Inter",
    fontWeight: 700,
    marginRight: "1rem",
  },
}));

export default function Intro({}) {
  const history = useHistory();

  const { authUserDetails, authUser } = useAuth();

  const mentorRequiredFields = [
    "job",
    "name",
    "bio",
    "careers",
    "location.country",
    "gender",
    "company",
    "links.linkedIn",
  ];

  const profileCompleteStatus = getProfileCompleteness(
    authUserDetails,
    mentorRequiredFields
  );

  const [tasksIsOpen, setTaskIsOpen] = useState(false);

  const { root, name, pointsWrapper, progressContainer, points, container } =
    useStyles();

  return !authUserDetails ? null : (
    <>
      {!authUserDetails?.mentor ? (
        <Section.Container className={container}>
          <div className={root}>
            <p className={name}>
              WELCOME,{" "}
              <Link to="/settings">
                <span className="color-primary">
                  {authUserDetails?.username}!
                </span>
              </Link>
            </p>
            <div className={progressContainer}>
              <LevelProgress />
              <div className={pointsWrapper}>
                <p className={points}>{authUserDetails?.points}</p>
                <StarSvg type="bronze" width={"5rem"} />
              </div>
            </div>
          </div>
        </Section.Container>
      ) : (
        <Section.Container className={container}>
          <div className={root}>
            <p className={name}>
              WELCOME,{" "}
              <Link to="/settings">
                <span className="color-primary">{authUserDetails.name}!</span>
              </Link>
            </p>
            {!authUserDetails.allowViewProfile && (
              <>
                <p className="paragraph u-margin-bottom-medium">
                  {
                    profileCompleteStatus.length < mentorRequiredFields.length
                      ? "We would like for you to complete your profile before you go to the Mentorship Portal."
                      : "Go to your account settings and allow students to view your profile" /*"You’re free to browse the resources or you can head to the Mentorship Portal. "*/
                  }
                </p>
                <div className="text-center">
                  <H3 align="center">
                    {(profileCompleteStatus.length * 100) /
                      mentorRequiredFields.length}
                    %
                  </H3>
                  <div className="u-margin-bottom-medium">
                    <Track
                      progress={
                        (profileCompleteStatus.length * 100) /
                        mentorRequiredFields.length
                      }
                    />
                  </div>
                  <ButtonDefault
                    onClick={() =>
                      history.push(
                        profileCompleteStatus.length <
                          mentorRequiredFields.length
                          ? "/settings/profile"
                          : "/settings/account"
                      )
                    }
                  >
                    {profileCompleteStatus.length < mentorRequiredFields.length
                      ? "Complete profile"
                      : "Go to settings"}
                  </ButtonDefault>
                </div>
              </>
            )}
          </div>
        </Section.Container>
      )}
    </>
  );
}
