import React from "react";
import { useState, useEffect } from "react";
import "./AnnouncementListScreen.scss";
import { useAuth } from "../../../Providers";
import { useAnnouncement } from "../../../Providers/AnnouncementProvider/AnnouncementProvider";
import WebNavBar from "../../../components/Layout/Nav/NavBar/WebNav";
import AnnouncementCard from "../../../components/Announcements/AnnouncementCard/AnnouncementCard";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import withAuthorizationComponent from "../../../components/Auth/WithAuthorization/WithAuthorizationComponent";
TimeAgo.addDefaultLocale(en);

export function AnnouncementListScreen() {
    const timeAgo = new TimeAgo("en-US");

    const [unreadTab, setTab] = useState(true);
    const [selectedAnnouncements, setSelectedAnnouncements] = useState([]);
    const { authUserDetails } = useAuth();
    const { announcements } = useAnnouncement();
    const [displayMessage, setDisplayMessage] = useState("");
    const unreadAnnouncements = [];
    const readAnnouncements = [];

    //set a delay in which will display a message if there are no announcement post read or in unread
    useEffect(() => {
        if (
            announcements.length &&
            (unreadAnnouncements || readAnnouncements)
        ) {
            announcements.forEach((announcement) => {
                if (announcement.viewers.includes(authUserDetails.id)) {
                    readAnnouncements.push(announcement);
                } else unreadAnnouncements.push(announcement);
            });
        }

        if (unreadTab) {
            setSelectedAnnouncements(unreadAnnouncements);
        } else {
            setSelectedAnnouncements(readAnnouncements);
        }
    }, [announcements, unreadTab]);

    useEffect(() => {
        if (!selectedAnnouncements.length) {
            setTimeout(
                () =>
                    setDisplayMessage(
                        `No announcements ${unreadTab ? "unread" : "read"}`
                    ),
                250
            );
        }
    }, [selectedAnnouncements]);

    return (
        <div className="announcement-list--background">
            <WebNavBar />
            <div className="announcement-list">
                <div className="announcement-list__title">
                    <div>Announcements</div>
                </div>
                <div className="announcement-list__container">
                    <div className="announcement-list__header">
                        <hr />
                        <div className="announcement-list__tabs">
                            <div
                                onClick={() => setTab(false)}
                                className={
                                    !unreadTab
                                        ? "announcement-list__tab-selected"
                                        : "announcement-list__tab"
                                }
                            >
                                Read
                            </div>
                            <div
                                onClick={() => setTab(true)}
                                className={
                                    unreadTab
                                        ? "announcement-list__tab-selected"
                                        : "announcement-list__tab"
                                }
                            >
                                Unread
                            </div>
                        </div>
                    </div>
                </div>

                <div className="announcement-list__display">
                    {selectedAnnouncements.length ? (
                        selectedAnnouncements.map(
                            ({ id, title, image, published_at }, i) => (
                                <AnnouncementCard
                                    id={id}
                                    key={id}
                                    imageUrl={image.url}
                                    title={title}
                                    date={timeAgo.format(
                                        new Date(published_at)
                                    )}
                                />
                            )
                        )
                    ) : (
                        <h2 className="announcement-list__list-message">
                            {displayMessage}
                        </h2>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withAuthorizationComponent((auth) => auth)(AnnouncementListScreen);
