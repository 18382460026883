import React from "react";
import "./LandingScreen.scss";
import { useHistory } from "react-router";
import withAuthorizationComponent from "../../components/Auth/WithAuthorization/WithAuthorizationComponent";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./Header";
import How from "./How";
import ImageSection from "./ImageSection";
import Features from "./Features";
import Testimonials from "./Testimonials";
import WebNavBar from "../../components/Layout/Nav/NavBar/WebNav";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridAutoRows: "min-content",
    minHeight: "90rem",
    padding: "var(--padding-screen-large-top) 0",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      padding: "var(--padding-screen-large-top) 0",
    },
  },
}));

function WebLandingScreen() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <WebNavBar />
      <Header />
      <How />
      <ImageSection />
      <Features />
      <Testimonials />
      <ImageSection />
    </div>
  );
}

export default withAuthorizationComponent(
  (authUser, authUserDetails) => !(authUser && authUserDetails),
  "/home"
)(WebLandingScreen);
