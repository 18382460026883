import React, { useEffect, useState } from "react";
import "./VideoThumbnail.scss";
import { MentorThumbnail } from "../..";
import Youtube from "../../../apis/youtube";
import { Link } from "react-router-dom";
import { limitWords } from "../../../helpers";
import { useUsers } from "../../../Providers";

export default function VideoThumbnail({ video }) {
  const [snippet, setSnippet] = useState(null);
  const [author, setAuthor] = useState(null);
  const [authorImage, setAuthorImage] = useState(null);
  const { getUser } = useUsers();

  useEffect(() => {
    Youtube.getSnippet(video.youtubeId).then((snippet) => {
      setSnippet(snippet);
    });
    video.author && getUser(video.author).then(setAuthor);
  }, []);

  useEffect(() => {
    author && author.image && setAuthorImage(author.image);
  }, [author]);

  return (
    <div className="videoThumbnail">
      <Link to={`/videos/${video.id}`}>
        <div
          className="videoThumbnail__thumbnail"
          style={{
            backgroundImage: snippet
              ? `url(${
                  (
                    snippet.thumbnails.standard ||
                    snippet.thumbnails.high ||
                    snippet.thumbnails.medium ||
                    snippet.thumbnails.default ||
                    snippet.thumbnails.maxres
                  ).url
                })`
              : "none",
          }}
        >
          <div className="videoThumbnail__play">
            <svg
              width="40"
              height="35"
              viewBox="0 0 40 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M37.3595 13.8199C40.603 15.2013 40.603 19.7987 37.3595 21.1801L5.8174 34.6143C3.17895 35.7381 0.25 33.802 0.25 30.9342V4.06577C0.25 1.19797 3.17896 -0.738087 5.81741 0.385668L37.3595 13.8199Z"
                fill="#C4C4C4"
              />
            </svg>
          </div>
        </div>
        <div className="videoThumbnail__content">
          <h4 className="videoThumbnail__title">
            {limitWords(video.title, 6, "...")}
          </h4>
          {author && (
            <MentorThumbnail mentor={author} wide={true}></MentorThumbnail>
          )}
          {/* {author && <Link to={`/mentors/${author ? author.id : null}`}>
            <div
              className="videoThumbnail__author"
            >
                <div
                  className="videoThumbnail__authorImage"
                  style={{ backgroundImage: `url(${authorImage})` }}
                ></div>
              <div
                className="videoThumbnail__authorText"
              >
                <div
                  className="videoThumbnail__authorName"
                >
                  {author.name}
                </div>
                <div
                  className="videoThumbnail__authorJob"
                >
                  {author.job}
                </div>
              </div>
            </div>
          </Link>} */}
        </div>
      </Link>
    </div>
  );
}
