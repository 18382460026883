import React, { useState, useContext, useEffect, useRef } from "react";
import "./SettingsProfile.scss";
import { AuthUserContext } from "../../../../Providers/AuthUser/AuthUserProvider";
import {
  useStatus,
  statusTypes,
} from "../../../../Providers/StatusProvider/StatusProvider";
import { TaskableContext } from "../../../../Providers/TaskableProvider/TaskableProvider";
import ButtonDefault from "../../../../components/Buttons/ButtonDefault/ButtonDefault";
import FormTextArea from "../../../../components/Form/FormTextArea/FormTextArea";
import FormInput from "../../../../components/Form/FormInput/FormInput";
import FormRadioGroup from "../../../../components/Form/FormRadioGroup/FormRadioGroup";
import FormUploadImage from "../../../../components/Form/FormUpload/FormUploadImage/FormUploadImage";
import { REGEX_EXPRESSIONS, taskTypes } from "../../../../constants";
import DropdownInput from "../../../../components/Form/DropdownInput/DropdownInput";
import { Firebase } from "../../../../apis";
import Azure from "../../../../apis/Azure";
import { COUNTRIES, STATES, PROVINCES } from "../../../../constants/Locations";
import { useCareers, useDegrees } from "../../../../Providers";
import { auth } from "firebase";
import normalizeUrl from "normalize-url";
import checkIfValidUrl from "../../../../helpers/checkIfValidUrl";
import { upsertUserFromGraphQL } from "../../../../azureGraphQL/userMutation";

export default function SettingsProfile() {
  const [isLoading, setIsLoading] = useState(false);

  const { authUser, authUserDetails, updateAuthUserDetails } =
    useContext(AuthUserContext);

  const { taskableHandler } = useContext(TaskableContext);

  const { getAllDegrees, degrees: allDegrees } = useDegrees();

  const { changeStatus } = useStatus();

  const { getAllCareers, careers: allCareers } = useCareers();
  const [allcareers, setCareers] = useState([]);

  // Refactor into one place
  const initialAvatarSrc = useRef();

  const initialState = {
    name: authUserDetails?.name,
    age: authUserDetails?.age || null,
    city: authUserDetails?.location?.city || null,
    province: authUserDetails?.location?.province || null,
    country: authUserDetails?.location?.country || null,
    gender: authUserDetails?.gender || null,
    bio: authUserDetails.bio || null,
    twitter: authUserDetails?.links?.twitter || null,
    linkedIn: authUserDetails?.links?.linkedIn || null,
    website: authUserDetails?.links?.website || null,
    instagram: authUserDetails?.links?.instagram || null,
    job: authUserDetails?.job || null,
    company: authUserDetails?.company || null,
    avatar: authUserDetails.image,
    avatarSrc: undefined,
    careers: [],
    degrees: [],
  };

  
  const [state, setState] = useState(initialState);
  
  useEffect(() => {
    getAllCareers().then( (data) => {
      setCareers(data);
    });
  }, []);

  useEffect(() => {
    setState( (state) => ({
      ...state,
      careers: allcareers
        .filter((career) => {
          return authUserDetails.mentor
            ? authUserDetails?.careers?.includes(career.id)
            : authUserDetails?.joinedCareers?.includes(career.id);
        })
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    }));
  }, [allCareers]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      degrees: getAllDegrees()
        .filter((degree) => {
          return authUserDetails.mentor
            ? authUserDetails?.degrees?.includes(degree.id)
            : authUserDetails?.joinedDegrees?.includes(degree.id);
        })
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    }));
  }, [allDegrees]);

  useEffect(() => {
    if (avatar) {
      Firebase.storage
        .ref(avatar)
        .getDownloadURL()
        .then((src) => {
          initialAvatarSrc.current = src;
          setState((state) => ({ ...state, avatarSrc: src }));
        });
    }
  }, []);

  const {
    name,
    age,
    gender,
    province,
    country,
    city,
    bio,
    twitter,
    linkedIn,
    instagram,
    website,
    job,
    avatar,
    careers,
    degrees,
    company,
    avatarSrc,
  } = state;

  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value.length
        ? event.target.value
        : null,
    });
  };

  const onDropdownChange = (selectedOption, selectionType) => {
    if (selectionType === "country") {
      setState({
        ...state,
        [selectionType]: selectedOption.length ? selectedOption : null,
        province: null,
      });
    } else {
      setState({
        ...state,
        [selectionType]: selectedOption.length ? selectedOption : null,
      });
    }
  };

  const personalInfoSubmitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const updates = {
      age: age?.length > 0 ? parseInt(age) : authUserDetails?.age,
      name: name?.length > 0 ? name : authUserDetails?.name,
      gender: gender?.length > 0 ? gender : authUserDetails?.gender,
      bio: bio?.length > 0 ? bio : authUserDetails?.bio,
      location: {
        country: country?.length > 0 ? country : authUserDetails?.country,
        province: province?.length > 0 ? province : authUserDetails?.province,
        city: city?.length > 0 ? city : authUserDetails?.city,
      },
      careers:
        getAllCareers().length > 0
          ? careers.map((career) => career.value)
          : authUserDetails.careers,
      //degrees: getAllDegrees().length > 0 ? degrees.map((degree) => degree.value) : authUserDetails.degrees,
      company: company,
      links: {
        twitter:
          twitter && checkIfValidUrl(twitter) ? normalizeUrl(twitter) : null,
        linkedIn:
          linkedIn && checkIfValidUrl(linkedIn) ? normalizeUrl(linkedIn) : null,
        website:
          website && checkIfValidUrl(website) ? normalizeUrl(website) : null,
        instagram:
          instagram && checkIfValidUrl(instagram)
            ? normalizeUrl(instagram)
            : null,
      },
    };

    if (!authUserDetails.mentor) {
      taskableHandler(
        taskTypes.giveMoreInfo,
        authUserDetails,
        getUpdatesCount(updates)
      );
      upsertUserFromGraphQL({
        id: authUserDetails.id,
        rowKey: authUserDetails.id,
        partitionKey: "users",
        age: updates.age,
        name: updates.name,
        gender: updates.gender,
        bio: updates.bio,
        location: updates.location,
      })
        .then(() =>
          updateAuthUserDetails({
            ...authUserDetails,
            age,
            name,
            gender,
            bio,
            location: updates.location,
          })
        )
        // Firebase.update("users", authUserDetails.id, {
        //   personalInfo: {
        //     name: updates.name,
        //     age: updates.age,
        //     gender: updates.gender,
        //     bio: updates.bio,
        //     location: updates.location,
        //   },
        // })
        .then(async () => {
          changeStatus(
            {
              message: "Your settings have been updated",
            },
            statusTypes.success
          );
        })
        .catch((err) => {
          changeStatus(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      Azure.upsertMentorFromGraphQL({
        id: authUserDetails.id,
        age: age,
        job: job,
        name: name,
        bio: bio,
        company: company,
        location: updates.location,
        gender: gender,
        careers: updates.careers,
        degrees: updates.degrees,
      })
        .then(async () => {
          if (avatarSrc) {
            if (avatarSrc !== initialAvatarSrc.current) {
              const imageUrl = await Azure.uploadImgToAzure(
                avatarSrc,
                `profileImages/${authUserDetails.id}`
              );
              await Azure.upsertMentorFromGraphQL({
                id: authUserDetails.id,
                image: imageUrl,
              });
            }
          }
          changeStatus(
            {
              message: "Your settings have been updated",
            },
            statusTypes.success
          );
        })
        .catch((err) => {
          changeStatus(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getUpdatesCount = (updates) => {
    let count = 0;
    Object.values(updates).forEach((update) => {
      if (typeof update !== "object")
        if (update !== null) count++;
        else {
          if (update.location.country || update.location.province) count++;
        }
    });
    return count;
  };

  const countryOptions = COUNTRIES.map((country) => {
    return {
      value: country,
      label: country,
    };
  });

  const provinceOptions = PROVINCES.map((province) => {
    return {
      value: province,
      label: province,
    };
  });

  const stateOptions = STATES.map((usaState) => {
    return {
      value: usaState,
      label: usaState,
    };
  });

  return (
    <form
      onSubmit={personalInfoSubmitHandler}
      className="section settingsProfile settingsScreen__section"
    >
      <div className="section">
        <h2 className="paragraph ">Let's know more about you.</h2>
        <p className="paragraph">Fields with an * are required</p>
      </div>
      {authUserDetails.mentor && (
        <div className="section">
          <FormUploadImage
            type="avatar"
            name="avatarSrc"
            onChange={onChange}
            required={false}
            value={avatarSrc}
          />
        </div>
      )}
      <div className="section">
        <h2 className="section__title heading-tertiary">
          Background Information
        </h2>
        <FormInput
          name="name"
          value={name}
          onChange={onChange}
          placeholder="Full Name*"
          required={false}
        />
        <FormInput
          min={4}
          max={90}
          name="age"
          value={age}
          onChange={onChange}
          placeholder="Age"
          type="number"
          required={false}
        />
        <DropdownInput
          name="country"
          value={country}
          onChange={onDropdownChange}
          placeholder="Country*"
          required={false}
          options={countryOptions}
          disabled={false}
        />
        <DropdownInput
          name="province"
          value={province}
          onChange={onDropdownChange}
          placeholder="Province/State"
          required={false}
          options={country === "Canada" ? provinceOptions : stateOptions}
          disabled={country !== "Canada" && country !== "United States"}
        />

        <FormInput
          name="city"
          value={city}
          onChange={onChange}
          placeholder="City"
          required={false}
        />
        <FormRadioGroup
          initialValue={gender}
          name="gender"
          title="How do you identify?*"
          radioData={[
            { value: "male", placeholder: "Male" },
            { value: "female", placeholder: "Female" },
            { value: "other", placeholder: "Other" },
          ]}
          onChange={onChange}
        />
      </div>
      <div className="section">
        <h2 className="section__title heading-tertiary">Biography </h2>
        <FormTextArea
          placeholder="Tell us something about yourself*"
          value={bio}
          name="bio"
          onChange={onChange}
          required={false}
        />
      </div>
      {authUserDetails.mentor && (
        <>
          <div className="section">
            <h2 className="section__title heading-tertiary">
              Social Media Links
            </h2>
            <FormInput
              name="linkedIn"
              value={linkedIn}
              onChange={onChange}
              type="text"
              placeholder="Your linkedIn profile link if you have one*"
              required={false}
              invalid={(value) =>
                !checkIfValidUrl(value) && { error: "Please use a valid link" }
              }
            />
            <FormInput
              name="twitter"
              value={twitter}
              onChange={onChange}
              type="text"
              placeholder="Your twitter handle if you have one"
              required={false}
              invalid={(value) =>
                !checkIfValidUrl(value) && { error: "Please use a valid link" }
              }
            />
            <FormInput
              name="instagram"
              value={instagram}
              onChange={onChange}
              type="text"
              placeholder="Your instagram handle if you have one"
              required={false}
              invalid={(value) =>
                !checkIfValidUrl(value) && { error: "Please use a valid link" }
              }
            />
            <FormInput
              name="website"
              value={website}
              onChange={onChange}
              type="text"
              placeholder="Your website link if you have one"
              required={false}
              invalid={(value) =>
                !checkIfValidUrl(value) && { error: "Please use a valid link" }
              }
            />
          </div>
          <div className="section">
            <div className="section__title heading-tertiary">
              Career Information
            </div>
            <DropdownInput
              name="careers"
              value={careers}
              onChange={onDropdownChange}
              placeholder="Choose careers from our selection*"
              required={true}
              limit={3}
              options={allcareers.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              disabled={allcareers.length < 0}
              multi
            />
            <DropdownInput
              name="degrees"
              value={degrees}
              onChange={onDropdownChange}
              placeholder="Choose degree from our selection"
              required={true}
              limit={3}
              options={getAllDegrees().map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              // disabled={country !== "Canada" && country !== "United States"}
              multi
            />
            <FormInput
              name="job"
              value={job}
              onChange={onChange}
              type="text"
              placeholder="Job*"
              required
            />
            <FormInput
              name="company"
              value={company}
              onChange={onChange}
              type="text"
              placeholder="Company Name*"
              required
            />
          </div>
        </>
      )}
      {/* <div className="section">
        <h2 className="section__title heading-secondary">Your picture</h2>
        <FormUploadImage
          name="mentorImage"
          onChange={onChange}
          required={false}
        />
      </div> */}
      <ButtonDefault
        isLoading={isLoading}
        onClick={(e) => {
          personalInfoSubmitHandler(e);
        }}
      >
        Submit
      </ButtonDefault>
    </form>
  );
}
