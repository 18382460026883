import React, { useEffect, useState } from "react";
import "./MentorsDescription.scss";
import { limitWords } from "../../../helpers";
import { useHistory } from "react-router";
import { ROUTES } from "../../../constants";

export default function ({ mentor }) {
  const [mentorImage, setMentorImage] = useState(null);

  useEffect(() => {
    setMentorImage(mentor.image)
  });

  const history = useHistory();
  return (
    <div
      className="mentorDescription"
      onClick={() => {
        history.push(`${ROUTES.mentorsList}/${mentor.id}`);
      }}
    >
      <div
        className="mentorDescription__thumbnail"
        style={{
          backgroundImage: `url(${
            mentor.image.includes("profileImages") ? mentorImage : mentor.image
          })`,
        }}
      ></div>
      <div className="mentorDescription__text">
        <div className="mentorDescription__name">{mentor.name}</div>
        <div className="mentorDescription__job">{`${mentor.job}${mentor.company ? ` @ ${mentor.company}` : ''}`}</div>
        <div className="mentorDescription__bio">
          {limitWords(mentor.bio, 15, "...")}
        </div>
      </div>
    </div>
  );
}
