const checkIfInRange = (number, min, max) => min <= number && max >= number;

function getDeviceType(window, queryer) {
  const screenWidth = queryer(window);
  if (checkIfInRange(screenWidth, 0, 400)) return 1;
  if (checkIfInRange(screenWidth, 400, 600)) return 2;
  if (checkIfInRange(screenWidth, 600, 900)) return 3;
  if (checkIfInRange(screenWidth, 900, 1200)) return 4;
  if (checkIfInRange(screenWidth, 1200, 1800)) return 5;
}

function getWindowWidth(window) {
  return window.innerWidth;
}

export default () => getDeviceType(window, getWindowWidth);
