export default class Youtube {
  static apiKey = "AIzaSyCbPnVxyQSilyiaJJfuWXX-D9VCAc8C49M";
  static getSnippet = async (id) => {
    const videoMeta = await fetch(
      `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${id}&key=${this.apiKey}`
    ).then((res) => {
      return res.json();
    });
    return videoMeta.items ? videoMeta.items[0].snippet : null;
  };
}
