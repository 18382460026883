import React, { useState } from "react";
import "./SignUpMoreInfo.scss";
import { useHistory } from "react-router";
import { ButtonDefault } from "../../../../components";
import { useStatus } from "../../../../Providers/StatusProvider/StatusProvider";
import { FormInput } from "../../../../components";
import { statusTypes } from "../../../../constants";
import { useAuth } from "../../../../Providers";
import { Firebase } from "../../../../apis";
import { upsertUserFromGraphQL } from "../../../../azureGraphQL/userMutation";


export default function SignUpMoreInfo({}) {
  const { authUser } = useAuth();

  const { changeStatus } = useStatus();

  const initialState = {
    username: "",
  };

  const [state, setState] = useState(initialState);
  const [error] = useState(null);

  const history = useHistory();

  const onSubmit = async (e) => {
    e.preventDefault();
    const available = (
      await Firebase.store
        .collection(Firebase.collections.users)
        .where("username", "==", username.toLowerCase())
        .get()
    ).empty;
    if (available) {
      var updateUseRef = Firebase.store.collection("users").doc(authUser.uid);
      upsertUserFromGraphQL({
        id: authUser.uid,
        rowKey: authUser.uid,
        partitionKey: "users",
        username: username.toLowerCase()
      });
      return updateUseRef
        .update({
          username: username.toLowerCase(),
        })
        .then(function () {
          console.log("Document successfully updated!");
          history.push("/signup/interests");
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    } else {
      changeStatus({ message: "Username already taken" }, statusTypes.error);
    }
  };

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { username } = state;

  return (
    <form onSubmit={onSubmit} className="signUpForm">
      <FormInput
        name="username"
        value={username}
        onChange={onChange}
        type="text"
        placeholder="Username"
        required
      />
      <div className="signUpForm__buttons">
        <ButtonDefault type="submit">Submit</ButtonDefault>
      </div>
      {error && <p>{error.message}</p>}
    </form>
  );
}
