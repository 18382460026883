import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function ({ src, path, ...props }) {
  return (
    <svg
      viewBox="0 0 502 432"
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="evenodd"
      clip-rule="evenodd"
      stroke-linejoin="round"
      {...props}
    >
      <clipPath id="clip">
        {path ? (
          <path d={path} />
        ) : (
          <path d="M258.966 1.00755C364.077 1.71951 462.865 57.225 493.427 148.626C522.464 235.464 464.185 320.155 383.609 373.772C301.9 428.144 194.515 454.981 110.733 403.28C19.2753 346.841 -21.6091 240.644 13.4963 145.376C47.713 52.5186 151.217 0.277716 258.966 1.00755Z" />
        )}
      </clipPath>
      <image
        width="100%"
        height="100%"
        clip-path="url(#clip)"
        preserveAspectRatio="xMidYMid slice"
        href={src}
      />
    </svg>
  );
}
