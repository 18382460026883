import React from "react";
import "./ButtonTransparent.scss";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btnRoot: {
    borderRadius: "10px",
    padding: "1rem",
    color: "var(--color-primary)", // #4dc591
    borderColor: "var(--color-primary)",
    fontWeight: 700,
    fontFamily: "Inter",
    transition: "all .3s",
    // float: "right",
    margin: "auto",
    display: "block",
    "&:hover": {
      color: "black",
      backgroundColor: "var(--color-primary)",
      boxShadow: "0 5px 10px 0 rgba(0,0,0,.15)",
      transform: "translateY(-2px) scale(1.01)",
    },
  },
  btnLabel: {
    textTransform: "uppercase",
  },
}));

export default function ButtonTransparent({
  children,
  variant = "success",
  isLoading = false,
  disabled = false,
  ...rest
}) {
  const classes = useStyles();
  let type = null;

  switch (variant) {
    case "success":
      type = "success";
      break;

    case "danger":
      type = "danger";
      break;

    case "neutral":
      type = "neutral";
      break;

    default:
      type = "success";
      break;
  }

  return (
    <Button
      {...rest}
      disabled={isLoading || disabled}
      className={classes.btnRoot}
      variant="outlined"
      classes={{ label: classes.btnLabel }}
    >
      {!isLoading ? children : "Loading"}
    </Button>
    // <button
    //
    //   disabled={isLoading || disabled}
    //   className={`buttonTransparent buttonTransparent-${type} hoverEffect borderRadius-medium`}
    // >
    //   {!isLoading ? children : "Loading"}
    // </button>
  );
}
