import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import getInstitutionImageUrl from "../../helpers/getInstitutionImage";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  cardRoot: {},
  media: {
    height: 130,
    backgroundSize: "contain",
    borderBottom: "1px solid #DADADA",
  },
}));

const InstitutionCard = ({ degreeName, linkTo, imgLink }) => {
  const classes = useStyles();

  const [schoolImage, setSchoolImage] = useState(null);

  useEffect(() => {
    getInstitutionImageUrl(imgLink).then(setSchoolImage);
  });

  return (
    <React.Fragment>
      <Grid item md={3} sm={4} xs={6}>
        <Paper elevation={3}>
          <Card className={classes.cardRoot}>
            <CardActionArea href={linkTo}>
              {schoolImage ? (
                <CardMedia
                  className={classes.media}
                  image={schoolImage}
                  title="Contemplative Reptile"
                />
              ) : (
                <Skeleton
                  variant="rect"
                  width={"100%"}
                  height={130}
                  animation="wave"
                />
              )}
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 700,
                    textAlign: "center",
                  }}
                >
                  {degreeName}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};
export default InstitutionCard;
