import React, { createContext, useState, useContext } from "react";
import StatusDisplay from "../../components/StatusDisplay/StatusDisplay";

const StatusContext = createContext();

export const useStatus = () => {
  const { status, setStatus } = useContext(StatusContext);
  return {
    status,
    changeStatus: (state, type) => {
      setStatus({ state, type });
    },
  };
};

export default function StatusProvider({ children }) {
  const [status, setStatus] = useState(null);

  return (
    <StatusContext.Provider value={{ status, setStatus }}>
      {status ? (
        <StatusDisplay state={status.state} type={status.type}></StatusDisplay>
      ) : null}
      {children}
    </StatusContext.Provider>
  );
}

export const statusTypes = {
  error: "error",
  success: "success",
  warning: "warning",
};
