import React, { useEffect } from "react";
import { Typography, Grid, TextField, InputAdornment } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { blackmintTheme } from "../../style/theme";
import DegreeCard from "../../components/Degree/DegreeCard";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import { useDegrees } from "../../Providers";
import ProgramsCard from "./ProgramsCard";
import { ButtonDefault, Screen, H2, Section } from "../../components";
import WebNavBar from "../../components/Layout/Nav/NavBar/WebNav";
import withAuthorizationComponent from "../../components/Auth/WithAuthorization/WithAuthorizationComponent";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--color-background-default)",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "290px",
    },
  },
  headerBg: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    paddingTop: "10rem",
    paddingBottom: "5rem",
    backgroundColor: "var(--color-primary)",
    textAlign: "center",
    marginBottom: "2rem",
  },
  secondaryHeading: {
    ...blackmintTheme.typographyBlackmint.h2,
    fontWeight: "bold",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.5rem",
    },
  },
  inputLabel: {
    color: "rgba(96, 115, 146, 1) !important",
    paddingBottom: "4px !important",
    textStrokeWidth: 0.25,
    letterSpacing: 0.5,
    fontSize: "16px",
  },
  outlined: {
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#FFFFFF",
    border: "none",
    width: "100%",
    height: "50px",
    marginTop: "10px",
    "@global": {
      fieldset: {
        border: "none",
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "64px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "48px",
    },
  },
  h1: {
    ...blackmintTheme.typographyBlackmint.heroBannerTitle,
    fontSize: 30,
    fontWeight: 700,
    lineHeight: "40px",
    [theme.breakpoints.down("sm")]: {
      ...blackmintTheme.typographyBlackmint.heroBannerTitle,
      lineHeight: "56px",
    },
  },
  titleContainer: {
    height: "100%",
    padding: "124px 0px",
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: "28px 0px",
    },
  },
  bookmarkBtn: {
    ...blackmintTheme.typographyBlackmint.h5,
    fontWeight: 400,
    border: "1px solid #272727",
    borderRadius: "10px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  tabRoot: {
    ...blackmintTheme.typographyBlackmint.mainContent,
    fontSize: "12px",
    padding: "0px 12px",
    minHeight: 32,
  },
  textColor: { color: "#757575" },
  activeTab: { color: "#4DC591" },
  indicator: {
    backgroundColor: "#4DC591",
  },
  subHeader: {
    ...blackmintTheme.typographyBlackmint.h3,
    color: "#00664F",
  },
  aboutParagraph: {
    ...blackmintTheme.typographyBlackmint.mainContent,
    color: "#272727",
  },
  gridContainer: {
    marginTop: "2rem",
    marginBottom: "2rem",
    display: "flex",
    alignItems: "center",
  },
  whiteBox: {
    backgroundColor: "white",
    borderRadius: 20,
    height: 150,
    padding: "20px 10px",
    margin: "0px 10px",
    textAlign: "center",
  },
  boxHeader: {
    ...blackmintTheme.typographyBlackmint.h5,
    color: "#272727",
  },
  boxNumber: {
    color: "#4DC591",
    fontSize: "45px",
    fontWeight: "bold",
    fontFamily: "Inter",
  },
  boxSubHeader: {
    ...blackmintTheme.typographyBlackmint.subContent,
  },
  table: { marginBottom: "2rem" },
  tableLeft: {
    ...blackmintTheme.typographyBlackmint.mainContent,
    color: "#272727",
    paddingLeft: 0,
    paddingRight: 0,
    width: "50%",
  },
  tableLeftReq: {
    fontFamily: "Inter",
    fontSize: "16px",
    color: "#272727",
    paddingLeft: 0,
    paddingRight: 0,
  },
  tableRight: {
    ...blackmintTheme.typographyBlackmint.subContent,
  },
  tableRightExpenses: {
    color: "#757575",
  },
  viewSchoolBtn: {
    backgroundColor: "#4DC591",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: 8,
    marginBottom: "2rem",
    marginTop: "2rem",
    "&:hover": {
      backgroundColor: "#00664F",
    },
  },
  careerChip: {
    backgroundColor: "white",
    fontSize: "14px",
    fontFamily: "Inter",
    margin: 10,
    marginLeft: 0,
  },
  cardMedia: {
    height: 200,
    borderBottom: "1px solid #DADADA",
  },
}));

export function ProgramsListScreen() {
  const classes = useStyle();
  const styles = useStyles();

  const { getAllDegrees } = useDegrees();

  useEffect(() => {}, []);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.title,
  });

  return (
    <>
      <WebNavBar />
      <Screen className={classes.root}>
        <div style={{ flex: 1 }}>
          <div className={classes.headerBg}>
            <Typography component="h2" className={classes.secondaryHeading}>
              Tech Degrees
            </Typography>
          </div>
          <Section.Container>
            <div>
              <Typography className={styles.subHeader}>
                Suggested degrees for you
              </Typography>
              <Typography className={styles.aboutParagraph}>
                Based on your career interests and learning history, we found
                some programs that are the best match for you.
              </Typography>
            </div>

            <div
              style={{
                marginBottom: "2rem",
                marginTop: "4rem",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "2rem",
              }}
            >
              {getAllDegrees()
                .filter((degree) => degree.image)
                .map((degree) => {
                  return (
                    <ProgramsCard
                      degreeName={degree.name}
                      linkTo={"/degrees/" + degree.id}
                      imgLink={degree.image}
                    />
                  );
                })}
            </div>
          </Section.Container>
        </div>
      </Screen>
    </>
  );
}

export default withAuthorizationComponent((auth) => auth)(ProgramsListScreen);
