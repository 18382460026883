import React, { useEffect, useState } from "react";
// import { taskTypes, ANALYTICS_EVENTS } from "../../../constants";
// import { useAuth } from "../../../Providers";
// import { useTask } from "../../../Providers";
// import { updateHistory, historyTypes } from "../../../helpers";
// import { Firebase } from "../../../apis";

export default function CareerVideo({ youtubeId }) {
  const [player, setPlayer] = useState(null);

  const playerOptions = {
    modestbranding: 1,
    iv_load_policy: 3,
    rel: 0,
    autoplay: false,
  };

  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";

      window.onYouTubeIframeAPIReady = loadVideo;

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      loadVideo();
    }
  }, [youtubeId]);

  const loadVideo = () => {
    if (player) return player.loadVideoById(youtubeId);

    return new window.YT.Player("player", {
      videoId: youtubeId,
      playerVars: playerOptions,
      width: "100%",
      height: "100%",
      events: {
        onStateChange: stateChangeHandler,
        onReady: playerReadyHandler,
      },
    });
  };

  function playerReadyHandler(event) {
    setPlayer(event.target);
  }
  async function stateChangeHandler(event) {}

  return (
    <div className="videoPlayer">
      <div className="videoPlayer__video">
        <div id="player"></div>
      </div>
    </div>
  );
}
