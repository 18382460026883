import React, { useState } from "react";
import "./MentorSignUpForm.scss";
import { useHistory, useRouteMatch } from "react-router";
import "firebase/auth";
import { useStatus } from "../../../../Providers/StatusProvider/StatusProvider";
import { ButtonDefault } from "../../../../components";
import { FormInput } from "../../../../components";
import { useAuth } from "../../../../Providers";
import { statusTypes } from "../../../../constants";
import { Firebase } from "../../../../apis";
import { Link } from "react-router-dom";

export default function MentorSignUpForm() {
  const { url } = useRouteMatch();

  const { changeStatus } = useStatus();

  const { updateAuthUser } = useAuth();

  const initialState = {
    email: "",
    passwordOne: "",
    passwordTwo: "",
    passKey: "",
  };

  const [state, setState] = useState(initialState);

  const history = useHistory();

  const onSubmit = (e) => {
    e.preventDefault();
    Firebase.store
      .collection("passKeys")
      .doc(passKey)
      .get()
      .then((passKeyDoc) => {
        if (passKeyDoc.exists) {
          Firebase.doCreateUserWithEmailAndPassword(email, passwordOne)
            .then((authUser) => {
              authUser.user.sendEmailVerification();
              updateAuthUser(authUser.user);
              history.push(`${url}/moreinfo`, { passKey });
            })
            .catch((error) => {
              changeStatus(error, statusTypes.error);
            });
        } else {
          changeStatus({ message: "Passkey incorrect" }, statusTypes.error);
        }
      });
  };

  // const signUpWithGoogleHandler = (e) => {
  //   e.preventDefault();
  //   Firebase.auth
  //     .signInWithPopup(new firebase.auth.GoogleAuthProvider())
  //     .then((authUser) => {
  //       updateAuthUser(authUser.user);
  //       history.push(`${url}/moreInfo`);
  //     })
  //     .catch((error) => {
  //       changeStatus(error, statusTypes.error);
  //     });
  // };

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { email, passwordOne, passwordTwo, passKey } = state;

  return (
    <form onSubmit={onSubmit} className="mentorSignUpForm">
      <FormInput
        name="passKey"
        value={passKey}
        onChange={onChange}
        placeholder="Passkey"
        required
      />
      <FormInput
        name="email"
        value={email}
        onChange={onChange}
        type="email"
        placeholder="Email Address"
        required
      />
      <p style={{ marginBottom: "1rem" }}>
        Password must be at least 8 characters with atleast one uppercase and
        lowercase letter and one number
      </p>
      <FormInput
        name="passwordOne"
        value={passwordOne}
        onChange={onChange}
        type="password"
        placeholder="Password"
        required
        invalid={
          !passwordOne.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
            ? {
                error:
                  "Password must be atleast 8 characters with atleast one uppercase and lowercase letter and one number",
              }
            : null
        }
      />
      <FormInput
        name="passwordTwo"
        value={passwordTwo}
        onChange={onChange}
        type="password"
        placeholder="Confirm Password"
        invalid={
          passwordOne !== passwordTwo
            ? { error: "Passwords do not match" }
            : null
        }
        required
      />
      <div className="mentorSignUpForm__buttons u-margin-bottom-medium">
        <ButtonDefault type="submit">Sign Up</ButtonDefault>
      </div>
      <div className="signUpForm__gotoSignIn u-margin-bottom-small">
        <Link to="/signin">Already a mentor? Click here</Link>
      </div>
    </form>
  );
}
