import React, { useEffect, useState } from "react";
import "./ArticleScreen.scss";
import { useParams } from "react-router";
import { ButtonTransparent, Screen } from "../../../components";
import { ReactComponent as MainImage } from "./images/mainImage.svg";
import { paragraphTypes } from "../../../constants";
import { useArticles } from "../../../Providers";
import WebNavBar from "../../../components/Layout/Nav/NavBar/WebNav";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--color-background-default)",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "290px",
    },
  },
  mainHeading: {
    width: "100%",
    margin: 0,
    display: "flex",
    borderRadius: 0,
    justifyContent: "center",
    alignItems: "center",
    padding: "5rem",
    backgroundColor: "#58c494",
  },
}));

export default function ArticleScreen() {
  const { root, mainHeading } = useStyles();

  const params = useParams();

  const [article, setArticle] = useState(null);

  const { getArticle } = useArticles();

  useEffect(() => {
    getArticle(params["id"]).then((article) => {
      setArticle(article);
    });
  }, [params]);

  return (
    <>
      <WebNavBar />
      <Screen className={root}>
        {article ? (
          <div style={{ flex: 1 }}>
            <div className="articleScreenMainHeading section">
              <div
                className="articleScreenMainHeading__content"
                style={{ padding: "0 5rem" }}
              >
                <h2 className="articleScreenMainHeading__name">
                  {article.title}
                </h2>
                <ButtonTransparent variant="neutral">
                  Bookmark
                </ButtonTransparent>
              </div>
              <div className="articleScreenMainHeading__image">
                <MainImage />
              </div>
            </div>
            <h3
              className="heading-secondary font-bold color-primary"
              style={{ padding: "0 5rem" }}
            >
              {article.subTitle}
            </h3>
            <div
              className="articleScreen__content"
              style={{ padding: "0 5rem" }}
            >
              {article.content
                ? article.content.map((paragraph) => {
                    switch (paragraph.type) {
                      case paragraphTypes.paragraph:
                        const paragraphSplit = paragraph.value.split("//");

                        return paragraphSplit.map((split, index) => {
                          return (
                            <p
                              className="paragraph articleScreen__paragraph"
                              key={index}
                            >
                              {split}
                            </p>
                          );
                        });
                        break;
                      case paragraphTypes.image:
                        return (
                          <div
                            className="paragraphImage articleScreen__paragraph"
                            style={{
                              backgroundImage: `url(${paragraph.value})`,
                            }}
                          ></div>
                        );
                        break;
                      case paragraphTypes.h2:
                        return (
                          <h2 className="heading-secondary font-bold color-primary articleScreen__paragraph">
                            {paragraph.value}
                          </h2>
                        );
                        break;
                      case paragraphTypes.h3:
                        return (
                          <h3 className="heading-tertiary color-primary articleScreen__paragraph">
                            {paragraph.value}
                          </h3>
                        );
                        break;
                      case "ulist":
                        return (
                          <ul className="articleScreen__paragraph">
                            {paragraph.value.map((value) => (
                              <li className="paragraph articleScreen__listItem">
                                {value}
                              </li>
                            ))}
                          </ul>
                        );
                        break;
                      case "quote":
                        return (
                          <h2 className="articleScreen__paragraph articleScreen__quote">
                            "{paragraph.value}" - {paragraph.author}
                          </h2>
                        );
                        break;
                      default:
                        return null;
                        break;
                    }
                  })
                : null}
            </div>
          </div>
        ) : null}
      </Screen>
    </>
  );
}
