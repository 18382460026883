import { Avatar, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import BlobImage from "../../../components/BlobImage";
import heroImage1 from "./images/hero-1.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: 'space-between',
    padding:
      "var(--padding-screen-large-top) var(--padding-screen-large-side) 0 var(--padding-screen-large-side)",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "var(--padding-screen-medium-side)",
      paddingRight: "var(--padding-screen-medium-side)",
      flexDirection: "column"
    },
    [theme.breakpoints.down("xs")]: {
      padding:
        "var(--padding-screen-large-top) var(--padding-screen-small-side) 0 var(--padding-screen-small-side)",
    },
  },
  landingHeader: {
    fontFamily: "Inter",
    marginBottom: "2rem",
    fontWeight: "normal",
    fontSize: "44px",
    padding: "4rem 0",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center'
    },
  },
  boldFont: {
    fontWeight: "bold",
  },
  sectionContainer: {
    marginBottom: "5rem",
    marginTop: "5rem",
    width: '45%',
    paddingRight: '5%',
    [theme.breakpoints.down("sm")]: {
    marginTop: "0",
      width: "100%"
    },
  },
  mainImage: {
    width: "50%",

    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.down("xs")]: {

    },
  },
  landingScreenMainText: {
    fontFamily: "Inter",
    lineHeight: "200% !important",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center'
    },
  },
}));

export default function Header({}) {
  const classes = useStyles();

  return (
    <div style={{}} className={classes.root}>
      <div className={classes.sectionContainer}>
        <Typography component="h3" className={classes.landingHeader}>
          Ready to be a{" "}
          <span className={classes.boldFont} style={{ color: "#ffd700" }}>
            Star?
          </span>
        </Typography>
        <p
          className={clsx(classes.paragraph, classes.landingScreenMainText)}
          style={{ paddingRight: "2rem" }}
        >
          Welcome to BlackMINT’s Rising Star platform! As a rising star, you
          will embark on a journey with us where you will gain access to{" "}
          <span className={classes.boldFont}>
            valuable materials which would inspire and guide you towards a
            career in tech.
          </span>{" "}
          And the best part,{" "}
          <span className={classes.boldFont}>this is absolutely free!</span>
        </p>
      </div>
      <BlobImage src={heroImage1} className={classes.mainImage}/>
    </div>
  );
}
