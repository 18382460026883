import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { blackmintTheme } from "../../../style/theme";

const useStyles = makeStyles((theme) => ({
  text: {
    ...blackmintTheme.typographyBlackmint.heading,
    fontSize: "6rem",
  },
}));

export default function H1({
  children,
  text,
  bold = true,
  align = "left",
  style = "",
  className = "",
}) {
  const styles = useStyles();
  return (
    <h1
      className={clsx(styles.text, className)}
      style={{
        fontWeight: bold ? "bold" : "normal",
        textAlign: align,
        ...style,
      }}
    >
      {children || text}
    </h1>
  );
}
