import React, { useState } from "react";
import "./ForgotPasswordPopUp.scss";
import {
  useStatus,
} from "../../../Providers";
import { PopUp, ButtonDefault, FormInput } from "../..";
import { Firebase } from "../../../apis";

export default function ForgotPasswordPopUp({ setForgotPassword }) {
  const [email, setEmail] = useState("");

  const { changeStatus } = useStatus();

  const sendEmailHandler = (e) => {
    e.preventDefault();
    Firebase.auth
      .sendPasswordResetEmail(email, {
        url: "https://risingstar.blackmint.io/signin",
      })
      .then(() => {
        changeStatus(
          { message: "A verification email has been sent to your account" },
          'success'
        );
        setForgotPassword(null);
      })
      .catch((err) => changeStatus(err));
  };

  return (
    <PopUp
      onBackgroundClick={() => {
        setForgotPassword(null);
      }}
    >
      <div className="forgotPasswordPopUp">
        <h2 className="forgotPasswordPopUp__heading heading-secondary">
          Forgot Password
        </h2>
        <p className="forgotPasswordPopUp__text paragraph">
          Enter the email you used to sign up and press SEND. A password reset
          email will be sent to your Email address.
        </p>
        <div className="forgotPasswordPopUp__input">
          <FormInput
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="email"
            required
            placeholder="E-mail"
          ></FormInput>
        </div>
        <div className="forgotPasswordPopUp__buttons">
          <ButtonDefault onClick={sendEmailHandler}>Send</ButtonDefault>
        </div>
      </div>
    </PopUp>
  );
}
