const getValueFromPropertyPath = (object, path = "") => {
  if (!object) return object;
  const pathArray = path.replace(" ", "").split(".");
  let nextObject = object;
  for (let index = 0; index < pathArray.length; index++) {
    nextObject = nextObject?.[pathArray?.[index]];
    if (nextObject === undefined) break;
  }
  return nextObject;
};

module.exports = getValueFromPropertyPath