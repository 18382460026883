import React, { useEffect, useState } from "react";
import "./MentorDashboard.scss";
import {
  ButtonCard,
  ButtonAdd,
  MentorThumbnail,
  Track,
  ButtonDefault,
  Section,
  H3,
  Screen,
} from "../../../components";
import { useHistory } from "react-router";
import withAuthorizationComponent from "../../../components/Auth/WithAuthorization/WithAuthorizationComponent";
import { useUsers, useCareers, useAuth } from "../../../Providers";
import getProfileCompleteness from "../../../utilities/getProfileCompleteness";
import { Link } from "react-router-dom";

function MentorDashboard() {
  const { authUserDetails } = useAuth();

  const { getAllUsers } = useUsers();

  const { getCareers } = useCareers();

  const [careers, setCareers] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (authUserDetails && !careers.length) {
      getCareers(authUserDetails.careers).then((careers) => {
        setCareers(Object.values(careers));
      });
    }
  }, [authUserDetails]);

  const profileCompleteStatus = getProfileCompleteness(authUserDetails, [
    "job",
    "name",
    "bio",
    "careers",
    "location.country",
  ]);

  return (
    <Screen>
      {authUserDetails ? (
        <>
          <Section.Container className="card">
            <H3 bold={false}>
              WELCOME,{" "}
              <Link to="/settings">
                <span className="color-primary">{authUserDetails.name}!</span>
              </Link>
            </H3>
            <p className="paragraph u-margin-bottom-medium">
              {profileCompleteStatus.length < 5
                ? "We would like for you to complete your profile before you go to the Mentorship Portal."
                : "You’re free to browse the resources or you can head to the Mentorship Portal. "}
            </p>
            <div className="text-center">
              <H3 align="center">
                {(profileCompleteStatus.length * 100) / 5}%
              </H3>
              <div className="u-margin-bottom-medium">
                <Track progress={(profileCompleteStatus.length * 100) / 5} />
              </div>
              <ButtonDefault
                onClick={() =>
                  history.push(
                    profileCompleteStatus.length < 5
                      ? "/settings/account"
                      : "/mentorship/dashboard"
                  )
                }
              >
                {profileCompleteStatus.length < 5
                  ? "Complete profile"
                  : "Go to Mentorship Portal"}
              </ButtonDefault>
            </div>
          </Section.Container>
          <Section.Container
            className="mentorDashboardMetrics"
            title="My Metrics"
          ></Section.Container>
          <Section.Container
            className="card borderRadius-medium mentorDashboardCareers"
            title="My Careers"
            viewMore
          >
            <div className="mentorDashboardCareers__grid">
              {careers.map((career, index) => {
                return (
                  <ButtonCard
                    key={index}
                    onClick={() => {
                      history.push(`/careers/${career.id}`);
                    }}
                  >
                    {career.name}
                  </ButtonCard>
                );
              })}
              <ButtonCard>
                <ButtonAdd></ButtonAdd>
              </ButtonCard>
            </div>
          </Section.Container>
          <Section.Container
            className="mentorDashboardArticles"
            title="My Articles"
            viewMore
          >
            <div className="mentorDashboardArticles__grid">
              <ButtonCard>Hello</ButtonCard>
              <ButtonCard>Hello</ButtonCard>
              <ButtonCard>Hello</ButtonCard>
              <ButtonCard>Hello</ButtonCard>
              <ButtonCard>Hello</ButtonCard>
              <ButtonCard>
                <ButtonAdd></ButtonAdd>
              </ButtonCard>
            </div>
          </Section.Container>
          <Section.Container
            className="card borderRadius-medium mentorDashboardStudents"
            viewMore
            title="My Students"
          >
            <div className="mentorDashboardStudents__grid">
              {getAllUsers()
                .slice(0, 5)
                .map((mentor, index) => {
                  return (
                    <MentorThumbnail
                      mentor={mentor}
                      key={index}
                    ></MentorThumbnail>
                  );
                })}
            </div>
          </Section.Container>
        </>
      ) : null}
    </Screen>
  );
}

export default withAuthorizationComponent(
  (auth) => auth,
  "/"
)(
  withAuthorizationComponent(
    (auth, authUserDetails) =>
      (authUserDetails && authUserDetails.mentor) || !authUserDetails,
    "/home"
  )(MentorDashboard)
);
