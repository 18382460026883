import React, { useState, useEffect } from "react";
import { VideoThumbnail,  H1, H2, Screen, Section } from "../../../components";
import { useAuth } from "../../../Providers";
import { Firebase } from "../../../apis";
import WebNav from "../../../components/Layout/Nav/NavBar/WebNav";
import { makeStyles } from "@material-ui/core/styles";
import WebVideoThumbnail from "../../../components/Video/WebVideoThumbnail/WebVideoThumbnail";
import Skeleton from "@material-ui/lab/Skeleton";
import {getVideosByListOfIdsFromGraphQL} from "../../../azureGraphQL/videoQuery";
import withAuthorizationComponent from "../../../components/Auth/WithAuthorization/WithAuthorizationComponent";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--color-background-default)",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      // gridTemplateColumns: "repeat(1, 1fr)",
      paddingLeft: "290px",
    },
  },
}));

export function LikeVideosScreen() {
  const [videoSaves, setVideoSaves] = useState([]);

  const { authUserDetails } = useAuth();

  const { root } = useStyles();


  useEffect(() => {
    if (authUserDetails) {
      if (authUserDetails.likes) {
        if (authUserDetails.likes.videos.length > 0) {
          getVideosByListOfIdsFromGraphQL(authUserDetails.likes.videos).then((fetchedVideos) => {
            setVideoSaves(fetchedVideos);
          });

        }
      } else {
        setVideoSaves([]);
      }
    }
  });



  return (
    <>
    <WebNav />
    <Screen className={root}>
      <div style={{ flex: 1 }}>
        <Section.Container style={{ paddingBottom: 0, margin: 0 }}>
          <H2 bold={true} style={{ marginBottom: 0 }}>
            Videos
          </H2>
        </Section.Container>
        <Section.Container containerClassName="videosListScreenVideos">
          {!videoSaves.length
            ? Array.from(Array(6).keys()).map(() => {
                return (
                  <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={"20rem"}
                    animation="wave"
                    style={{ borderRadius: 10 }}
                  />
                );
              })
            : videoSaves.map((video) => {
                return <WebVideoThumbnail video={video}/>
              })}
        </Section.Container>
      </div>
    </Screen>
  </>
  );
}

export default withAuthorizationComponent((auth) => auth)(LikeVideosScreen);