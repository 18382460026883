import React, { createContext, useState, useEffect, useContext } from "react";
import { pushToObject } from "../../helpers";
import {
  getAllCareersFromGraphQL,
  getCareerByIdFromGraphQL,
  getCareersByListOfIdsFromGraphQL,
} from "../../azureGraphQL/careerQuery";
import { Firebase } from "../../apis";
export const CareersContext = createContext();

export const useCareers = () => useContext(CareersContext);

export default function CareersProvider({ children }) {
  useEffect(() => {
    return Firebase.auth.onAuthStateChanged(async (auth) => {
      if (auth) {
        initializeCareers();
      }
    });
  }, []);

  /*   const initializeCareers = async () => {
    const result = await Firebase.getCollection(Firebase.collections.careers);
    if (result) updateCareersStore(result);
    return result;
  }; */

  const initializeCareers = async () => {
    const data = await getAllCareersFromGraphQL();
    if (data) updateCareersStore(data);
    return data;
  };

  const [careers, setCareers] = useState({});

  const updateCareersStore = (newCareers) =>
    setCareers(pushToObject({}, newCareers));

  const addCareerToStore = (newCareer) =>
    setCareers((careers) => ({ ...careers, [newCareer.id]: newCareer }));

  const addCareersToStore = (newCareers) =>
    setCareers((careers) => pushToObject(careers, newCareers));

  /*
  
   const refreshCareersStore = async () => {
    const careerListSnapshot = await Firebase.getList(
      Firebase.collections.careers
    )
      .where("id", "in", Object.keys(careers))
      .get();
    const careers = Firebase.snapshotArrayToDataArray(careerListSnapshot);
    if (careers) setCareers(pushToObject({}, careers));
  }; 
  */

  const getCareer = async (id) => {
    if (careers[id]) return careers[id];
    //const result = await Firebase.get(Firebase.collections.careers, id);
    const result = await getCareerByIdFromGraphQL(id);

    addCareerToStore(result);
    return result;
  };

  const getCareers = async (arrayOfIds) => {
    const returnedCareers = [];
    const unStoredCareers = [];
    arrayOfIds.forEach((id) => {
      const career = careers[id];
      if (career) returnedCareers.push(career);
      else unStoredCareers.push(id);
    });
    if (!unStoredCareers.length) return returnedCareers;
    else {
      /*
      const result = await Firebase.store
        .collection(Firebase.collections.careers)
        .where("id", "in", unStoredCareers)
        .get();
      result.docs.forEach((data) => {
        console.log(data.data());
      });
      const resultData = Firebase.snapshotArrayToDataArray(result);
      */
      const resultData = await getCareersByListOfIdsFromGraphQL(
        unStoredCareers
      );
      if (resultData) {
        returnedCareers.push(...resultData);
        addCareersToStore(resultData);
      }
      return returnedCareers;
    }
  };

  const refreshCareer = async (id) => {
    const result = await getCareerByIdFromGraphQL(id);
    //const result = await Firebase.get(Firebase.collections.careers, id);
    addCareerToStore(result);
    return result;
  };

  // const getAllCareers = () => {
  //   return Object.values(careers);
  // };

  const getAllCareers = async () => {
    const data = await getAllCareersFromGraphQL();
    return Object.values(data);
  };

  return (
    <CareersContext.Provider
      value={{
        updateCareersStore,
        addCareerToStore,
        addCareersToStore,
        //refreshCareersStore,
        refreshCareer,
        getCareer,
        getCareers,
        getAllCareers,
        initializeCareers,
        careers,
      }}
    >
      {children}
    </CareersContext.Provider>
  );
}
