import React, { useState, useEffect } from "react";
import "./SettingsHistory.scss";
import { useStatus, useVideos, useAuth } from "../../../../Providers";
import { VideoThumbnail, ButtonDefault } from "../../../../components";
import { statusTypes } from "../../../../constants";
import { Firebase } from "../../../../apis";
import { upsertUserFromGraphQL } from "../../../../azureGraphQL/userMutation";


export default function SettingsHistory() {
  const [isLoading, setIsLoading] = useState(false);

  const { authUser, authUserDetails } = useAuth();

  const { getVideos } = useVideos();

  const { changeStatus } = useStatus();

  const [videoHistory, setVideoHistory] = useState([]);

  useEffect(() => {
    getVideos(authUserDetails.history.videos).then(setVideoHistory);
  }, []);

  const clearHistory = (type) => {
    const historyPath = `history.${type}`;

    setIsLoading(true);
    upsertUserFromGraphQL({
      id: authUser.uid,
      rowKey: authUser.uid,
      partitionKey: "users",
      [historyPath]: []
    });
    Firebase.user(authUser.uid)
      .update({ [historyPath]: [] })
      .then(() => {
        changeStatus(
          {
            message: "Your settings have been updated",
          },
          statusTypes.success
        );
      })
      .catch((err) => {
        changeStatus(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="section settingsHistory settingsScreen__section">
      <h2 className="section__title heading-tertiary">Video History</h2>
      <div className="settingsHistory__grid">
        {videoHistory.map((video, index) => {
          return <VideoThumbnail video={video} key={index}></VideoThumbnail>;
        })}
      </div>
      <ButtonDefault
        onClick={() => {
          clearHistory("videos");
        }}
        type="submit"
        isLoading={isLoading}
      >
        Clear History
      </ButtonDefault>
    </div>
  );
}
