import React, { useEffect, useState } from "react";
import "./SignUpCareers.scss";
import { ButtonDefault } from "../../../../components";
import { useHistory } from "react-router";
import { removeValueFromArray } from "../../../../helpers";
import { careerInterests } from "../../../../testData";
import { useStatus, useAuth, useCareers } from "../../../../Providers";
import { FormBoxInput } from "../../../../components";
import { statusTypes } from "../../../../constants";
import { Firebase } from "../../../../apis";
import { upsertUserFromGraphQL } from "../../../../azureGraphQL/userMutation";


export default function SignUpCareers() {
  const [selectedCareers, setSelectedCareers] = useState([]);

  const { changeStatus } = useStatus();

  const { setListenForAuthUserDetails, authUser } = useAuth();
  const history = useHistory();

  const { initializeCareers } = useCareers();

  useEffect(() => {
    initializeCareers();
  }, []);

  const minSelected = 2,
    maxSelected = 8;

  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      selectedCareers.length >= minSelected &&
      selectedCareers.length <= maxSelected
    ) {
      upsertUserFromGraphQL({
        id: authUser.uid,
        rowKey: authUser.uid,
        partitionKey: "users",
        interests:{
          "careers": selectedCareers
        }
      });
      await Firebase.user(authUser.uid).update({
        "interests.careers": selectedCareers,
      });
      const careersToJoin = [];
      const careers = await Firebase.getCollection("careers");

      careers.forEach((career) => {
        if (
          selectedCareers.some(
            (careerInterest) =>
              career.tags && career.tags.includes(careerInterest)
          )
        ) {
          careersToJoin.push(career.id);
        }
      });
      upsertUserFromGraphQL({
        id: authUser.uid,
        rowKey: authUser.uid,
        partitionKey: "users",
        joinedCareers: careersToJoin
      });
      Firebase.user(authUser.uid)
        .update({ joinedCareers: careersToJoin })
        .then(() => {
          setListenForAuthUserDetails(true);
          history.push("/home");
        });
    } else {
      if (selectedCareers.length <= minSelected)
        changeStatus(
          {
            message:
              "You have selected too few options. Please select between 1 and 8 options",
          },
          statusTypes.error
        );
      else
        changeStatus(
          {
            message:
              "You have selected too many options. Please select between 1 and 8 options.",
          },
          statusTypes.error
        );
    }
  };

  const careerClickHandler = (e) => {
    const newSelectedCareers = [...selectedCareers];
    const element = e.target;
    if (!newSelectedCareers.includes(element.value)) {
      newSelectedCareers.push(e.target.value);
      setSelectedCareers(newSelectedCareers);
    } else {
      setSelectedCareers(
        removeValueFromArray(
          newSelectedCareers,
          (item) => element.value === item
        )
      );
    }
  };

  return (
    <>
      <form className="signUpCareers__form" onSubmit={submitHandler}>
        <h1 className="heading-tertiary signUpCareers__title">
          Which of the Careers interest you?
        </h1>
        <div className="signUpCareers__grid">
          {careerInterests.map((interest, index) => (
            <div className="signUpCareers__input">
              <FormBoxInput
                label={interest}
                value={interest}
                key={index}
                onClick={careerClickHandler}
              ></FormBoxInput>
            </div>
          ))}
        </div>
        <p className="signUpCareers__counter">{`${selectedCareers.length}/${maxSelected}`}</p>
        <div className="signUpCareers__submit">
          <ButtonDefault>Proceed &rarr;</ButtonDefault>
        </div>
      </form>
    </>
  );
}
