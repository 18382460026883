import React from "react";
import "./ButtonAdd.scss";

export default function ButtonAdd() {
  return (
    <div className="buttonAdd">
      <div className="buttonAdd__circle">
        <div className="buttonAdd__plus">+</div>
      </div>
    </div>
  );
}
