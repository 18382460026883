import React from "react";
import "./StarSvg.scss";

export default function StarSvg({ type, ...props }) {
  const colors = {
    bronze: "#cd7f32",
    silver: "#c0c0c0",
    gold: "#ffd700",
  };

  return (
    <svg
      className="starSvg"
      width={props.width||'100%'}
      height={props.width}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.9999 17.8995L16.1874 21.037C17.1374 21.612 18.2999 20.762 18.0499 19.687L16.6749 13.787L21.2624 9.81196C22.0999 9.08696 21.6499 7.71196 20.5499 7.62446L14.5124 7.11196L12.1499 1.53696C11.7249 0.524463 10.2749 0.524463 9.84991 1.53696L7.48741 7.09946L1.44991 7.61196C0.349908 7.69946 -0.100092 9.07446 0.737408 9.79946L5.32491 13.7745L3.94991 19.6745C3.69991 20.7495 4.86241 21.5995 5.81241 21.0245L10.9999 17.8995Z"
        fill={colors[type]}
      />
    </svg>
  );
}
