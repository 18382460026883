import React, { createContext, useState, useContext } from "react";
import { tasks } from "../../testData";
import { taskTypes } from "../../constants";
import TaskComplete from "../../components/Tasks/TaskComplete/TaskComplete";
import { Firebase } from "../../apis";
import { upsertUserFromGraphQL } from "../../azureGraphQL/userMutation";
import { getUserByIdFromGraphQL } from "../../azureGraphQL/userQuery";
import { useAuth } from "../AuthUser/AuthUserProvider";

export const TaskableContext = createContext();

export const useTask = () => useContext(TaskableContext);

export default function TaskableProvider({ children }) {
  const [taskComplete, setTaskComplete] = useState(null);

  const { authUserDetails, authUser, updateAuthUserDetails } = useAuth();

  const taskableHandler = (type, user, quantity = 1, stateUpdate) => {
    const newUser = { ...user };
    const taskIndex = getUserTaskIndex(type, user);
    if (!taskIndex && taskIndex !== 0) return newUser;
    const task = newUser.runningTasks[taskIndex];
    if (!shouldUpdateUserTask(task, quantity, stateUpdate)) return newUser;
    const updatedTask = updateUserTask(task, quantity, stateUpdate);
    const updatedUser = updateUserRunningTasks(updatedTask, newUser, taskIndex);
    const leveledUpUser = shouldLevelUp(updatedUser)
      ? levelUpUser(user)
      : updatedUser;
    return upsertUserFromGraphQL({
      id: leveledUpUser.id,
      rowKey: leveledUpUser.id,
      partitionKey: "users",
      level: leveledUpUser.level,
      runningTasks: leveledUpUser.runningTasks,
      completedTasks: leveledUpUser.completedTasks,
      points: leveledUpUser.points
    }).then((res) => {
      console.log(res);
      return updateAuthUserDetails(leveledUpUser);
    });
  };

  const completedTaskHandler = async (task, user, callback) => {
    setTaskComplete(
      <TaskComplete
        task={task}
        setTaskComplete={setTaskComplete}
      ></TaskComplete>
    );
  };

  const getUserTaskIndex = (taskType, user, id) => {
    if (id) {
      const index = user.runningTasks?.findIndex((value) => value.id === id);
      return index !== -1 ? index : null;
    } else {
      const index = user.runningTasks?.findIndex(
        (value) => value.type === taskType
      );
      return index !== -1 ? index : null;
    }
  };

  const getUserTask = (taskType, user, id) => {
    const index = getUserTaskIndex(taskType, user, id);
    if (index) {
      return user.runningTasks[index];
    }
    return null;
  };

  const updateUserTask = (task, quantity = 1, stateUpdate) => ({
    ...task,
    progress: (task.progress && task.progress + quantity) || quantity,
    state: updateUserTaskState(task, stateUpdate),
  });

  const shouldUpdateUserTask = (task, quantity = 1, stateUpdate) => {
    const { state, type } = task;
    switch (type) {
      case taskTypes.readNumberCareers:
        if (state && state.history)
          return state.history.find((value) => value === stateUpdate)
            ? false
            : true;
        else return true;
      case taskTypes.readNumberMentors:
        if (state && state.history)
          return state.history.find((value) => value === stateUpdate)
            ? false
            : true;
        else return true;
      case taskTypes.readNumberDegrees:
        if (state && state.history)
          return state.history.find((value) => value === stateUpdate)
            ? false
            : true;
        else return true;
      case taskTypes.watchNumberVideos:
        if (state && state.history)
          return state.history.find((value) => value === stateUpdate)
            ? false
            : true;
        else return true;
      default:
        return true;
    }
  };

  const updateUserTaskState = (task, stateUpdate) => {
    const { state, type } = task;
    switch (type) {
      case taskTypes.readNumberCareers:
        return {
          ...state,
          history: state ? [...state.history, stateUpdate] : [stateUpdate],
        };
      case taskTypes.readNumberMentors:
        return {
          ...state,
          history: state ? [...state.history, stateUpdate] : [stateUpdate],
        };
      case taskTypes.readNumberDegrees:
        return {
          ...state,
          history: state ? [...state.history, stateUpdate] : [stateUpdate],
        };
      case taskTypes.watchNumberVideos:
        return {
          ...state,
          history: state ? [...state.history, stateUpdate] : [stateUpdate],
        };
      default:
        return { ...state };
    }
  };

  const updateUserRunningTasks = (task, user, taskIndex) => {
    const index =
      taskIndex === 0 ? 0 : taskIndex || getUserTaskIndex(task.type, user);
    const newUser = { ...user };
    if (task.progress >= task.amount) {
      completedTaskHandler(task);
      newUser.points += task.points;
      newUser.completedTasks.push(task);
      const nextTask = tasks.find(
        (newTask) =>
          task.type === newTask.type && newTask.step === task.step + 1
      );
      if (nextTask) newUser.runningTasks.push(nextTask);
      newUser.runningTasks.splice(index, 1);
    } else {
      newUser.runningTasks[index] = task;
    }
    return newUser;
  };

  const shouldLevelUp = (user) => (user.runningTasks.length ? false : true);

  const levelUpUser = (user) => ({
    ...user,
    level: user.level + 1,
    runningTasks: getCurrentTasks({ ...user, level: user.level + 1 }),
  });

  const getCurrentTasks = (authUserDetails) => {
    console.log(authUserDetails);
    const { runningTasks, completedTasks, level } = authUserDetails;
    const completedTasksIds = completedTasks.map((task) => task.id);
    const allTasks = tasks;
    const currentTasks = [];
    allTasks.forEach((task) => {
      if (completedTasksIds.includes(task.id)) return;
      const currentlyRunningTask = runningTasks.find(
        (runningTask) => runningTask.id === task.id
      );
      if (currentlyRunningTask) return currentTasks.push(currentlyRunningTask);
      if (task.step > 1 || task.level > level) return;
      currentTasks.push(task);
    });

    return currentTasks;
  };

  return (
    <TaskableContext.Provider value={{ taskableHandler }}>
      {taskComplete}
      {children}
    </TaskableContext.Provider>
  );
}
