import React from "react";
import { Link } from "react-router-dom";
import "./BlogCard.scss";

const BlogCard = ({ id, title, author, imageUrl }) => {
    return (
        <Link to={`/blogs/${id}`} className="blog-card__container">
            <div className="blog-card">
                <h3 className="blog-card__title">{title}</h3>
                <hr className="blog-card__hr"></hr>
                <div className="blog-card__author-section">
                    <img
                        src={author.image}
                        alt={author.name}
                        className="blog-card__author-pfp"
                    />
                    <div>
                        <div className="blog-card__author-name">
                            {author.name}
                        </div>
                        <div className="blog-card__author-career">
                            {author.career} @ {author.company}
                        </div>
                    </div>
                </div>
            </div>
            <img
                className="blog-card__img"
                src={`https://blog.risingstar.blackmint.io${imageUrl}`}
            />
        </Link>
    );
};

export default BlogCard;
