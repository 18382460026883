import { makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import {
  TaskTracker,
  ButtonDefault,
  MentorThumbnail,
  VideoThumbnail,
  HelpTag,
  Screen,
  H2,
  Section,
  Track,
  H3,
} from "../../../components";
import { useCareers, useDegrees } from "../../../Providers";
import DegreeCard from "../../../components/Degree/DegreeCard";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "5rem",
    paddingTop: 0,
  },
  grid: {
    display: "grid",
    gap: "3rem",
    marginTop: "5rem",
    gridTemplateColumns: "repeat(2, 1fr)",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
}));

export default function TechPrograms({}) {
  const { grid, container } = useStyles();

  const { getAllDegrees } = useDegrees();

  const history = useHistory();

  return (
    <Section.Container
      title={
        <>
          Explore Degree Programs{" "}
          <HelpTag message="Choosing the right program is a huge decision! Have a look at our tech degree guide to learn more about the different schools and requirements." />
        </>
      }
      // style={{backgroundColor: 'green'}}
      className={container}
      viewMore
      onViewMore={() => {
        history.push(`/degrees`);
      }}
    >
      <div className={grid}>
        {getAllDegrees()
          .filter((degree) => degree.image)
          .slice(0, 6)
          .map((degree, index) => {
            return (
              <DegreeCard
                degreeName={degree.name}
                linkTo={"/degrees/" + degree.id}
                imgLink={degree.image}
              />
            );
          })}
      </div>
    </Section.Container>
  );
}
