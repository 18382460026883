import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { blackmintTheme } from "../../../style/theme";

const useStyles = makeStyles((theme) => ({
  text: {
    ...blackmintTheme.typographyBlackmint.heading,
    fontSize: "4rem",
    letterSpacing: ".2rem",
    fontFamily: 'Inter',
    fontWeight: "700",
    marginBottom: '3rem',
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.5rem",
    },
  },
}));

export default function H2({
  children,
  text,
  bold = true,
  align = "left",
  style,
  className = ''
}) {
  const styles = useStyles();
  return (
    <h1
    className={clsx(styles.text, className)}
      style={{
        fontWeight: bold ? "700" : "normal",
        textAlign: align,
        ...style,
      }}
    >
      {children || text}
    </h1>
  );
}
