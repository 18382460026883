import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import {
  TaskTracker,
  ButtonDefault,
  MentorThumbnail,
  VideoThumbnail,
  HelpTag,
  Screen,
  H2,
  Section,
  Track,
  H3,
} from "../../components";
import { useCareers, useDegrees } from "../../Providers";
import WebCareerCard from "../../components/Career/WebCareerCard";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "5rem",
    paddingTop: 0,
  },
  grid: {
    display: "grid",
    gap: "3rem",
    marginTop: "5rem",
    gridTemplateColumns: "repeat(2, 1fr)",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
}));

const WebCareers = ({ careerName, linkTo, imgLink }) => {
  const { grid, container } = useStyles();

  const [schoolImage, setSchoolImage] = useState(null);

  const history = useHistory();
  const { getAllCareers } = useCareers();
  const [sortedCareers, setSortedCareers] = useState([]);

  useEffect(() => {
    getAllCareers().then(async (data) => {setSortedCareers(data);});
  },[]);

  return (
    <Section.Container
      title={
        <>
          Explore Careers{" "}
          <HelpTag message="Curious about life after high school? Get a head start and learn about the many tech careers that you can potentially do!" />
        </>
      }
      // style={{backgroundColor: 'green'}}
      className={container}
      viewMore
      onViewMore={() => {
        history.push(`/careers`);
      }}
    >
      <div className={grid}>
        {sortedCareers
          .filter((career) => career.description)
          .slice(0, 4)
          .map((career) => {
            return (
              <WebCareerCard
                careerName={career.name}
                linkTo={`/careers/${career.id}`}
                imgLink={career.image}
              />
            );
          })}
      </div>
    </Section.Container>
  );
};
export default WebCareers;
