import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import WebNav from "../../../components/Layout/Nav/NavBar/WebNav";
import { useBlogs } from "../../../Providers/BlogsProvider/BlogsProvider";
import "./BlogPostScreen.scss";
import ReactMarkdown from "react-markdown";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Providers";
import BlogComment from "../../../components/Blogs/BlogComment/BlogComment";
import Modal from "react-modal";

const BlogPostScreen = (props) => {
    const [blog, setBlog] = useState(undefined);
    const [liked, setLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [comment, setComment] = useState("");
    const { authUserDetails } = useAuth();
    const params = useParams();
    const { getBlogPost } = useBlogs();
    const history = useHistory();
    const [showPopup, setShowPopup] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [isComment, setIsComment] = useState(null);

    useEffect(() => {
        (async () => {
            const blogRes = await getBlogPost(params.id);
            setBlog(blogRes);
        })();
    }, []);

    useEffect(() => {
        if (
            blog &&
            authUserDetails &&
            blog.likedBy.includes(authUserDetails.id)
        )
            setLiked(true);
        if (blog && blog.likedBy.length) {
            setLikeCount(blog.likedBy.length);
        }
    }, [blog]);

    const likePost = async () => {
        const likedBy = [...blog.likedBy];

        const sendLikeReq = async () => {
            try {
                const likeReq = await fetch(
                    `https://blog.risingstar.blackmint.io/blogs/${blog.id}`,
                    {
                        method: "PUT",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            likedBy,
                        }),
                    }
                );

                if (likeReq.status !== 200) {
                    throw new Error("Something went wrong");
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (authUserDetails) {
            if (!liked) {
                likedBy.push(authUserDetails.id);
                await sendLikeReq();
                setLiked(true);
                setLikeCount(likeCount + 1);
            } else {
                const likedIndex = likedBy.indexOf(authUserDetails.id);
                likedBy.splice(likedIndex, 1);
                await sendLikeReq();
                setLiked(false);
                setLikeCount(likeCount - 1);
            }
        } else {
            history.push("/signup");
        }
    };

    const onDeleteComment = async (id) => {
        blog.comments = blog.comments.filter((comment) => comment.id !== id);
        await updateCommentFetch();
        setSelectedId(null);
    };

    const deleteCommentHandler = async (id) => {
        setIsComment(true);
        setSelectedId(id);
        setShowPopup(true);
    };

    const deleteReplyHandler = async (id) => {
        setIsComment(false);
        setSelectedId(id);
        setShowPopup(true);
    };

    const updateCommentFetch = async () => {
        try {
            const blogReq = await fetch(
                `https://blog.risingstar.blackmint.io/blogs/${blog.id}`,
                {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        comments: blog.comments,
                    }),
                }
            );

            const blogRes = await blogReq;
            if (blogRes.status === 200) {
                const blogRes = await getBlogPost(params.id);
                setBlog(blogRes);
            }
        } catch (err) {
            console.err(err);
        }
    };

    const submitComment = async (text) => {
        try {
            const allComments = blog.comments;
            allComments.push({
                text,
                date: new Date(),
                user: authUserDetails,
                id: uuidv4(),
                replies: [],
                edited: false,
            });

            await updateCommentFetch();
            setComment("");
        } catch (err) {
            console.err(err);
        }
    };

    return (
        <div className="blog-post--background">
            <Modal
                ariaHideApp={false}
                isOpen={showPopup}
                contentLabel={`Are you sure you want to delete your ${
                    isComment ? "comment" : "reply"
                }?`}
            >
                <h3 className="blog-post__popup-warning">
                    Are you sure you want to delete your{" "}
                    {isComment ? "comment" : "reply"}?
                </h3>
                <div className="blog-post__popup-message">
                    Your blog post will be permanently deleted and will not
                    appear on the platform
                </div>
                <div className="blog-post__popup-options">
                    <div
                        onClick={() => {
                            setShowPopup(false);
                            onDeleteComment(selectedId);
                        }}
                    >
                        DELETE
                    </div>
                    <div
                        onClick={() => {
                            setShowPopup(false);
                            setSelectedId(null);
                        }}
                    >
                        GO BACK
                    </div>
                </div>
            </Modal>
            <WebNav />
            <div className="blog-post">
                {!blog ? (
                    <div className="blog-post__error">
                        <h1 className="blog-post__error-message">
                            Something went wrong with getting the blog post
                        </h1>
                    </div>
                ) : (
                    <article>
                        <div className="blog-post__header">Blog Posts</div>
                        <div className="blog-post__container">
                            <div className="blog-post__image-container">
                                <img
                                    className="blog-post__image"
                                    src={`https://blog.risingstar.blackmint.io${blog.image.url}`}
                                />
                            </div>
                            <div className="blog-post__title">
                                <h1>{blog.title}</h1>
                            </div>
                            <div className="blog-post__section blog-post__author">
                                <div className="blog-post__author-written-by">
                                    Written By:
                                    <Link to={`/mentor/${blog.user.id}`}>
                                        <div className="blog-post__author-card">
                                            <img
                                                className="blog-post__author-image"
                                                src={blog.user.image}
                                            />
                                            <div>
                                                <h3>{blog.user.name}</h3>
                                                <div>
                                                    {blog.user.career} @{" "}
                                                    {blog.user.company}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="blog-post__section">
                                <div>
                                    {/* <BookmarkBorderIcon
                                            style={{ fontSize: 50 }}
                                        /> */}
                                    <div
                                        className="blog-post__likes-section"
                                        onClick={() => likePost()}
                                    >
                                        {liked ? (
                                            <FavoriteIcon
                                                style={{
                                                    fontSize: 50,
                                                    color: "#4DC591",
                                                }}
                                            />
                                        ) : (
                                            <FavoriteBorderIcon
                                                style={{ fontSize: 50 }}
                                            />
                                        )}
                                        {likeCount ? (
                                            <span>
                                                {likeCount} like
                                                {likeCount > 1 ? "s" : ""}
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="blog-post__section blog-post__sub-title">
                                <p>{blog.subTitle}</p>
                            </div>
                            <summary className="blog-post__section blog-post__text">
                                <ReactMarkdown>{blog.text}</ReactMarkdown>
                            </summary>
                            <div className="blog-post__section blog-post__title">
                                <h2>Related Careers</h2>
                                <div className="blog-post__career">
                                    {blog.careers.map((career) => (
                                        <Link
                                            className="blog-post__career-item"
                                            key={career.value}
                                            to={
                                                career.value !== "#"
                                                    ? `/careers/${career.value}`
                                                    : "#"
                                            }
                                        >
                                            {career.label}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                            <div className="blog-post__section blog-post__title">
                                <h2>Comments</h2>
                                <input
                                    className="blog-post__comment-input"
                                    placeholder="Your comment..."
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                                <div className="blog-post__comment-buttons">
                                    <button
                                        disabled={!(authUserDetails && comment)}
                                        tye="button"
                                        className="blog-post__comment-submit"
                                        onClick={() => submitComment(comment)}
                                    >
                                        SUBMIT
                                    </button>
                                    <div
                                        className="blog-post__comment-cancel"
                                        onClick={() => setComment("")}
                                    >
                                        CANCEL
                                    </div>
                                </div>
                                <div className="blog-comments__list">
                                    {blog.comments.map((comment) => (
                                        <BlogComment
                                            comment={comment}
                                            updateComment={updateCommentFetch}
                                            key={comment.id}
                                            deleteCommentHandler={
                                                deleteCommentHandler
                                            }
                                            deleteReplyHandler={
                                                deleteReplyHandler
                                            }
                                            replyIdToDelete={
                                                !isComment && selectedId
                                            }
                                        />
                                    ))}
                                </div>{" "}
                            </div>
                        </div>
                    </article>
                )}
            </div>
        </div>
    );
};

export default BlogPostScreen;
