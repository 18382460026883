const queryFetch = async (query, variables) =>{
    return fetch('https://risingstarplatform-dataservice.azurewebsites.net/api/risingstargql', {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: query,
      variables: variables
    })
  }).then(res => res.json())
    .catch(err => {
    console.log("error:", err);
  });
}

export default queryFetch;