import React, { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import { getIsMentee } from "../../../azureGraphQL/userQuery";

const BlogReply = ({
  reply,
  currentUser,
  deleteReplyHandler,
  updateComment,
}) => {
  const [isReplierMentee, setIsReplierMentee] = useState(true);
  const [hover, setHover] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newReply, setNewReply] = useState(reply.text);

  reply.date = new Date(reply.date);

  useEffect(() => {
    (async () => {
      const val = await getIsMentee(reply.user.id);
      setIsReplierMentee(val);
    })();
  }, []);

  return (
    <div
      className="blog-comments__reply"
      onMouseEnter={() => {
        if (reply.user.id === currentUser.id) setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
    >
      <div className="blog-comments__comment-metadata--format">
        <div className="blog-comments__comment-user">{reply.user.name}</div>
        {!isReplierMentee && (
          <div className="blog-comments__comment-mentor_tag">Mentor</div>
        )}
        <div className="blog-comments__comment-date">
          {reply.date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}
        </div>
        {reply.edited && <div>Edited</div>}
        {hover && (
          <div className="blog-comments__buttons">
            <IconButton onClick={() => setEditMode(!editMode)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => deleteReplyHandler(reply.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
      {!editMode ? (
        <div>{reply.text}</div>
      ) : (
        <div className="blog-comments__edit-flex">
          <textarea
            className="blog-comments__comment-edit"
            value={newReply}
            onChange={(e) => setNewReply(e.target.value)}
          />
          <button
            className="blog-comments__comment-submit-edit"
            onClick={async () => {
              reply.text = newReply;
              await updateComment();
              setEditMode(false);
            }}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default BlogReply;
